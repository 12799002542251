import * as Types from "../types";

import gql from "graphql-tag";

export type EducationRecFieldsFragment = { __typename?: "ProfileEducation" } & Pick<
	Types.ProfileEducation,
	| "Id"
	| "RegionId"
	| "City"
	| "Type"
	| "EducationalInstitution"
	| "Specialization"
	| "Mode"
	| "StartYear"
	| "EndYear"
	| "IssueYear"
	| "ProfileId"
>;

export const EducationRecFieldsFragmentDoc = gql`
	fragment EducationRecFields on ProfileEducation {
		Id
		RegionId
		City
		Type
		EducationalInstitution
		Specialization
		Mode
		StartYear
		EndYear
		IssueYear
		ProfileId
	}
`;
