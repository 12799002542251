import { navigate, PageRendererProps } from "gatsby";
import React, { ReactNode, useEffect } from "react";
import Calendar from "../components/calendar";
import Layout from "../components/layout";
import Profile from "../components/profile";
import ProfileEdit from "../components/profileEdit/ProfileEdit";
import Statistic from "../components/profileEdit/Statistic";
import SEO from "../components/seo";
import { useQueryByHashQuery } from "../graphql/query/QueryByHash.generated";
import { EQueryType } from "../graphql/types";
import { parseQueryParams } from "../utils/utils";

interface IWrapperLayoutProps {
	title: string;
	children: ReactNode;
}

interface ISharePageProps extends PageRendererProps {
	"*": string;
}

const WrapperLayout = (props: IWrapperLayoutProps) => {
	return (
		<Layout>
			<SEO title={props.title} />
			<div className="container">{props.children}</div>
		</Layout>
	);
};

const SharePage: React.ComponentType<ISharePageProps> = (props) => {
	// Считаем все, что после /share/, хешем
	const hash = props["*"];
	const { data, loading } = useQueryByHashQuery({ variables: { hash: hash } });

	// Получаем параметры запроса в адресной строке
	const urlQueryParams = parseQueryParams(props.location.search);
	const isIframe = urlQueryParams.view === "iframe";

	const Wrapper = isIframe ? React.Fragment : WrapperLayout;

	// Если нет хеша или нет такого хеша в БД, переадресуем на главную
	useEffect(() => {
		if (!hash || data === null) {
			navigate("/");
		}
	}, [hash, data]);

	if (loading || !data?.QueryByHash) {
		return (
			<Wrapper title="Стань чемпионом!">
				<p>Идет загрузка данных...</p>
			</Wrapper>
		);
	}

	switch (data?.QueryByHash.QueryType) {
		case EQueryType.Calendar: {
			return (
				<Wrapper title="Календарь">
					<Calendar {...data?.QueryByHash} titleShow={!isIframe} />
				</Wrapper>
			);
		}
		case EQueryType.Profile: {
			return (
				<Wrapper title="Профиль">
					<Profile {...data?.QueryByHash} titleShow={!isIframe} />
				</Wrapper>
			);
		}
		case EQueryType.ProfileEdit: {
			return (
				<Wrapper title="Редактирование профиля">
					<ProfileEdit {...data?.QueryByHash} hash={hash} />
				</Wrapper>
			);
		}
		case EQueryType.Statistic: {
			return (
				<Wrapper title="Статистика">
					<Statistic hash={hash} />
				</Wrapper>
			);
		}
		default: {
			return (
				<Wrapper title="Стань чемпионом!">
					<p>Идет загрузка данных...</p>
				</Wrapper>
			);
		}
	}
};

export default SharePage;
