import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ProfilePlaceFullFragment } from "../fragment/ProfilePlace.generated";
import { ProfilePlaceFullFragmentDoc } from "../fragment/ProfilePlace.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Ui_ProfilePlacesQueryVariables = Types.Exact<{
	input?: Types.Maybe<Types.PlaceArgsInput>;
}>;

export type Ui_ProfilePlacesQuery = { __typename?: "Query" } & {
	Places: Array<{ __typename?: "ProfilePlace" } & ProfilePlaceFullFragment>;
};

export const Ui_ProfilePlacesDocument = gql`
	query UI_ProfilePlaces($input: PlaceArgsInput) {
		Places(input: $input) {
			...ProfilePlaceFull
		}
	}
	${ProfilePlaceFullFragmentDoc}
`;
export type Ui_ProfilePlacesComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>,
	"query"
>;

export const Ui_ProfilePlacesComponent = (props: Ui_ProfilePlacesComponentProps) => (
	<ApolloReactComponents.Query<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>
		query={Ui_ProfilePlacesDocument}
		{...props}
	/>
);

export type Ui_ProfilePlacesProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>;
} &
	TChildProps;
export function withUi_ProfilePlaces<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		Ui_ProfilePlacesQuery,
		Ui_ProfilePlacesQueryVariables,
		Ui_ProfilePlacesProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		Ui_ProfilePlacesQuery,
		Ui_ProfilePlacesQueryVariables,
		Ui_ProfilePlacesProps<TChildProps, TDataName>
	>(Ui_ProfilePlacesDocument, {
		alias: "uiProfilePlaces",
		...operationOptions
	});
}

/**
 * __useUi_ProfilePlacesQuery__
 *
 * To run a query within a React component, call `useUi_ProfilePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUi_ProfilePlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUi_ProfilePlacesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUi_ProfilePlacesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>
) {
	return ApolloReactHooks.useQuery<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>(
		Ui_ProfilePlacesDocument,
		baseOptions
	);
}
export function useUi_ProfilePlacesLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<Ui_ProfilePlacesQuery, Ui_ProfilePlacesQueryVariables>(
		Ui_ProfilePlacesDocument,
		baseOptions
	);
}
export type Ui_ProfilePlacesQueryHookResult = ReturnType<typeof useUi_ProfilePlacesQuery>;
export type Ui_ProfilePlacesLazyQueryHookResult = ReturnType<typeof useUi_ProfilePlacesLazyQuery>;
export type Ui_ProfilePlacesQueryResult = ApolloReactCommon.QueryResult<
	Ui_ProfilePlacesQuery,
	Ui_ProfilePlacesQueryVariables
>;
