import * as Types from "../types";

import gql from "graphql-tag";
import { AddressFullFragment } from "./Address.generated";
import { AddressFullFragmentDoc } from "./Address.generated";
import { AttachmentForGalleryFragment, AttachmentFullFragment, AttachmentLinkFragment } from "./Attachment.generated";
import {
	AttachmentForGalleryFragmentDoc,
	AttachmentFullFragmentDoc,
	AttachmentLinkFragmentDoc
} from "./Attachment.generated";
import { OrganizationForAvatarFragment, OrganizationOpenFragment } from "./Organization.generated";
import { OrganizationForAvatarFragmentDoc, OrganizationOpenFragmentDoc } from "./Organization.generated";
import { PersonForAvatarFragment, PersonOpenFragment } from "./Person.generated";
import { PersonForAvatarFragmentDoc, PersonOpenFragmentDoc } from "./Person.generated";
import { ProfileContactFullFragment } from "./ProfileContact.generated";
import { ProfileContactFullFragmentDoc } from "./ProfileContact.generated";

export type ProfileOpenFullFragment = { __typename?: "Profile" } & Pick<
	Types.Profile,
	"Id" | "ReviewsCount" | "CheckStatus"
> & {
		PersonOpen?: Types.Maybe<{ __typename?: "Person" } & PersonOpenFragment>;
		OrganizationOpen?: Types.Maybe<{ __typename?: "Organization" } & OrganizationOpenFragment>;
		AvatarAttachment?: Types.Maybe<{ __typename?: "Attachment" } & AttachmentLinkFragment>;
		Attachments: Array<{ __typename?: "Attachment" } & AttachmentForGalleryFragment>;
		Contacts: Array<{ __typename?: "ProfileContact" } & ProfileContactFullFragment>;
		Address?: Types.Maybe<{ __typename?: "Address" } & AddressFullFragment>;
	};

export type ProfileForAvatarFragment = { __typename?: "Profile" } & Pick<Types.Profile, "Id" | "CheckStatus"> & {
		PersonOpen?: Types.Maybe<{ __typename?: "Person" } & PersonForAvatarFragment>;
		OrganizationOpen?: Types.Maybe<{ __typename?: "Organization" } & OrganizationForAvatarFragment>;
		AvatarAttachment?: Types.Maybe<{ __typename?: "Attachment" } & AttachmentLinkFragment>;
	};

export const ProfileOpenFullFragmentDoc = gql`
	fragment ProfileOpenFull on Profile {
		Id
		ReviewsCount
		CheckStatus
		PersonOpen {
			...PersonOpen
		}
		OrganizationOpen {
			...OrganizationOpen
		}
		AvatarAttachment {
			...AttachmentLink
		}
		Attachments {
			...AttachmentForGallery
		}
		Contacts {
			...ProfileContactFull
		}
		Address {
			...AddressFull
		}
	}
	${PersonOpenFragmentDoc}
	${OrganizationOpenFragmentDoc}
	${AttachmentLinkFragmentDoc}
	${AttachmentForGalleryFragmentDoc}
	${ProfileContactFullFragmentDoc}
	${AddressFullFragmentDoc}
`;
export const ProfileForAvatarFragmentDoc = gql`
	fragment ProfileForAvatar on Profile {
		Id
		CheckStatus
		PersonOpen {
			...PersonForAvatar
		}
		OrganizationOpen {
			...OrganizationForAvatar
		}
		AvatarAttachment {
			...AttachmentLink
		}
	}
	${PersonForAvatarFragmentDoc}
	${OrganizationForAvatarFragmentDoc}
	${AttachmentLinkFragmentDoc}
`;
