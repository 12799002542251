import MomentUtils from "@date-io/moment";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import "moment/locale/ru";
import React, { useState } from "react";

moment.locale("ru");

interface ICheckboxDatapickerProps {
	date?: moment.Moment | null;
	onChange: (newDate: MaterialUiPickersDate, value?: string | null | undefined) => void;
	checkBoxLabel?: string;
	datapickerLabel?: string;
}

const CheckboxDatapicker = (props: ICheckboxDatapickerProps) => {
	const { date, onChange, checkBoxLabel = "Бессрочно", datapickerLabel = "Действительно до:" } = props;

	const [checked, setChecked] = useState(!date);

	const changeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		if (event.target.checked) {
			onChange(null);
		}
	};

	return (
		<>
			<FormControlLabel
				control={<Checkbox color="primary" checked={checked} onChange={changeChecked} />}
				label={checkBoxLabel}
			/>
			{!checked && (
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardDatePicker
						fullWidth
						disableToolbar
						format="DD.MM.YYYY"
						margin="dense"
						invalidDateMessage="Некорректный формат"
						label={datapickerLabel}
						value={date}
						onChange={onChange}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
			)}
		</>
	);
};

export default React.memo(CheckboxDatapicker);
