import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from "@material-ui/core";
import React, { Fragment } from "react";

export type TCustomStyleClasses = {
	rows?: {
		[key: number]: string;
	};
	columns?: {
		[key: number]: string;
	};
};

interface ICommonTableProps {
	title: string;
	headers: Array<string | number>;
	dataSet: Array<Array<number | string | undefined>>;
	customStyleClassesIndexes?: TCustomStyleClasses;
}

const CommonTable = ({ title, headers, dataSet, customStyleClassesIndexes }: ICommonTableProps) => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Fragment key={title}>
			<h2>{title}</h2>
			<TableContainer className="statistic-table" component={Paper}>
				<Table size="small">
					<TableHead className="header">
						<TableRow>
							{headers.map((item, index) => (
								<TableCell className="cell" key={index} align="center">
									{item}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody className="body">
						{dataSet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
							<TableRow className="row" key={index}>
								{row.map((item, cellIndex) => (
									<TableCell
										className={
											"cell " +
											(customStyleClassesIndexes?.columns?.[cellIndex] || "") +
											" " +
											(customStyleClassesIndexes?.rows?.[page * rowsPerPage + index] || "")
										}
										key={cellIndex}
										align="center"
									>
										{item}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					className="pagination"
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					labelRowsPerPage={"Отображать строк:"}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} из ${count !== -1 ? count : `больше ${to}`}`
					}
					count={dataSet.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</TableContainer>
		</Fragment>
	);
};

export default CommonTable;
