import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { OrganizationTypeList } from "../../../constants";
import {
	StatisticsOrganizationsFullFragment,
	StatisticsOrganizationsRowFragment
} from "../../../graphql/fragment/StatisticsOrganizationsFull.generated";
import { useStatisticsOrganizationsQuery } from "../../../graphql/query/StatisticsOrganizations.generated";
import { EOrganizationType } from "../../../graphql/types";
import CommonTable, { TCustomStyleClasses } from "../../common/CommonTable";

type TStatisticOrgsKey = keyof Omit<StatisticsOrganizationsRowFragment, "__typename">;
type TStatisticOrgsRowsKeys = keyof Omit<StatisticsOrganizationsFullFragment, "__typename">;

const statisticOrgsRowMap: Array<[TStatisticOrgsKey, string]> = [
	["ChildrenSchool", OrganizationTypeList[EOrganizationType.ChildrenSchool]],
	["School", OrganizationTypeList[EOrganizationType.School]],
	["OlympicChildrenSchool", OrganizationTypeList[EOrganizationType.OlympicChildrenSchool]],
	["OlympicSchool", OrganizationTypeList[EOrganizationType.OlympicSchool]],
	["OlympicCollege", OrganizationTypeList[EOrganizationType.OlympicCollege]],
	["Center", OrganizationTypeList[EOrganizationType.Center]],
	["OlympicCenter", OrganizationTypeList[EOrganizationType.OlympicCenter]],
	["Other", OrganizationTypeList[EOrganizationType.Other]]
];

const statisticOrgs1TableColumnsMap: Array<[TStatisticOrgsRowsKeys, string]> = [
	["Education", "сфере образования"],
	["Sport", "сфере физкультуры и спорта"],
	["Other", "другая принадлежность"]
];
const statisticOrgs2TableColumnsMap: Array<[TStatisticOrgsRowsKeys, string]> = [
	["SportsProgramm", "спортивной подготовки в организациях сферы образования"],
	["AdditionalEducation", "дополнительного образования в сфере физкультуры и спорта"]
];

const dataOfTables = [
	{ rows: statisticOrgs1TableColumnsMap, title: "Ведомственая принадлежность организаций" },
	{ rows: statisticOrgs2TableColumnsMap, title: "Подразделения в организациях" }
];

interface IStatisticsOrganizationsProps {
	hash: string;
}

const StatisticsOrganizations = (props: IStatisticsOrganizationsProps) => {
	const { hash } = props;

	const { data, loading } = useStatisticsOrganizationsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	if (loading) {
		return <CircularProgress color="primary" size="small" />;
	}

	const statistic: StatisticsOrganizationsFullFragment | null | undefined = data?.StatisticsOrganizations;

	return (
		<>
			{dataOfTables.map(({ rows, title }, index) => (
				<StatisticsOrganizationsTable
					key={index}
					colums={statisticOrgsRowMap}
					rows={rows}
					statistic={statistic}
					title={title}
				/>
			))}
		</>
	);
};

interface IStatisticsOrganizationsTableProps {
	statistic: StatisticsOrganizationsFullFragment | null | undefined;
	colums: Array<[TStatisticOrgsKey, string]>;
	rows: Array<[TStatisticOrgsRowsKeys, string]>;
	title: string;
}

const StatisticsOrganizationsTable = (props: IStatisticsOrganizationsTableProps) => {
	const { rows, colums, statistic, title } = props;

	const headers = ["", ...colums.map(([, value]) => value), "Всего"];

	const sumByColums = useMemo(
		() =>
			colums.map(([keyColumn]) =>
				rows.reduce((res, [keyRow]) => {
					const value = statistic?.[keyRow]?.[keyColumn];
					return value && typeof value === "number" ? res + value : res;
				}, 0)
			),
		[statistic, colums]
	);
	const finalSum = useMemo(() => sumByColums.reduce((res, value) => res + value, 0), [sumByColums]);

	const dataSet = [
		...rows.map(([keyRow, rowText]) => {
			let rowSum = 0;
			return [
				rowText,
				...colums.map(([keyColumn]) => {
					const value = statistic?.[keyRow]?.[keyColumn];
					rowSum += value && typeof value === "number" ? value : 0;
					return value;
				}),
				rowSum
			];
		}),
		["Итого", ...sumByColums, finalSum]
	];

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: { [dataSet.length - 1]: "blue-cell" },
		columns: {
			[dataSet[0].length - 1]: "green-cell"
		}
	};

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

export default StatisticsOrganizations;
