import * as Types from "../types";

import gql from "graphql-tag";
import { SportFullFragment } from "./Sport.generated";
import { SportFullFragmentDoc } from "./Sport.generated";

export type ProfileAchievementFullFragment = { __typename?: "ProfileAchievement" } & Pick<
	Types.ProfileAchievement,
	"Id" | "Info" | "Date" | "SportId" | "OtherSport" | "EventType" | "EventLevel" | "ProfileId"
> & { Sport?: Types.Maybe<{ __typename?: "Sport" } & SportFullFragment> };

export const ProfileAchievementFullFragmentDoc = gql`
	fragment ProfileAchievementFull on ProfileAchievement {
		Id
		Info
		Date
		SportId
		Sport {
			...SportFull
		}
		OtherSport
		EventType
		EventLevel
		ProfileId
	}
	${SportFullFragmentDoc}
`;
