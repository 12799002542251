import { sportIsOlimpic, sportIsSummer, sportIsWinter } from "@athlete/utils";
import React, { useMemo } from "react";
import { StatisticsCoachesRanksOrgFullFragment } from "../../../../graphql/fragment/StatisticsCoachesRanksOrg.generated";
import { StatisticsCoachesRanksSportFullFragment } from "../../../../graphql/fragment/StatisticsCoachesRanksSport.generated";
import CommonTable, { TCustomStyleClasses } from "../../../common/CommonTable";

interface IStatisticsCoachesRanksSportTableProps {
	statistic: Array<StatisticsCoachesRanksSportFullFragment> | null | undefined;
	title: string;
}

type TColumnsKeys = keyof Omit<
	StatisticsCoachesRanksSportFullFragment,
	"__typename" | "SportId" | "SportName" | "SportCode" | "All"
>;

const columns: Array<[TColumnsKeys, string]> = [
	["HighestRank", "Высшая квалификационная категория"],
	["FirstRank", "Первая квалификационная категория"],
	["SecondRank", "Вторая квалификационная категория"],
	["CoachVeteran", "Заслуженный тренер России"],
	["SportVeteran", "Заслуженный работник физической культуры РФ"]
];

const headers = ["", ...columns.map(([, value]) => value)];

const headersKeys = columns.map(([key]) => key);

export const StatisticsCoachesRanksSportTable = (props: IStatisticsCoachesRanksSportTableProps) => {
	const { statistic, title } = props;

	const dataSet = useMemo(() => {
		const totalSports: { [key in TColumnsKeys]: number } = {
			HighestRank: 0,
			FirstRank: 0,
			SecondRank: 0,
			CoachVeteran: 0,
			SportVeteran: 0
		};
		const totalOlimpicSports: { [key in TColumnsKeys]: number } = {
			HighestRank: 0,
			FirstRank: 0,
			SecondRank: 0,
			CoachVeteran: 0,
			SportVeteran: 0
		};
		const totalSummer: { [key in TColumnsKeys]: number } = {
			HighestRank: 0,
			FirstRank: 0,
			SecondRank: 0,
			CoachVeteran: 0,
			SportVeteran: 0
		};
		const totalWinter: { [key in TColumnsKeys]: number } = {
			HighestRank: 0,
			FirstRank: 0,
			SecondRank: 0,
			CoachVeteran: 0,
			SportVeteran: 0
		};
		const totalAllSeasons: { [key in TColumnsKeys]: number } = {
			HighestRank: 0,
			FirstRank: 0,
			SecondRank: 0,
			CoachVeteran: 0,
			SportVeteran: 0
		};

		const result = statistic?.map((row) => [
			row.SportName,
			...headersKeys.map((key) => {
				totalSports[key] += row[key];

				if (sportIsOlimpic(row.SportCode)) {
					totalOlimpicSports[key] += row[key];
					if (sportIsSummer(row.SportCode)) totalSummer[key] += row[key];
					else if (sportIsWinter(row.SportCode)) totalWinter[key] += row[key];
					else totalAllSeasons[key] += row[key];
				}
				return row[key];
			})
		]);

		result?.push(
			[
				"Итого по спортам ",
				totalSports.HighestRank,
				totalSports.FirstRank,
				totalSports.SecondRank,
				totalSports.CoachVeteran,
				totalSports.SportVeteran
			],
			[
				"Итого по олимпийским видам спорта",
				totalOlimpicSports.HighestRank,
				totalOlimpicSports.FirstRank,
				totalOlimpicSports.SecondRank,
				totalOlimpicSports.CoachVeteran,
				totalOlimpicSports.SportVeteran
			],
			[
				"в т.ч по летним видам",
				totalSummer.HighestRank,
				totalSummer.FirstRank,
				totalSummer.SecondRank,
				totalSummer.CoachVeteran,
				totalSummer.SportVeteran
			],
			[
				"в т.ч по зимним видам",
				totalWinter.HighestRank,
				totalWinter.FirstRank,
				totalWinter.SecondRank,
				totalWinter.CoachVeteran,
				totalWinter.SportVeteran
			],
			[
				"в т.ч по внесезонным видам",
				totalAllSeasons.HighestRank,
				totalAllSeasons.FirstRank,
				totalAllSeasons.SecondRank,
				totalAllSeasons.CoachVeteran,
				totalAllSeasons.SportVeteran
			]
		);
		return result;
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {
			[dataSet.length - 5]: "blue-cell",
			[dataSet.length - 4]: "blue-cell"
		}
	};

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

interface IStatisticsCoachesRanksOrgsTableProps {
	statistic: Array<StatisticsCoachesRanksOrgFullFragment> | null | undefined;
	title: string;
}

export const StatisticsCoachesRanksOrgsTable = (props: IStatisticsCoachesRanksOrgsTableProps) => {
	const { statistic, title } = props;

	const orgTypeTitles: { [key: string]: string } = useMemo(
		() => ({
			"0": "Всего по СШ",
			"1": "Всего по ДЮСШ",
			"8": "Всего по СДЮСШОР",
			"9": "Всего по СШОР",
			"10": "Всего по УОР",
			"11": "Всего по ЦСП",
			"12": "Всего по ЦОП",
			"4": "Всего других"
		}),
		[]
	);
	const [dataSet, totalRowsIndexes] = useMemo(() => {
		const totalIndexes: Array<number> = [];

		return [
			statistic?.map((row, index) => {
				let text = "";
				switch (row.Department) {
					case null:
						text = orgTypeTitles[row.OrgType] || "";
						totalIndexes.push(index);
						break;
					case "Education":
						text = "приналдежат образованию";
						break;
					case "Sport":
						text = "принадлежат ФКиС";
						break;
					case "Other":
						text = "другая принадлежность";
						break;
					default:
						break;
				}

				return [text, ...headersKeys.map((key) => row[key])];
			}),
			totalIndexes
		];
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {}
	};

	totalRowsIndexes.forEach((index) => {
		if (customStyleClassesIndexes?.rows) {
			customStyleClassesIndexes.rows[index] = "blue-cell";
		}
	});

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};
