import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsProfileFillFullFragment = { __typename?: "StatisticsProfileFill" } & Pick<
	Types.StatisticsProfileFill,
	"Count"
> & {
		Profiles: Array<
			{ __typename?: "ProfileFill" } & Pick<
				Types.ProfileFill,
				| "Id"
				| "CheckStatus"
				| "Inn"
				| "Name"
				| "Rating"
				| "UpdatedAt"
				| "Type"
				| "Place"
				| "Coach"
				| "WorkingHours"
				| "Contacts"
				| "Sports"
				| "Events"
				| "FullName"
				| "Description"
				| "Director"
				| "Rules"
				| "Achievements"
				| "Equipment"
				| "Address"
				| "Region"
				| "Link"
			>
		>;
	};

export const StatisticsProfileFillFullFragmentDoc = gql`
	fragment StatisticsProfileFillFull on StatisticsProfileFill {
		Count
		Profiles {
			Id
			CheckStatus
			Inn
			Name
			Rating
			UpdatedAt
			Type
			Place
			Coach
			WorkingHours
			Contacts
			Sports
			Events
			FullName
			Description
			Director
			Rules
			Achievements
			Equipment
			Address
			Region
			Link
		}
	}
`;
