import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsCoachesSportFullFragment } from "../fragment/StatisticsCoachesSportFull.generated";
import { StatisticsCoachesSportFullFragmentDoc } from "../fragment/StatisticsCoachesSportFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsCoachesBySportsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsCoachesBySportsQuery = { __typename?: "Query" } & {
	StatisticsCoachesBySports?: Types.Maybe<
		Array<{ __typename?: "StatisticsCoachesSport" } & StatisticsCoachesSportFullFragment>
	>;
};

export const StatisticsCoachesBySportsDocument = gql`
	query StatisticsCoachesBySports {
		StatisticsCoachesBySports {
			...StatisticsCoachesSportFull
		}
	}
	${StatisticsCoachesSportFullFragmentDoc}
`;
export type StatisticsCoachesBySportsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables
	>,
	"query"
>;

export const StatisticsCoachesBySportsComponent = (props: StatisticsCoachesBySportsComponentProps) => (
	<ApolloReactComponents.Query<StatisticsCoachesBySportsQuery, StatisticsCoachesBySportsQueryVariables>
		query={StatisticsCoachesBySportsDocument}
		{...props}
	/>
);

export type StatisticsCoachesBySportsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsCoachesBySports<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables,
		StatisticsCoachesBySportsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables,
		StatisticsCoachesBySportsProps<TChildProps, TDataName>
	>(StatisticsCoachesBySportsDocument, {
		alias: "statisticsCoachesBySports",
		...operationOptions
	});
}

/**
 * __useStatisticsCoachesBySportsQuery__
 *
 * To run a query within a React component, call `useStatisticsCoachesBySportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCoachesBySportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCoachesBySportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsCoachesBySportsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<StatisticsCoachesBySportsQuery, StatisticsCoachesBySportsQueryVariables>(
		StatisticsCoachesBySportsDocument,
		baseOptions
	);
}
export function useStatisticsCoachesBySportsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsCoachesBySportsQuery,
		StatisticsCoachesBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<StatisticsCoachesBySportsQuery, StatisticsCoachesBySportsQueryVariables>(
		StatisticsCoachesBySportsDocument,
		baseOptions
	);
}
export type StatisticsCoachesBySportsQueryHookResult = ReturnType<typeof useStatisticsCoachesBySportsQuery>;
export type StatisticsCoachesBySportsLazyQueryHookResult = ReturnType<typeof useStatisticsCoachesBySportsLazyQuery>;
export type StatisticsCoachesBySportsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsCoachesBySportsQuery,
	StatisticsCoachesBySportsQueryVariables
>;
