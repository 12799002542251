import { Box, Button, Tab, Tabs } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ProfileEditQuery } from "../../../graphql/query/ProfileEdit.generated";
import TabPanel from "../TabPanel";
import StatisticsCoaches from "./StatisticsCoaches/StatisticsCoaches";
import StatisticsOrganizations from "./StatisticsOrganizations";
import StatisticsProfileFill from "./StatisticsProfileFill";
import StatisticsSportRanks from "./StatisticsSportRanks";
import StatisticsStagesSport from "./StatisticsStagesSport";

interface IStatisticsTabsProps {
	hash: string;
	data: ProfileEditQuery | undefined;
}

const StatisticsTabs: React.ComponentType<IStatisticsTabsProps> = (props) => {
	const { hash, data } = props;

	const [tabIndex, setTabIndex] = React.useState(0);

	// TODO: Исправить <any> на корректный тип после добавления соответствующего фрагмента
	const [profile, setProfile] = useState<any>();
	useEffect(() => {
		if (data) {
			setProfile(data.MyProfile);
		}
	}, [data]);

	if (!profile) {
		return null;
	}

	const handleSelectTab = (_event: React.ChangeEvent<{}>, newTabIndex: number) => {
		setTabIndex(newTabIndex);
	};

	return (
		<>
			<Box boxShadow={3} className="profile-edit-form">
				<Tabs
					value={tabIndex}
					onChange={handleSelectTab}
					aria-label="profile tabs"
					className="profile-tabs"
					variant="fullWidth"
					indicatorColor="primary"
				>
					<Tab label="Профили" value={0} className="profile-tabs__item" />
					<Tab label="Организации" value={1} className="profile-tabs__item" />
					<Tab label="Программы обучения" value={2} className="profile-tabs__item" />
					<Tab label="Звания и достижения" value={3} className="profile-tabs__item" />
					<Tab label="Тренера" value={4} className="profile-tabs__item" />
				</Tabs>
				<TabPanel value={tabIndex} index={0} name="profile">
					<StatisticsProfileFill profile={profile} setProfile={setProfile} hash={hash} />
				</TabPanel>
				<TabPanel value={tabIndex} index={1} name="profile">
					<StatisticsOrganizations hash={hash} />
				</TabPanel>
				<TabPanel value={tabIndex} index={2} name="profile">
					<StatisticsStagesSport hash={hash} />
				</TabPanel>
				<TabPanel value={tabIndex} index={3} name="profile">
					<StatisticsSportRanks hash={hash} />
				</TabPanel>
				<TabPanel value={tabIndex} index={4} name="profile">
					<StatisticsCoaches hash={hash} />
				</TabPanel>
				<Button
					className="upsert-profile-btn"
					variant="contained"
					size="large"
					color="primary"
					href={`${process.env.GATSBY_DOWNLOAD_URL}/${hash}`}
				>
					Сохранить всю статистику в *.XLS
				</Button>
			</Box>
		</>
	);
};

export default StatisticsTabs;
