import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { DisciplineFullFragment } from "../fragment/Discipline.generated";
import { DisciplineFullFragmentDoc } from "../fragment/Discipline.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DisciplinesDataQueryVariables = Types.Exact<{
	ids?: Types.Maybe<Array<Types.Scalars["ID"]>>;
	sportIds?: Types.Maybe<Array<Types.Scalars["ID"]>>;
}>;

export type DisciplinesDataQuery = { __typename?: "Query" } & {
	Disciplines: Array<{ __typename?: "Discipline" } & DisciplineFullFragment>;
};

export const DisciplinesDataDocument = gql`
	query DisciplinesData($ids: [ID!], $sportIds: [ID!]) {
		Disciplines(ids: $ids, sportIds: $sportIds) {
			...DisciplineFull
		}
	}
	${DisciplineFullFragmentDoc}
`;
export type DisciplinesDataComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<DisciplinesDataQuery, DisciplinesDataQueryVariables>,
	"query"
>;

export const DisciplinesDataComponent = (props: DisciplinesDataComponentProps) => (
	<ApolloReactComponents.Query<DisciplinesDataQuery, DisciplinesDataQueryVariables>
		query={DisciplinesDataDocument}
		{...props}
	/>
);

export type DisciplinesDataProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<DisciplinesDataQuery, DisciplinesDataQueryVariables>;
} &
	TChildProps;
export function withDisciplinesData<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		DisciplinesDataQuery,
		DisciplinesDataQueryVariables,
		DisciplinesDataProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		DisciplinesDataQuery,
		DisciplinesDataQueryVariables,
		DisciplinesDataProps<TChildProps, TDataName>
	>(DisciplinesDataDocument, {
		alias: "disciplinesData",
		...operationOptions
	});
}

/**
 * __useDisciplinesDataQuery__
 *
 * To run a query within a React component, call `useDisciplinesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplinesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisciplinesDataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      sportIds: // value for 'sportIds'
 *   },
 * });
 */
export function useDisciplinesDataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<DisciplinesDataQuery, DisciplinesDataQueryVariables>
) {
	return ApolloReactHooks.useQuery<DisciplinesDataQuery, DisciplinesDataQueryVariables>(
		DisciplinesDataDocument,
		baseOptions
	);
}
export function useDisciplinesDataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisciplinesDataQuery, DisciplinesDataQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<DisciplinesDataQuery, DisciplinesDataQueryVariables>(
		DisciplinesDataDocument,
		baseOptions
	);
}
export type DisciplinesDataQueryHookResult = ReturnType<typeof useDisciplinesDataQuery>;
export type DisciplinesDataLazyQueryHookResult = ReturnType<typeof useDisciplinesDataLazyQuery>;
export type DisciplinesDataQueryResult = ApolloReactCommon.QueryResult<
	DisciplinesDataQuery,
	DisciplinesDataQueryVariables
>;
