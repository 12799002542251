import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsSportRanksSportFullFragment } from "../fragment/StatisticsSportRanksSportFull.generated";
import { StatisticsSportRanksSportFullFragmentDoc } from "../fragment/StatisticsSportRanksSportFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsSportRanksBySportsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsSportRanksBySportsQuery = { __typename?: "Query" } & {
	StatisticsSportRanksBySports?: Types.Maybe<
		Array<{ __typename?: "StatisticsSportRanksSport" } & StatisticsSportRanksSportFullFragment>
	>;
};

export const StatisticsSportRanksBySportsDocument = gql`
	query StatisticsSportRanksBySports {
		StatisticsSportRanksBySports {
			...StatisticsSportRanksSportFull
		}
	}
	${StatisticsSportRanksSportFullFragmentDoc}
`;
export type StatisticsSportRanksBySportsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables
	>,
	"query"
>;

export const StatisticsSportRanksBySportsComponent = (props: StatisticsSportRanksBySportsComponentProps) => (
	<ApolloReactComponents.Query<StatisticsSportRanksBySportsQuery, StatisticsSportRanksBySportsQueryVariables>
		query={StatisticsSportRanksBySportsDocument}
		{...props}
	/>
);

export type StatisticsSportRanksBySportsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsSportRanksBySports<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables,
		StatisticsSportRanksBySportsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables,
		StatisticsSportRanksBySportsProps<TChildProps, TDataName>
	>(StatisticsSportRanksBySportsDocument, {
		alias: "statisticsSportRanksBySports",
		...operationOptions
	});
}

/**
 * __useStatisticsSportRanksBySportsQuery__
 *
 * To run a query within a React component, call `useStatisticsSportRanksBySportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsSportRanksBySportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsSportRanksBySportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsSportRanksBySportsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<StatisticsSportRanksBySportsQuery, StatisticsSportRanksBySportsQueryVariables>(
		StatisticsSportRanksBySportsDocument,
		baseOptions
	);
}
export function useStatisticsSportRanksBySportsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsSportRanksBySportsQuery,
		StatisticsSportRanksBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<StatisticsSportRanksBySportsQuery, StatisticsSportRanksBySportsQueryVariables>(
		StatisticsSportRanksBySportsDocument,
		baseOptions
	);
}
export type StatisticsSportRanksBySportsQueryHookResult = ReturnType<typeof useStatisticsSportRanksBySportsQuery>;
export type StatisticsSportRanksBySportsLazyQueryHookResult = ReturnType<
	typeof useStatisticsSportRanksBySportsLazyQuery
>;
export type StatisticsSportRanksBySportsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsSportRanksBySportsQuery,
	StatisticsSportRanksBySportsQueryVariables
>;
