import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { AddressFullFragment } from "../../graphql/fragment/Address.generated";
import { SearchFragment } from "../../graphql/fragment/Search.generated";
import { useUi_SearchLazyQuery } from "../../graphql/query/UI_Search.generated";
import { ESearchType, ProfileEducation } from "../../graphql/types";

interface IPlacesSearchProps {
	hash: string;
	name?: string;
	changeText: (text: string | undefined) => void;
	changeAddress: (text: string | AddressFullFragment) => void;
}

const PlacesSearch = (props: IPlacesSearchProps) => {
	const [name, setName] = useState(props.name || "");
	const [listSearch, setListSearch] = useState<Array<SearchFragment>>();
	const [searchQuery, { data, loading }] = useUi_SearchLazyQuery();

	const [debouncedName] = useDebounce(name, 500);

	useEffect(() => {
		if (!name || name.length < 3) {
			setListSearch(undefined);
			return;
		}
		searchQuery({
			context: { headers: { Authorization: `Digest ${props.hash}` } },
			variables: {
				input: {
					Name: name,
					Type: ESearchType.GeoObject,
					Limit: 100,
					Offset: 0
				}
			}
		});
	}, [debouncedName]);

	const onChange = (v: string) => {
		setName(v);
		props.changeText(v);
	};
	const getLabel = useCallback((option: SearchFragment) => option.Name || "", []);

	const selectOption = (_event: React.ChangeEvent<{}>, value: string | SearchFragment | null) => {
		if (typeof value !== "string") {
			const newName = value?.Name || "";
			setName(newName);
			props.changeText(newName);
			props.changeAddress(value?.Address || "");
		}
	};

	useEffect(() => {
		if (data && data.Search) {
			setListSearch(data.Search);
		}
	}, [data]);

	return (
		<Autocomplete
			id="place-name"
			freeSolo
			noOptionsText="Нет элементов"
			loading={loading}
			options={listSearch || []}
			value={{ Id: "-1", Name: name }}
			getOptionLabel={getLabel}
			onChange={selectOption}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth
					required
					label="Название площадки"
					margin="normal"
					onChange={(event) => {
						const v = event.target.value?.trim() ? event.target.value : "";
						onChange(v);
					}}
					onBlur={() => onChange(name?.trim())}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
		/>
	);
};

export default React.memo(PlacesSearch);
