import * as Types from "../types";

import gql from "graphql-tag";

export type ProfileContactFullFragment = { __typename?: "ProfileContact" } & Pick<
	Types.ProfileContact,
	"Id" | "Type" | "Value" | "Comment"
>;

export const ProfileContactFullFragmentDoc = gql`
	fragment ProfileContactFull on ProfileContact {
		Id
		Type
		Value
		Comment
	}
`;
