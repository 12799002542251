import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsCoachesSportFullFragment = { __typename?: "StatisticsCoachesSport" } & Pick<
	Types.StatisticsCoachesSport,
	"SportId" | "SportName" | "SportCode" | "All" | "FullTime" | "Mid" | "High"
>;

export const StatisticsCoachesSportFullFragmentDoc = gql`
	fragment StatisticsCoachesSportFull on StatisticsCoachesSport {
		SportId
		SportName
		SportCode
		All
		FullTime
		Mid
		High
	}
`;
