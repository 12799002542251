import { CircularProgress, FormControl, FormHelperText, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback } from "react";
import { useSportsDataQuery } from "../../graphql/query/SportsData.generated";
import { Sport } from "../../graphql/types";

interface ISportSelectorProps {
	hash: string;
	value?: Sport | null;
	onChange: (sport: Sport | null) => void;
	description?: string;
	disabled?: boolean;
}

const SportSelector = (props: ISportSelectorProps) => {
	const { hash, value, onChange, description, disabled } = props;

	const { data, loading: loadingSports, error } = useSportsDataQuery({
		context: { headers: { Authorization: `Digest ${hash}` } }
	});

	const onOptionChange = useCallback((sport: Sport | null): void => {
		onChange(sport);
	}, []);
	const getOptionLabel = useCallback((option: Sport): string => option.Name, []);
	if (!data?.Sports) return null;

	return (
		<FormControl fullWidth>
			<Autocomplete
				loading={loadingSports}
				disabled={disabled}
				fullWidth
				options={data.Sports}
				getOptionSelected={(option, item) => option.Id === item.Id}
				getOptionLabel={getOptionLabel}
				value={value}
				onChange={(_event, item) => onOptionChange(item)}
				loadingText="Загрузка..."
				noOptionsText="Нет элементов"
				renderInput={(params) => (
					<TextField
						{...params}
						label="Вид спорта"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loadingSports ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							)
						}}
					/>
				)}
			/>
			{!!description && <FormHelperText>{description}</FormHelperText>}
		</FormControl>
	);
};

export default React.memo(SportSelector);
