import { MutationFunctionOptions } from "@apollo/client";
import { ProfileFill } from "@athlete/calendar/dist/generated/graphql";
import { CircularProgress, Icon, ListItemIcon, ListItemText, Menu, MenuItem, TableRow } from "@material-ui/core";
import React, { ReactNode, useState } from "react";
import { ORGS_PER_PAGE } from "../../../../constants";
import {
	UpdateProfileCheckStatusMutation,
	useUpdateProfileCheckStatusMutation
} from "../../../../graphql/mutation/updateProfileCheckStatus.generated";
import { StatisticsProfileFillDocument } from "../../../../graphql/query/StatisticsProfileFill.generated";
import { EProfileCheckStatus } from "../../../../graphql/types";

interface IWorkingHoursProps {
	row: ProfileFill;
	hash: string;
	children: ReactNode;
}

const initialMenuState = { mouseX: null, mouseY: null };

const StatisticsActionsRow = (props: IWorkingHoursProps) => {
	const { row, hash } = props;

	const [menuPosition, setmenuPosition] = useState<{ mouseX: null | number; mouseY: null | number }>(
		initialMenuState
	);
	const [updateStatus, { data, loading }] = useUpdateProfileCheckStatusMutation();

	const mutationOptions: MutationFunctionOptions<UpdateProfileCheckStatusMutation> = {
		context: {
			headers: {
				Authorization: `Digest ${hash}`
			}
		},
		refetchQueries: [
			{
				query: StatisticsProfileFillDocument,
				context: {
					headers: {
						Authorization: `Digest ${hash}`
					}
				},
				variables: {
					input: {
						Offset: 0,
						Limit: ORGS_PER_PAGE
					}
				}
			}
		]
	};
	const openMenu = (event: React.MouseEvent<HTMLTableRowElement>) => {
		setmenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
	};

	const closeMenu = () => {
		setmenuPosition(initialMenuState);
	};

	const goToEdit = (event: React.MouseEvent<HTMLAnchorElement>) => {
		closeMenu();
	};
	const setTrusted = () => {
		updateStatus({ ...mutationOptions, variables: { profileId: row.Id, status: EProfileCheckStatus.Trusted } });
		closeMenu();
	};

	const setBlocked = () => {
		updateStatus({ ...mutationOptions, variables: { profileId: row.Id, status: EProfileCheckStatus.Blocked } });
		closeMenu();
	};

	if (loading) {
		return <CircularProgress color="primary" size="small" />;
	}

	return (
		<>
			<TableRow hover key={row.Id} onClick={openMenu}>
				{props.children}
			</TableRow>
			<Menu
				id={`menu-${row.Id}`}
				anchorReference="anchorPosition"
				anchorPosition={
					menuPosition.mouseY !== null && menuPosition.mouseX !== null
						? { top: menuPosition.mouseY, left: menuPosition.mouseX }
						: undefined
				}
				keepMounted
				open={menuPosition.mouseX !== null}
				onClose={closeMenu}
				className="statistics-menu"
			>
				<MenuItem onClick={goToEdit} alignItems="flex-start" component="a" href={row.Link} target="_blank">
					<ListItemIcon>
						<Icon color="primary" fontSize="large">
							<i className="icon icon--edit-web" />
						</Icon>
					</ListItemIcon>
					<ListItemText
						primary="Редактировать профиль"
						secondary="Перейти на страницу редактирования профиля (откроется в новом окне)"
						secondaryTypographyProps={{ className: "description" }}
					/>
				</MenuItem>
				<MenuItem onClick={setTrusted} alignItems="flex-start">
					<ListItemIcon>
						<Icon color="primary" fontSize="large">
							<i className="icon icon--validate-profile" />
						</Icon>
					</ListItemIcon>
					<ListItemText
						primary='Установить статус "Доверенный"'
						secondary="Информация в профиле является достоверной. Профиль будет доступен всем пользователям без ограничений."
						secondaryTypographyProps={{ className: "description" }}
					/>
				</MenuItem>
				<MenuItem onClick={setBlocked} alignItems="flex-start">
					<ListItemIcon>
						<Icon color="primary" fontSize="large">
							<i className="icon icon--not-validate-profile" />
						</Icon>
					</ListItemIcon>
					<ListItemText
						primary='Установить статус "Заблокированный"'
						secondary="Информация в профиле недостоверна и требует исправления владельцем профиля. Профиль будет доступен пользователям только через специальный фильтр."
						secondaryTypographyProps={{ className: "description" }}
					/>
				</MenuItem>
			</Menu>
		</>
	);
};

export default StatisticsActionsRow;
