import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { SportFullFragment } from "../fragment/Sport.generated";
import { SportFullFragmentDoc } from "../fragment/Sport.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type SportsDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SportsDataQuery = { __typename?: "Query" } & {
	Sports: Array<{ __typename?: "Sport" } & SportFullFragment>;
};

export const SportsDataDocument = gql`
	query SportsData {
		Sports {
			...SportFull
		}
	}
	${SportFullFragmentDoc}
`;
export type SportsDataComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<SportsDataQuery, SportsDataQueryVariables>,
	"query"
>;

export const SportsDataComponent = (props: SportsDataComponentProps) => (
	<ApolloReactComponents.Query<SportsDataQuery, SportsDataQueryVariables> query={SportsDataDocument} {...props} />
);

export type SportsDataProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<SportsDataQuery, SportsDataQueryVariables>;
} &
	TChildProps;
export function withSportsData<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		SportsDataQuery,
		SportsDataQueryVariables,
		SportsDataProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		SportsDataQuery,
		SportsDataQueryVariables,
		SportsDataProps<TChildProps, TDataName>
	>(SportsDataDocument, {
		alias: "sportsData",
		...operationOptions
	});
}

/**
 * __useSportsDataQuery__
 *
 * To run a query within a React component, call `useSportsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsDataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<SportsDataQuery, SportsDataQueryVariables>
) {
	return ApolloReactHooks.useQuery<SportsDataQuery, SportsDataQueryVariables>(SportsDataDocument, baseOptions);
}
export function useSportsDataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsDataQuery, SportsDataQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<SportsDataQuery, SportsDataQueryVariables>(SportsDataDocument, baseOptions);
}
export type SportsDataQueryHookResult = ReturnType<typeof useSportsDataQuery>;
export type SportsDataLazyQueryHookResult = ReturnType<typeof useSportsDataLazyQuery>;
export type SportsDataQueryResult = ApolloReactCommon.QueryResult<SportsDataQuery, SportsDataQueryVariables>;
