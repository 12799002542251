import _ from "lodash";
import moment from "moment";
import "moment/locale/ru";
import React, { useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import Rating from "react-rating";
import { Themes } from "../constants";
import { useProfileOpenFullQuery } from "../graphql/query/ProfileOpenFull.generated";
import { getAddressTitle, getProfileName, getProfileType, getProfileTypeName } from "../utils/utils";
import Attachments from "./attachments";
import Avatar from "./avatar";
import Modal from "./modal";
import { IContactInfo } from "./profileContact";
import ProfileContact from "./profileContact";

interface IProfileProps {
	QueryParams: string;
	titleShow?: boolean;
}

const groupContacts = (contacts: Array<IContactInfo>) => {
	const groups = {
		Phone: [] as Array<IContactInfo>,
		Email: [] as Array<IContactInfo>,
		URL: [] as Array<IContactInfo>,
		Other: [] as Array<IContactInfo>
	};
	for (const contact of contacts) {
		switch (contact.Type) {
			case "Phone":
			case "CellPhone":
				groups.Phone.push(contact);
				break;
			case "Email":
				groups.Email.push(contact);
				break;
			case "URL":
				groups.URL.push(contact);
				break;
			default:
				groups.Other.push(contact);
				break;
		}
	}
	return groups;
};

moment.locale("ru");

const Profile: React.ComponentType<IProfileProps> = (props) => {
	const { QueryParams, titleShow = true } = props;

	const [descriptionShown, setDescriptionShown] = useState<boolean>(false);

	// iframe
	const iframeText = `<iframe width="100%" height="600" src="${window.location}?view=iframe" frameborder="0"></iframe>`;

	// Параметры запроса из хеша
	const params = useMemo(() => {
		try {
			return JSON.parse(QueryParams);
		} catch (e) {
			console.log("Error parsing QueryParams:", e);
			return;
		}
	}, [QueryParams]);

	// Профиль
	const { data, loading, error } = useProfileOpenFullQuery({
		variables: {
			input: {
				Id: params.ProfileId
			}
		}
	});

	if (loading || !data?.Profile) {
		return <p>Идет загрузка данных...</p>;
	}

	const showModal = () => {
		const element = document.getElementById("profile-iframe-modal");
		if (element) {
			element.classList.add("active");
		}
	};

	const copyIframeText = () => {
		const copyText = document.getElementById("profile-copy-text");
		if (!copyText) {
			return;
		}
		(copyText as HTMLTextAreaElement).select();

		document.execCommand("copy");
	};

	const avatarURL = data.Profile.AvatarAttachment && data.Profile.AvatarAttachment.FileLink;
	const profileType = getProfileType(data.Profile);
	const themeProfile = Themes[profileType];

	const groupedContacts = groupContacts(data.Profile.Contacts);
	return (
		<>
			{titleShow && (
				<div className="profile-content profile-header">
					<button className={`button ${themeProfile.background}`} onClick={showModal}>
						Встроить на сайт
					</button>
					<Modal id="profile-iframe-modal" buttons={[{ text: "Копировать", onClick: copyIframeText }]}>
						<textarea
							id="profile-copy-text"
							className="profile-copy-text"
							placeholder="Код для вставки"
							defaultValue={iframeText}
						/>
					</Modal>
				</div>
			)}
			<div className="profile-content profile-header">
				<Avatar size={100} type={getProfileType(data.Profile)} URL={avatarURL} />
				<div className="base-info">
					<h1>{getProfileName(data.Profile)}</h1>
					<div className={`profile-type ${themeProfile.color}`}>
						<i className={`icon ${themeProfile.icon}`} />
						<span>{getProfileTypeName(data.Profile)}</span>
					</div>
					{data.Profile.OrganizationOpen?.FullName && (
						<p className="full-name">{data.Profile.OrganizationOpen.FullName}</p>
					)}
					<div className="rating-container">
						<Rating
							readonly
							initialRating={
								data.Profile.PersonOpen?.Rating || data.Profile.OrganizationOpen?.Rating || 0
							}
							emptySymbol="rating-empty"
							fullSymbol="rating-full"
						/>
						<div>Отзывов: {data.Profile.ReviewsCount || 0}</div>
					</div>
				</div>
			</div>
			{data.Profile.OrganizationOpen?.Description && (
				<div className="profile-content profile-description">
					<h2>Описание:</h2>
					<Collapse
						isOpened={descriptionShown}
						theme={{ collapse: `ReactCollapse--collapse ${descriptionShown ? "collapsed" : ""}` }}
					>
						<pre className="description-content">{data.Profile.OrganizationOpen.Description}</pre>
					</Collapse>
					<button onClick={() => setDescriptionShown(!descriptionShown)}>
						<i
							className={`icon icon--web_arrow_up ${themeProfile.color} ${
								descriptionShown ? "rotated" : ""
							}`}
						/>
					</button>
				</div>
			)}
			{groupedContacts && (
				<div className="profile-content profile-contact-info">
					<h2>Контактная информация:</h2>
					<div className="contact-blocks">
						{!!data.Profile.Address?.Text.length && (
							<div className={`contact-list ${themeProfile.color}`}>
								<i className="icon icon--universal_poi list-header-icon" />
								<div className="contact-item">
									{data.Profile.Address.GeoLongitude || data.Profile.Address.GeoLatitude ? (
										<a
											href={`https://yandex.ru/maps/?pt=${data.Profile.Address.GeoLongitude},${data.Profile.Address.GeoLatitude}&z=18&l=map`}
											target="blank"
										>
											{getAddressTitle(data.Profile.Address)}
										</a>
									) : (
										<span>{getAddressTitle(data.Profile.Address)}</span>
									)}
								</div>
							</div>
						)}
						{!!groupedContacts.Phone.length && (
							<div className={`contact-list ${themeProfile.color}`}>
								<i className="icon icon--icon-sity-phone list-header-icon" />
								{groupedContacts.Phone.map((contactInfo: IContactInfo) => (
									<ProfileContact {...contactInfo} key={contactInfo.Id} Label />
								))}
							</div>
						)}
						{!!groupedContacts.Email.length && (
							<div className={`contact-list ${themeProfile.color}`}>
								<i className="icon icon--email-disabled list-header-icon" />
								{groupedContacts.Email.map((contactInfo: IContactInfo) => (
									<ProfileContact {...contactInfo} key={contactInfo.Id} Label />
								))}
							</div>
						)}
						{!!groupedContacts.URL.length && (
							<div className={`contact-list ${themeProfile.color}`}>
								<i className="icon icon--website-disabled list-header-icon" />
								{groupedContacts.URL.map((contactInfo: IContactInfo) => (
									<ProfileContact {...contactInfo} key={contactInfo.Id} Label />
								))}
							</div>
						)}
						{!!groupedContacts.Other.length && (
							<div className={`contact-list ${themeProfile.color} icons-list`}>
								<i className="icon icon--chat_0 list-header-icon" />
								{groupedContacts.Other.map((contactInfo: IContactInfo) => (
									<ProfileContact {...contactInfo} key={contactInfo.Id} Icon />
								))}
							</div>
						)}
					</div>
				</div>
			)}
			{!!data.Profile.Attachments.length && (
				<div className="profile-content">
					<Attachments attachments={data.Profile.Attachments} theme={themeProfile} />
				</div>
			)}
		</>
	);
};

export default Profile;
