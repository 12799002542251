import * as Types from "../types";

import gql from "graphql-tag";
import { AddressFullFragment } from "./Address.generated";
import { AddressFullFragmentDoc } from "./Address.generated";

export type ProfilePlaceFullFragment = { __typename?: "ProfilePlace" } & Pick<
	Types.ProfilePlace,
	"Id" | "Name" | "Comment" | "IsPublic" | "Deleted" | "Favorite"
> & { Address?: Types.Maybe<{ __typename?: "Address" } & AddressFullFragment> };

export const ProfilePlaceFullFragmentDoc = gql`
	fragment ProfilePlaceFull on ProfilePlace {
		Id
		Name
		Comment
		IsPublic
		Deleted
		Favorite
		Address {
			...AddressFull
		}
	}
	${AddressFullFragmentDoc}
`;
