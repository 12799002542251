import * as Types from "../types";

import gql from "graphql-tag";
import { AddressFullFragment } from "./Address.generated";
import { AddressFullFragmentDoc } from "./Address.generated";

export type EducationOrganizationRecFieldsFragment = { __typename?: "EducationOrganization" } & Pick<
	Types.EducationOrganization,
	"Id" | "Name"
> & {
		Address?: Types.Maybe<
			{ __typename?: "Address" } & {
				Region?: Types.Maybe<{ __typename?: "Region" } & Pick<Types.Region, "Id" | "Code">>;
			} & AddressFullFragment
		>;
	};

export const EducationOrganizationRecFieldsFragmentDoc = gql`
	fragment EducationOrganizationRecFields on EducationOrganization {
		Id
		Name
		Address {
			...AddressFull
			Region {
				Id
				Code
			}
		}
	}
	${AddressFullFragmentDoc}
`;
