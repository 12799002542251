import * as Types from "../types";

import gql from "graphql-tag";

export type PersonFullFragment = { __typename?: "Person" } & Pick<
	Types.Person,
	| "Id"
	| "Type"
	| "Name"
	| "CostPerHour"
	| "Rating"
	| "FirstName"
	| "LastName"
	| "PreviousLastName"
	| "MiddleName"
	| "Birthday"
	| "StartSportDate"
	| "MaritalStatus"
	| "SportTrainingStage"
	| "PassportNumber"
	| "SubdivisionName"
	| "SubdivisionCode"
	| "IssuedAt"
	| "Birthplace"
	| "SSN"
	| "BirthCertificate"
	| "BirthRecordAcceptedBy"
	| "BirthRecordDate"
	| "MedicalInsuranceNumber"
	| "NextMedicalExaminationDate"
	| "Gender"
	| "Citizenship"
	| "AgreePersonalData"
	| "PersonalCoachId"
	| "FavoriteCoachId"
	| "FavoriteOrganizationId"
	| "FederationId"
> & {
		Data: { __typename?: "PersonExtraData" } & {
			Gto?: Types.Maybe<
				{ __typename?: "PersonGtoData" } & Pick<Types.PersonGtoData, "Medal" | "Age" | "Certificate">
			>;
		};
	};

export type PersonForAvatarFragment = { __typename?: "Person" } & Pick<
	Types.Person,
	"Id" | "Type" | "Name" | "FirstName" | "LastName" | "MiddleName"
>;

export type PersonOpenFragment = { __typename?: "Person" } & Pick<Types.Person, "Id" | "Type" | "Name" | "Rating">;

export const PersonFullFragmentDoc = gql`
	fragment PersonFull on Person {
		Id
		Type
		Name
		CostPerHour
		Rating
		FirstName
		LastName
		PreviousLastName
		MiddleName
		Birthday
		StartSportDate
		MaritalStatus
		SportTrainingStage
		PassportNumber
		SubdivisionName
		SubdivisionCode
		IssuedAt
		Birthplace
		SSN
		BirthCertificate
		BirthRecordAcceptedBy
		BirthRecordDate
		MedicalInsuranceNumber
		NextMedicalExaminationDate
		Gender
		Citizenship
		AgreePersonalData
		PersonalCoachId
		FavoriteCoachId
		FavoriteOrganizationId
		FederationId
		Data {
			Gto {
				Medal
				Age
				Certificate
			}
		}
	}
`;
export const PersonForAvatarFragmentDoc = gql`
	fragment PersonForAvatar on Person {
		Id
		Type
		Name
		FirstName
		LastName
		MiddleName
	}
`;
export const PersonOpenFragmentDoc = gql`
	fragment PersonOpen on Person {
		Id
		Type
		Name
		Rating
	}
`;
