import _ from "lodash";
import React, { useState } from "react";
import { weekDaysShort } from "../../constants";
import { EWeekDays } from "../../graphql/types";
import { hoursTextToMinutes } from "../../utils/utils";
import TimeRangeSlider from "../common/TimeRangeSlider";

import Checkbox from "@material-ui/core/Checkbox";

const defaultIntervalWork = ["09:00", "19:00"];
const defaultIntervalBreak = ["12:00", "13:00"];

export interface IWorkingDay {
	weekday: EWeekDays;
	startTime?: string;
	endTime?: string;
	startLunchTime?: string;
	endLunchTime?: string;
}

interface IWorkingDayProps {
	workingDay: IWorkingDay;
	onChange: (value: IWorkingDay) => void;
}

const WorkingDay = (props: IWorkingDayProps) => {
	const { workingDay, onChange } = props;

	const [dayon, setDayOn] = useState(!!(workingDay?.startTime && workingDay?.endTime));
	const [withLunch, setWithLunch] = useState(Boolean(workingDay?.startLunchTime && workingDay?.endLunchTime));

	const onChangePeriod = (value: Array<string>, lunch?: boolean) => {
		if (!lunch) {
			workingDay.startTime = value[0];
			workingDay.endTime = value[1];
		} else {
			workingDay.startLunchTime = value[0];
			workingDay.endLunchTime = value[1];
		}
		onChange({ ...workingDay });
	};

	const changeDayoff = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDayOn(event.target.checked);
		if (event.target.checked) {
			workingDay.startTime = defaultIntervalWork[0];
			workingDay.endTime = defaultIntervalWork[1];
			if (withLunch) {
				workingDay.startLunchTime = defaultIntervalBreak[0];
				workingDay.endLunchTime = defaultIntervalBreak[1];
			}
		} else {
			workingDay.startTime = undefined;
			workingDay.endTime = undefined;
			workingDay.startLunchTime = undefined;
			workingDay.endLunchTime = undefined;
		}
		onChange({ ...workingDay });
	};

	const changeWithLunch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWithLunch(event.target.checked);
		if (event.target.checked) {
			workingDay.startLunchTime = defaultIntervalBreak[0];
			workingDay.endLunchTime = defaultIntervalBreak[1];
		} else {
			workingDay.startLunchTime = undefined;
			workingDay.endLunchTime = undefined;
		}
		onChange({ ...workingDay });
	};

	return (
		<div className="working-table__day">
			<div className="working-table__name">
				<span className={dayon ? "working-table__dayname" : "working-table__dayname dayoff"}>
					{weekDaysShort[workingDay.weekday]}
				</span>
				<Checkbox checked={dayon} onChange={changeDayoff} color="primary" />
				<span className="working-table__daycheck-comment">
					Рабочий
					<br />
					день
				</span>
			</div>
			<TimeRangeSlider
				value={
					workingDay.startTime && workingDay.endTime ? [workingDay.startTime, workingDay.endTime] : undefined
				}
				onChange={onChangePeriod}
				visible={dayon}
				title="Часы работы"
				description="Выберите часы работы"
				defaultInterval={defaultIntervalWork}
			/>
			<TimeRangeSlider
				value={
					workingDay.startLunchTime && workingDay.endLunchTime
						? [workingDay.startLunchTime, workingDay.endLunchTime]
						: undefined
				}
				min={withLunch && dayon ? hoursTextToMinutes(workingDay.startTime) : undefined}
				max={withLunch && dayon ? hoursTextToMinutes(workingDay.endTime) : undefined}
				onChange={(value) => onChangePeriod(value, true)}
				visible={withLunch && dayon}
				description="Выберите часы перерыва"
				titleComponent={
					<div className="title-container">
						<Checkbox checked={withLunch} onChange={changeWithLunch} disabled={!dayon} color="primary" />
						<div className="title">Перерыв</div>
					</div>
				}
				defaultInterval={defaultIntervalBreak}
			/>
		</div>
	);
};

export default WorkingDay;
