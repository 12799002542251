import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ContactFullFragment } from "../fragment/Contact.generated";
import { ContactFullFragmentDoc } from "../fragment/Contact.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertContactMutationVariables = Types.Exact<{
	data: Types.UpsertContactInput;
}>;

export type UpsertContactMutation = { __typename?: "Mutation" } & {
	upsertContact: { __typename?: "UpsertContactResult" } & Pick<Types.UpsertContactResult, "success" | "message"> & {
			data?: Types.Maybe<
				{ __typename?: "UpsertContactResultData" } & {
					Contact: { __typename?: "Contact" } & ContactFullFragment;
				}
			>;
		};
};

export const UpsertContactDocument = gql`
	mutation upsertContact($data: UpsertContactInput!) {
		upsertContact(data: $data) {
			success
			message
			data {
				Contact {
					...ContactFull
				}
			}
		}
	}
	${ContactFullFragmentDoc}
`;
export type UpsertContactMutationFn = ApolloReactCommon.MutationFunction<
	UpsertContactMutation,
	UpsertContactMutationVariables
>;
export type UpsertContactComponentProps = Omit<
	ApolloReactComponents.MutationComponentOptions<UpsertContactMutation, UpsertContactMutationVariables>,
	"mutation"
>;

export const UpsertContactComponent = (props: UpsertContactComponentProps) => (
	<ApolloReactComponents.Mutation<UpsertContactMutation, UpsertContactMutationVariables>
		mutation={UpsertContactDocument}
		{...props}
	/>
);

export type UpsertContactProps<TChildProps = {}, TDataName extends string = "mutate"> = {
	[key in TDataName]: ApolloReactCommon.MutationFunction<UpsertContactMutation, UpsertContactMutationVariables>;
} &
	TChildProps;
export function withUpsertContact<TProps, TChildProps = {}, TDataName extends string = "mutate">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		UpsertContactMutation,
		UpsertContactMutationVariables,
		UpsertContactProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withMutation<
		TProps,
		UpsertContactMutation,
		UpsertContactMutationVariables,
		UpsertContactProps<TChildProps, TDataName>
	>(UpsertContactDocument, {
		alias: "upsertContact",
		...operationOptions
	});
}

/**
 * __useUpsertContactMutation__
 *
 * To run a mutation, you first call `useUpsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactMutation, { data, loading, error }] = useUpsertContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertContactMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertContactMutation, UpsertContactMutationVariables>
) {
	return ApolloReactHooks.useMutation<UpsertContactMutation, UpsertContactMutationVariables>(
		UpsertContactDocument,
		baseOptions
	);
}
export type UpsertContactMutationHookResult = ReturnType<typeof useUpsertContactMutation>;
export type UpsertContactMutationResult = ApolloReactCommon.MutationResult<UpsertContactMutation>;
export type UpsertContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
	UpsertContactMutation,
	UpsertContactMutationVariables
>;
