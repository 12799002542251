import _ from "lodash";
import React from "react";
import { weekDays } from "../../constants";
import WorkingDay, { IWorkingDay } from "./WorkingDay";

interface IWorkingHoursProps {
	workingDays: { [key: string]: IWorkingDay } | undefined;
	setWorkingDays: React.Dispatch<React.SetStateAction<{ [key: string]: IWorkingDay } | undefined>>;
}

const WorkingHours = (props: IWorkingHoursProps) => {
	const { workingDays, setWorkingDays } = props;

	if (!workingDays) {
		return null;
	}

	const onChange = (value: IWorkingDay) => {
		workingDays[value.weekday] = value;
		setWorkingDays({ ...workingDays });
	};

	return (
		<section className="working-table">
			{Object.keys(weekDays).map((day) => (
				<WorkingDay key={day} workingDay={workingDays[day] || { weekday: day }} onChange={onChange} />
			))}
		</section>
	);
};

export default WorkingHours;
