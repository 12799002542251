import React from "react";
import ProfileTypes from "../constants/profileTypes";
import Themes from "../constants/themes";
import { EProfileCheckStatus } from "../graphql/types";
import { getProfileCheckStatusClass } from "../utils/utils";

interface IAvatarProps {
	size: number;
	type?: ProfileTypes;
	URL?: string | null;
	checkStatus?: EProfileCheckStatus | null;
}

const getAvatarStyles = (size: number) => {
	const border = Math.min(Math.max(Math.round(size / 25), 2), 5);
	const outerRadius = Math.round(size / 2);
	const outerSize = size - border * 2;
	const innerRadius = Math.round(outerSize / 2);
	const innerSize = outerSize - border * 2;
	return {
		outer: { borderWidth: border, borderRadius: outerRadius, width: outerSize, height: outerSize },
		inner: { borderWidth: border, borderRadius: innerRadius, width: innerSize, height: innerSize },
		icon: { fontSize: size / 2, lineHeight: `${innerSize}px` }
	};
};

const Avatar: React.ComponentType<IAvatarProps> = (props) => {
	const { size, type = ProfileTypes.Sportsman, URL, checkStatus } = props;

	const themeProfile = Themes[type] || Themes[ProfileTypes.Organization];
	const styles = getAvatarStyles(size);
	const statusClass = getProfileCheckStatusClass(checkStatus);
	const needCheckStatus = !!checkStatus && (type === ProfileTypes.Federation || type === ProfileTypes.School);

	return (
		<div className={`avatar ${themeProfile.border}`} style={styles.outer}>
			<div className={`avatar__content ${themeProfile.background}`} style={styles.inner}>
				{URL ? (
					<div style={{ backgroundImage: `url(${URL})` }} />
				) : (
					<i className={`icon ${themeProfile.avatar}`} style={styles.icon} />
				)}
			</div>
			{needCheckStatus && (
				<>
					<i className="icon badge-icon icon--profile-star" />
					<i className={`icon badge-icon icon--${statusClass}`} />
				</>
			)}
		</div>
	);
};

export default Avatar;
