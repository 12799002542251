import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ProfileOpenFullFragment } from "../fragment/Profile.generated";
import { ProfileOpenFullFragmentDoc } from "../fragment/Profile.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ProfileOpenFullQueryVariables = Types.Exact<{
	input: Types.ProfileArgsInput;
}>;

export type ProfileOpenFullQuery = { __typename?: "Query" } & {
	Profile: { __typename?: "Profile" } & ProfileOpenFullFragment;
};

export const ProfileOpenFullDocument = gql`
	query ProfileOpenFull($input: ProfileArgsInput!) {
		Profile(input: $input) {
			...ProfileOpenFull
		}
	}
	${ProfileOpenFullFragmentDoc}
`;
export type ProfileOpenFullComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>,
	"query"
> &
	({ variables: ProfileOpenFullQueryVariables; skip?: boolean } | { skip: boolean });

export const ProfileOpenFullComponent = (props: ProfileOpenFullComponentProps) => (
	<ApolloReactComponents.Query<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>
		query={ProfileOpenFullDocument}
		{...props}
	/>
);

export type ProfileOpenFullProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>;
} &
	TChildProps;
export function withProfileOpenFull<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		ProfileOpenFullQuery,
		ProfileOpenFullQueryVariables,
		ProfileOpenFullProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		ProfileOpenFullQuery,
		ProfileOpenFullQueryVariables,
		ProfileOpenFullProps<TChildProps, TDataName>
	>(ProfileOpenFullDocument, {
		alias: "profileOpenFull",
		...operationOptions
	});
}

/**
 * __useProfileOpenFullQuery__
 *
 * To run a query within a React component, call `useProfileOpenFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileOpenFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileOpenFullQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileOpenFullQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>
) {
	return ApolloReactHooks.useQuery<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>(
		ProfileOpenFullDocument,
		baseOptions
	);
}
export function useProfileOpenFullLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<ProfileOpenFullQuery, ProfileOpenFullQueryVariables>(
		ProfileOpenFullDocument,
		baseOptions
	);
}
export type ProfileOpenFullQueryHookResult = ReturnType<typeof useProfileOpenFullQuery>;
export type ProfileOpenFullLazyQueryHookResult = ReturnType<typeof useProfileOpenFullLazyQuery>;
export type ProfileOpenFullQueryResult = ApolloReactCommon.QueryResult<
	ProfileOpenFullQuery,
	ProfileOpenFullQueryVariables
>;
