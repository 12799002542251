import { CalendarEvent, generateEvents } from "@athlete/calendar";
import _ from "lodash";
import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { typesEvent } from "../constants";
import { useCalendarLinkLazyQuery } from "../graphql/query/CalendarLink.generated";
import { useLinkParticipateEventLazyQuery } from "../graphql/query/LinkParticipateEvent.generated";
import { useProfileShortLazyQuery } from "../graphql/query/ProfileShort.generated";
import { ELinkParticipateEventType } from "../graphql/types";
import { getContactName, getProfileName } from "../utils/utils";
import Modal from "./modal";

interface ILoadAgendaEventList {
	evtList: Map<number, Array<CalendarEvent>>;
	viewedFromDate: Date;
	viewedTillDate: Date;
	calendarEventList: any;
}

interface ICalendarDayProps {
	event: CalendarEvent;
}

interface ICalendarWeekProps {
	startWeekDay: number;
	evtList: Map<number, Array<CalendarEvent>>;
}

interface ICalendarProps {
	QueryParams: string;
	titleShow?: boolean;
}

moment.locale("ru");

const nowUnix = moment()
	.startOf("day")
	.unix();

// Генерируюет события из исходных данных запроса, группирует по дате и записывает в единое хранилище
const loadAgendaEventList = (props: ILoadAgendaEventList) => {
	const { evtList, viewedFromDate, viewedTillDate, calendarEventList } = props;
	// Генерируются события для календаря
	const calendarGenerateEvents = _.sortBy(
		_.compact(
			_.flatMap(calendarEventList, (evt) => {
				return generateEvents(viewedFromDate, viewedTillDate, evt);
			})
		),
		["data.StartTime", "data.Name"]
	);

	// Группируются по дате
	const groupItems = _.groupBy(calendarGenerateEvents, (eventItem) => {
		return moment(eventItem.data.StartTime)
			.startOf("day")
			.unix();
	});

	// Записываем события в единое хранилище
	// Исходим из предположения, что мы не получаем дублирующих событий и не получаем событий, которые необходимо докидывать в существующий день
	for (const key in groupItems) {
		if (groupItems.hasOwnProperty(key)) {
			evtList.set(Number(key), groupItems[key]);
		}
	}

	return evtList;
};

const CalendarDay = (props: ICalendarDayProps) => {
	const { event } = props;
	const status = event.data.Status;
	let statusClass = "new";
	if (status === "FINISHED") {
		statusClass = "finished";
	} else if (status === "CANCELED") {
		statusClass = "canceled";
	}

	const [getLinkQuery, { data, loading, error }] = useLinkParticipateEventLazyQuery();

	const isVisibleButtonParticipate =
		status !== "FINISHED" && status !== "CANCELED" && moment.utc().isBefore(event.data.EndTime);

	const onClickButton = async () => {
		if (data?.LinkParticipateEvent) {
			window.location.href = data.LinkParticipateEvent;
			return;
		}
		getLinkQuery({
			variables: {
				input: {
					Type:
						!event.data.MaxParticipants || event.data.AccessType === "CLOSED"
							? ELinkParticipateEventType.Follower
							: ELinkParticipateEventType.Participant,
					EventId: event.getRealId(),
					StartTime: event.data.Recurrent ? event.data.StartTime.toISOString() : undefined
				}
			}
		});
	};

	useEffect(() => {
		if (data?.LinkParticipateEvent) {
			window.location.href = data.LinkParticipateEvent;
		}
	}, [data]);

	return (
		<div className="calendar-day">
			<div className={`calendar-day__content ${statusClass}`}>
				<div className="calendar-day__time daytime">
					<span className={`daytime__icon ${statusClass}`} />
					<p className="daytime__time">{moment(event.data.StartTime).format("HH:mm")}</p>
					<p className="daytime__time">&nbsp;-&nbsp;</p>
					<p className="daytime__time">{moment(event.data.EndTime).format("HH:mm")}</p>
				</div>
				<p className="name">{event.data.Name}</p>
				<div className="type">
					{event.data.Badges && event.data.Badges.New && <span className="type__label">New</span>}
					<p className="type__name">{typesEvent[event.data.AccessType]}</p>
				</div>
				{event.data.ProfilePlace && <p>{event.data.ProfilePlace.Name}</p>}
				<div className="calendar-day__coach">
					{event.data.Coaches ? (
						event.data.Coaches.map((coach) => (
							<p key={coach.Id} className="coachname">
								{getContactName(coach, "тренер не указан")}
							</p>
						))
					) : (
						<p className="coachname">(без тренера)</p>
					)}
				</div>
				<p>Участников: {event.data.MaxParticipants ? event.data.MaxParticipants : "не ограничено"}</p>
				{!!event.data.Cost && <span className="paid">₽</span>}
				{isVisibleButtonParticipate && (
					<button
						className={`calendar-day__button ${
							!event.data.MaxParticipants
								? "calendar-day__button--follower"
								: "calendar-day__button--participant"
						}`}
						onClick={onClickButton}
						disabled={loading}
					>
						{!event.data.MaxParticipants || event.data.AccessType === "CLOSED"
							? "В расписание"
							: "Записаться"}
					</button>
				)}
			</div>
		</div>
	);
};

// Генерация недели по конкретной дате
const calendarWeekArray = (startWeekDayUnix: number) => {
	const dates = [];
	for (let i = 0; i < 7; i += 1) {
		dates[i] = moment.unix(startWeekDayUnix).add(i, "day");
	}
	return dates;
};

const CalendarWeek = (props: ICalendarWeekProps) => {
	const { startWeekDay, evtList } = props;
	const daysWeek = useMemo(() => calendarWeekArray(startWeekDay), [startWeekDay]);
	return (
		<div className="calendar-week">
			{daysWeek.map((day, key) => {
				const dayUnix = day.unix();
				const events = evtList.get(dayUnix);
				const today = nowUnix === dayUnix ? " today" : "";
				return (
					<div className={`calendar-week__day${today}`} key={key}>
						<div className="calendar-week__header">
							<div className="week-day-name">{day.format("dddd")}</div>
							<div>{day.format("DD MMMM")}</div>
						</div>
						<div className="calendar-week__content">
							{events &&
								events.map((event) => {
									return <CalendarDay event={event} key={event.data.Id} />;
								})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

const Calendar: React.ComponentType<ICalendarProps> = (props) => {
	const { QueryParams, titleShow = true } = props;

	// События: Дата события - массив событий
	const evtList = useRef<Map<number, Array<CalendarEvent>>>(new Map());
	// Загруженные недели
	const [weeksLoaded, setWeeksLoaded] = useState<Array<number>>([]);
	// Начало недели (понедельник)
	const [startWeekDay, setStartWeekDay] = useState(
		moment()
			.startOf("isoWeek")
			.unix()
	);
	// iframe
	const iframeText = `<iframe width="100%" height="600" src="${window.location}?view=iframe" frameborder="0"></iframe>`;

	// Параметры запроса из хеша
	const params = useMemo(() => {
		try {
			return JSON.parse(QueryParams);
		} catch (e) {
			console.log("Error parsing QueryParams:", e);
			return;
		}
	}, [QueryParams]);

	const [getCalendar, result] = useCalendarLinkLazyQuery();
	const [
		getProfile,
		{ data: profileData, loading: profileLoading, error: profileError }
	] = useProfileShortLazyQuery();

	useEffect(() => {
		if (!titleShow) {
			return;
		}
		getProfile({ variables: { input: { Id: params.ProfileId } } });
	}, []);

	const name = getProfileName(profileData?.Profile);

	useEffect(() => {
		if (!result.data?.CalendarLink || !result.variables) {
			return;
		}
		const startTime = moment.utc(result.variables.input.StartTime);
		const endTime = moment.utc(result.variables.input.EndTime);
		if (result.data.CalendarLink.length) {
			loadAgendaEventList({
				evtList: evtList.current,
				viewedFromDate: startTime.toDate(),
				viewedTillDate: endTime.toDate(),
				calendarEventList: result.data.CalendarLink
			});
		}

		const weeksLoadedCurrent: Array<number> = [];
		let currentWeekLoaded = startTime.unix();
		do {
			weeksLoadedCurrent.push(currentWeekLoaded);
			currentWeekLoaded = moment
				.unix(currentWeekLoaded)
				.add(1, "week")
				.unix();
		} while (currentWeekLoaded <= endTime.unix());
		setWeeksLoaded((prev) => [...prev, ...weeksLoadedCurrent]);
	}, [result]);

	// При смене недели запускается
	// В useEffect должны быть все зависимости по мнению gatsby, которые используются, иначе будет кидать warning
	useEffect(() => {
		if (!params) {
			return;
		}
		// Предыдущая неделя
		const prev = moment
			.unix(startWeekDay)
			.add(-1, "week")
			.unix();
		// Следующая неделя
		const next = moment
			.unix(startWeekDay)
			.add(1, "week")
			.unix();

		const weeksLoad = [];
		if (!weeksLoaded.includes(prev)) {
			weeksLoad.push(prev);
		}
		if (!weeksLoaded.includes(startWeekDay)) {
			weeksLoad.push(startWeekDay);
		}
		if (!weeksLoaded.includes(next)) {
			weeksLoad.push(next);
		}

		if (!weeksLoad.length) {
			return;
		}

		const start = Math.min.apply(null, weeksLoad);
		const end = Math.max.apply(null, weeksLoad);

		getCalendar({
			variables: {
				input: {
					StartTime: moment.unix(start).toISOString(),
					EndTime: moment
						.unix(end)
						.endOf("isoWeek")
						.toISOString(),
					...params
				}
			}
		});
	}, [startWeekDay, params, weeksLoaded]);

	const showModal = () => {
		const element = document.getElementById("calendar-iframe-modal");
		if (element) {
			element.classList.add("active");
		}
	};

	const copyIframeText = () => {
		const copyText = document.getElementById("calendar-copy-text");
		if (!copyText) {
			return;
		}
		(copyText as HTMLTextAreaElement).select();

		document.execCommand("copy");
	};

	return (
		<>
			{titleShow && (
				<div className="calendar-header">
					<h1>{name ? `Расписание "${name}"` : "Расписание"}</h1>
					<button className="button" onClick={showModal}>
						Встроить на сайт
					</button>
					<Modal id="calendar-iframe-modal" buttons={[{ text: "Копировать", onClick: copyIframeText }]}>
						<textarea
							id="calendar-copy-text"
							className="calendar-copy-text"
							placeholder="Код для вставки"
							defaultValue={iframeText}
						/>
					</Modal>
				</div>
			)}
			<div className="calendar-nav">
				<button
					onClick={() =>
						setStartWeekDay(
							moment
								.unix(startWeekDay)
								.add(-1, "week")
								.unix()
						)
					}
				>
					Предыдущая неделя
				</button>
				<div className="calendar-nav__titleblock">
					<h2>{moment.unix(startWeekDay).format("MMMM")}</h2>
					{result.loading && <span className="loading"> - загрузка...</span>}
				</div>
				<button
					onClick={() =>
						setStartWeekDay(
							moment
								.unix(startWeekDay)
								.add(1, "week")
								.unix()
						)
					}
				>
					Следующая неделя
				</button>
			</div>
			<CalendarWeek startWeekDay={startWeekDay} evtList={evtList.current} />
		</>
	);
};

export default Calendar;
