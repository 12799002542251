import * as Types from "../types";

import gql from "graphql-tag";

export type AttachmentFullFragment = { __typename?: "Attachment" } & Pick<
	Types.Attachment,
	| "Id"
	| "CreatedAt"
	| "UpdatedAt"
	| "Type"
	| "Data"
	| "Description"
	| "Relation"
	| "RelationId"
	| "FileLink"
	| "PreviewLink"
	| "MutationStatus"
>;

export type AttachmentLinkFragment = { __typename?: "Attachment" } & Pick<Types.Attachment, "Id" | "FileLink">;

export type AttachmentForGalleryFragment = { __typename?: "Attachment" } & Pick<
	Types.Attachment,
	"Id" | "Type" | "FileLink" | "PreviewLink" | "Description" | "Data"
>;

export const AttachmentFullFragmentDoc = gql`
	fragment AttachmentFull on Attachment {
		Id
		CreatedAt
		UpdatedAt
		Type
		Data
		Description
		Relation
		RelationId
		FileLink
		PreviewLink
		MutationStatus
	}
`;
export const AttachmentLinkFragmentDoc = gql`
	fragment AttachmentLink on Attachment {
		Id
		FileLink
	}
`;
export const AttachmentForGalleryFragmentDoc = gql`
	fragment AttachmentForGallery on Attachment {
		Id
		Type
		FileLink
		PreviewLink
		Description
		Data
	}
`;
