import { sportIsOlimpic, sportIsSummer, sportIsWinter } from "@athlete/utils";
import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { SportTrainingStageList } from "../../../constants";
import { StatisticsStagesSportFullFragment } from "../../../graphql/fragment/StatisticsStagesFull.generated";
import { StatisticsStagesOrgFullFragment } from "../../../graphql/fragment/StatisticsStagesOrgFull.generated";
import { useStatisticsStageseByOrganizationsQuery } from "../../../graphql/query/StatisticsStageseByOrganizations.generated";
import { useStatisticsStageseBySportsQuery } from "../../../graphql/query/StatisticsStageseBySports.generated";
import { EProfileSportTrainingStage } from "../../../graphql/types";
import CommonTable, { TCustomStyleClasses } from "../../common/CommonTable";

interface IStatisticsSportsProps {
	hash: string;
}

const StatisticsStagesSport = (props: IStatisticsSportsProps) => {
	const { hash } = props;

	const { data, loading } = useStatisticsStageseBySportsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const { data: dataOrgs, loading: loadingOrgs } = useStatisticsStageseByOrganizationsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	if (loading || loadingOrgs) {
		return <CircularProgress color="primary" size="small" />;
	}

	const statisticBySports: Array<StatisticsStagesSportFullFragment> | null | undefined =
		data?.StatisticsStageseBySports;
	const statisticByOrgs: Array<StatisticsStagesOrgFullFragment> | null | undefined =
		dataOrgs?.StatisticsStageseByOrganizations;

	return (
		<>
			<StatisticsStagesSportTable
				key={0}
				statistic={statisticBySports}
				title={"Программы обучения - виды спорта"}
			/>
			<StatisticsStagesOrgsTable
				key={1}
				statistic={statisticByOrgs}
				title={"Программы обучения - типы организаций"}
			/>
		</>
	);
};

interface IStatisticsStagesSportTableProps {
	statistic: Array<StatisticsStagesSportFullFragment> | null | undefined;
	title: string;
}

type TColumnsKeys = keyof Omit<StatisticsStagesSportFullFragment, "__typename" | "SportId" | "SportName" | "SportCode">;

const columns: Array<[TColumnsKeys, string]> = [
	["Wellness", SportTrainingStageList[EProfileSportTrainingStage.Wellness]],
	["Initial", SportTrainingStageList[EProfileSportTrainingStage.Initial]],
	["Training", SportTrainingStageList[EProfileSportTrainingStage.Training]],
	["Improvement", SportTrainingStageList[EProfileSportTrainingStage.Improvement]],
	["Higher", SportTrainingStageList[EProfileSportTrainingStage.Higher]],
	["All", "Всего на этапах спортивной подготовки"],
	["AdditionalEducation", "Обучающиеся по дополнительным общеобразовательным программам"]
];

const headers = ["", ...columns.map(([, value]) => value)];

const headersKeys = columns.map(([key]) => key);

const StatisticsStagesSportTable = (props: IStatisticsStagesSportTableProps) => {
	const { statistic, title } = props;

	const dataSet = useMemo(() => {
		const totalSports: { [key in TColumnsKeys]: number } = {
			AdditionalEducation: 0,
			All: 0,
			Higher: 0,
			Improvement: 0,
			Initial: 0,
			Training: 0,
			Wellness: 0
		};
		const totalOlimpicSports: { [key in TColumnsKeys]: number } = {
			AdditionalEducation: 0,
			All: 0,
			Higher: 0,
			Improvement: 0,
			Initial: 0,
			Training: 0,
			Wellness: 0
		};
		const totalSummer: { [key in TColumnsKeys]: number } = {
			AdditionalEducation: 0,
			All: 0,
			Higher: 0,
			Improvement: 0,
			Initial: 0,
			Training: 0,
			Wellness: 0
		};
		const totalWinter: { [key in TColumnsKeys]: number } = {
			AdditionalEducation: 0,
			All: 0,
			Higher: 0,
			Improvement: 0,
			Initial: 0,
			Training: 0,
			Wellness: 0
		};
		const totalAllSeasons: { [key in TColumnsKeys]: number } = {
			AdditionalEducation: 0,
			All: 0,
			Higher: 0,
			Improvement: 0,
			Initial: 0,
			Training: 0,
			Wellness: 0
		};

		const result = statistic?.map((row) => [
			row.SportName,
			...headersKeys.map((key) => {
				totalSports[key] += row[key];

				if (sportIsOlimpic(row.SportCode)) {
					totalOlimpicSports[key] += row[key];
					if (sportIsSummer(row.SportCode)) totalSummer[key] += row[key];
					else if (sportIsWinter(row.SportCode)) totalWinter[key] += row[key];
					else totalAllSeasons[key] += row[key];
				}
				return row[key];
			})
		]);

		result?.push(
			[
				"Итого по спортам ",
				totalSports.Wellness,
				totalSports.Initial,
				totalSports.Training,
				totalSports.Improvement,
				totalSports.Higher,
				totalSports.All,
				totalSports.AdditionalEducation
			],
			[
				"Итого по олимпийским видам спорта",
				totalOlimpicSports.Wellness,
				totalOlimpicSports.Initial,
				totalOlimpicSports.Training,
				totalOlimpicSports.Improvement,
				totalOlimpicSports.Higher,
				totalOlimpicSports.All,
				totalOlimpicSports.AdditionalEducation
			],
			[
				"в т.ч по летним видам",
				totalSummer.Wellness,
				totalSummer.Initial,
				totalSummer.Training,
				totalSummer.Improvement,
				totalSummer.Higher,
				totalSummer.All,
				totalSummer.AdditionalEducation
			],
			[
				"в т.ч по зимним видам",
				totalWinter.Wellness,
				totalWinter.Initial,
				totalWinter.Training,
				totalWinter.Improvement,
				totalWinter.Higher,
				totalWinter.All,
				totalWinter.AdditionalEducation
			],
			[
				"в т.ч по внесезонным видам",
				totalAllSeasons.Wellness,
				totalAllSeasons.Initial,
				totalAllSeasons.Training,
				totalAllSeasons.Improvement,
				totalAllSeasons.Higher,
				totalAllSeasons.All,
				totalAllSeasons.AdditionalEducation
			]
		);
		return result;
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {
			[dataSet.length - 5]: "blue-cell",
			[dataSet.length - 4]: "blue-cell"
		},
		columns: {
			[dataSet[0].length - 1]: "green-cell",
			[dataSet[0].length - 2]: "green-cell"
		}
	};

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

interface IStatisticsStagesOrgsTableProps {
	statistic: Array<StatisticsStagesOrgFullFragment> | null | undefined;
	title: string;
}

const StatisticsStagesOrgsTable = (props: IStatisticsStagesOrgsTableProps) => {
	const { statistic, title } = props;

	const orgTypeTitles: { [key: string]: string } = useMemo(
		() => ({
			"0": "Всего по СШ",
			"1": "Всего по ДЮСШ",
			"8": "Всего по СДЮСШОР",
			"9": "Всего по СШОР",
			"10": "Всего по УОР",
			"11": "Всего по ЦСП",
			"12": "Всего по ЦОП",
			"4": "Всего других"
		}),
		[]
	);
	const [dataSet, totalRowsIndexes] = useMemo(() => {
		const totalIndexes: Array<number> = [];

		return [
			statistic?.map((row, index) => {
				let text = "";
				switch (row.Department) {
					case null:
						text = orgTypeTitles[row.OrgType] || "";
						totalIndexes.push(index);
						break;
					case "Education":
						text = "приналдежат образованию";
						break;
					case "Sport":
						text = "принадлежат ФКиС";
						break;
					case "Other":
						text = "другая принадлежность";
						break;
					default:
						break;
				}

				return [text, ...headersKeys.map((key) => row[key])];
			}),
			totalIndexes
		];
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {},
		columns: {
			[dataSet[0].length - 1]: "green-cell",
			[dataSet[0].length - 2]: "green-cell"
		}
	};

	totalRowsIndexes.forEach((index) => {
		if (customStyleClassesIndexes?.rows) {
			customStyleClassesIndexes.rows[index] = "blue-cell";
		}
	});

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

export default StatisticsStagesSport;
