import { sportIsOlimpic, sportIsSummer, sportIsWinter } from "@athlete/utils";
import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { shortSportRankList } from "../../../constants";
import { StatisticsSportRanksOrgFullFragment } from "../../../graphql/fragment/StatisticsSportRanksOrgFull.generated";
import { StatisticsSportRanksSportFullFragment } from "../../../graphql/fragment/StatisticsSportRanksSportFull.generated";
import { useStatisticsSportRanksByOrganizationsQuery } from "../../../graphql/query/StatisticsSportRanksByOrganizations.generated";
import { useStatisticsSportRanksBySportsQuery } from "../../../graphql/query/StatisticsSportRanksBySports.generated";
import { ESportRank } from "../../../graphql/types";
import CommonTable, { TCustomStyleClasses } from "../../common/CommonTable";

type TSection =
	| "ranks-Total"
	| "ranks-Gained"
	| "ranks-Confirmed"
	| "masters-Total"
	| "masters-Gained"
	| "total-Total"
	| "total-Gained-Confirmed";

type TColumnsKeys =
	| keyof Omit<StatisticsSportRanksSportFullFragment, "__typename" | "SportId" | "SportName" | "SportCode">
	| "TotalRank"
	| "TotalMasters"
	| "Total";

const masterColums: Array<[TColumnsKeys, string]> = [
	["SportsMasterVeteran", shortSportRankList[ESportRank.SportsMasterVeteran]],
	["InternationalSportsMaster", shortSportRankList[ESportRank.InternationalSportsMaster]],
	["SportsMaster", shortSportRankList[ESportRank.SportsMaster]],
	["TotalMasters", "Всего по званиям"]
];

const columns: { [key in TSection]: Array<[TColumnsKeys, string]> } = {
	"ranks-Total": [
		["SportsMasterCandidate", shortSportRankList[ESportRank.SportsMasterCandidate]],
		["FirstRank", shortSportRankList[ESportRank.FirstRank]],
		["Other", "другие разряды"],
		["TotalRank", "Всего по разрядам"]
	],
	"ranks-Gained": [
		["SportsMasterCandidate", shortSportRankList[ESportRank.SportsMasterCandidate] + " присвоен"],
		["FirstRank", shortSportRankList[ESportRank.FirstRank] + " присвоен"],
		["Other", "другие разряды присвоены"],
		["TotalRank", "Всего присвоено"]
	],
	"ranks-Confirmed": [
		["SportsMasterCandidate", shortSportRankList[ESportRank.SportsMasterCandidate] + " подтвержден"],
		["FirstRank", shortSportRankList[ESportRank.FirstRank] + " подтвержден"],
		["Other", "другие разряды подтверждены"],
		["TotalRank", "Всего подтверждено"]
	],
	"masters-Total": masterColums,
	"masters-Gained": masterColums,
	"total-Total": [["Total", "Общее количество"]],
	"total-Gained-Confirmed": [["Total", "Всего"]]
};

const getValueByKey = (
	key: TColumnsKeys,
	row: Pick<
		StatisticsSportRanksSportFullFragment,
		| "FirstRank"
		| "InternationalSportsMaster"
		| "Other"
		| "SportsMaster"
		| "SportsMasterCandidate"
		| "SportsMasterVeteran"
	>,
	fields: Array<"Total" | "Gained" | "Confirmed">
) => {
	let value: number = 0;
	fields.forEach((field) => {
		switch (key) {
			case "SportsMasterCandidate":
			case "FirstRank":
			case "SportsMasterVeteran":
			case "InternationalSportsMaster":
			case "SportsMaster":
			case "Other":
				value += row[key][field] || 0;
				break;
			case "TotalRank":
				value +=
					(row.SportsMasterCandidate[field] || 0) + (row.FirstRank[field] || 0) + (row.Other[field] || 0);
				break;
			case "TotalMasters":
				value +=
					(row.InternationalSportsMaster[field] || 0) +
					(row.SportsMaster[field] || 0) +
					(row.SportsMasterVeteran[field] || 0);
				break;
			case "Total":
				value +=
					(row.SportsMasterCandidate[field] || 0) +
					(row.FirstRank[field] || 0) +
					(row.Other[field] || 0) +
					(row.InternationalSportsMaster[field] || 0) +
					(row.SportsMaster[field] || 0) +
					(row.SportsMasterVeteran[field] || 0);
				break;
			default:
				break;
		}
	});
	return value;
};

interface IStatisticsSportsProps {
	hash: string;
}

const StatisticsSportRanks = (props: IStatisticsSportsProps) => {
	const { hash } = props;

	const { data, loading } = useStatisticsSportRanksBySportsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const { data: dataOrgs, loading: loadingOrgs } = useStatisticsSportRanksByOrganizationsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	if (loading || loadingOrgs) {
		return <CircularProgress color="primary" size="small" />;
	}

	const statisticBySports: Array<StatisticsSportRanksSportFullFragment> | null | undefined =
		data?.StatisticsSportRanksBySports;
	const statisticByOrgs: Array<StatisticsSportRanksOrgFullFragment> | null | undefined =
		dataOrgs?.StatisticsSportRanksByOrganizations;

	return (
		<>
			<StatisticsSportRanksTable
				key={0}
				statistic={statisticBySports}
				title={"Разряды и звания по видам спорта"}
				sections={["ranks-Total", "masters-Total", "total-Total"]}
			/>
			<StatisticsSportRanksOrgsTable
				key={1}
				statistic={statisticByOrgs}
				title={"Разряды и звания по типам организаций"}
				sections={["ranks-Total", "masters-Total", "total-Total"]}
			/>
			<StatisticsSportRanksTable
				key={2}
				statistic={statisticBySports}
				title={"Полученные и подтвержденные звания по видам спорта"}
				sections={["ranks-Gained", "ranks-Confirmed", "masters-Gained", "total-Gained-Confirmed"]}
			/>
			<StatisticsSportRanksOrgsTable
				key={3}
				statistic={statisticByOrgs}
				title={"Полученные и подтвержденные звания по типам организаций"}
				sections={["ranks-Gained", "ranks-Confirmed", "masters-Gained", "total-Gained-Confirmed"]}
			/>
		</>
	);
};

interface IStatisticsSportRanksTableProps {
	statistic: Array<StatisticsSportRanksSportFullFragment> | null | undefined;
	title: string;
	sections: Array<TSection>;
}

const StatisticsSportRanksTable = (props: IStatisticsSportRanksTableProps) => {
	const { statistic, title, sections } = props;

	const headers = ["", ...sections.map((sectionName) => columns[sectionName].map(([, value]) => value)).flat()];

	const dataSet = useMemo(() => {
		const totalSports: { [key: string]: number | undefined } = {};
		const totalOlimpicSports: { [key: string]: number | undefined } = {};
		const totalSummer: { [key: string]: number | undefined } = {};
		const totalWinter: { [key: string]: number | undefined } = {};
		const totalAllSeasons: { [key: string]: number | undefined } = {};

		const result: Array<Array<number | string | undefined>> | undefined = statistic?.map((row) => [
			row.SportName,
			...sections
				.map((sectionName) => {
					const [, ...fields] = sectionName.split("-");

					return columns[sectionName].map(([key]) => {
						const value = getValueByKey(key, row, fields as Array<"Total" | "Gained" | "Confirmed">);

						totalSports[sectionName + key] = (totalSports[sectionName + key] || 0) + value;
						if (sportIsOlimpic(row.SportCode)) {
							totalOlimpicSports[key] = (totalOlimpicSports[key] || 0) + value;
							if (sportIsSummer(row.SportCode)) totalSummer[key] = (totalSummer[key] || 0) + value;
							else if (sportIsWinter(row.SportCode)) totalWinter[key] = (totalWinter[key] || 0) + value;
							else totalAllSeasons[key] = (totalAllSeasons[key] || 0) + value;
						}
						return value;
					});
				})
				.flat()
		]);

		result?.push(
			[
				"Итого по спортам ",
				...sections
					.map((sectionName) => columns[sectionName].map(([key]) => totalSports[sectionName + key] || 0))
					.flat()
			],
			[
				"Итого по олимпийским видам спорта",
				...sections
					.map((sectionName) =>
						columns[sectionName].map(([key]) => totalOlimpicSports[sectionName + key] || 0)
					)
					.flat()
			],
			[
				"в т.ч по летним видам",
				...sections
					.map((sectionName) => columns[sectionName].map(([key]) => totalSummer[sectionName + key] || 0))
					.flat()
			],
			[
				"в т.ч по зимним видам",
				...sections
					.map((sectionName) => columns[sectionName].map(([key]) => totalWinter[sectionName + key] || 0))
					.flat()
			],
			[
				"в т.ч по внесезонным видам",
				...sections
					.map((sectionName) => columns[sectionName].map(([key]) => totalAllSeasons[sectionName + key] || 0))
					.flat()
			]
		);
		return result;
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: { [dataSet.length - 5]: "blue-cell", [dataSet.length - 4]: "blue-cell" },

		columns: {
			4: "orange-cell",
			8: "orange-cell",
			12: "orange-cell",
			[dataSet[0].length - 1]: "green-cell"
		}
	};

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

interface IStatisticsSportRanksOrgsTableProps {
	statistic: Array<StatisticsSportRanksOrgFullFragment> | null | undefined;
	title: string;
	sections: Array<TSection>;
}

const StatisticsSportRanksOrgsTable = (props: IStatisticsSportRanksOrgsTableProps) => {
	const { statistic, title, sections } = props;

	const orgTypeTitles: { [key: string]: string } = useMemo(
		() => ({
			"0": "Всего по СШ",
			"1": "Всего по ДЮСШ",
			"8": "Всего по СДЮСШОР",
			"9": "Всего по СШОР",
			"10": "Всего по УОР",
			"11": "Всего по ЦСП",
			"12": "Всего по ЦОП",
			"4": "Всего других"
		}),
		[]
	);
	const [dataSet, totalRowsIndexes] = useMemo(() => {
		const totalIndexes: Array<number> = [];

		return [
			statistic?.map((row, index) => {
				let text = "";
				switch (row.Department) {
					case null:
						text = orgTypeTitles[row.OrgType] || "";
						totalIndexes.push(index);
						break;
					case "Education":
						text = "приналдежат образованию";
						break;
					case "Sport":
						text = "принадлежат ФКиС";
						break;
					case "Other":
						text = "другая принадлежность";
						break;
					default:
						break;
				}

				return [
					text,
					...sections
						.map((sectionName) => {
							const [, ...fields] = sectionName.split("-");

							return columns[sectionName].map(([key]) =>
								getValueByKey(key, row, fields as Array<"Total" | "Gained" | "Confirmed">)
							);
						})
						.flat()
				];
			}),
			totalIndexes
		];
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const headers = ["", ...sections.map((sectionName) => columns[sectionName].map(([, value]) => value)).flat()];

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {},
		columns: {
			4: "orange-cell",
			8: "orange-cell",
			12: "orange-cell",
			[dataSet[0].length - 1]: "green-cell"
		}
	};

	totalRowsIndexes.forEach((index) => {
		if (customStyleClassesIndexes?.rows) {
			customStyleClassesIndexes.rows[index] = "blue-cell";
		}
	});

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

export default StatisticsSportRanks;
