import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { EventForCalendarFragment } from "../fragment/Event.generated";
import { EventForCalendarFragmentDoc } from "../fragment/Event.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type CalendarLinkQueryVariables = Types.Exact<{
	input: Types.CalendarViewInput;
}>;

export type CalendarLinkQuery = { __typename?: "Query" } & {
	CalendarLink?: Types.Maybe<Array<{ __typename?: "Event" } & EventForCalendarFragment>>;
};

export const CalendarLinkDocument = gql`
	query CalendarLink($input: CalendarViewInput!) {
		CalendarLink(input: $input) {
			...EventForCalendar
		}
	}
	${EventForCalendarFragmentDoc}
`;
export type CalendarLinkComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<CalendarLinkQuery, CalendarLinkQueryVariables>,
	"query"
> &
	({ variables: CalendarLinkQueryVariables; skip?: boolean } | { skip: boolean });

export const CalendarLinkComponent = (props: CalendarLinkComponentProps) => (
	<ApolloReactComponents.Query<CalendarLinkQuery, CalendarLinkQueryVariables>
		query={CalendarLinkDocument}
		{...props}
	/>
);

export type CalendarLinkProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<CalendarLinkQuery, CalendarLinkQueryVariables>;
} &
	TChildProps;
export function withCalendarLink<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		CalendarLinkQuery,
		CalendarLinkQueryVariables,
		CalendarLinkProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		CalendarLinkQuery,
		CalendarLinkQueryVariables,
		CalendarLinkProps<TChildProps, TDataName>
	>(CalendarLinkDocument, {
		alias: "calendarLink",
		...operationOptions
	});
}

/**
 * __useCalendarLinkQuery__
 *
 * To run a query within a React component, call `useCalendarLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarLinkQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarLinkQuery, CalendarLinkQueryVariables>
) {
	return ApolloReactHooks.useQuery<CalendarLinkQuery, CalendarLinkQueryVariables>(CalendarLinkDocument, baseOptions);
}
export function useCalendarLinkLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarLinkQuery, CalendarLinkQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<CalendarLinkQuery, CalendarLinkQueryVariables>(
		CalendarLinkDocument,
		baseOptions
	);
}
export type CalendarLinkQueryHookResult = ReturnType<typeof useCalendarLinkQuery>;
export type CalendarLinkLazyQueryHookResult = ReturnType<typeof useCalendarLinkLazyQuery>;
export type CalendarLinkQueryResult = ApolloReactCommon.QueryResult<CalendarLinkQuery, CalendarLinkQueryVariables>;
