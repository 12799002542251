import * as Types from "../types";

import gql from "graphql-tag";
import { ContactForAvatarFragment } from "./Contact.generated";
import { ContactForAvatarFragmentDoc } from "./Contact.generated";
import { ProfilePlaceFullFragment } from "./ProfilePlace.generated";
import { ProfilePlaceFullFragmentDoc } from "./ProfilePlace.generated";

export type EventChangeForCalendarFragment = { __typename?: "EventChange" } & Pick<
	Types.EventChange,
	| "Id"
	| "EventId"
	| "StartTime"
	| "EndTime"
	| "CreatedAt"
	| "UpdatedAt"
	| "ChangeType"
	| "MaxParticipants"
	| "Duration"
	| "Day"
	| "StartAt"
	| "Name"
	| "Comment"
	| "Cost"
	| "Status"
	| "Difficulty"
	| "MutationStatus"
	| "MetricName"
	| "MetricValue"
> & {
		Badges?: Types.Maybe<{ __typename?: "Badges" } & Pick<Types.Badges, "New" | "Promo">>;
		ProfilePlace?: Types.Maybe<{ __typename?: "ProfilePlace" } & ProfilePlaceFullFragment>;
		Coaches?: Types.Maybe<Array<{ __typename?: "Contact" } & ContactForAvatarFragment>>;
	};

export const EventChangeForCalendarFragmentDoc = gql`
	fragment EventChangeForCalendar on EventChange {
		Id
		EventId
		StartTime
		EndTime
		CreatedAt
		UpdatedAt
		ChangeType
		MaxParticipants
		Duration
		Day
		StartAt
		Name
		Comment
		Cost
		Status
		Difficulty
		MutationStatus
		MetricName
		MetricValue
		Badges {
			New
			Promo
		}
		ProfilePlace {
			...ProfilePlaceFull
		}
		Coaches {
			...ContactForAvatar
		}
	}
	${ProfilePlaceFullFragmentDoc}
	${ContactForAvatarFragmentDoc}
`;
