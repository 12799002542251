import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsStagesOrgFullFragment = { __typename?: "StatisticsStagesOrg" } & Pick<
	Types.StatisticsStagesOrg,
	| "OrgType"
	| "Department"
	| "Wellness"
	| "Initial"
	| "Training"
	| "Improvement"
	| "Higher"
	| "All"
	| "AdditionalEducation"
>;

export const StatisticsStagesOrgFullFragmentDoc = gql`
	fragment StatisticsStagesOrgFull on StatisticsStagesOrg {
		OrgType
		Department
		Wellness
		Initial
		Training
		Improvement
		Higher
		All
		AdditionalEducation
	}
`;
