import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type QueryByHashQueryVariables = Types.Exact<{
	hash: Types.Scalars["String"];
}>;

export type QueryByHashQuery = { __typename?: "Query" } & {
	QueryByHash?: Types.Maybe<{ __typename?: "Link" } & Pick<Types.Link, "Id" | "QueryType" | "QueryParams">>;
};

export const QueryByHashDocument = gql`
	query QueryByHash($hash: String!) {
		QueryByHash(hash: $hash) {
			Id
			QueryType
			QueryParams
		}
	}
`;
export type QueryByHashComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<QueryByHashQuery, QueryByHashQueryVariables>,
	"query"
> &
	({ variables: QueryByHashQueryVariables; skip?: boolean } | { skip: boolean });

export const QueryByHashComponent = (props: QueryByHashComponentProps) => (
	<ApolloReactComponents.Query<QueryByHashQuery, QueryByHashQueryVariables> query={QueryByHashDocument} {...props} />
);

export type QueryByHashProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<QueryByHashQuery, QueryByHashQueryVariables>;
} &
	TChildProps;
export function withQueryByHash<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		QueryByHashQuery,
		QueryByHashQueryVariables,
		QueryByHashProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		QueryByHashQuery,
		QueryByHashQueryVariables,
		QueryByHashProps<TChildProps, TDataName>
	>(QueryByHashDocument, {
		alias: "queryByHash",
		...operationOptions
	});
}

/**
 * __useQueryByHashQuery__
 *
 * To run a query within a React component, call `useQueryByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryByHashQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useQueryByHashQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<QueryByHashQuery, QueryByHashQueryVariables>
) {
	return ApolloReactHooks.useQuery<QueryByHashQuery, QueryByHashQueryVariables>(QueryByHashDocument, baseOptions);
}
export function useQueryByHashLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryByHashQuery, QueryByHashQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<QueryByHashQuery, QueryByHashQueryVariables>(QueryByHashDocument, baseOptions);
}
export type QueryByHashQueryHookResult = ReturnType<typeof useQueryByHashQuery>;
export type QueryByHashLazyQueryHookResult = ReturnType<typeof useQueryByHashLazyQuery>;
export type QueryByHashQueryResult = ApolloReactCommon.QueryResult<QueryByHashQuery, QueryByHashQueryVariables>;
