import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type RegionsDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RegionsDataQuery = { __typename?: "Query" } & {
	Regions: Array<
		{ __typename?: "Region" } & Pick<Types.Region, "Id" | "Name" | "Type" | "GeoLatitude" | "GeoLongitude">
	>;
};

export const RegionsDataDocument = gql`
	query RegionsData {
		Regions {
			Id
			Name
			Type
			GeoLatitude
			GeoLongitude
		}
	}
`;
export type RegionsDataComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<RegionsDataQuery, RegionsDataQueryVariables>,
	"query"
>;

export const RegionsDataComponent = (props: RegionsDataComponentProps) => (
	<ApolloReactComponents.Query<RegionsDataQuery, RegionsDataQueryVariables> query={RegionsDataDocument} {...props} />
);

export type RegionsDataProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<RegionsDataQuery, RegionsDataQueryVariables>;
} &
	TChildProps;
export function withRegionsData<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		RegionsDataQuery,
		RegionsDataQueryVariables,
		RegionsDataProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		RegionsDataQuery,
		RegionsDataQueryVariables,
		RegionsDataProps<TChildProps, TDataName>
	>(RegionsDataDocument, {
		alias: "regionsData",
		...operationOptions
	});
}

/**
 * __useRegionsDataQuery__
 *
 * To run a query within a React component, call `useRegionsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsDataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsDataQuery, RegionsDataQueryVariables>
) {
	return ApolloReactHooks.useQuery<RegionsDataQuery, RegionsDataQueryVariables>(RegionsDataDocument, baseOptions);
}
export function useRegionsDataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsDataQuery, RegionsDataQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<RegionsDataQuery, RegionsDataQueryVariables>(RegionsDataDocument, baseOptions);
}
export type RegionsDataQueryHookResult = ReturnType<typeof useRegionsDataQuery>;
export type RegionsDataLazyQueryHookResult = ReturnType<typeof useRegionsDataLazyQuery>;
export type RegionsDataQueryResult = ApolloReactCommon.QueryResult<RegionsDataQuery, RegionsDataQueryVariables>;
