import { DeepPartial } from "@athlete/utils";
import MomentUtils from "@date-io/moment";
import {
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Select
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { MedalList, SportTrainingStageList } from "../../constants";
import { EMedal, EProfileSportTrainingStage, Person, ProfileAchievement } from "../../graphql/types";
import { tempId } from "../../utils/utils";
import StandartTextInput from "../common/StandartTextInput";
import TitleAdd from "../common/TitleAdd";
import AchievementsModal from "./AchievementsModal";
import FederationsSearch from "./FederationsSearch";
import { TPartialPerson } from "./mainTab/MainPersonInfo";
import { IValidProfileEditTabsState } from "./ProfileEditTabs";

interface IAchievementsEditProps {
	hash: string;
	achievements: Array<TPartialAchievement> | undefined;
	person: Partial<Person> | undefined;
	changeAchievements: (newContacts: Array<TPartialAchievement>) => void;
	changePersonData: (
		newPersonState: DeepPartial<TPartialPerson>,
		newValidState?: DeepPartial<IValidProfileEditTabsState>
	) => void;
}
export type TPartialAchievement = Partial<ProfileAchievement> & { Id: string };

const AchievementsEdit = (props: IAchievementsEditProps) => {
	const { hash, achievements, person, changeAchievements, changePersonData } = props;

	const [modalState, setModalState] = useState<{
		visible: boolean;
		achievement?: TPartialAchievement;
	}>({ visible: false });

	const addAchievement = (achievement: TPartialAchievement) => {
		const filteredAchievement = achievements?.filter((item) => item.Id !== achievement.Id);
		changeAchievements([...(filteredAchievement || []), achievement]);
	};

	return (
		<Grid container spacing={6}>
			<AchievementsModal
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				addAchievement={addAchievement}
				hash={hash}
				{...modalState}
			/>
			<Grid item sm={6}>
				<h2>Дополнительно</h2>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardDatePicker
						disableToolbar
						fullWidth
						format="DD.MM.YYYY"
						margin="normal"
						label="Занимаюсь спортом с"
						maxDate={moment()}
						maxDateMessage="Необходимо указать дату в прошлом"
						invalidDateMessage="Некорректный формат"
						value={person?.StartSportDate || null}
						onChange={(date) => changePersonData({ StartSportDate: date })}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
				<FederationsSearch
					value={person?.Federation}
					hash={hash}
					changeValue={(newFederation) => {
						changePersonData({
							Federation: newFederation,
							FederationId: newFederation?.ProfileId || null
						});
					}}
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel id="select-sportstage-label">Этап спортивной подготовки</InputLabel>
					<Select
						value={person?.SportTrainingStage || null}
						onChange={(event) =>
							changePersonData({
								SportTrainingStage: (event.target.value as EProfileSportTrainingStage | "") || null
							})
						}
						labelId="select-sportstage-label"
						placeholder="Выберите"
					>
						{["", ...Object.values(EProfileSportTrainingStage)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{SportTrainingStageList[item] || "Не выбран"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<h2>ГТО</h2>

				<StandartTextInput
					id="gto-certificate"
					fullWidth
					value={person?.Data?.Gto?.Certificate || null}
					onChange={(value, isValid) =>
						changePersonData(
							{
								Data: {
									...person?.Data,
									Gto: {
										...person?.Data?.Gto,
										Certificate: value
									}
								}
							},
							{ PersonAchievements: { GtoCertificate: isValid } }
						)
					}
					mask="бб999999"
					pattern={/[А-Яа-я]{2}[0-9]{6}/}
					errorMessage="Используйте кириллицу (А-Яа-я) и цифры (0-9)"
					label="Номер удостоверения ВФСК ГТО (в формате АА123456)"
					margin="normal"
				/>
				<StandartTextInput
					value={person?.Data?.Gto?.Age || ""}
					onChange={(value) =>
						changePersonData({
							Data: {
								...person?.Data,
								Gto: {
									...person?.Data?.Gto,
									Age: Number(value?.slice(0, 2))
								}
							}
						})
					}
					label="Возраст на момент получения"
					margin="normal"
				/>

				<FormControl fullWidth margin="normal">
					<InputLabel id="select-gtomedal-label">Категория значка</InputLabel>
					<Select
						value={person?.Data?.Gto?.Medal || null}
						onChange={(event) =>
							changePersonData({
								Data: {
									...person?.Data,
									Gto: {
										...person?.Data?.Gto,
										Medal: (event.target.value as EMedal | "") || undefined
									}
								}
							})
						}
						labelId="select-gtomedal-label"
						placeholder="Выберите"
					>
						{["", ...Object.values(EMedal)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{MedalList[item] || "Не выбрано"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item sm={6}>
				<TitleAdd
					title="Список достижений"
					onClick={() => setModalState({ visible: true, achievement: { Id: tempId() } })}
				/>
				<List className="contact-list">
					{_.orderBy(achievements, "Date")?.map((item) => (
						<ListItem
							key={item.Id}
							button
							onClick={() => setModalState({ visible: true, achievement: item })}
						>
							<ListItemText primary={item.Info} secondary={item.Sport?.Name} />
							<ListItemSecondaryAction
								onClick={() => {
									const filteredAchievement = achievements?.filter(
										(achievement) => item.Id !== achievement.Id
									);
									if (filteredAchievement) {
										changeAchievements(filteredAchievement);
									}
								}}
							>
								<IconButton edge="end" aria-label="delete">
									<i className={"icon icon--event-canceled"} />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</Grid>
		</Grid>
	);
};

export default React.memo(AchievementsEdit);
