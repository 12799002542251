import { CircularProgress } from "@material-ui/core";
import React from "react";
import { StatisticsCoachesOrgFullFragment } from "../../../../graphql/fragment/StatisticsCoachesOrgFull.generated";
import { StatisticsCoachesRanksOrgFullFragment } from "../../../../graphql/fragment/StatisticsCoachesRanksOrg.generated";
import { StatisticsCoachesRanksSportFullFragment } from "../../../../graphql/fragment/StatisticsCoachesRanksSport.generated";
import { StatisticsCoachesSportFullFragment } from "../../../../graphql/fragment/StatisticsCoachesSportFull.generated";
import { useStatisticsCoachesBySportsQuery } from "../../../../graphql/query/StatisticsCoachesBySports.generated";
import { useStatisticsCoachesRanksByOrganizationsQuery } from "../../../../graphql/query/StatisticsCoachesRanksByOrganizations.generated";
import { useStatisticsCoachesRanksBySportsQuery } from "../../../../graphql/query/StatisticsCoachesRanksBySports.generated";
import { useStatisticsCoachessByOrganizationsQuery } from "../../../../graphql/query/StatisticsCoachessByOrganizations.generated";
import { StatisticsCoachesRanksOrgsTable, StatisticsCoachesRanksSportTable } from "./StatisticsCoachesRanksTables";
import { StatisticsCoachesOrgsTable, StatisticsCoachesSportTable } from "./StatisticsCoachesTables";

interface IStatisticsSportsProps {
	hash: string;
}

const StatisticsCoaches = (props: IStatisticsSportsProps) => {
	const { hash } = props;

	const { data, loading } = useStatisticsCoachesBySportsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const { data: dataOrgs, loading: loadingOrgs } = useStatisticsCoachessByOrganizationsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const { data: dataSportsRanks, loading: loadingSportsRanks } = useStatisticsCoachesRanksBySportsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const { data: dataOrgsRanks, loading: loadingOrgsRanks } = useStatisticsCoachesRanksByOrganizationsQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	if (loading || loadingOrgs || loadingSportsRanks || loadingOrgsRanks) {
		return <CircularProgress color="primary" size="small" />;
	}

	const statisticBySports: Array<StatisticsCoachesSportFullFragment> | null | undefined =
		data?.StatisticsCoachesBySports;
	const statisticByOrgs: Array<StatisticsCoachesOrgFullFragment> | null | undefined =
		dataOrgs?.StatisticsCoachessByOrganizations;
	const statisticRanksBySports: Array<StatisticsCoachesRanksSportFullFragment> | null | undefined =
		dataSportsRanks?.StatisticsCoachesRanksBySports;
	const statisticRanksByOrgs: Array<StatisticsCoachesRanksOrgFullFragment> | null | undefined =
		dataOrgsRanks?.StatisticsCoachesRanksByOrganizations;

	return (
		<>
			<StatisticsCoachesSportTable
				key={0}
				statistic={statisticBySports}
				title={"Тренера и образование по видам спорта"}
			/>
			<StatisticsCoachesOrgsTable
				key={1}
				statistic={statisticByOrgs}
				title={"Тренера и образование по типам организаций"}
			/>
			<StatisticsCoachesRanksSportTable
				key={2}
				statistic={statisticRanksBySports}
				title={"Категории и заслуги по видам спорта"}
			/>
			<StatisticsCoachesRanksOrgsTable
				key={3}
				statistic={statisticRanksByOrgs}
				title={"Категории и заслуги по типам организаций"}
			/>
		</>
	);
};

export default StatisticsCoaches;
