import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsStagesSportFullFragment = { __typename?: "StatisticsStagesSport" } & Pick<
	Types.StatisticsStagesSport,
	| "SportId"
	| "SportName"
	| "SportCode"
	| "Wellness"
	| "Initial"
	| "Training"
	| "Improvement"
	| "Higher"
	| "All"
	| "AdditionalEducation"
>;

export const StatisticsStagesSportFullFragmentDoc = gql`
	fragment StatisticsStagesSportFull on StatisticsStagesSport {
		SportId
		SportName
		SportCode
		Wellness
		Initial
		Training
		Improvement
		Higher
		All
		AdditionalEducation
	}
`;
