import { Fab } from "@material-ui/core";
import React from "react";

interface ITitleAddProps {
	title: string;
	onClick: () => void;
}

const TitleAdd = (props: ITitleAddProps) => {
	const { title, onClick } = props;

	return (
		<div className="header-add-button">
			<h2>{title}</h2>
			<Fab onClick={onClick} size="small" color="primary" aria-label="add" className="add-button-container">
				<i className={"icon icon--positive add-button"} />
			</Fab>
		</div>
	);
};

export default React.memo(TitleAdd);
