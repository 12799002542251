import { Button, ButtonGroup, createMuiTheme, ThemeProvider } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Rating from "react-rating";
import { Themes } from "../../constants";
import { useProfileEditQuery } from "../../graphql/query/ProfileEdit.generated";
import { useProfileShortLazyQuery } from "../../graphql/query/ProfileShort.generated";
import { EOrganizationType } from "../../graphql/types";
import { getProfileName, getProfileType, getProfileTypeName } from "../../utils/utils";
import Avatar from "../avatar";
import ProfileEditTabs from "./ProfileEditTabs";
import StatisticsTabs from "./statisticsTabs/StatisticsTabs";

interface IProfileEditProps {
	hash: string;
	QueryParams: string;
}

const ProfileEdit: React.ComponentType<IProfileEditProps> = (props) => {
	const { QueryParams, hash } = props;

	// TODO: Исправить <any> на корректный тип после добавления соответствующего фрагмента
	const [profile, setProfile] = useState<any>();
	const [showStats, setShowStats] = useState<boolean>(false);

	const { data } = useProfileEditQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});
	useEffect(() => {
		if (data) setProfile(data.MyProfile);
	}, [data]);

	// Параметры запроса из хеша
	const params: {
		ProfileId: string;
		QueriedBy: string;
	} = useMemo(() => {
		try {
			return JSON.parse(QueryParams);
		} catch (e) {
			console.log("Error parsing QueryParams:", e);
			return;
		}
	}, [QueryParams]);

	const isProfileEditProvided = params.ProfileId !== params.QueriedBy;

	const [getProfile, { data: ownerProfileData }] = useProfileShortLazyQuery();

	useEffect(() => {
		if (!isProfileEditProvided) return;
		getProfile({ variables: { input: { Id: params.QueriedBy } } });
	}, [params]);

	if (!profile) {
		return <p>Загрузка данных...</p>;
	}

	const canShowStats =
		profile?.Organization?.Type === EOrganizationType.Federation ||
		profile?.Organization?.Type === EOrganizationType.Ministry;

	const avatarURL = profile?.AvatarAttachment?.FileLink;
	const profileType = getProfileType(profile);
	const themeProfile = Themes[profileType];

	const profileName = getProfileName(profile);
	const profileTypeName = getProfileTypeName(profile);

	const queriedProfileName = getProfileName(ownerProfileData?.Profile);

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: themeProfile.colors.main,
				light: themeProfile.colors.light
			}
		}
	});
	return (
		<section className="container edit-profile">
			<div className="profile-content profile-header">
				<Avatar size={100} type={profileType} URL={avatarURL} checkStatus={profile.CheckStatus} />
				<div className="base-info">
					<h1>{profileName}</h1>
					<div className={`profile-type ${themeProfile.color}`}>
						<i className={`icon ${themeProfile.icon}`} />
						<span>{profileTypeName}</span>
					</div>
					{profile?.Organization?.FullName && <p className="full-name">{profile.Organization.FullName}</p>}
					<div className="rating-container">
						<Rating
							readonly
							initialRating={profile?.Person?.Rating || profile?.Organization?.Rating || 0}
							emptySymbol="rating-empty"
							fullSymbol="rating-full"
						/>
						<div>Отзывов: {profile?.ReviewsCount || 0}</div>
					</div>
					{!!queriedProfileName && (
						<p className="full-name">Доступ предоставлен профилем "{queriedProfileName}"</p>
					)}
				</div>
			</div>
			<ThemeProvider theme={theme}>
				{canShowStats && (
					<ButtonGroup>
						<Button
							variant={showStats ? "outlined" : "contained"}
							size="large"
							color="primary"
							onClick={() => showStats && setShowStats(false)}
						>
							Редактирование профиля
						</Button>
						<Button
							variant={showStats ? "contained" : "outlined"}
							size="large"
							color="primary"
							onClick={() => !showStats && setShowStats(true)}
						>
							Статистика профиля
						</Button>
					</ButtonGroup>
				)}
				{!showStats && <ProfileEditTabs data={data} hash={hash} />}
				{canShowStats && showStats && <StatisticsTabs data={data} hash={hash} />}
			</ThemeProvider>
		</section>
	);
};

export default ProfileEdit;
