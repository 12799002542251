import * as Types from "../types";

import gql from "graphql-tag";

export type SportFullFragment = { __typename?: "Sport" } & Pick<
	Types.Sport,
	"Id" | "CreatedAt" | "UpdatedAt" | "Name" | "Code"
>;

export const SportFullFragmentDoc = gql`
	fragment SportFull on Sport {
		Id
		CreatedAt
		UpdatedAt
		Name
		Code
		CreatedAt
		UpdatedAt
	}
`;
