import React from "react";
import { RegionFullFragment } from "../../../graphql/fragment/Region.generated";
import { Profile } from "../../../graphql/types";
import RegionSelector from "../../common/RegionSelector";
import StandartTextInput from "../../common/StandartTextInput";
import { ITabProfile } from "../MainTab";

const AddressSection = (props: ITabProfile) => {
	const { profile, setProfile, hash } = props;

	const changeRegion = (type: "PrimaryRegion" | "SecondaryRegion", region: RegionFullFragment | null) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, [type]: region };
		});
	};

	const changeAddressText = (v: string) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Address: { ...prevState.Address, Text: v, Changed: true } };
		});
	};

	return (
		<>
			<h2>Адрес</h2>
			<RegionSelector
				title="Регион"
				description="Выберите ваш основной регион для использования в карте"
				hash={hash}
				selectedKey={profile?.PrimaryRegion?.Id}
				onChange={(value) => changeRegion("PrimaryRegion", value)}
			/>
			<RegionSelector
				title="Дополнительный регион"
				description="Выберите дополнительный регион"
				hash={hash}
				selectedKey={profile?.SecondaryRegion?.Id}
				disabled={!profile?.PrimaryRegion?.Id}
				onChange={(value) => changeRegion("SecondaryRegion", value)}
			/>
			<StandartTextInput
				name="address"
				multiline
				label="Введите адрес (город, улица, дом)"
				value={profile?.Address?.Text || ""}
				onChange={changeAddressText}
				fullWidth
				margin="normal"
				inputProps={{ maxLength: 150 }}
			/>
		</>
	);
};

export default AddressSection;
