import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ContactFullFragment } from "../fragment/Contact.generated";
import { ContactFullFragmentDoc } from "../fragment/Contact.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type RemoveContactMutationVariables = Types.Exact<{
	id: Types.Scalars["Float"];
}>;

export type RemoveContactMutation = { __typename?: "Mutation" } & {
	removeContact: { __typename?: "ChangeContactStatusResult" } & Pick<
		Types.ChangeContactStatusResult,
		"success" | "message"
	> & {
			data?: Types.Maybe<
				{ __typename?: "ChangeContactStatusResultData" } & {
					Updated: { __typename?: "Contact" } & ContactFullFragment;
				}
			>;
		};
};

export const RemoveContactDocument = gql`
	mutation removeContact($id: Float!) {
		removeContact(id: $id) {
			success
			message
			data {
				Updated {
					...ContactFull
				}
			}
		}
	}
	${ContactFullFragmentDoc}
`;
export type RemoveContactMutationFn = ApolloReactCommon.MutationFunction<
	RemoveContactMutation,
	RemoveContactMutationVariables
>;
export type RemoveContactComponentProps = Omit<
	ApolloReactComponents.MutationComponentOptions<RemoveContactMutation, RemoveContactMutationVariables>,
	"mutation"
>;

export const RemoveContactComponent = (props: RemoveContactComponentProps) => (
	<ApolloReactComponents.Mutation<RemoveContactMutation, RemoveContactMutationVariables>
		mutation={RemoveContactDocument}
		{...props}
	/>
);

export type RemoveContactProps<TChildProps = {}, TDataName extends string = "mutate"> = {
	[key in TDataName]: ApolloReactCommon.MutationFunction<RemoveContactMutation, RemoveContactMutationVariables>;
} &
	TChildProps;
export function withRemoveContact<TProps, TChildProps = {}, TDataName extends string = "mutate">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		RemoveContactMutation,
		RemoveContactMutationVariables,
		RemoveContactProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withMutation<
		TProps,
		RemoveContactMutation,
		RemoveContactMutationVariables,
		RemoveContactProps<TChildProps, TDataName>
	>(RemoveContactDocument, {
		alias: "removeContact",
		...operationOptions
	});
}

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContactMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveContactMutation, RemoveContactMutationVariables>
) {
	return ApolloReactHooks.useMutation<RemoveContactMutation, RemoveContactMutationVariables>(
		RemoveContactDocument,
		baseOptions
	);
}
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = ApolloReactCommon.MutationResult<RemoveContactMutation>;
export type RemoveContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
	RemoveContactMutation,
	RemoveContactMutationVariables
>;
