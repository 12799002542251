import { sportIsOlimpic, sportIsSummer, sportIsWinter } from "@athlete/utils";
import React, { useMemo } from "react";
import { StatisticsCoachesOrgFullFragment } from "../../../../graphql/fragment/StatisticsCoachesOrgFull.generated";
import { StatisticsCoachesSportFullFragment } from "../../../../graphql/fragment/StatisticsCoachesSportFull.generated";
import CommonTable, { TCustomStyleClasses } from "../../../common/CommonTable";

interface IStatisticsCoachesSportTableProps {
	statistic: Array<StatisticsCoachesSportFullFragment> | null | undefined;
	title: string;
}

type TColumnsKeys = keyof Omit<
	StatisticsCoachesSportFullFragment,
	"__typename" | "SportId" | "SportName" | "SportCode"
>;

const columns: Array<[TColumnsKeys, string]> = [
	["All", "Число тренеров общее"],
	["FullTime", "В том числе штатные"],
	["High", "Тренера с высшим образованием"],
	["Mid", "Тренера со средним образованием"]
];

const headers = ["", ...columns.map(([, value]) => value)];

const headersKeys = columns.map(([key]) => key);

export const StatisticsCoachesSportTable = (props: IStatisticsCoachesSportTableProps) => {
	const { statistic, title } = props;

	const dataSet = useMemo(() => {
		const totalSports: { [key in TColumnsKeys]: number } = {
			All: 0,
			FullTime: 0,
			High: 0,
			Mid: 0
		};
		const totalOlimpicSports: { [key in TColumnsKeys]: number } = {
			All: 0,
			FullTime: 0,
			High: 0,
			Mid: 0
		};
		const totalSummer: { [key in TColumnsKeys]: number } = {
			All: 0,
			FullTime: 0,
			High: 0,
			Mid: 0
		};
		const totalWinter: { [key in TColumnsKeys]: number } = {
			All: 0,
			FullTime: 0,
			High: 0,
			Mid: 0
		};
		const totalAllSeasons: { [key in TColumnsKeys]: number } = {
			All: 0,
			FullTime: 0,
			High: 0,
			Mid: 0
		};

		const result = statistic?.map((row) => [
			row.SportName,
			...headersKeys.map((key) => {
				totalSports[key] += row[key];

				if (sportIsOlimpic(row.SportCode)) {
					totalOlimpicSports[key] += row[key];
					if (sportIsSummer(row.SportCode)) totalSummer[key] += row[key];
					else if (sportIsWinter(row.SportCode)) totalWinter[key] += row[key];
					else totalAllSeasons[key] += row[key];
				}
				return row[key];
			})
		]);

		result?.push(
			["Итого по спортам ", totalSports.All, totalSports.FullTime, totalSports.High, totalSports.Mid],
			[
				"Итого по олимпийским видам спорта",
				totalOlimpicSports.All,
				totalOlimpicSports.FullTime,
				totalOlimpicSports.High,
				totalOlimpicSports.Mid
			],
			["в т.ч по летним видам", totalSummer.All, totalSummer.FullTime, totalSummer.High, totalSummer.Mid],
			["в т.ч по зимним видам", totalWinter.All, totalWinter.FullTime, totalWinter.High, totalWinter.Mid],
			[
				"в т.ч по внесезонным видам",
				totalAllSeasons.All,
				totalAllSeasons.FullTime,
				totalAllSeasons.High,
				totalAllSeasons.Mid
			]
		);
		return result;
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {
			[dataSet.length - 5]: "blue-cell",
			[dataSet.length - 4]: "blue-cell"
		},
		columns: { [1]: "orange-cell" }
	};

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};

interface IStatisticsCoachesOrgsTableProps {
	statistic: Array<StatisticsCoachesOrgFullFragment> | null | undefined;
	title: string;
}

export const StatisticsCoachesOrgsTable = (props: IStatisticsCoachesOrgsTableProps) => {
	const { statistic, title } = props;

	const orgTypeTitles: { [key: string]: string } = useMemo(
		() => ({
			"0": "Всего по СШ",
			"1": "Всего по ДЮСШ",
			"8": "Всего по СДЮСШОР",
			"9": "Всего по СШОР",
			"10": "Всего по УОР",
			"11": "Всего по ЦСП",
			"12": "Всего по ЦОП",
			"4": "Всего других"
		}),
		[]
	);
	const [dataSet, totalRowsIndexes] = useMemo(() => {
		const totalIndexes: Array<number> = [];

		return [
			statistic?.map((row, index) => {
				let text = "";
				switch (row.Department) {
					case null:
						text = orgTypeTitles[row.OrgType] || "";
						totalIndexes.push(index);
						break;
					case "Education":
						text = "приналдежат образованию";
						break;
					case "Sport":
						text = "принадлежат ФКиС";
						break;
					case "Other":
						text = "другая принадлежность";
						break;
					default:
						break;
				}

				return [text, ...headersKeys.map((key) => row[key])];
			}),
			totalIndexes
		];
	}, [statistic]);

	if (!dataSet || !dataSet.length) return null;

	const customStyleClassesIndexes: TCustomStyleClasses = {
		rows: {},
		columns: { [1]: "orange-cell" }
	};

	totalRowsIndexes.forEach((index) => {
		if (customStyleClassesIndexes?.rows) {
			customStyleClassesIndexes.rows[index] = "blue-cell";
		}
	});

	return (
		<CommonTable
			headers={headers}
			dataSet={dataSet}
			title={title}
			customStyleClassesIndexes={customStyleClassesIndexes}
		/>
	);
};
