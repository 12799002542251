import MomentUtils from "@date-io/moment";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AchievementEventLevelList, AchievementEventTypeList } from "../../constants";
import { EAchievementEventLevel, EAchievementEventType, ProfileAchievement, Sport } from "../../graphql/types";
import { tempId } from "../../utils/utils";
import SportSelector from "../common/SportSelector";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";
import { TPartialAchievement } from "./AchievementsEdit";

interface IAchievementsModalProps {
	hash: string;
	visible: boolean;
	achievement?: TPartialAchievement;
	onClose: () => void;
	addAchievement: (achievement: TPartialAchievement) => void;
}

const AchievementsModal = (props: IAchievementsModalProps) => {
	const { hash, visible, achievement, onClose, addAchievement } = props;

	const [achievementState, setAchievementState] = useState<TPartialAchievement>();
	const [date, setDate] = useState<moment.Moment | null>();

	useEffect(() => {
		setAchievementState(achievement);
		setDate(achievement?.Date ? moment(achievement.Date) : null);
	}, [achievement]);

	if (!achievementState) {
		return null;
	}

	const saveAchievement = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		addAchievement({ ...achievementState, Date: date?.toISOString() });
		onClose();
	};

	const changeData = (newState: Partial<ProfileAchievement>) => {
		setAchievementState((prevState) => {
			return { ...prevState, Id: prevState?.Id || tempId(), ...newState };
		});
	};

	const changeSport = (sport: Sport | null): void => {
		changeData({ Sport: sport, SportId: sport?.Id || null });
	};

	const changeInfo = (value: string) => changeData({ Info: value });

	const changeDate = (newDate: MaterialUiPickersDate, value?: string | null | undefined) => {
		setDate(newDate);
	};

	const changeEventType = (event: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
		const v = event.target.value as EAchievementEventType;
		changeData({ EventType: v || null });
	};

	const changeEventLevel = (event: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
		const v = event.target.value as EAchievementEventLevel;
		changeData({ EventLevel: v || null });
	};
	const formId = "achievement-form";
	return (
		<StandartDialog
			visible={visible}
			onClose={onClose}
			buttons={[{ title: "Сохранить", form: formId, type: "submit" }]}
		>
			<form onSubmit={saveAchievement} id={formId} className="edit-achievements-form">
				<StandartTextInput
					required
					label="Заголовок достижения:"
					value={achievementState.Info || ""}
					onChange={changeInfo}
					margin="dense"
					multiline
					rowsMax={15}
					error={!achievementState.Info}
					inputProps={{ maxLength: 150 }}
				/>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardDatePicker
						disableToolbar
						fullWidth
						format="DD.MM.YYYY"
						margin="normal"
						label="Дата"
						invalidDateMessage="Некорректный формат"
						value={achievementState.Date || null}
						onChange={changeDate}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
				<SportSelector hash={hash} onChange={changeSport} value={achievementState.Sport} />

				<FormControl fullWidth margin="normal">
					<InputLabel id="select-contacttype-label">Тип мероприятия</InputLabel>
					<Select
						value={achievementState.EventType || null}
						onChange={changeEventType}
						labelId="select-eventtype-label"
						placeholder="Выберите"
					>
						{["", ...Object.values(EAchievementEventType)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{AchievementEventTypeList[item] || "Не выбран"}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth margin="normal">
					<InputLabel id="select-contacttype-label">Уровень мероприятия</InputLabel>
					<Select
						value={achievementState.EventLevel || null}
						onChange={changeEventLevel}
						labelId="select-eventlevel-label"
						placeholder="Выберите"
					>
						{["", ...Object.values(EAchievementEventLevel)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{AchievementEventLevelList[item] || "Не выбран"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</form>
		</StandartDialog>
	);
};

export default React.memo(AchievementsModal);
