import React from "react";

import {
	Box,
	Button,
	CircularProgress,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core";
import moment from "moment";
import { ORGS_PER_PAGE, ProfileTypes } from "../../../constants";
import {
	StatisticsProfileFillDocument,
	useStatisticsProfileFillQuery
} from "../../../graphql/query/StatisticsProfileFill.generated";
import { ProfileFill } from "../../../graphql/types";
import { getProfileCheckStatusClass, getProfileType } from "../../../utils/utils";
import CircularProgressWithLabel from "../../common/CircularProgressWithLabel";
import StatisticsActionsRow from "./components/StatisticsActionsRow";

interface ITabProfile {
	profile: any;
	setProfile: React.Dispatch<any>;
	hash: string;
}
interface IStatisticsColumn {
	key: keyof ProfileFill;
	label: string;
	icon: string;
}

const columns: Array<IStatisticsColumn> = [
	{ key: "FullName", label: "Полное название организации заполнено", icon: "organization" },
	{ key: "Description", label: "Описание организации заполнено", icon: "indicate-your-name" },
	{ key: "Contacts", label: "Контактная информация заполнена", icon: "icon-sity-phone" },
	{ key: "Region", label: "Домашний регион выбран", icon: "website-disabled" },
	{ key: "Address", label: "Адрес организации заполнен", icon: "geo" },
	{ key: "Sports", label: "Виды спорта выбраны", icon: "my_sport_line" },
	{ key: "WorkingHours", label: "Часы работы заполнены", icon: "working_hours" },
	{ key: "Coach", label: "Тренерский состав заполнен", icon: "couch" },
	{ key: "Place", label: "Площадки заполнены", icon: "object" },
	{ key: "Events", label: "Мероприятия запланированы", icon: "event" },
	{ key: "Equipment", label: "Информация о необходимой экипировке заполнена", icon: "avatar_training" },
	{ key: "Rules", label: "Правила приема заполнены", icon: "visits_1" },
	{ key: "Achievements", label: "Информация о достижениях заполнена", icon: "competitions_merit" },
	{ key: "Director", label: "Информация о директоре организации заполнена", icon: "contact-customer" }
];

const StatisticsProfileFill = (props: ITabProfile) => {
	const { hash, profile } = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(ORGS_PER_PAGE / 2);
	const { data, loading, error, fetchMore } = useStatisticsProfileFillQuery({
		context: {
			headers: {
				Authorization: `Digest ${hash}`
			}
		},
		variables: { input: { Limit: ORGS_PER_PAGE, Offset: 0 } }
	});

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		if (
			(data?.StatisticsProfileFill?.Count || 0) > (data?.StatisticsProfileFill?.Profiles?.length || 0) &&
			rowsPerPage * (newPage + 2) > (data?.StatisticsProfileFill?.Profiles?.length || 0)
		) {
			fetchMore({
				query: StatisticsProfileFillDocument,
				context: {
					headers: {
						Authorization: `Digest ${hash}`
					}
				},
				variables: {
					input: {
						Offset: data?.StatisticsProfileFill?.Profiles?.length || 0,
						Limit: ORGS_PER_PAGE
					}
				},
				updateQuery: (prevResult, { fetchMoreResult }) => {
					if (
						!fetchMoreResult ||
						!fetchMoreResult.StatisticsProfileFill ||
						fetchMoreResult.StatisticsProfileFill.Profiles.length === 0
					) {
						return prevResult;
					}

					const fetchListSearch = [
						...prevResult.StatisticsProfileFill.Profiles,
						...fetchMoreResult.StatisticsProfileFill.Profiles
					];
					const res = {
						StatisticsProfileFill: {
							...prevResult.StatisticsProfileFill,
							Count: fetchMoreResult.StatisticsProfileFill.Count,
							Profiles: fetchListSearch
						}
					};
					return res;
				}
			});
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	if (loading) {
		return (
			<div className="content-center">
				<CircularProgress />
			</div>
		);
	}

	if (error || !data?.StatisticsProfileFill) {
		return <h2>Не удалось загрузить список подведомственных организаций!</h2>;
	}

	if (!data?.StatisticsProfileFill?.Profiles?.length) {
		const profileType = getProfileType(profile);

		const description =
			profileType === ProfileTypes.Federation
				? "(возможно у вас не указан домашний регион или виды спорта)"
				: "(возможно у вас не указан домашний регион)";

		return (
			<>
				<h2>Нет подведомственных организаций.</h2>
				<h3>{description}</h3>
			</>
		);
	}

	return (
		<>
			<TableContainer className="stats-table-container">
				<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
							<TableCell key="info">
								<Typography variant="h6">Название организации</Typography>
							</TableCell>
							{columns.map((column) => (
								<TableCell key={column.key} className="checkbox-cell">
									<Tooltip title={column.label} placement="top" arrow>
										<Icon fontSize="small">
											<i className={`icon icon--${column.icon}`} />
										</Icon>
									</Tooltip>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.StatisticsProfileFill.Profiles.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						).map((row) => {
							const percent =
								(columns.filter((column) => row[column.key] === true).length * 100) / columns.length;
							const progressClass =
								percent <= 25 ? "progress-bad" : percent <= 50 ? "progress-medium" : "progress-good";
							const innClass =
								(row.Inn.length === 10 || row.Inn.length === 12) && row.Inn[0] !== "-"
									? "inn progress-good"
									: "inn progress-bad";
							const statusClass = getProfileCheckStatusClass(row.CheckStatus) || "new-profile";

							return (
								<StatisticsActionsRow row={row} hash={hash} key={row.Id}>
									<TableCell className="head-cell">
										<Box display="flex">
											<Box className="status-badge">
												<CircularProgressWithLabel value={percent} className={progressClass} />
												<i className="icon icon--profile-star" />
												<i className={`icon icon--${statusClass}`} />
											</Box>
											<Box className="content-container">
												<Box>{row.Name}</Box>
												<Box className="info">
													<Box>
														<Icon fontSize="small">
															<i className="icon icon--reiew-star-1" />
														</Icon>
														<Typography variant="subtitle2" component="span">
															{parseFloat(row.Rating).toFixed(2)}
														</Typography>
														<Typography
															variant="subtitle2"
															component="span"
															className={innClass}
														>
															ИНН {row.Inn}
														</Typography>
													</Box>
													<Typography
														color="textSecondary"
														variant="subtitle2"
														component="div"
													>
														Изменено:
														{moment
															.utc(row.UpdatedAt)
															.local()
															.format(" DD.MM.YYYY HH.mm")}
													</Typography>
												</Box>
											</Box>
											<IconButton
												aria-controls="simple-menu"
												aria-haspopup="true"
												size="small"
												color="primary"
												disableRipple
											>
												<i className="icon icon--menu" />
											</IconButton>
										</Box>
									</TableCell>
									{columns.map((column) => {
										const value = row[column.key];
										return (
											<TableCell key={column.key} className="checkbox-cell">
												<Icon fontSize="small">
													<i
														className={`icon icon--${
															value ? "event-held success" : "event-canceled error"
														}`}
													/>
												</Icon>
											</TableCell>
										);
									})}
								</StatisticsActionsRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				className="stats-table-pagination"
				rowsPerPageOptions={[ORGS_PER_PAGE / 5, ORGS_PER_PAGE / 2, ORGS_PER_PAGE]}
				component="div"
				count={data?.StatisticsProfileFill?.Count || -1}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				labelDisplayedRows={({ from, to, count }) => `${from}-${to}${count !== -1 ? ` из ${count}` : ""}`}
				labelRowsPerPage="Строк на странице:"
				nextIconButtonText="След."
				backIconButtonText="Пред."
			/>
		</>
	);
};

export default StatisticsProfileFill;
