import { DeepPartial, okvedToNumber, okvedToString } from "@athlete/utils";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import { OrganizationTypeList, OrganizationTypesList } from "../../../constants";
import { EOrganizationType } from "../../../graphql/types";
import StandartTextInput from "../../common/StandartTextInput";
import { ITabProfile } from "../MainTab";
import { IValidProfileEditTabsState } from "../ProfileEditTabs";
import AddressSection from "./AddressSection";

// Поля организации, которые мы тут редактируем
type TTypeFieldMainOrgInfo = "Name" | "FullName" | "Inn" | "OKVED" | "Type" | "Description";

export const checkInnValid = (value: string) => /^[0-9]{10}$|^[0-9]{12}$/.test(value);

const MainOrgInfoSection = (props: ITabProfile) => {
	const { profile, changeOrganizationData } = props;
	const [innValid, setInnValid] = useState<boolean>(checkInnValid(profile?.Organization?.Inn));

	const [okvedString, setOkvedString] = useState(okvedToString(profile.Organization?.OKVED));

	if (!profile?.Organization) return null;

	// Проверка на валидность данных ИНН

	// Единая функция изменения данных
	const changeMainOrgInfo = (type: TTypeFieldMainOrgInfo, v: string) => {
		const newValidState: DeepPartial<IValidProfileEditTabsState> = {};
		switch (type) {
			case "Inn":
				const newInnValidValue = checkInnValid(v);
				setInnValid(newInnValidValue);
				newValidState.Organization = { ...newValidState.Organization, Inn: newInnValidValue };
				break;
			case "Name":
				newValidState.Organization = { ...newValidState.Organization, Name: !!v.trim().length };
				break;
			default:
				break;
		}
		changeOrganizationData({ [type]: v }, newValidState);
	};

	const needOrgTypeField =
		profile.Organization.Type !== EOrganizationType.Ministry &&
		profile.Organization.Type !== EOrganizationType.Federation;

	return (
		<>
			<h2>Основная информация по организации</h2>
			<Grid container spacing={6}>
				<Grid item sm={6}>
					<StandartTextInput
						required
						label="Сокращенное название организации"
						value={profile.Organization.Name}
						onChange={(e) => changeMainOrgInfo("Name", e)}
						margin="normal"
						error={!profile.Organization.Name?.trim().length}
						inputProps={{ maxLength: 150 }}
					/>
					<StandartTextInput
						multiline
						label="Полное название организации"
						value={profile.Organization.FullName || undefined}
						onChange={(e) => changeMainOrgInfo("FullName", e)}
						margin="normal"
						inputProps={{ maxLength: 300 }}
					/>
					<FormControl fullWidth>
						<StandartTextInput
							label="ИНН организации"
							value={profile.Organization.Inn}
							onChange={(e) => changeMainOrgInfo("Inn", e)}
							margin="normal"
							error={!innValid}
							inputProps={{ maxLength: 12 }}
						/>
						{!innValid && (
							<FormHelperText error={true}>Инн должен состоять из 10 или 12 цифр</FormHelperText>
						)}
					</FormControl>
					<StandartTextInput
						fullWidth
						label="ОКВЭД v2"
						mask="99.99.99"
						maskChar={null}
						value={okvedString}
						onChange={setOkvedString}
						onBlur={() => {
							const numberValue = okvedToNumber(okvedString);
							changeMainOrgInfo("OKVED", numberValue?.toString() || "");
							setOkvedString(okvedToString(numberValue));
						}}
						margin="normal"
					/>
					{needOrgTypeField && (
						<FormControl fullWidth margin="normal">
							<InputLabel id="select-orgtype-label">Тип организации</InputLabel>
							<Select
								value={profile.Organization.Type}
								onChange={(e) => changeMainOrgInfo("Type", e.target.value as EOrganizationType)}
								labelId="select-orgtype-label"
							>
								{OrganizationTypesList.map((item) => (
									<MenuItem key={item} value={item}>
										{OrganizationTypeList[item]}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>Выберите тип организации</FormHelperText>
						</FormControl>
					)}
					<AddressSection {...props} />
				</Grid>
				<Grid item sm={6}>
					<StandartTextInput
						name="description"
						multiline
						rows={15}
						inputProps={{ maxLength: 4000 }}
						label="Описание организации"
						value={profile.Organization.Description || undefined}
						onChange={(e) => changeMainOrgInfo("Description", e)}
						margin="normal"
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default MainOrgInfoSection;
