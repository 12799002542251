import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsSportRanksOrgFullFragment = { __typename?: "StatisticsSportRanksOrg" } & Pick<
	Types.StatisticsSportRanksOrg,
	"OrgType" | "Department"
> & {
		InternationalSportsMaster: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMasterVeteran: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMaster: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMasterCandidate: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		FirstRank: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		Other: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
	};

export const StatisticsSportRanksOrgFullFragmentDoc = gql`
	fragment StatisticsSportRanksOrgFull on StatisticsSportRanksOrg {
		InternationalSportsMaster {
			Total
			Gained
			Confirmed
		}
		SportsMasterVeteran {
			Total
			Gained
			Confirmed
		}
		SportsMaster {
			Total
			Gained
			Confirmed
		}
		SportsMasterCandidate {
			Total
			Gained
			Confirmed
		}
		FirstRank {
			Total
			Gained
			Confirmed
		}
		Other {
			Total
			Gained
			Confirmed
		}
		OrgType
		Department
	}
`;
