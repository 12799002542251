import React, { ReactNode } from "react";

interface IModalButtonProps {
	text: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	style?: string;
}

interface IModalProps {
	id: string;
	buttons?: Array<IModalButtonProps>;
	onClose?: (event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>) => void;
	children: ReactNode;
}

const Modal = (props: IModalProps) => {
	const { id, buttons, onClose } = props;

	const closeModal = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>) => {
		const element = document.getElementById(id);

		if (element) {
			element.classList.remove("active");
		}

		e.preventDefault();
		if (onClose) {
			onClose(e);
		}
	};

	return (
		<div id={id} className="modal">
			<div className="modal__background" onClick={closeModal as any} />
			<div className="modal__wrapper">
				<div className="modal__header">
					<span className="modal__title">Вставить на сайт</span>
					<a href="#" className="modal__close" onClick={closeModal as any}>
						<i className="icon icon--event-canceled" />
					</a>
				</div>
				<div className="modal__content">{props.children}</div>
				{buttons && (
					<div className="modal__footer">
						{buttons.map((btn, index) => (
							<button
								key={index}
								onClick={btn.onClick}
								className={btn.style ? `button ${btn.style}` : "button"}
							>
								{btn.text}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Modal;
