import { DeepPartial } from "@athlete/utils";
import React from "react";
import { Organization } from "../../graphql/types";
import MainOrgInfoSection from "./mainTab/MainOrgInfoSection";
import MainPersonInfo, { TPartialPerson } from "./mainTab/MainPersonInfo";
import { IValidProfileEditTabsState } from "./ProfileEditTabs";

export interface ITabProfile {
	profile: any;
	setProfile: React.Dispatch<any>;
	changePersonData: (
		newPersonState: DeepPartial<TPartialPerson>,
		newValidState?: DeepPartial<IValidProfileEditTabsState>
	) => void;
	changeOrganizationData: (
		newOrganizationState: Partial<Organization>,
		newValidState?: DeepPartial<IValidProfileEditTabsState>
	) => void;
	hash: string;
}

const MainTab = (props: ITabProfile) => {
	return (
		<>
			<MainOrgInfoSection {...props} />
			<MainPersonInfo {...props} />
		</>
	);
};

export default MainTab;
