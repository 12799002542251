import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsOrganizationsFullFragment } from "../fragment/StatisticsOrganizationsFull.generated";
import { StatisticsOrganizationsFullFragmentDoc } from "../fragment/StatisticsOrganizationsFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsOrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsOrganizationsQuery = { __typename?: "Query" } & {
	StatisticsOrganizations?: Types.Maybe<
		{ __typename?: "StatisticsOrganizations" } & StatisticsOrganizationsFullFragment
	>;
};

export const StatisticsOrganizationsDocument = gql`
	query StatisticsOrganizations {
		StatisticsOrganizations {
			...StatisticsOrganizationsFull
		}
	}
	${StatisticsOrganizationsFullFragmentDoc}
`;
export type StatisticsOrganizationsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>,
	"query"
>;

export const StatisticsOrganizationsComponent = (props: StatisticsOrganizationsComponentProps) => (
	<ApolloReactComponents.Query<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>
		query={StatisticsOrganizationsDocument}
		{...props}
	/>
);

export type StatisticsOrganizationsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>;
} &
	TChildProps;
export function withStatisticsOrganizations<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsOrganizationsQuery,
		StatisticsOrganizationsQueryVariables,
		StatisticsOrganizationsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsOrganizationsQuery,
		StatisticsOrganizationsQueryVariables,
		StatisticsOrganizationsProps<TChildProps, TDataName>
	>(StatisticsOrganizationsDocument, {
		alias: "statisticsOrganizations",
		...operationOptions
	});
}

/**
 * __useStatisticsOrganizationsQuery__
 *
 * To run a query within a React component, call `useStatisticsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsOrganizationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>
) {
	return ApolloReactHooks.useQuery<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>(
		StatisticsOrganizationsDocument,
		baseOptions
	);
}
export function useStatisticsOrganizationsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsOrganizationsQuery,
		StatisticsOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<StatisticsOrganizationsQuery, StatisticsOrganizationsQueryVariables>(
		StatisticsOrganizationsDocument,
		baseOptions
	);
}
export type StatisticsOrganizationsQueryHookResult = ReturnType<typeof useStatisticsOrganizationsQuery>;
export type StatisticsOrganizationsLazyQueryHookResult = ReturnType<typeof useStatisticsOrganizationsLazyQuery>;
export type StatisticsOrganizationsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsOrganizationsQuery,
	StatisticsOrganizationsQueryVariables
>;
