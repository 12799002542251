import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AddressFullFragment } from "../../graphql/fragment/Address.generated";
import { tempId } from "../../utils/utils";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";
import { TPartialProfilePlace } from "./PlacesEdit";
import PlacesSearch from "./PlacesSearch";

interface IPlacesModalProps {
	hash: string;
	visible: boolean;
	place?: TPartialProfilePlace;
	onClose: () => void;
	addPlace: (place: TPartialProfilePlace) => void;
}

const PlacesModal = (props: IPlacesModalProps) => {
	const { hash, visible, place, onClose, addPlace } = props;

	const [placeState, setPlaceState] = useState<TPartialProfilePlace>();

	useEffect(() => {
		setPlaceState(place);
	}, [place]);

	if (!placeState) {
		return null;
	}
	const savePlace = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		addPlace(placeState);
		onClose();
	};

	const setAddress = (value: string | AddressFullFragment) => {
		setPlaceState((prevState) => {
			const address =
				typeof value === "string"
					? { ...prevState?.Address, Id: prevState?.Address?.Id || tempId(), Text: value }
					: value;
			return {
				...prevState,
				Id: prevState?.Id || tempId(),
				Address: address
			};
		});
	};

	const changeComment = (v: string) => {
		setPlaceState((prevState) => {
			return { ...prevState, Id: prevState?.Id || tempId(), Comment: v };
		});
	};

	const changePublic = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = event.target.checked;
		setPlaceState((prevState) => {
			return { ...prevState, Id: prevState?.Id || tempId(), IsPublic: v };
		});
	};

	const changeFavorite = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = event.target.checked;
		setPlaceState((prevState) => {
			return { ...prevState, Id: prevState?.Id || tempId(), Favorite: v };
		});
	};

	const changeInstitute = (text: string | undefined) => {
		setPlaceState((prevState) => ({
			...prevState,
			Id: prevState?.Id || tempId(),
			Name: text
		}));
	};
	const formId = "place-edit-form";
	return (
		<StandartDialog
			onClose={onClose}
			visible={visible}
			title="Редактирование"
			buttons={[{ title: "Сохранить", type: "submit", form: formId }]}
		>
			<form onSubmit={savePlace} id={formId} className="edit-places-form">
				<PlacesSearch
					hash={hash}
					changeText={changeInstitute}
					changeAddress={setAddress}
					name={placeState.Name}
				/>
				<StandartTextInput
					value={placeState.Address?.Text || ""}
					onChange={setAddress}
					label="Адрес"
					margin="dense"
					inputProps={{ maxLength: 150 }}
				/>
				<StandartTextInput
					multiline
					value={placeState.Comment || ""}
					onChange={changeComment}
					id="place-comment"
					label="Комментарий"
					margin="dense"
					inputProps={{ maxLength: 150 }}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={placeState.IsPublic || false}
							onChange={changePublic}
							name="place-public"
							color="primary"
						/>
					}
					label="Публичное место"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={placeState.Favorite || false}
							onChange={changeFavorite}
							name="place-favorite"
							color="primary"
						/>
					}
					label="Избранное"
				/>
			</form>
		</StandartDialog>
	);
};

export default React.memo(PlacesModal);
