import { DeepPartial } from "@athlete/utils";
import MomentUtils from "@date-io/moment";
import {
	Checkbox,
	Chip,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { CoachRankList, RefereeRankList, SportRankList } from "../../constants";
import { useDisciplinesDataLazyQuery } from "../../graphql/query/DisciplinesData.generated";
import { Discipline, ECoachRank, ERefereeRank, ESportRank, ProfileDiscipline, ProfileSport } from "../../graphql/types";
import { tempId } from "../../utils/utils";
import CheckboxDatapicker from "../common/CheckboxDatapicker";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";

type TDiscipline = Omit<ProfileDiscipline, "Id"> & { Id?: string };

const indefinitelyRanks = [
	ESportRank.ChessRank,
	ESportRank.InternationalSportsMaster,
	ESportRank.SportsMasterVeteran,
	ESportRank.SportsMaster
];
interface ISportModalContentProps {
	hash: string;
	visible: boolean;
	sport?: DeepPartial<ProfileSport>;
	updateSportData: (sport: DeepPartial<ProfileSport>) => void;
	disciplines?: Array<TDiscipline>;
	setSelectedDisciplines: (disciplines: Array<TDiscipline>) => void;
	onClose: () => void;
	isOrganization?: boolean;
}

const SportModalContent: React.ComponentType<ISportModalContentProps> = (props) => {
	const {
		hash,
		visible,
		sport,
		updateSportData,
		onClose,
		disciplines: selectedDisciplines,
		setSelectedDisciplines,
		isOrganization
	} = props;

	const [getDisciplines, { data, loading, error }] = useDisciplinesDataLazyQuery();

	const [sportState, setSportState] = useState<DeepPartial<ProfileSport> | undefined>(sport);

	useEffect(() => {
		if (!sport?.Sport?.Id) {
			return;
		}
		getDisciplines({
			variables: {
				sportIds: [sport.Sport.Id]
			},
			context: { headers: { Authorization: `Digest ${hash}` } }
		});
		setSportState(sport);
	}, [sport]);

	const changeAdditionalRank = (v: string) => {
		return setSportState((prevState) => ({ ...prevState, AdditionalRank: v }));
	};

	const onOptionChange = useCallback(
		(disciplines: Array<Discipline>): void => {
			if (!disciplines.length) {
				return;
			}
			const discipline = disciplines[0];
			if (selectedDisciplines?.every((discItem) => discItem.Discipline?.Id !== discipline?.Id)) {
				setSelectedDisciplines([...selectedDisciplines, { Discipline: discipline }]);
			}
		},
		[selectedDisciplines]
	);
	const getOptionLabel = useCallback((option: Discipline): string => option.Name, []);
	const getIsOptionDisabled = useCallback(
		(option: Discipline): boolean =>
			!!selectedDisciplines?.some((discItem) => discItem.Discipline?.Id === option?.Id),
		[selectedDisciplines]
	);

	if (!sportState || !sportState.Sport) {
		return null;
	}

	const saveSport = () => {
		const id = sportState.Sport?.Id === "-1" ? tempId() : sportState.Sport?.Id;
		updateSportData({ ...sportState, Sport: { ...sportState.Sport, Id: id } });
		onClose();
	};

	const changeDate = (
		value: MaterialUiPickersDate | null,
		dateType: "SportRankGained" | "SportRankConfirmed" | "RefereeRankTill" | "CoachRankTill"
	) => {
		const newDate: moment.Moment | null = value === null ? null : value;
		setSportState((prevState) => ({ ...prevState, [dateType]: newDate }));
	};

	const changeSportRank = (event: React.ChangeEvent<{ value: unknown }>) => {
		let sportRankGained = sportState.SportRankGained;
		let sportRankConfirmed = sportState.SportRankConfirmed;
		const v = event.target.value as ESportRank;
		if (!v) {
			sportRankGained = null;
			sportRankConfirmed = null;
		} else if (indefinitelyRanks.includes(v)) {
			sportRankConfirmed = null;
		}
		setSportState((prevState) => ({
			...prevState,
			SportRank: v || null,
			SportRankGained: sportRankGained,
			SportRankConfirmed: sportRankConfirmed
		}));
	};
	const changeRefereeRank = (event: React.ChangeEvent<{ value: unknown }>) => {
		let refereeRankTill = sportState.RefereeRankTill;
		const v = event.target.value as ERefereeRank;
		if (!v) {
			refereeRankTill = null;
		}
		setSportState((prevState) => ({
			...prevState,
			RefereeRank: v || null,
			RefereeRankTill: refereeRankTill
		}));
	};
	const changeCoachRank = (event: React.ChangeEvent<{ value: unknown }>) => {
		let coachRankTill = sportState.CoachRankTill;
		const v = event.target.value as ECoachRank;
		if (!v) {
			coachRankTill = null;
		}
		setSportState((prevState) => ({
			...prevState,
			CoachRank: v || null,
			CoachRankTill: coachRankTill
		}));
	};
	const changeCoachVeteran = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = event.target.checked;
		setSportState((prevState) => ({
			...prevState,
			CoachVeteran: v
		}));
	};
	const changeSportVeteran = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = event.target.checked;
		setSportState((prevState) => ({
			...prevState,
			SportVeteran: v
		}));
	};

	const isOtherSport = sportState.Sport.Id === "-1" || sportState.OtherSport;

	return (
		<StandartDialog
			visible={visible}
			onClose={onClose}
			title={sportState.Sport?.Name}
			buttons={[
				{
					title: "Сохранить",
					onClick: saveSport
				}
			]}
		>
			<div className="edit-sports-form">
				{isOtherSport ? (
					<StandartTextInput
						label="Название вида спорта (в свободной форме)"
						value={sportState.OtherSport || ""}
						onChange={(v) => setSportState((prevState) => ({ ...prevState, OtherSport: v }))}
						margin="normal"
						inputProps={{ maxLength: 150 }}
					/>
				) : (
					<>
						<h2>Дисциплины</h2>
						{!!data?.Disciplines?.length ? (
							<>
								<Grid container spacing={6}>
									<Grid item sm={6}>
										<div className="disciplines">
											{_.orderBy(selectedDisciplines, "Discipline.Name")?.map((item) => (
												<Chip
													key={item.Id + item.Discipline.Id}
													label={item.Discipline.Name}
													onDelete={() => {
														const filteredDisciplines = selectedDisciplines?.filter(
															(disc) => item.Discipline.Id !== disc.Discipline.Id
														);
														if (filteredDisciplines) {
															setSelectedDisciplines(filteredDisciplines);
														}
													}}
													color="primary"
													variant="outlined"
												/>
											))}
										</div>
									</Grid>
									<Grid item sm={6}>
										<Autocomplete
											loading={loading}
											fullWidth
											multiple
											options={data?.Disciplines || []}
											getOptionSelected={(option, value) => option.Id === value.Id}
											getOptionLabel={getOptionLabel}
											getOptionDisabled={getIsOptionDisabled}
											value={[]}
											disableCloseOnSelect={true}
											onChange={(_event, value) => onOptionChange(value)}
											loadingText="Загрузка..."
											noOptionsText="Нет элементов"
											renderInput={(params) => (
												<TextField
													{...params}
													label="Выберите дисциплину для добавления"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{loading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</>
														)
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<InputLabel id="select-discipline-label">
								У данного вида спорта дисциплины отсутствуют
							</InputLabel>
						)}
					</>
				)}
				{!isOrganization && (
					<>
						<h2>Звания и категории</h2>
						<Grid container spacing={6}>
							<Grid item sm={6}>
								<>
									<FormControl fullWidth margin="normal">
										<InputLabel id="select-sportrank-label">Спортивное звание</InputLabel>
										<Select
											value={sportState.SportRank || null}
											onChange={changeSportRank}
											labelId="select-sportrank-label"
											placeholder="Не выбрано"
										>
											{["", ...Object.values(ESportRank)].map((item, index) => (
												<MenuItem key={index} value={item}>
													{SportRankList[item] || "Не выбрано"}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{sportState.SportRank && (
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												fullWidth
												disableToolbar
												format="DD.MM.YYYY"
												margin="dense"
												invalidDateMessage="Некорректный формат"
												label="Получено"
												value={
													sportState.SportRankGained
														? moment(sportState.SportRankGained)
														: null
												}
												onChange={(value) => changeDate(value, "SportRankGained")}
												KeyboardButtonProps={{
													"aria-label": "change date"
												}}
											/>
										</MuiPickersUtilsProvider>
									)}
									{sportState.SportRank && (
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												disabled={
													!sportState.SportRankGained ||
													indefinitelyRanks.includes(sportState.SportRank)
												}
												fullWidth
												disableToolbar
												format="DD.MM.YYYY"
												margin="dense"
												invalidDateMessage="Некорректный формат"
												label="Подтверждено"
												value={
													sportState.SportRankConfirmed
														? moment(sportState.SportRankConfirmed)
														: null
												}
												onChange={(value) => changeDate(value, "SportRankConfirmed")}
												KeyboardButtonProps={{
													"aria-label": "change date"
												}}
											/>
										</MuiPickersUtilsProvider>
									)}

									<FormControl fullWidth margin="normal">
										<InputLabel id="select-refereerank-label">Судейская категория</InputLabel>
										<Select
											value={sportState.RefereeRank || null}
											onChange={changeRefereeRank}
											labelId="select-refereerank-label"
											placeholder="Не выбрано"
										>
											{["", ...Object.values(ERefereeRank)].map((item, index) => (
												<MenuItem key={index} value={item}>
													{RefereeRankList[item] || "Не выбрано"}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{sportState.RefereeRank && (
										<CheckboxDatapicker
											date={
												sportState.RefereeRankTill ? moment(sportState?.RefereeRankTill) : null
											}
											onChange={(value) => changeDate(value, "RefereeRankTill")}
										/>
									)}
									<FormControl fullWidth margin="normal">
										<InputLabel id="select-coachrank-label">Тренерская категория</InputLabel>
										<Select
											value={sportState.CoachRank || null}
											onChange={changeCoachRank}
											labelId="select-coachrank-label"
											placeholder="Не выбрано"
										>
											{["", ...Object.values(ECoachRank)].map((item, index) => (
												<MenuItem key={index} value={item}>
													{CoachRankList[item] || "Не выбрано"}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{sportState.CoachRank && (
										<CheckboxDatapicker
											date={sportState.CoachRankTill ? moment(sportState?.CoachRankTill) : null}
											onChange={(value) => changeDate(value, "CoachRankTill")}
										/>
									)}
									<FormControlLabel
										control={
											<Checkbox
												checked={sportState.CoachVeteran || false}
												onChange={changeCoachVeteran}
												name="part-time"
												color="primary"
											/>
										}
										label="Заслуженный тренер России"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={sportState.SportVeteran || false}
												onChange={changeSportVeteran}
												name="part-time"
												color="primary"
											/>
										}
										label="Заслуженный работник ФКиС"
									/>
								</>
							</Grid>
							<Grid item sm={6}>
								<StandartTextInput
									label="Дополнительные звания и награды (в свободной форме)"
									value={sportState.AdditionalRank || ""}
									onChange={changeAdditionalRank}
									margin="normal"
									multiline
									inputProps={{ maxLength: 4000 }}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</div>
		</StandartDialog>
	);
};

export default React.memo(SportModalContent);
