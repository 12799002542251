import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsCoachesRanksSportFullFragment } from "../fragment/StatisticsCoachesRanksSport.generated";
import { StatisticsCoachesRanksSportFullFragmentDoc } from "../fragment/StatisticsCoachesRanksSport.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsCoachesRanksBySportsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsCoachesRanksBySportsQuery = { __typename?: "Query" } & {
	StatisticsCoachesRanksBySports?: Types.Maybe<
		Array<{ __typename?: "StatisticsCoachesRanksSport" } & StatisticsCoachesRanksSportFullFragment>
	>;
};

export const StatisticsCoachesRanksBySportsDocument = gql`
	query StatisticsCoachesRanksBySports {
		StatisticsCoachesRanksBySports {
			...StatisticsCoachesRanksSportFull
		}
	}
	${StatisticsCoachesRanksSportFullFragmentDoc}
`;
export type StatisticsCoachesRanksBySportsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables
	>,
	"query"
>;

export const StatisticsCoachesRanksBySportsComponent = (props: StatisticsCoachesRanksBySportsComponentProps) => (
	<ApolloReactComponents.Query<StatisticsCoachesRanksBySportsQuery, StatisticsCoachesRanksBySportsQueryVariables>
		query={StatisticsCoachesRanksBySportsDocument}
		{...props}
	/>
);

export type StatisticsCoachesRanksBySportsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsCoachesRanksBySports<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables,
		StatisticsCoachesRanksBySportsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables,
		StatisticsCoachesRanksBySportsProps<TChildProps, TDataName>
	>(StatisticsCoachesRanksBySportsDocument, {
		alias: "statisticsCoachesRanksBySports",
		...operationOptions
	});
}

/**
 * __useStatisticsCoachesRanksBySportsQuery__
 *
 * To run a query within a React component, call `useStatisticsCoachesRanksBySportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCoachesRanksBySportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCoachesRanksBySportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsCoachesRanksBySportsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<StatisticsCoachesRanksBySportsQuery, StatisticsCoachesRanksBySportsQueryVariables>(
		StatisticsCoachesRanksBySportsDocument,
		baseOptions
	);
}
export function useStatisticsCoachesRanksBySportsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		StatisticsCoachesRanksBySportsQuery,
		StatisticsCoachesRanksBySportsQueryVariables
	>(StatisticsCoachesRanksBySportsDocument, baseOptions);
}
export type StatisticsCoachesRanksBySportsQueryHookResult = ReturnType<typeof useStatisticsCoachesRanksBySportsQuery>;
export type StatisticsCoachesRanksBySportsLazyQueryHookResult = ReturnType<
	typeof useStatisticsCoachesRanksBySportsLazyQuery
>;
export type StatisticsCoachesRanksBySportsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsCoachesRanksBySportsQuery,
	StatisticsCoachesRanksBySportsQueryVariables
>;
