import { Button, ButtonProps, Dialog, DialogActions, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React from "react";

interface IStandartDialogProps {
	visible: boolean;
	onClose: () => void;
	title?: string;
	children: React.ReactNode | React.ReactNodeArray;
	buttons?: Array<ButtonProps>;
}

export interface IDialogTitleProps {
	children: React.ReactNode;
	onClose: () => void;
}

const Title = (props: IDialogTitleProps) => {
	const { children, onClose, ...other } = props;
	return (
		<DialogTitle {...other} className="standart-modal-title">
			<Typography variant="h6">{children}</Typography>
			<IconButton aria-label="close" onClick={onClose} size="small">
				<i className={"icon icon--event-canceled"} />
			</IconButton>
		</DialogTitle>
	);
};

const StandartDialog = (props: IStandartDialogProps) => {
	const { visible, onClose, title = "Редактирование", children, buttons } = props;

	return (
		<Dialog onClose={onClose} open={visible} maxWidth="xl">
			<Title onClose={onClose}>{title}</Title>
			<div className="standart-modal-content">{children}</div>
			{buttons && (
				<DialogActions>
					{buttons.map(({ title: buttonTitle, ...buttonProps }, index) => (
						<Button key={index} {...buttonProps}>
							{buttonTitle}
						</Button>
					))}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default React.memo(StandartDialog);
