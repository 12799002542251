import * as Types from "../types";

import gql from "graphql-tag";

export type AddressFullFragment = { __typename?: "Address" } & Pick<
	Types.Address,
	| "Id"
	| "Text"
	| "Index"
	| "City"
	| "CityType"
	| "Settlement"
	| "SettlementType"
	| "Street"
	| "StreetType"
	| "House"
	| "HouseType"
	| "GeoLatitude"
	| "GeoLongitude"
>;

export const AddressFullFragmentDoc = gql`
	fragment AddressFull on Address {
		Id
		Text
		Index
		City
		CityType
		Settlement
		SettlementType
		Street
		StreetType
		House
		HouseType
		GeoLatitude
		GeoLongitude
	}
`;
