import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type LinkParticipateEventQueryVariables = Types.Exact<{
	input: Types.LinkParticipateEventInput;
}>;

export type LinkParticipateEventQuery = { __typename?: "Query" } & Pick<Types.Query, "LinkParticipateEvent">;

export const LinkParticipateEventDocument = gql`
	query LinkParticipateEvent($input: LinkParticipateEventInput!) {
		LinkParticipateEvent(input: $input)
	}
`;
export type LinkParticipateEventComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>,
	"query"
> &
	({ variables: LinkParticipateEventQueryVariables; skip?: boolean } | { skip: boolean });

export const LinkParticipateEventComponent = (props: LinkParticipateEventComponentProps) => (
	<ApolloReactComponents.Query<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>
		query={LinkParticipateEventDocument}
		{...props}
	/>
);

export type LinkParticipateEventProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>;
} &
	TChildProps;
export function withLinkParticipateEvent<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		LinkParticipateEventQuery,
		LinkParticipateEventQueryVariables,
		LinkParticipateEventProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		LinkParticipateEventQuery,
		LinkParticipateEventQueryVariables,
		LinkParticipateEventProps<TChildProps, TDataName>
	>(LinkParticipateEventDocument, {
		alias: "linkParticipateEvent",
		...operationOptions
	});
}

/**
 * __useLinkParticipateEventQuery__
 *
 * To run a query within a React component, call `useLinkParticipateEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkParticipateEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkParticipateEventQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkParticipateEventQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>
) {
	return ApolloReactHooks.useQuery<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>(
		LinkParticipateEventDocument,
		baseOptions
	);
}
export function useLinkParticipateEventLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<LinkParticipateEventQuery, LinkParticipateEventQueryVariables>(
		LinkParticipateEventDocument,
		baseOptions
	);
}
export type LinkParticipateEventQueryHookResult = ReturnType<typeof useLinkParticipateEventQuery>;
export type LinkParticipateEventLazyQueryHookResult = ReturnType<typeof useLinkParticipateEventLazyQuery>;
export type LinkParticipateEventQueryResult = ApolloReactCommon.QueryResult<
	LinkParticipateEventQuery,
	LinkParticipateEventQueryVariables
>;
