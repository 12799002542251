import { EContactInfoType } from "@athlete/calendar/dist/generated/graphql";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import "react-responsive-modal/styles.css";
import { ContactTypes } from "../../constants";
import { tempId } from "../../utils/utils";
import ContactModal, { TPartialContact } from "./ContactModal";

interface IContactsEditProps {
	contacts: Array<TPartialContact> | undefined;
	changeContacts: (newContacts: Array<TPartialContact>) => void;
}

const ContactsEdit = (props: IContactsEditProps) => {
	const { contacts, changeContacts } = props;

	const [modalState, setModalState] = useState<{
		visible: boolean;
		contact?: TPartialContact;
	}>({ visible: false });

	const typesContact = Object.keys(ContactTypes) as Array<EContactInfoType>;

	const onOptionChange = useCallback((event: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
		const selectType = event.target.value as EContactInfoType;
		setModalState({ visible: true, contact: { Type: selectType, Id: tempId() } });
	}, []);

	const addContact = (contact: TPartialContact) => {
		const filteredContacts = contacts?.filter((item) => item.Id !== contact.Id);
		if (filteredContacts?.every((item) => item.Type !== contact.Type || item.Value !== contact.Value)) {
			changeContacts([...(filteredContacts || []), contact]);
			return true;
		} else {
			alert("Такой контакт уже существует!");
		}
		return false;
	};

	return (
		<>
			<ContactModal
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				addContact={addContact}
				{...modalState}
			/>
			<Grid container spacing={6}>
				<Grid item sm={6}>
					<h2>Список контактов</h2>
					<List className="contact-list">
						{_.orderBy(contacts, "Value")?.map((item) => (
							<ListItem
								key={item.Id}
								button
								onClick={() => setModalState({ visible: true, contact: item })}
							>
								<ListItemIcon>
									<i
										className={`contact-list__type-icon icon icon--${
											ContactTypes[item.Type].iconName
										}`}
									/>
								</ListItemIcon>
								<ListItemText primary={item.Value} secondary={item.Comment} />
								<ListItemSecondaryAction
									onClick={() => {
										const filteredContacts = contacts?.filter((contact) => item.Id !== contact.Id);
										if (filteredContacts) {
											changeContacts(filteredContacts);
										}
									}}
								>
									<IconButton edge="end" aria-label="delete" color="primary" size="small">
										<i className={"icon icon--event-canceled"} />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</Grid>
				<Grid item sm={6}>
					<h2>Добавить контакт</h2>
					<FormControl fullWidth margin="normal">
						<InputLabel id="select-contacttype-label">Тип контакта</InputLabel>
						<Select
							value=""
							onChange={onOptionChange}
							labelId="select-contacttype-label"
							placeholder="Выберите"
						>
							{typesContact.map((item, index) => (
								<MenuItem key={index} value={item}>
									<i className={`icon icon--margin-right icon--${ContactTypes[item].iconName}`} />
									{ContactTypes[item].title}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Выберите тип контакта для добавления в список</FormHelperText>
					</FormControl>
				</Grid>
			</Grid>
		</>
	);
};

export default React.memo(ContactsEdit);
