import moment from "moment";
import ProfileTypes from "../constants/profileTypes";
import { OrganizationTypeList, PersonTypeNames } from "../constants/strings";
import { EProfileCheckStatus } from "../graphql/types";

// Парсинг параметров из строки (?param1=value1&param2=value2), возвращает объект параметров (ключ - значение)
export function parseQueryParams(search: string) {
	const params: { [key: string]: string } = {};

	if (!search.length || search.length === 1) {
		return params;
	}

	const searchParams = search.slice(1).split("&");

	searchParams.forEach((el) => {
		const searchParam = el.split("=");
		params[searchParam[0]] = searchParam[1];
	});

	return params;
}

interface IGetContactNameContactParam {
	Name?: string | null;
	Profile?: IGetProfileNameParam | null;
}

export function getContactName(contact: IGetContactNameContactParam, defaultName: string) {
	const profileName = getProfileName(contact.Profile);
	return profileName || contact.Name || defaultName;
}

interface IGetProfileNameParam {
	// Person и Organization нужны лишь для типизации
	Person?: {
		Name?: string | null;
		Type?: string | null;
	} | null;
	Organization?: {
		Name?: string | null;
		Type?: string | null;
	} | null;
	PersonOpen?: {
		Name?: string | null;
		Type?: string | null;
	} | null;
	OrganizationOpen?: {
		Name?: string | null;
		Type?: string | null;
	} | null;
}

export function getProfileName(profile?: IGetProfileNameParam | null) {
	if (!profile) {
		return;
	}

	if (profile.Person) {
		if (profile.Person.Name) {
			return profile.Person.Name;
		}
	}

	if (profile.Organization) {
		if (profile.Organization.Name) {
			return profile.Organization.Name;
		}
	}

	if (profile.PersonOpen) {
		if (profile.PersonOpen.Name) {
			return profile.PersonOpen.Name;
		}
	}

	if (profile.OrganizationOpen) {
		if (profile.OrganizationOpen.Name) {
			return profile.OrganizationOpen.Name;
		}
	}

	return;
}

export function getProfileType(profile?: IGetProfileNameParam | null) {
	if (!profile) {
		return ProfileTypes.Sportsman;
	}

	const personType = profile.Person?.Type || profile.PersonOpen?.Type;
	switch (personType) {
		case "Sportsman":
			return ProfileTypes.Sportsman;
		case "Coach":
			return ProfileTypes.Coach;
		case "Child":
			return ProfileTypes.Parent;
	}

	const orgType = profile.Organization?.Type || profile.OrganizationOpen?.Type;
	if (!orgType) {
		return ProfileTypes.Sportsman;
	}
	switch (orgType) {
		case "School":
		case "ChildrenSchool":
			return ProfileTypes.School;
		case "Ministry":
			return ProfileTypes.Ministry;
		case "Federation":
			return ProfileTypes.Federation;
		case "SportObject":
			return ProfileTypes.SportObject;
		default:
			return ProfileTypes.Organization;
	}
}

export function getProfileTypeName(profile?: IGetProfileNameParam | null) {
	if (!profile) {
		return PersonTypeNames.Sportsman;
	}

	const personType = profile.Person?.Type || profile.PersonOpen?.Type;
	if (personType) {
		return PersonTypeNames[personType] || PersonTypeNames.Sportsman;
	}

	const orgType = profile.Organization?.Type || profile.OrganizationOpen?.Type;
	if (orgType) {
		return OrganizationTypeList[orgType] || OrganizationTypeList.Organization;
	}
	return PersonTypeNames.Sportsman;
}

export interface IAddressTitle {
	GeoLatitude?: number | null;
	GeoLongitude?: number | null;
	Text: string;
	City?: string | null;
	CityType?: string | null;
	Settlement?: string | null;
	SettlementType?: string | null;
	Street?: string | null;
	StreetType?: string | null;
	House?: string | null;
	HouseType?: string | null;
}

export function getAddressTitle(item?: IAddressTitle) {
	let addressTitle: string | undefined;
	let listAddressItems: ReadonlyArray<string> = [];

	if (item) {
		if (!item.GeoLatitude || !item.GeoLongitude) {
			addressTitle = item.Text;
			return addressTitle;
		}
		if (item.City) {
			listAddressItems = listAddressItems.concat(item.CityType ? `${item.CityType}. ${item.City}` : item.City);
		}
		if (item.Settlement) {
			listAddressItems = listAddressItems.concat(
				item.SettlementType ? `${item.SettlementType}. ${item.Settlement}` : item.Settlement
			);
		}
		if (item.Street) {
			listAddressItems = listAddressItems.concat(
				item.StreetType ? `${item.StreetType}. ${item.Street}` : item.Street
			);
		}
		if (item.House) {
			listAddressItems = listAddressItems.concat(
				item.HouseType ? `${item.HouseType}. ${item.House}` : `${item.House}`
			);
		}
		if (listAddressItems.length > 0) {
			addressTitle = listAddressItems.join(", ");
		} else {
			addressTitle = item.Text;
		}

		return addressTitle;
	}
	return;
}

export const check18age = (age: string): boolean => {
	return moment().diff(age, "years") >= 18;
};

export function minutesToHoursText(minutes: number) {
	return `${Math.floor(minutes / 60)
		.toString()
		.padStart(2, "0")}:${(minutes % 60).toString().padStart(2, "0")}`;
}

export function hoursTextToMinutes(text?: string) {
	return moment.duration(text).asMinutes();
}

export function tempId() {
	return (-Date.now() + Math.random()).toString();
}

export function isTempId(id: string | undefined) {
	const num = Number(id);
	return !isNaN(num) && num < 0;
}

export function getProfileCheckStatusClass(status?: EProfileCheckStatus | null) {
	switch (status) {
		case EProfileCheckStatus.New:
			return "new-profile";
		case EProfileCheckStatus.Trusted:
			return "validate-profile";
		case EProfileCheckStatus.Blocked:
			return "not-validate-profile";
		case EProfileCheckStatus.Checking:
			return "review-profile";
	}
	return undefined;
}
