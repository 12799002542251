import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsCoachesRanksSportFullFragment = { __typename?: "StatisticsCoachesRanksSport" } & Pick<
	Types.StatisticsCoachesRanksSport,
	| "All"
	| "HighestRank"
	| "FirstRank"
	| "SecondRank"
	| "CoachVeteran"
	| "SportVeteran"
	| "SportId"
	| "SportName"
	| "SportCode"
>;

export const StatisticsCoachesRanksSportFullFragmentDoc = gql`
	fragment StatisticsCoachesRanksSportFull on StatisticsCoachesRanksSport {
		All
		HighestRank
		FirstRank
		SecondRank
		CoachVeteran
		SportVeteran
		SportId
		SportName
		SportCode
	}
`;
