import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ProfileForAvatarFragment } from "../fragment/Profile.generated";
import { ProfileForAvatarFragmentDoc } from "../fragment/Profile.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ProfileShortQueryVariables = Types.Exact<{
	input: Types.ProfileArgsInput;
}>;

export type ProfileShortQuery = { __typename?: "Query" } & {
	Profile: { __typename?: "Profile" } & ProfileForAvatarFragment;
};

export const ProfileShortDocument = gql`
	query ProfileShort($input: ProfileArgsInput!) {
		Profile(input: $input) {
			...ProfileForAvatar
		}
	}
	${ProfileForAvatarFragmentDoc}
`;
export type ProfileShortComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<ProfileShortQuery, ProfileShortQueryVariables>,
	"query"
> &
	({ variables: ProfileShortQueryVariables; skip?: boolean } | { skip: boolean });

export const ProfileShortComponent = (props: ProfileShortComponentProps) => (
	<ApolloReactComponents.Query<ProfileShortQuery, ProfileShortQueryVariables>
		query={ProfileShortDocument}
		{...props}
	/>
);

export type ProfileShortProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<ProfileShortQuery, ProfileShortQueryVariables>;
} &
	TChildProps;
export function withProfileShort<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		ProfileShortQuery,
		ProfileShortQueryVariables,
		ProfileShortProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		ProfileShortQuery,
		ProfileShortQueryVariables,
		ProfileShortProps<TChildProps, TDataName>
	>(ProfileShortDocument, {
		alias: "profileShort",
		...operationOptions
	});
}

/**
 * __useProfileShortQuery__
 *
 * To run a query within a React component, call `useProfileShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileShortQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileShortQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileShortQuery, ProfileShortQueryVariables>
) {
	return ApolloReactHooks.useQuery<ProfileShortQuery, ProfileShortQueryVariables>(ProfileShortDocument, baseOptions);
}
export function useProfileShortLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileShortQuery, ProfileShortQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<ProfileShortQuery, ProfileShortQueryVariables>(
		ProfileShortDocument,
		baseOptions
	);
}
export type ProfileShortQueryHookResult = ReturnType<typeof useProfileShortQuery>;
export type ProfileShortLazyQueryHookResult = ReturnType<typeof useProfileShortLazyQuery>;
export type ProfileShortQueryResult = ApolloReactCommon.QueryResult<ProfileShortQuery, ProfileShortQueryVariables>;
