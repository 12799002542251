import * as Types from "../types";

import gql from "graphql-tag";

export type ProfileWorkingHoursFullFragment = { __typename?: "ProfileWorkingHours" } & Pick<
	Types.ProfileWorkingHours,
	"Id" | "CreatedAt" | "UpdatedAt" | "Day" | "WorkFrom" | "WorkTo"
>;

export const ProfileWorkingHoursFullFragmentDoc = gql`
	fragment ProfileWorkingHoursFull on ProfileWorkingHours {
		Id
		CreatedAt
		UpdatedAt
		Day
		WorkFrom
		WorkTo
	}
`;
