import React from "react";

import Box from "@material-ui/core/Box";

interface ITabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	name: string;
}

const TabPanel = (props: ITabPanelProps) => {
	const { children, value, index, name } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`${name}-tabpanel-${index}`}
			aria-labelledby={`${name}-tab-${index}`}
		>
			<Box p={4}>{children}</Box>
		</div>
	);
};

export default TabPanel;
