import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsOrganizationsFullFragment = { __typename?: "StatisticsOrganizations" } & {
	Education: { __typename?: "OrganizatonsByTypes" } & StatisticsOrganizationsRowFragment;
	Sport: { __typename?: "OrganizatonsByTypes" } & StatisticsOrganizationsRowFragment;
	Other?: Types.Maybe<{ __typename?: "OrganizatonsByTypes" } & StatisticsOrganizationsRowFragment>;
	AdditionalEducation: { __typename?: "OrganizatonsByTypes" } & StatisticsOrganizationsRowFragment;
	SportsProgramm: { __typename?: "OrganizatonsByTypes" } & StatisticsOrganizationsRowFragment;
};

export type StatisticsOrganizationsRowFragment = { __typename?: "OrganizatonsByTypes" } & Pick<
	Types.OrganizatonsByTypes,
	| "School"
	| "ChildrenSchool"
	| "OlympicSchool"
	| "OlympicChildrenSchool"
	| "OlympicCollege"
	| "OlympicCenter"
	| "Center"
	| "Other"
>;

export const StatisticsOrganizationsRowFragmentDoc = gql`
	fragment StatisticsOrganizationsRow on OrganizatonsByTypes {
		School
		ChildrenSchool
		OlympicSchool
		OlympicChildrenSchool
		OlympicCollege
		OlympicCenter
		Center
		Other
	}
`;
export const StatisticsOrganizationsFullFragmentDoc = gql`
	fragment StatisticsOrganizationsFull on StatisticsOrganizations {
		Education {
			...StatisticsOrganizationsRow
		}
		Sport {
			...StatisticsOrganizationsRow
		}
		Other {
			...StatisticsOrganizationsRow
		}
		AdditionalEducation {
			...StatisticsOrganizationsRow
		}
		SportsProgramm {
			...StatisticsOrganizationsRow
		}
	}
	${StatisticsOrganizationsRowFragmentDoc}
`;
