import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsCoachesOrgFullFragment } from "../fragment/StatisticsCoachesOrgFull.generated";
import { StatisticsCoachesOrgFullFragmentDoc } from "../fragment/StatisticsCoachesOrgFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsCoachessByOrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsCoachessByOrganizationsQuery = { __typename?: "Query" } & {
	StatisticsCoachessByOrganizations?: Types.Maybe<
		Array<{ __typename?: "StatisticsCoachesOrg" } & StatisticsCoachesOrgFullFragment>
	>;
};

export const StatisticsCoachessByOrganizationsDocument = gql`
	query StatisticsCoachessByOrganizations {
		StatisticsCoachessByOrganizations {
			...StatisticsCoachesOrgFull
		}
	}
	${StatisticsCoachesOrgFullFragmentDoc}
`;
export type StatisticsCoachessByOrganizationsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>,
	"query"
>;

export const StatisticsCoachessByOrganizationsComponent = (props: StatisticsCoachessByOrganizationsComponentProps) => (
	<ApolloReactComponents.Query<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>
		query={StatisticsCoachessByOrganizationsDocument}
		{...props}
	/>
);

export type StatisticsCoachessByOrganizationsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsCoachessByOrganizations<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables,
		StatisticsCoachessByOrganizationsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables,
		StatisticsCoachessByOrganizationsProps<TChildProps, TDataName>
	>(StatisticsCoachessByOrganizationsDocument, {
		alias: "statisticsCoachessByOrganizations",
		...operationOptions
	});
}

/**
 * __useStatisticsCoachessByOrganizationsQuery__
 *
 * To run a query within a React component, call `useStatisticsCoachessByOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCoachessByOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCoachessByOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsCoachessByOrganizationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>(StatisticsCoachessByOrganizationsDocument, baseOptions);
}
export function useStatisticsCoachessByOrganizationsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		StatisticsCoachessByOrganizationsQuery,
		StatisticsCoachessByOrganizationsQueryVariables
	>(StatisticsCoachessByOrganizationsDocument, baseOptions);
}
export type StatisticsCoachessByOrganizationsQueryHookResult = ReturnType<
	typeof useStatisticsCoachessByOrganizationsQuery
>;
export type StatisticsCoachessByOrganizationsLazyQueryHookResult = ReturnType<
	typeof useStatisticsCoachessByOrganizationsLazyQuery
>;
export type StatisticsCoachessByOrganizationsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsCoachessByOrganizationsQuery,
	StatisticsCoachessByOrganizationsQueryVariables
>;
