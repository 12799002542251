import * as Types from "../types";

import gql from "graphql-tag";

export type OrganizationFullFragment = { __typename?: "Organization" } & Pick<
	Types.Organization,
	"Id" | "Type" | "Inn" | "OKVED" | "Name" | "FullName" | "Description" | "Rating" | "Voices" | "ProfileId"
> & {
		Data: { __typename?: "OrganizationData" } & Pick<
			Types.OrganizationData,
			"Equipment" | "Rules" | "Achievements"
		> & { Director?: Types.Maybe<{ __typename?: "DirectorData" } & Pick<Types.DirectorData, "Name">> };
	};

export type OrganizationForAvatarFragment = { __typename?: "Organization" } & Pick<
	Types.Organization,
	"Id" | "Type" | "Name"
>;

export type OrganizationOpenFragment = { __typename?: "Organization" } & Pick<
	Types.Organization,
	"Id" | "Type" | "Name" | "FullName" | "Description" | "Rating"
>;

export const OrganizationFullFragmentDoc = gql`
	fragment OrganizationFull on Organization {
		Id
		Type
		Inn
		OKVED
		Name
		FullName
		Description
		Data {
			Equipment
			Rules
			Achievements
			Director {
				Name
			}
		}
		Rating
		Voices
		ProfileId
	}
`;
export const OrganizationForAvatarFragmentDoc = gql`
	fragment OrganizationForAvatar on Organization {
		Id
		Type
		Name
	}
`;
export const OrganizationOpenFragmentDoc = gql`
	fragment OrganizationOpen on Organization {
		Id
		Type
		Name
		FullName
		Description
		Rating
	}
`;
