import * as Types from "../types";

import gql from "graphql-tag";
import { ContactForAvatarFragment } from "./Contact.generated";
import { ContactForAvatarFragmentDoc } from "./Contact.generated";
import { EventChangeForCalendarFragment } from "./EventChange.generated";
import { EventChangeForCalendarFragmentDoc } from "./EventChange.generated";
import { ProfilePlaceFullFragment } from "./ProfilePlace.generated";
import { ProfilePlaceFullFragmentDoc } from "./ProfilePlace.generated";

export type EventForCalendarFragment = { __typename?: "Event" } & Pick<
	Types.Event,
	| "Id"
	| "CreatedAt"
	| "UpdatedAt"
	| "Name"
	| "StartTime"
	| "EndTime"
	| "Duration"
	| "Day"
	| "StartAt"
	| "Comment"
	| "Recurrent"
	| "Type"
	| "Cost"
	| "AccessType"
	| "Status"
	| "MaxParticipants"
	| "MutationStatus"
	| "Organizers"
	| "ShowOwner"
	| "Difficulty"
	| "ProfilePlaceId"
	| "ProfileId"
	| "OtherSport"
	| "MetricName"
	| "MetricValue"
> & {
		Badges?: Types.Maybe<{ __typename?: "Badges" } & Pick<Types.Badges, "New" | "Promo">>;
		ProfilePlace?: Types.Maybe<{ __typename?: "ProfilePlace" } & ProfilePlaceFullFragment>;
		Coaches?: Types.Maybe<Array<{ __typename?: "Contact" } & ContactForAvatarFragment>>;
		Changes?: Types.Maybe<Array<{ __typename?: "EventChange" } & EventChangeForCalendarFragment>>;
	};

export const EventForCalendarFragmentDoc = gql`
	fragment EventForCalendar on Event {
		Id
		CreatedAt
		UpdatedAt
		Name
		StartTime
		EndTime
		Duration
		Day
		StartAt
		Comment
		Recurrent
		Type
		Cost
		AccessType
		Status
		MaxParticipants
		MutationStatus
		Organizers
		ShowOwner
		Difficulty
		ProfilePlaceId
		ProfileId
		OtherSport
		MetricName
		MetricValue
		Badges {
			New
			Promo
		}
		ProfilePlace {
			...ProfilePlaceFull
		}
		Coaches {
			...ContactForAvatar
		}
		Changes {
			...EventChangeForCalendar
		}
	}
	${ProfilePlaceFullFragmentDoc}
	${ContactForAvatarFragmentDoc}
	${EventChangeForCalendarFragmentDoc}
`;
