import { ContactInfo, EContactInfoType } from "@athlete/calendar/dist/generated/graphql";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { ContactTypes } from "../../constants";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";

export type TPartialContact = Partial<ContactInfo> & { Type: EContactInfoType; Id: string };

interface IContactModalProps {
	visible: boolean;
	contact?: TPartialContact;
	onClose: () => void;
	addContact: (contact: TPartialContact) => boolean;
}

interface IContactValueInputProps {
	contact: TPartialContact;
	changeValue: (value: string, isValid: boolean) => void;
}

const defaultValdidty = { Value: true };

const ContactValueInput = (props: IContactValueInputProps) => {
	const { contact, changeValue } = props;
	const [error, setError] = useState(false);

	const contactStrings = ContactTypes[contact.Type];

	switch (contact.Type) {
		case EContactInfoType.CellPhone:
		case EContactInfoType.Phone:
		case EContactInfoType.Viber:
		case EContactInfoType.WhatsApp:
			return (
				<StandartTextInput
					required
					value={contact.Value || ""}
					onChange={changeValue}
					label={`Введите ${contactStrings.title.toLowerCase()}`}
					margin="normal"
					mask={contactStrings.mask}
					pattern={contactStrings.pattern}
					errorMessage={contactStrings.errorMessage}
				/>
			);
		case EContactInfoType.Email:
		case EContactInfoType.Url:
		case EContactInfoType.Icq:
		case EContactInfoType.Telegram:
		case EContactInfoType.Vk:
		case EContactInfoType.Facebook:
		case EContactInfoType.Instagramm:
		case EContactInfoType.Od:
		case EContactInfoType.Twitter:
		case EContactInfoType.Skype:
		case EContactInfoType.YouTube:
			const checkValue = (value: string) => {
				let isValid = true;
				if (contactStrings.pattern) {
					isValid = contactStrings.pattern.test(value);
					setError(!isValid);
				}
				changeValue(value, isValid);
			};

			return (
				<StandartTextInput
					required
					placeholder={contactStrings.placeholder}
					value={contact.Value || ""}
					onChange={checkValue}
					label={`Введите ${contactStrings.title.toLowerCase()}`}
					margin="normal"
					error={error}
					helperText={error ? contactStrings.errorMessage : undefined}
				/>
			);

		default:
			return null;
	}
};

const ContactModal = (props: IContactModalProps) => {
	const { visible, contact, onClose, addContact } = props;

	const [contactState, setContactState] = useState<TPartialContact>();

	const [formFieldsValid, setFormFieldsValid] = useState(defaultValdidty);

	useEffect(() => {
		if (visible) {
			setContactState(contact);
			setFormFieldsValid({ Value: !!contact?.Value?.length });
		}
	}, [contact, visible]);

	if (!contactState) {
		return null;
	}

	const changeValue = (value: string, isValid: boolean) => {
		setContactState((prevState) => ({ ...(prevState || contactState), Value: value }));
		setFormFieldsValid((prevState) => ({ ...prevState, Value: isValid }));
	};

	const changeComment = (v: string) => {
		setContactState({ ...contactState, Comment: v });
	};

	const saveContact = async () => {
		const res = addContact(contactState);
		if (res) {
			onClose();
		}
	};

	return (
		<StandartDialog
			visible={visible}
			onClose={onClose}
			title={ContactTypes[contactState.Type]?.title}
			buttons={[
				{
					title: "Сохранить",
					disabled: Object.values(formFieldsValid).includes(false),
					onClick: saveContact
				}
			]}
		>
			<div className="edit-contacts-form">
				<ContactValueInput contact={contactState} changeValue={changeValue} />
				<label>
					<StandartTextInput
						label="Коментарий к контакту (не обязательно)"
						multiline
						margin="normal"
						rows={2}
						onChange={changeComment}
						value={contactState.Comment || ""}
						inputProps={{ maxLength: 150 }}
					/>
				</label>
			</div>
		</StandartDialog>
	);
};

export default React.memo(ContactModal);
