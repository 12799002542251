import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { SearchFragment } from "../../graphql/fragment/Search.generated";
import { useUi_SearchLazyQuery } from "../../graphql/query/UI_Search.generated";
import { EOrganizationType, ESearchType } from "../../graphql/types";

interface IFederationsSearchProps {
	hash: string;
	value?: SearchFragment | null;
	changeValue: (newValue: SearchFragment | null) => void;
}

const FederationsSearch = (props: IFederationsSearchProps) => {
	const [value, setValue] = useState(props.value?.Name || "");
	const [listSearch, setListSearch] = useState<Array<SearchFragment>>();
	const [searchQuery, { data, loading }] = useUi_SearchLazyQuery();

	const [debouncedName] = useDebounce(value, 500);

	useEffect(() => {
		searchQuery({
			context: { headers: { Authorization: `Digest ${props.hash}` } },
			variables: {
				input: {
					Name: value || "",
					Type: ESearchType.Organization,
					OrganizationType: [EOrganizationType.Federation],
					Limit: 100,
					Offset: 0
				}
			}
		});
	}, [debouncedName]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const v = event.target.value;
		setValue(v || "");
		props.changeValue(listSearch?.find((item) => item.Name === v) || null);
	};
	const getLabel = useCallback((option: SearchFragment) => option.Name || "", []);

	const selectOption = (_event: React.ChangeEvent<{}>, option: string | SearchFragment | null) => {
		if (typeof option !== "string") {
			const newName = option?.Name || "";
			setValue(newName);
			props.changeValue(option);
		}
	};

	useEffect(() => {
		if (data && data.Search) {
			setListSearch(data.Search);
		}
	}, [data]);

	return (
		<Autocomplete
			id="place-name"
			noOptionsText="Нет элементов"
			loading={loading}
			options={listSearch || []}
			value={{ Id: "-1", Name: value }}
			getOptionLabel={getLabel}
			onChange={selectOption}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth
					label="Членство в спортивной федерации"
					margin="normal"
					onChange={onChange}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
		/>
	);
};

export default React.memo(FederationsSearch);
