import * as Types from "../types";

import gql from "graphql-tag";
import { AddressFullFragment } from "./Address.generated";
import { AddressFullFragmentDoc } from "./Address.generated";

export type SearchFragment = { __typename?: "SearchResult" } & Pick<
	Types.SearchResult,
	| "Id"
	| "Name"
	| "ProfileId"
	| "PersonType"
	| "OrgType"
	| "ObjType"
	| "EventType"
	| "Rating"
	| "Distance"
	| "Cost"
	| "StartTime"
	| "EndTime"
	| "WorkFrom"
	| "WorkTo"
	| "FollowersCount"
	| "IsFollower"
	| "MapDistance"
> & {
		AvatarAttachment?: Types.Maybe<{ __typename?: "Attachment" } & Pick<Types.Attachment, "Id" | "FileLink">>;
		Address?: Types.Maybe<
			{ __typename?: "Address" } & {
				Region?: Types.Maybe<{ __typename?: "Region" } & Pick<Types.Region, "Id" | "Code">>;
			} & AddressFullFragment
		>;
		location?: Types.Maybe<
			{ __typename?: "SearchResultLocation" } & Pick<Types.SearchResultLocation, "latitude" | "longitude">
		>;
	};

export const SearchFragmentDoc = gql`
	fragment Search on SearchResult {
		Id
		Name
		ProfileId
		PersonType
		OrgType
		ObjType
		EventType
		Rating
		Distance
		Cost
		StartTime
		EndTime
		WorkFrom
		WorkTo
		FollowersCount
		IsFollower
		AvatarAttachment {
			Id
			FileLink
		}
		Address {
			...AddressFull
			Region {
				Id
				Code
			}
		}
		location {
			latitude
			longitude
		}
		MapDistance
	}
	${AddressFullFragmentDoc}
`;
