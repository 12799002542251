import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import Rating from "react-rating";
import { Themes } from "../../constants";
import { useProfileEditQuery } from "../../graphql/query/ProfileEdit.generated";
import { getProfileName, getProfileType, getProfileTypeName } from "../../utils/utils";
import Avatar from "../avatar";
import StatisticsTabs from "./statisticsTabs/StatisticsTabs";

interface IStatisticProps {
	hash: string;
}

const Statistic: React.ComponentType<IStatisticProps> = (props) => {
	const { hash } = props;

	const { data } = useProfileEditQuery({
		context: { headers: { Authorization: `Digest ${hash}` } },
		fetchPolicy: "cache-and-network"
	});

	const profile = data?.MyProfile;

	if (!profile) {
		return <p>Загрузка данных...</p>;
	}

	const avatarURL = profile?.AvatarAttachment?.FileLink;
	const profileType = getProfileType(profile);
	const themeProfile = Themes[profileType];

	const profileName = getProfileName(profile);
	const profileTypeName = getProfileTypeName(profile);

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: themeProfile.colors.main,
				light: themeProfile.colors.light
			}
		}
	});
	return (
		<section className="container edit-profile">
			<div className="profile-content profile-header">
				<Avatar size={100} type={profileType} URL={avatarURL} checkStatus={profile.CheckStatus} />
				<div className="base-info">
					<h1>{profileName}</h1>
					<div className={`profile-type ${themeProfile.color}`}>
						<i className={`icon ${themeProfile.icon}`} />
						<span>{profileTypeName}</span>
					</div>
					{profile?.Organization?.FullName && <p className="full-name">{profile.Organization.FullName}</p>}
					<div className="rating-container">
						<Rating
							readonly
							initialRating={profile?.Person?.Rating || profile?.Organization?.Rating || 0}
							emptySymbol="rating-empty"
							fullSymbol="rating-full"
						/>
						<div>Отзывов: {profile?.ReviewsCount || 0}</div>
					</div>
				</div>
			</div>
			<ThemeProvider theme={theme}>
				<StatisticsTabs data={data} hash={hash} />
			</ThemeProvider>
		</section>
	);
};

export default Statistic;
