import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { hoursTextToMinutes, minutesToHoursText } from "../../utils/utils";

interface ITimeRangeSliderProps {
	defaultInterval: Array<string>;
	min?: number;
	max?: number;
	value?: Array<string>;
	onChange: (value: Array<string>) => void;
	visible?: boolean;
	title?: string;
	titleComponent?: ReactNode;
	description?: string;
}

export default function TimeRangeSlider(props: ITimeRangeSliderProps) {
	const {
		value,
		onChange,
		min = 0,
		max = 1440, // 24 * 60 количество минут в сутках
		visible = true,
		title,
		titleComponent,
		description,
		defaultInterval
	} = props;
	const [state, setState] = useState<{ value: Array<number>; text: Array<string> }>({
		text: value?.map((item) => item.slice(0, 5)) || defaultInterval,
		value: (value || defaultInterval).map((time) => hoursTextToMinutes(time))
	});

	useEffect(() => {
		setState({
			text: value?.map((item) => item.slice(0, 5)) || defaultInterval,
			value: (value || defaultInterval).map((time) => hoursTextToMinutes(time))
		});
	}, [value && value[0], value && value[1]]);

	const handleChange = (_event: any, newValue: number | Array<number>) => {
		const textValue = (newValue as Array<number>).map((minutes) => minutesToHoursText(minutes));
		setState({
			value: newValue as Array<number>,
			text: textValue
		});
		onChange(textValue);
	};

	const marks = [
		{
			value: min,
			label: moment.utc(min * 60 * 1000).format("HH:mm")
		},
		{
			value: max,
			label: moment.utc(max * 60 * 1000).format("kk:mm")
		}
	];

	return (
		<div className={!visible ? "working-table__time disabled" : "working-table__time"}>
			<div className="text-content">
				{titleComponent}
				{!titleComponent && !!title && <div className="title">{title}</div>}
				<span className="time">{`${state.text[0]} - ${state.text[1]}`}</span>
			</div>
			{!!description && (
				<Typography className="text-content__comment" gutterBottom>
					{description}
				</Typography>
			)}
			<Slider
				disabled={!visible}
				min={min}
				max={max}
				step={5}
				value={state.value}
				onChange={handleChange}
				valueLabelDisplay="auto"
				aria-labelledby="range-slider"
				valueLabelFormat={minutesToHoursText}
				marks={marks}
			/>
		</div>
	);
}
