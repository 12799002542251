import { DeepPartial } from "@athlete/utils";
import { CircularProgress, Grid, Icon, IconButton, Snackbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import _ from "lodash";
import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useMemo, useState } from "react";
import { EduTypes } from "../../constants";
import { ContactFullFragment } from "../../graphql/fragment/Contact.generated";
import { useUpsertProfileMutation } from "../../graphql/mutation/UpsertProfile.generated";
import { ProfileEditQuery } from "../../graphql/query/ProfileEdit.generated";
import {
	EContactStatus,
	EPersonType,
	EWeekDays,
	Organization,
	Profile,
	ProfileAchievement,
	ProfileEducation,
	ProfileSport,
	ProfileWorkingHoursData
} from "../../graphql/types";
import { check18age, isTempId } from "../../utils/utils";
import AchievementsEdit, { TPartialAchievement } from "./AchievementsEdit";
import CoachesEdit, { TPartialCoach } from "./CoachesEdit";
import { TPartialContact } from "./ContactModal";
import ContactsEdit from "./ContactsEdit";
import EducationEdit, { TPartialProfileEducation } from "./EducationEdit";
import ExtraTab from "./ExtraTab";
import MainTab from "./MainTab";
import { checkInnValid } from "./mainTab/MainOrgInfoSection";
import { TPartialPerson } from "./mainTab/MainPersonInfo";
import PlacesEdit from "./PlacesEdit";
import SportsEdit, { TProfileDiscipline } from "./SportsEdit";
import TabPanel from "./TabPanel";
import { IWorkingDay } from "./WorkingDay";
import WorkingHours from "./WorkingHours";

interface IProfileEditTabsProps {
	hash: string;
	data: ProfileEditQuery | undefined;
}

moment.locale("ru");

export interface IValidProfileEditTabsState {
	Person: {
		Name: boolean;
		SSN: boolean;
		MedicalInsuranceNumber: boolean;
		NextMedicalExaminationDate: boolean;
		StartSportDate: boolean;
		Birthday: boolean;
	};
	Organization: {
		Name: boolean;
		Inn: boolean;
	};
	PersonPassport: {
		PassportNumber: boolean;
		SubdivisionCode: boolean;
		IssuedAt: boolean;
	};
	PersonBrCert: {
		BirthCertificate: boolean;
		BirthRecordDate: boolean;
	};
	PersonAchievements: {
		GtoCertificate: boolean;
	};
}

const defaultValdity: IValidProfileEditTabsState = {
	Person: {
		Name: true,
		SSN: true,
		MedicalInsuranceNumber: true,
		NextMedicalExaminationDate: true,
		StartSportDate: true,
		Birthday: true
	},
	Organization: {
		Name: true,
		Inn: true
	},
	PersonPassport: {
		PassportNumber: true,
		SubdivisionCode: true,
		IssuedAt: true
	},
	PersonBrCert: {
		BirthCertificate: true,
		BirthRecordDate: true
	},
	PersonAchievements: {
		GtoCertificate: true
	}
};

const ProfileEditTabs: React.ComponentType<IProfileEditTabsProps> = (props) => {
	const { hash, data } = props;

	const [tabIndex, setTabIndex] = React.useState(0);

	// TODO: Исправить <any> на корректный тип после добавления соответствующего фрагмента
	const [profile, setProfile] = useState<any>();
	const [formFieldsValid, setFormFieldsValid] = useState<IValidProfileEditTabsState>(defaultValdity);

	const [selectedSports, setSelectedSports] = useState<Array<DeepPartial<ProfileSport>>>();
	const [selectedDisciplines, setSelectedDisciplines] = useState<Array<TProfileDiscipline>>();

	const [upsert, { loading: loadingUpsert }] = useUpsertProfileMutation();
	const [workingDays, setWorkingDays] = useState<{ [key: string]: IWorkingDay }>();
	useEffect(() => {
		if (data) {
			setProfile(data.MyProfile);
			setSelectedSports(data.MyProfile?.Sports);
			setSelectedDisciplines(data.MyProfile?.Disciplines);
			setFormFieldsValid({
				Organization: {
					Name: !!data.MyProfile.Organization?.Name?.length,
					Inn: !!data.MyProfile.Organization?.Inn && checkInnValid(data.MyProfile.Organization?.Inn)
				},
				Person: {
					Name: !!data.MyProfile.Person?.Name?.length,
					MedicalInsuranceNumber: true,
					SSN: true,
					NextMedicalExaminationDate:
						!data.MyProfile.Person?.NextMedicalExaminationDate ||
						moment(data.MyProfile.Person.NextMedicalExaminationDate).isAfter(moment()),
					StartSportDate:
						!data.MyProfile.Person?.StartSportDate ||
						moment(data.MyProfile.Person.StartSportDate).isBefore(moment()),
					Birthday:
						!data.MyProfile.Person?.Birthday || moment(data.MyProfile.Person.Birthday).isBefore(moment())
				},
				PersonBrCert: {
					BirthCertificate: true,
					BirthRecordDate:
						!data.MyProfile.Person?.BirthRecordDate ||
						moment(data.MyProfile.Person.BirthRecordDate).isBefore(moment())
				},
				PersonPassport: {
					PassportNumber: true,
					SubdivisionCode: true,
					IssuedAt:
						!data.MyProfile.Person?.IssuedAt || moment(data.MyProfile.Person.IssuedAt).isBefore(moment())
				},
				PersonAchievements: {
					GtoCertificate: true
				}
			});

			const groupedWorkingPeriods = _.groupBy(data.MyProfile?.WorkingHours, "Day");
			const days: { [key: string]: IWorkingDay } = {};
			Object.entries(groupedWorkingPeriods).forEach(([day, periods]) => {
				switch (periods.length) {
					case 1:
						days[day] = {
							weekday: day as EWeekDays,
							startTime: periods[0].WorkFrom,
							endTime: periods[0].WorkTo
						};
						break;
					case 2:
						days[day] = {
							weekday: day as EWeekDays,
							startTime: periods[0].WorkFrom,
							endTime: periods[1].WorkTo,
							startLunchTime: periods[0].WorkTo,
							endLunchTime: periods[1].WorkFrom
						};
						break;
					default:
						days[day] = {
							weekday: day as EWeekDays
						};
				}
			});
			setWorkingDays(days);
		}
	}, [data]);

	const [savedSnackbarVisible, setSavedSnackbarVisible] = useState(false);
	const upsertProfile = async () => {
		const person = !profile.Person
			? {}
			: {
					...profile.Person,
					...(check18age(profile.Person.Birthday)
						? {
								BirthCertificate: null,
								BirthRecordAcceptedBy: null,
								BirthRecordDate: null
						  }
						: {
								PassportNumber: null,
								SubdivisionName: null,
								SubdivisionCode: null,
								IssuedAt: null
						  }),
					NextMedicalExaminationDate:
						!profile.Person.NextMedicalExaminationDate ||
						typeof profile.Person.NextMedicalExaminationDate === "string"
							? profile.Person.NextMedicalExaminationDate
							: profile.Person.NextMedicalExaminationDate.format("YYYY.MM.DD"),
					StartSportDate:
						!profile.Person.StartSportDate || typeof profile.Person.StartSportDate === "string"
							? profile.Person.StartSportDate
							: profile.Person.StartSportDate.toISOString(),
					Birthday:
						!profile.Person.Birthday || typeof profile.Person.Birthday === "string"
							? profile.Person.Birthday
							: profile.Person.Birthday.toISOString(),
					IssuedAt:
						!profile.Person.IssuedAt || typeof profile.Person.IssuedAt === "string"
							? profile.Person.IssuedAt
							: profile.Person.IssuedAt.toISOString(),
					BirthRecordDate:
						!profile.Person.BirthRecordDate || typeof profile.Person.BirthRecordDate === "string"
							? profile.Person.BirthRecordDate
							: profile.Person.BirthRecordDate.toISOString(),
					__typename: undefined,
					Data: {
						__typename: undefined,
						Gto: !profile.Person.Data.Gto
							? undefined
							: { ...profile.Person.Data.Gto, __typename: undefined }
					}
			  };

		// Оставляем только необходимые поля для мутации
		const { Id: personId, Rating: personRating, LegalRepresentative, Federation, ...personParams } = person;

		const organization = !profile.Organization
			? {}
			: {
					...profile.Organization,
					Data: {
						...profile.Organization.Data,
						Director: {
							...profile.Organization.Data.Director,
							__typename: undefined
						},
						__typename: undefined
					},
					__typename: undefined
			  };

		// Оставляем только необходимые поля для мутации
		const {
			Id: organizationId,
			Voices,
			Rating: organizationRating,
			ProfileId,
			...organizationParams
		} = organization;
		if (organizationId) {
			organizationParams.OKVED = Number.parseInt(organizationParams.OKVED, 10);
		}

		const sportsParams = selectedSports?.map((sportItem) => {
			const { __typename, Sport: sport, ...sportInput } = sportItem;
			const DisciplineId = selectedDisciplines
				?.filter((discItem) => discItem.Discipline?.SportId.toString() === sport?.Id)
				.map((item) => item.Discipline?.Id);
			return { ...sportInput, DisciplineId };
		});

		const workingHoursParams: Array<ProfileWorkingHoursData> = [];
		Object.values(workingDays || {}).forEach((day) => {
			if (!day.startLunchTime) {
				if (day.startTime && day.endTime) {
					workingHoursParams.push({ Day: day.weekday, WorkFrom: day.startTime, WorkTo: day.endTime });
				}
			} else {
				workingHoursParams.push({ Day: day.weekday, WorkFrom: day.startTime!, WorkTo: day.startLunchTime! });
				workingHoursParams.push({ Day: day.weekday, WorkFrom: day.endLunchTime!, WorkTo: day.endTime! });
			}
		});

		const contactsParams = profile?.Contacts?.map((contactItem: TPartialContact) => {
			if (isTempId(contactItem.Id)) {
				const { __typename, Id, ...contactInput } = contactItem;
				return contactInput;
			} else {
				const { __typename, ...contactInput } = contactItem;
				return contactInput;
			}
		});

		const educationParams = profile?.Education?.map((item: TPartialProfileEducation) => {
			if (isTempId(item.Id)) {
				const { __typename, Id, ProfileId: educationProfileId, ...educationInput } = item;
				return educationInput;
			} else {
				const { __typename, ProfileId: educationProfileId, ...educationInput } = item;
				return educationInput;
			}
		});

		const achievementsParams = profile?.Achievements?.map((item: TPartialAchievement) => {
			if (isTempId(item.Id)) {
				const {
					__typename,
					Id,
					Sport,
					MutationStatus,
					ProfileId: achievementProfileId,
					...achievementInput
				} = item;
				return achievementInput;
			} else {
				const {
					__typename,
					Sport,
					MutationStatus,
					ProfileId: achievementProfileId,
					...achievementInput
				} = item;
				return achievementInput;
			}
		});

		const res = await upsert({
			context: {
				headers: {
					Authorization: `Digest ${hash}`
				}
			},
			variables: {
				data: {
					Id: profile.Id,
					PrimaryRegionId: profile?.PrimaryRegion?.Id || null,
					SecondaryRegionId: profile?.PrimaryRegion?.Id ? profile?.SecondaryRegion?.Id : null,
					Address: profile?.Address?.Changed ? { Text: profile.Address.Text } : undefined,
					Person: _.isEmpty(personParams) ? null : personParams,
					Organization: _.isEmpty(organizationParams) ? null : organizationParams,
					Sport: sportsParams,
					ContactInfo: contactsParams,
					WorkingHours: workingHoursParams,
					Education: educationParams,
					Achievements: achievementsParams
				}
			}
		});

		if (res.errors || !res.data?.upsertProfile.success) {
			alert("Не удалось сохранить изменения");
			return;
		}
		setSavedSnackbarVisible(true);
	};

	const coaches = useMemo(
		() => profile?.Coaches.filter((item: ContactFullFragment) => item.Status !== EContactStatus.Deleted),
		[profile?.Coaches]
	);

	if (!profile) {
		return null;
	}
	const tabsError = {
		Organization: profile.Organization && Object.values(formFieldsValid.Organization).includes(false),
		Person:
			profile.Person &&
			(Object.values(formFieldsValid.Person).includes(false) ||
				(profile.Person?.Birthday && check18age(profile.Person.Birthday)
					? Object.values(formFieldsValid.PersonPassport).includes(false)
					: Object.values(formFieldsValid.PersonBrCert).includes(false))),
		Achievements: profile.Person && Object.values(formFieldsValid.PersonAchievements).includes(false)
	};

	const saveButtonDisabled = loadingUpsert || Object.values(tabsError).includes(true);

	const changeContacts = (newContacts: Array<TPartialContact>) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Contacts: newContacts };
		});
	};
	const changeCoaches = (newCoaches: Array<TPartialCoach>) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Coaches: newCoaches };
		});
	};

	const changeEducation = (newEducation: Array<Partial<ProfileEducation>>) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Education: newEducation };
		});
	};

	const changeAchievements = (newAchievements: Array<Partial<ProfileAchievement>>) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Achievements: newAchievements };
		});
	};

	const changePersonData = (
		newPersonState: DeepPartial<TPartialPerson>,
		newValidState?: DeepPartial<IValidProfileEditTabsState>
	) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Person: { ...prevState.Person, ...newPersonState } };
		});
		if (newValidState) {
			setFormFieldsValid((prevValidState) => ({
				...prevValidState,
				Person: { ...prevValidState.Person, ...newValidState.Person },
				PersonBrCert: { ...prevValidState.PersonBrCert, ...newValidState.PersonBrCert },
				PersonPassport: { ...prevValidState.PersonPassport, ...newValidState.PersonPassport },
				PersonAchievements: { ...prevValidState.PersonAchievements, ...newValidState.PersonAchievements }
			}));
		}
	};

	const changeOrganizationData = (
		newOrganizationState: Partial<Organization>,
		newValidState?: DeepPartial<IValidProfileEditTabsState>
	) => {
		setProfile((prevState: Profile) => {
			return { ...prevState, Organization: { ...prevState.Organization, ...newOrganizationState } };
		});
		if (newValidState) {
			setFormFieldsValid((prevValidState) => ({
				...prevValidState,
				Organization: { ...prevValidState.Organization, ...newValidState.Organization }
			}));
		}
	};

	const handleSelectTab = (_event: React.ChangeEvent<{}>, newTabIndex: number) => {
		setTabIndex(newTabIndex);
	};

	return (
		<>
			<Box boxShadow={3} className="profile-edit-form">
				<Tabs
					value={tabIndex}
					onChange={handleSelectTab}
					aria-label="profile tabs"
					className="profile-tabs"
					variant="fullWidth"
					indicatorColor="primary"
				>
					<Tab
						label={
							<div>
								<span className="padding-attention">Информация</span>
								{(tabsError.Organization || tabsError.Person) && (
									<Icon
										className="icon icon--Attention margin-left-attention "
										fontSize="small"
										color="error"
									/>
								)}
							</div>
						}
						value={0}
						className="profile-tabs__item"
					/>
					<Tab label="Виды спорта" value={1} className="profile-tabs__item" />
					{profile?.Organization && <Tab label="Дополнительно" value={2} className="profile-tabs__item" />}
					{(profile?.Person?.Type === EPersonType.Coach || !!profile?.Organization) && (
						<Tab label="Часы работы" value={3} className="profile-tabs__item" />
					)}
					{!!profile?.Person && (
						<Tab
							label={
								<div>
									<span className="padding-attention">Достижения</span>
									{tabsError.Achievements && (
										<Icon
											className="icon icon--Attention margin-left-attention"
											fontSize="small"
											color="error"
										/>
									)}
								</div>
							}
							value={4}
							className="profile-tabs__item"
						/>
					)}
					{!!profile?.Person && <Tab label="Образование" value={5} className="profile-tabs__item" />}
					<Tab label="Контакты" value={6} className="profile-tabs__item" />
					<Tab
						label={profile?.Organization ? "Площадки и тренеры" : "Площадки"}
						value={7}
						className="profile-tabs__item"
					/>
				</Tabs>
				<TabPanel value={tabIndex} index={0} name="profile">
					<MainTab
						profile={profile}
						setProfile={setProfile}
						changePersonData={changePersonData}
						changeOrganizationData={changeOrganizationData}
						hash={hash}
					/>
				</TabPanel>
				<TabPanel value={tabIndex} index={1} name="profile">
					{profile?.Organization?.Type && EduTypes.includes(profile.Organization.Type) ? (
						<Grid container spacing={6}>
							<SportsEdit
								profileId={profile?.Id}
								isOrganization={!!profile?.Organization}
								hash={hash}
								setSelectedSports={setSelectedSports}
								setSelectedDisciplines={setSelectedDisciplines}
								selectedSports={selectedSports}
								selectedDisciplines={selectedDisciplines}
								loading={!selectedSports && !selectedDisciplines}
								title="Программы спортивной подготовки"
								schoolOptions={{ SportProgramm: true }}
							/>
							<SportsEdit
								profileId={profile?.Id}
								isOrganization={!!profile?.Organization}
								hash={hash}
								setSelectedSports={setSelectedSports}
								setSelectedDisciplines={setSelectedDisciplines}
								selectedSports={selectedSports}
								selectedDisciplines={selectedDisciplines}
								loading={!selectedSports && !selectedDisciplines}
								title="Общеобразовательные программы "
								schoolOptions={{ AdditionalEducation: true }}
							/>
						</Grid>
					) : (
						<SportsEdit
							profileId={profile?.Id}
							isOrganization={!!profile?.Organization}
							hash={hash}
							setSelectedSports={setSelectedSports}
							setSelectedDisciplines={setSelectedDisciplines}
							selectedSports={selectedSports}
							selectedDisciplines={selectedDisciplines}
							loading={!selectedSports && !selectedDisciplines}
						/>
					)}
				</TabPanel>
				{profile?.Organization && (
					<TabPanel value={tabIndex} index={2} name="profile">
						<ExtraTab profile={profile} setProfile={setProfile} hash={hash} />
					</TabPanel>
				)}
				{(profile?.Person?.Type === EPersonType.Coach || !!profile?.Organization) && (
					<TabPanel value={tabIndex} index={3} name="profile">
						<WorkingHours workingDays={workingDays} setWorkingDays={setWorkingDays} />
					</TabPanel>
				)}
				{!!profile?.Person && (
					<TabPanel value={tabIndex} index={4} name="profile">
						<AchievementsEdit
							hash={hash}
							person={profile.Person}
							achievements={profile.Achievements}
							changeAchievements={changeAchievements}
							changePersonData={changePersonData}
						/>
					</TabPanel>
				)}
				{!!profile?.Person && (
					<TabPanel value={tabIndex} index={5} name="profile">
						<EducationEdit
							hash={hash}
							educationList={profile.Education}
							changeEducationList={changeEducation}
						/>
					</TabPanel>
				)}
				<TabPanel value={tabIndex} index={6} name="profile">
					<ContactsEdit contacts={profile.Contacts} changeContacts={changeContacts} />
				</TabPanel>
				<TabPanel value={tabIndex} index={7} name="profile">
					<Grid container spacing={6}>
						<PlacesEdit hash={hash} />
						{profile?.Organization && (
							<CoachesEdit hash={hash} coaches={coaches} changeCoaches={changeCoaches} />
						)}
					</Grid>
				</TabPanel>

				<Button
					variant="contained"
					size="large"
					color="primary"
					onClick={() => upsertProfile()}
					className="upsert-profile-btn"
					disabled={saveButtonDisabled}
				>
					{loadingUpsert && <CircularProgress color="inherit" size={20} />}
					Сохранить
				</Button>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					open={savedSnackbarVisible}
					autoHideDuration={3000}
					onClose={() => setSavedSnackbarVisible(false)}
					message="Изменения сохранены!"
					action={
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => setSavedSnackbarVisible(false)}
						>
							<Icon fontSize="small">
								<i className="icon icon--lesson-1-canceled" />
							</Icon>
						</IconButton>
					}
				/>
			</Box>
		</>
	);
};

export default ProfileEditTabs;
