import { Countries } from "@athlete/utils";
import MomentUtils from "@date-io/moment";
import { Checkbox, FormControl, Grid, InputLabel, Link, MenuItem, Select, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useState } from "react";
import { GenderList, MaritalStatusList, PersonTypeNames } from "../../../constants";
import { EGender, EPersonType, EProfileMaritalStatus, Person } from "../../../graphql/types";
import { PrivacyPageContent } from "../../../pages/privacy";
import { check18age } from "../../../utils/utils";
import StandartDialog from "../../common/StandartDialog";
import StandartTextInput from "../../common/StandartTextInput";
import { ITabProfile } from "../MainTab";
import AddressSection from "./AddressSection";

export type TPartialPerson = Partial<
	Omit<Person, "NextMedicalExaminationDate" | "StartSportDate" | "Birthday" | "IssuedAt" | "BirthRecordDate">
> & {
	NextMedicalExaminationDate?: moment.Moment | null;
	StartSportDate?: moment.Moment | null;
	Birthday?: moment.Moment | null;
	IssuedAt?: moment.Moment | null;
	BirthRecordDate?: moment.Moment | null;
};

const MainPersonInfo = (props: ITabProfile) => {
	const { profile, changePersonData } = props;

	const [privacyVisible, setPrivacyVisible] = useState(false);

	if (!profile?.Person) return null;

	return (
		<form>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Grid container spacing={6}>
					<Grid item sm={6}>
						<>
							<h2>Основная информация</h2>
							<StandartTextInput
								required
								value={profile.Person?.Name || ""}
								onChange={(value, isValid) => {
									return changePersonData({ Name: value }, { Person: { Name: isValid } });
								}}
								label="Имя профиля"
								inputProps={{ maxLength: 150 }}
								margin="normal"
								error={!profile.Person.Name?.length}
							/>

							<KeyboardDatePicker
								disableToolbar
								fullWidth
								format="DD.MM.YYYY"
								margin="normal"
								minDate={moment()}
								label="Дата следующей диспансеризации"
								minDateMessage="Дата должа быть больше текущей"
								invalidDateMessage="Некорректный формат"
								value={profile.Person?.NextMedicalExaminationDate || null}
								onChange={(date) =>
									changePersonData(
										{ NextMedicalExaminationDate: date },
										{ Person: { NextMedicalExaminationDate: !date || date.isAfter(moment()) } }
									)
								}
								KeyboardButtonProps={{
									"aria-label": "change date"
								}}
							/>
							<FormControl fullWidth margin="normal">
								<InputLabel id="select-perstype-label">Тип профиля</InputLabel>
								<Select
									value={profile?.Person?.Type}
									onChange={(event) => changePersonData({ Type: event.target.value as EPersonType })}
									labelId="select-perstype-label"
								>
									{Object.values(EPersonType).map((item) => (
										<MenuItem key={item} value={item}>
											{PersonTypeNames[item]}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<AddressSection {...props} />
						</>
					</Grid>
					<Grid item sm={6}>
						<h2>Личные данные</h2>
						{!profile.Person?.AgreePersonalData && (
							<div className="personal-data">
								<Checkbox
									checked={profile.Person?.AgreePersonalData || false}
									onChange={(event) => changePersonData({ AgreePersonalData: event.target.checked })}
									color="primary"
								/>
								<p>
									Подтверждаю, что ознакомлен с{" "}
									<Link onClick={() => setPrivacyVisible(true)}>
										“Согласием на обработку персональных данных”
									</Link>
									, действую без принуждения, по собственной воле и в своих интересах.
								</p>
								<StandartDialog
									visible={privacyVisible}
									onClose={() => setPrivacyVisible(false)}
									title="Согласие на обработку персональных данных"
								>
									<Typography variant="body1" className="privacy-modal">
										<PrivacyPageContent />
									</Typography>
								</StandartDialog>
							</div>
						)}
						{profile.Person.AgreePersonalData && (
							<>
								<StandartTextInput
									value={profile.Person?.LastName || ""}
									onChange={(value) => changePersonData({ LastName: value })}
									label="Фамилия"
									margin="normal"
									inputProps={{ maxLength: 150 }}
								/>
								<StandartTextInput
									value={profile.Person?.FirstName || ""}
									onChange={(value) => changePersonData({ FirstName: value })}
									label="Имя"
									margin="normal"
									inputProps={{ maxLength: 150 }}
								/>
								<StandartTextInput
									fullWidth
									value={profile.Person?.MiddleName || ""}
									onChange={(value) => changePersonData({ MiddleName: value })}
									label="Отчество"
									margin="normal"
									inputProps={{ maxLength: 150 }}
								/>
								<StandartTextInput
									value={profile.Person?.PreviousLastName || ""}
									onChange={(value) => changePersonData({ PreviousLastName: value })}
									label="Предыдущая фамилия"
									margin="normal"
									inputProps={{ maxLength: 150 }}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="select-perstype-label">Пол</InputLabel>
									<Select
										value={profile?.Person?.Gender}
										onChange={(event) =>
											changePersonData({ Gender: event.target.value as EGender })
										}
										labelId="select-perstype-label"
									>
										{Object.values(EGender).map((item) => (
											<MenuItem key={item} value={item}>
												{GenderList[item]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<KeyboardDatePicker
									disableToolbar
									fullWidth
									format="DD.MM.YYYY"
									margin="normal"
									label="Дата рождения"
									invalidDateMessage="Некорректный формат"
									maxDateMessage="Дата должа быть меньше текущей"
									maxDate={moment()}
									value={profile.Person?.Birthday || null}
									onChange={(date) =>
										changePersonData(
											{ Birthday: date },
											{ Person: { Birthday: !date || date.isBefore(moment()) } }
										)
									}
									KeyboardButtonProps={{
										"aria-label": "change date"
									}}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="select-perstype-label">Семейное положение</InputLabel>
									<Select
										value={profile?.Person?.MaritalStatus || EProfileMaritalStatus.Unknown}
										onChange={(event) =>
											changePersonData({
												MaritalStatus: event.target.value as EProfileMaritalStatus
											})
										}
										labelId="select-perstype-label"
									>
										{Object.values(EProfileMaritalStatus).map((item) => (
											<MenuItem key={item} value={item}>
												{MaritalStatusList[item]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{profile.Person?.Birthday && check18age(profile.Person.Birthday) ? (
									<>
										<h2>Паспорт</h2>

										<StandartTextInput
											id="passport-number"
											fullWidth
											value={profile?.Person?.PassportNumber || ""}
											onChange={(value, isValid) =>
												changePersonData(
													{
														PassportNumber: value
															? Number(value?.replace(/[\s_]/g, ""))
															: null
													},
													{ PersonPassport: { PassportNumber: isValid } }
												)
											}
											mask="99 99 999999"
											pattern={/[0-9]{2} [0-9]{2} [0-9]{6}/}
											errorMessage="Используйте цифры (0-9)"
											label="Серия номер паспорта"
											margin="normal"
										/>
										<StandartTextInput
											multiline
											value={profile.Person?.SubdivisionName || ""}
											onChange={(value) => changePersonData({ SubdivisionName: value })}
											label="Кем выдан"
											margin="normal"
											inputProps={{ maxLength: 150 }}
										/>
										<StandartTextInput
											value={profile.Person?.SubdivisionCode || ""}
											onChange={(value, isValid) =>
												changePersonData(
													{ SubdivisionCode: value },
													{ PersonPassport: { SubdivisionCode: isValid } }
												)
											}
											mask="999-999"
											pattern={/[0-9]{3}-[0-9]{3}/}
											errorMessage="Используйте цифры (0-9)"
											label="Код подразделения"
											margin="normal"
										/>

										<KeyboardDatePicker
											disableToolbar
											fullWidth
											format="DD.MM.YYYY"
											margin="normal"
											label="Дата выдачи паспорта"
											maxDateMessage="Дата должа быть меньше текущей"
											invalidDateMessage="Некорректный формат"
											maxDate={moment()}
											value={profile.Person?.IssuedAt || null}
											onChange={(date) =>
												changePersonData(
													{ IssuedAt: date },
													{ PersonPassport: { IssuedAt: !date || date.isBefore(moment()) } }
												)
											}
											KeyboardButtonProps={{
												"aria-label": "change date"
											}}
										/>
									</>
								) : (
									<>
										<h2>Свидетельство о рождении</h2>

										<StandartTextInput
											value={profile.Person?.BirthCertificate || ""}
											onChange={(value, isValid) =>
												changePersonData(
													{ BirthCertificate: value },
													{ PersonBrCert: { BirthCertificate: isValid } }
												)
											}
											mask="II-бб 999999"
											pattern={/[iI]{2}-[А-Яа-я]{2} [0-9]{6}/}
											errorMessage="Используйте кириллицу (а-я, А-Я) и цифры (0-9)"
											label="Номер свидетельства (формат II-AA xxxxxx)"
											margin="normal"
										/>

										<StandartTextInput
											multiline
											value={profile.Person?.BirthRecordAcceptedBy || ""}
											onChange={(value) => changePersonData({ BirthRecordAcceptedBy: value })}
											label="Кем выдано свидетельство"
											margin="normal"
											inputProps={{ maxLength: 300 }}
										/>

										<KeyboardDatePicker
											disableToolbar
											fullWidth
											format="DD.MM.YYYY"
											margin="normal"
											label="Дата выдачи свидетельства"
											maxDateMessage="Дата должа быть меньше текущей"
											invalidDateMessage="Некорректный формат"
											maxDate={moment()}
											value={profile.Person?.BirthRecordDate || null}
											onChange={(date) =>
												changePersonData(
													{ BirthRecordDate: date },
													{
														PersonBrCert: {
															BirthRecordDate: !date || date.isBefore(moment())
														}
													}
												)
											}
											KeyboardButtonProps={{
												"aria-label": "change date"
											}}
										/>
									</>
								)}
								<FormControl fullWidth margin="normal">
									<InputLabel id="select-perstype-label">Гражданство</InputLabel>
									<Select
										value={profile?.Person?.Citizenship}
										onChange={(event) =>
											changePersonData({ Citizenship: event.target.value as number })
										}
										labelId="select-perstype-label"
									>
										{Array.from(Countries.entries()).map(([key, value]) => (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<StandartTextInput
									multiline
									value={profile.Person?.Birthplace || ""}
									onChange={(value) => changePersonData({ Birthplace: value })}
									label="Место рождения"
									margin="normal"
									inputProps={{ maxLength: 150 }}
								/>

								<h2>Документы</h2>

								<StandartTextInput
									value={profile.Person?.SSN || ""}
									onChange={(value, isValid) =>
										changePersonData({ SSN: value }, { Person: { SSN: isValid } })
									}
									mask="999-999-999 99"
									pattern={/[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}/}
									errorMessage="Используйте цифры (0-9)"
									label="Номер СНИЛС"
									margin="normal"
								/>
								<StandartTextInput
									value={profile.Person?.MedicalInsuranceNumber || ""}
									onChange={(value, isValid) =>
										changePersonData(
											{ MedicalInsuranceNumber: value },
											{ Person: { MedicalInsuranceNumber: isValid } }
										)
									}
									mask="9999999999999999"
									pattern={/[0-9]{16}/}
									errorMessage="Используйте цифры (0-9)"
									label="Номер медицинского полиса (16 цифр)"
									margin="normal"
								/>
							</>
						)}
					</Grid>
				</Grid>
			</MuiPickersUtilsProvider>
		</form>
	);
};

export default MainPersonInfo;
