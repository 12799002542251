import { Fab, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { EducationTypeList } from "../../constants";
import { ProfileEducation } from "../../graphql/types";
import { tempId } from "../../utils/utils";
import TitleAdd from "../common/TitleAdd";
import EducationModal from "./EducationModal";

interface IEducationEditProps {
	hash: string;
	educationList: Array<TPartialProfileEducation> | undefined;
	changeEducationList: (newContacts: Array<TPartialProfileEducation>) => void;
}

export type TPartialProfileEducation = Partial<ProfileEducation> & { Id: string };

const EducationEdit = (props: IEducationEditProps) => {
	const { hash, educationList, changeEducationList } = props;

	const [modalState, setModalState] = useState<{
		visible: boolean;
		education?: TPartialProfileEducation;
	}>({ visible: false });

	const addEducation = (education: TPartialProfileEducation) => {
		if (education.Id != null) {
			const filteredEducation = educationList?.filter((item) => item.Id !== education.Id);
			changeEducationList([...(filteredEducation || []), education]);
			return;
		}
		changeEducationList([...(educationList || []), education]);
	};

	return (
		<Grid container spacing={6}>
			<EducationModal
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				addEducation={addEducation}
				hash={hash}
				{...modalState}
			/>
			<Grid item sm={6}>
				<TitleAdd
					title="Образование"
					onClick={() => setModalState({ visible: true, education: { Id: tempId() } })}
				/>
				<List className="contact-list">
					{_.orderBy(educationList, "IssueYear")?.map((item) => (
						<ListItem
							key={item.Id}
							button
							onClick={() => setModalState({ visible: true, education: item })}
						>
							<ListItemText
								primary={
									<>
										{item.Type && (
											<>
												{EducationTypeList[item.Type]}
												<br />
											</>
										)}
										{item.Specialization}
									</>
								}
								secondary={item.EducationalInstitution}
							/>
							<ListItemSecondaryAction
								onClick={() => {
									const filteredEducation = educationList?.filter(
										(education) => item.Id !== education.Id
									);
									if (filteredEducation) {
										changeEducationList(filteredEducation);
									}
								}}
							>
								<IconButton edge="end" aria-label="delete">
									<i className={"icon icon--event-canceled"} />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</Grid>
		</Grid>
	);
};

export default React.memo(EducationEdit);
