import { CircularProgress, FormControl, FormHelperText, Icon, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback } from "react";
import { RegionFullFragment } from "../../graphql/fragment/Region.generated";
import { useRegionsDataQuery } from "../../graphql/query/RegionsData.generated";

interface IRegionSelectorProps {
	hash: string;
	title: string;
	description?: string;
	selectedKey?: string;
	onChange: (value: RegionFullFragment | null) => void;
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
}

const RegionSelector: React.ComponentType<IRegionSelectorProps> = (props) => {
	const { hash, title, selectedKey, onChange, disabled, description, required, error } = props;

	const { data, loading, error: errorQuery } = useRegionsDataQuery({
		context: { headers: { Authorization: `Digest ${hash}` } }
	});

	const onOptionChange = useCallback((option: RegionFullFragment | null) => onChange(option), []);
	const getOptionLabel = useCallback((option: RegionFullFragment) => `${option.Type} ${option.Name}`, []);

	if (!data?.Regions) return null;

	return (
		<FormControl fullWidth>
			<Autocomplete
				loading={loading}
				disabled={disabled}
				fullWidth
				options={data.Regions}
				getOptionSelected={(option, value) => option.Id === value.Id}
				getOptionLabel={getOptionLabel}
				value={data?.Regions.find((item) => item.Id === selectedKey) || null}
				onChange={(_event, value) => onOptionChange(value)}
				loadingText="Загрузка..."
				noOptionsText="Регион не найден"
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						error={error}
						label={title}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : (
										error && (
											<Icon className="icon icon--Attention" fontSize="small" color="error" />
										)
									)}
									{params.InputProps.endAdornment}
								</>
							)
						}}
					/>
				)}
			/>
			{!!description && <FormHelperText>{description}</FormHelperText>}
		</FormControl>
	);
};

export default RegionSelector;
