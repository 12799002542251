import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { SearchFragment } from "../fragment/Search.generated";
import { SearchFragmentDoc } from "../fragment/Search.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Ui_SearchQueryVariables = Types.Exact<{
	input: Types.SearchArgsInput;
}>;

export type Ui_SearchQuery = { __typename?: "Query" } & {
	Search: Array<{ __typename?: "SearchResult" } & SearchFragment>;
};

export const Ui_SearchDocument = gql`
	query UI_Search($input: SearchArgsInput!) {
		Search(input: $input) {
			...Search
		}
	}
	${SearchFragmentDoc}
`;
export type Ui_SearchComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<Ui_SearchQuery, Ui_SearchQueryVariables>,
	"query"
> &
	({ variables: Ui_SearchQueryVariables; skip?: boolean } | { skip: boolean });

export const Ui_SearchComponent = (props: Ui_SearchComponentProps) => (
	<ApolloReactComponents.Query<Ui_SearchQuery, Ui_SearchQueryVariables> query={Ui_SearchDocument} {...props} />
);

export type Ui_SearchProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<Ui_SearchQuery, Ui_SearchQueryVariables>;
} &
	TChildProps;
export function withUi_Search<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		Ui_SearchQuery,
		Ui_SearchQueryVariables,
		Ui_SearchProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		Ui_SearchQuery,
		Ui_SearchQueryVariables,
		Ui_SearchProps<TChildProps, TDataName>
	>(Ui_SearchDocument, {
		alias: "uiSearch",
		...operationOptions
	});
}

/**
 * __useUi_SearchQuery__
 *
 * To run a query within a React component, call `useUi_SearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUi_SearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUi_SearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUi_SearchQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<Ui_SearchQuery, Ui_SearchQueryVariables>
) {
	return ApolloReactHooks.useQuery<Ui_SearchQuery, Ui_SearchQueryVariables>(Ui_SearchDocument, baseOptions);
}
export function useUi_SearchLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Ui_SearchQuery, Ui_SearchQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<Ui_SearchQuery, Ui_SearchQueryVariables>(Ui_SearchDocument, baseOptions);
}
export type Ui_SearchQueryHookResult = ReturnType<typeof useUi_SearchQuery>;
export type Ui_SearchLazyQueryHookResult = ReturnType<typeof useUi_SearchLazyQuery>;
export type Ui_SearchQueryResult = ApolloReactCommon.QueryResult<Ui_SearchQuery, Ui_SearchQueryVariables>;
