import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsCoachesRanksOrgFullFragment = { __typename?: "StatisticsCoachesRanksOrg" } & Pick<
	Types.StatisticsCoachesRanksOrg,
	"All" | "HighestRank" | "FirstRank" | "SecondRank" | "CoachVeteran" | "SportVeteran" | "OrgType" | "Department"
>;

export const StatisticsCoachesRanksOrgFullFragmentDoc = gql`
	fragment StatisticsCoachesRanksOrgFull on StatisticsCoachesRanksOrg {
		All
		HighestRank
		FirstRank
		SecondRank
		CoachVeteran
		SportVeteran
		OrgType
		Department
	}
`;
