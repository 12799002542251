import * as Types from "../types";

import gql from "graphql-tag";

export type DisciplineFullFragment = { __typename?: "Discipline" } & Pick<
	Types.Discipline,
	"Id" | "CreatedAt" | "UpdatedAt" | "Name" | "Code" | "SportId"
>;

export const DisciplineFullFragmentDoc = gql`
	fragment DisciplineFull on Discipline {
		Id
		CreatedAt
		UpdatedAt
		Name
		Code
		SportId
	}
`;
