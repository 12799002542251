import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ContactFullFragment } from "../../graphql/fragment/Contact.generated";
import { tempId } from "../../utils/utils";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";
import { TPartialCoach } from "./CoachesEdit";

interface ICoachesModalProps {
	visible: boolean;
	coach?: TPartialCoach;
	addCoach: (coach: TPartialCoach) => void;
	onClose: () => void;
}
const defaultValdidty = { Name: true, Phone: true, Snils: true };

const CoachesModal = (props: ICoachesModalProps) => {
	const { visible, coach, onClose, addCoach } = props;

	const [coachState, setCoachState] = useState<TPartialCoach>();
	const [formFieldsValid, setFormFieldsValid] = useState(defaultValdidty);

	useEffect(() => {
		if (visible) {
			setCoachState(coach);
			setFormFieldsValid({ Name: !!coach?.Name?.length, Phone: !!coach?.Phone?.length, Snils: true });
		}
	}, [coach, visible]);

	if (!coachState) {
		return null;
	}

	const saveCoach = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		addCoach(coachState);
		onClose();
	};

	const changeData = (newState: Partial<ContactFullFragment>) => {
		setCoachState((prevState) => {
			return { ...prevState, Id: prevState?.Id || tempId(), ...newState };
		});
	};

	return (
		<StandartDialog
			title="Редактирование"
			onClose={onClose}
			visible={visible}
			buttons={[
				{ title: "Сохранить", disabled: Object.values(formFieldsValid).includes(false), onClick: saveCoach }
			]}
		>
			<div className="coach-modal">
				<StandartTextInput
					required
					error={!coachState.Name}
					margin="dense"
					value={coachState.Name || ""}
					onChange={(value, isValid) => {
						changeData({ Name: value });
						setFormFieldsValid((prevState) => ({ ...prevState, Name: isValid }));
					}}
					label="Имя"
				/>
				<StandartTextInput
					required
					fullWidth
					value={coachState.Phone || ""}
					onChange={(value, isValid) => {
						changeData({ Phone: value });
						setFormFieldsValid((prevState) => ({ ...prevState, Phone: isValid }));
					}}
					label="Телефон"
					margin="normal"
					mask="+7 (999) 999 99 99"
					pattern={/^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/}
					errorMessage="Используйте цифры (0-9) и символы «+,(,)»"
				/>
				<StandartTextInput
					fullWidth
					value={coachState.Snils || ""}
					onChange={(value, isValid) => {
						changeData({ Snils: value });
						setFormFieldsValid((prevState) => ({ ...prevState, Snils: isValid }));
					}}
					mask="999-999-999 99"
					pattern={/[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}/}
					errorMessage="Используйте цифры (0-9)"
					label="СНИЛС"
					margin="normal"
				/>
				<StandartTextInput
					multiline
					value={coachState.Comment || ""}
					onChange={(value) => changeData({ Comment: value })}
					id="coach-comment"
					label="Комментарий"
					margin="dense"
				/>
				<StandartTextInput
					multiline
					value={coachState.Data?.Position || ""}
					onChange={(value) => changeData({ Data: { ...coachState?.Data, Position: value } })}
					label="Должность"
					margin="dense"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={coachState.PartTime || false}
							onChange={(event) => changeData({ PartTime: event.target.checked })}
							name="part-time"
							color="primary"
						/>
					}
					label="Совместитель"
				/>
			</div>
		</StandartDialog>
	);
};

export default React.memo(CoachesModal);
