import Grid from "@material-ui/core/Grid";
import React from "react";
import { Profile } from "../../graphql/types";
import StandartTextInput from "../common/StandartTextInput";

interface ITabProfile {
	profile: any;
	setProfile: React.Dispatch<any>;
	hash: string;
}

// Поля организации, которые мы тут редактируем
type TTypeFieldOrgData = "Director" | "Equipment" | "Achievements" | "Rules";

const ExtraTab = (props: ITabProfile) => {
	const { profile, setProfile } = props;

	if (!profile?.Organization) return null;

	// Единая функция изменения данных
	const changeOrganizationData = (type: TTypeFieldOrgData, value: string) => {
		let obj: {} | undefined;
		if (type === "Director")
			obj = {
				...profile.Organization?.Data.Director,
				Name: value
			};
		setProfile((prevState: Profile) => {
			return {
				...prevState,
				Organization: {
					...prevState.Organization,
					Data: {
						...prevState.Organization?.Data,
						[type]: obj || value
					}
				}
			};
		});
	};

	return (
		<Grid container spacing={6}>
			<Grid item sm={6}>
				<h2>Директор организации</h2>
				<StandartTextInput
					label="Фамилия Имя Отчество"
					value={profile.Organization.Data?.Director?.Name || undefined}
					onChange={(e) => changeOrganizationData("Director", e)}
					margin="normal"
					inputProps={{ maxLength: 150 }}
				/>
			</Grid>
			<Grid item xs={12}>
				<h2>Правила поведения</h2>
				<StandartTextInput
					label="Правила поведения (в свободной форме)"
					value={profile.Organization?.Data?.Rules || undefined}
					onChange={(e) => changeOrganizationData("Rules", e)}
					margin="normal"
					multiline
					rows={15}
					inputProps={{ maxLength: 4000 }}
				/>
			</Grid>
			<Grid item sm={6}>
				<h2>Экипировка</h2>
				<StandartTextInput
					label="Необходимая экпировка"
					value={profile.Organization?.Data?.Equipment || undefined}
					onChange={(e) => changeOrganizationData("Equipment", e)}
					margin="normal"
					multiline
					rows={15}
					inputProps={{ maxLength: 4000 }}
				/>
			</Grid>
			<Grid item sm={6}>
				<h2>Заслуги</h2>
				<StandartTextInput
					label="Заслуги школы, лучшие спортсмены"
					value={profile.Organization?.Data?.Achievements || undefined}
					onChange={(e) => changeOrganizationData("Achievements", e)}
					margin="normal"
					multiline
					rows={15}
					inputProps={{ maxLength: 4000 }}
				/>
			</Grid>
		</Grid>
	);
};

export default ExtraTab;
