import ProfileTypes from "./profileTypes";

export interface ITheme {
	color: string;
	background: string;
	backgroundSecondary: string;
	border: string;
	active: string;
	icon: string;
	avatar: string;
	colors: IColors;
}
interface IThemes {
	[key: number]: ITheme;
}

interface IColors {
	main: string;
	light: string;
}

export const Themes: IThemes = {
	[ProfileTypes.Organization]: {
		color: "theme-org--color",
		background: "theme-org--bg",
		backgroundSecondary: "theme-org--bg-secondary",
		border: "theme-org--border",
		active: "active--organization",
		icon: "icon--organization_line",
		avatar: "icon--organization",
		colors: {
			main: "#e0773c",
			light: "#f6d6c5"
		}
	},
	[ProfileTypes.Coach]: {
		color: "theme-coach--color",
		background: "theme-coach--bg",
		backgroundSecondary: "theme-coach--bg-secondary",
		border: "theme-coach--border",
		active: "active--coach",
		icon: "icon--coach_line",
		avatar: "icon--couch",
		colors: {
			main: "#9c004f",
			light: "#e1b3ca"
		}
	},
	[ProfileTypes.Sportsman]: {
		color: "theme-sportsman--color",
		background: "theme-sportsman--bg",
		backgroundSecondary: "theme-sportsman--bg-secondary",
		border: "theme-sportsman--border",
		active: "active--sportsman",
		icon: "icon--sportsman",
		avatar: "icon--default-avatar",
		colors: {
			main: "#0071a4",
			light: "#b3d4e4"
		}
	},
	[ProfileTypes.Parent]: {
		color: "theme-parent--color",
		background: "theme-parent--bg",
		backgroundSecondary: "theme-parent--bg-secondary",
		border: "theme-parent--border",
		active: "active--parent",
		icon: "icon--contact-parent",
		avatar: "icon--contact-parent",
		colors: {
			main: "#007c5a",
			light: "#b3d8ce"
		}
	},
	[ProfileTypes.School]: {
		color: "theme-school--color",
		background: "theme-school--bg",
		backgroundSecondary: "theme-school--bg-secondary",
		border: "theme-school--border",
		active: "active--school",
		icon: "icon--shcool_line",
		avatar: "icon--shcool",
		colors: {
			main: "#fbbc04",
			light: "#fcdd81"
		}
	},
	[ProfileTypes.Federation]: {
		color: "theme-federation--color",
		background: "theme-federation--bg",
		backgroundSecondary: "theme-federation--bg-secondary",
		border: "theme-federation--border",
		active: "active--federation",
		icon: "icon--federation_line",
		avatar: "icon--federation",
		colors: {
			main: "#e0773c",
			light: "#f6d6c5"
		}
	},
	[ProfileTypes.Ministry]: {
		color: "theme-ministry--color",
		background: "theme-ministry--bg",
		backgroundSecondary: "theme-ministry--bg-secondary",
		border: "theme-ministry--border",
		active: "active--ministry",
		icon: "icon--ministry_line",
		avatar: "icon--ministry",
		colors: {
			main: "#e0773c",
			light: "#f6d6c5"
		}
	},
	[ProfileTypes.SportObject]: {
		color: "theme-sportobject--color",
		background: "theme-sportobject--bg",
		backgroundSecondary: "theme-sportobject--bg-secondary",
		border: "theme-sportobject--border",
		active: "active--sportobject",
		icon: "icon--object",
		avatar: "icon--object",
		colors: {
			main: "#aa78b7",
			light: "#d5bcdb"
		}
	}
};

export default Themes;
