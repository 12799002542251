export enum ProfileTypes {
	Organization = 0,
	Coach = 1,
	Sportsman = 2,
	Parent = 3,
	School = 4,
	Federation = 5,
	Ministry = 6,
	SportObject = 7
}

export default ProfileTypes;
