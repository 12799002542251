export const contactInfoIcons: { [key: string]: string } = {
	["Email"]: "icon--email-disabled",
	["Phone"]: "icon--icon-sity-phone",
	["CellPhone"]: "icon--sms",
	["URL"]: "icon--website-disabled",
	["Skype"]: "icon--icon-skype",
	["WhatsApp"]: "icon--icon-whatsapp",
	["Telegram"]: "icon--icon-telegramm",
	["Viber"]: "icon--icon-viber",
	["ICQ"]: "icon--icon-icq",
	["Facebook"]: "icon--icon-facebook",
	["Twitter"]: "icon--icon-twitter",
	["Instagramm"]: "icon--icon-instagramm",
	["YouTube"]: "icon--icon-youtube",
	["Vk"]: "icon--icon-vk",
	["Od"]: "icon--icon-ok"
};
export default contactInfoIcons;
