import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsStagesSportFullFragment } from "../fragment/StatisticsStagesFull.generated";
import { StatisticsStagesSportFullFragmentDoc } from "../fragment/StatisticsStagesFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsStageseBySportsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsStageseBySportsQuery = { __typename?: "Query" } & {
	StatisticsStageseBySports?: Types.Maybe<
		Array<{ __typename?: "StatisticsStagesSport" } & StatisticsStagesSportFullFragment>
	>;
};

export const StatisticsStageseBySportsDocument = gql`
	query StatisticsStageseBySports {
		StatisticsStageseBySports {
			...StatisticsStagesSportFull
		}
	}
	${StatisticsStagesSportFullFragmentDoc}
`;
export type StatisticsStageseBySportsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables
	>,
	"query"
>;

export const StatisticsStageseBySportsComponent = (props: StatisticsStageseBySportsComponentProps) => (
	<ApolloReactComponents.Query<StatisticsStageseBySportsQuery, StatisticsStageseBySportsQueryVariables>
		query={StatisticsStageseBySportsDocument}
		{...props}
	/>
);

export type StatisticsStageseBySportsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsStageseBySports<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables,
		StatisticsStageseBySportsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables,
		StatisticsStageseBySportsProps<TChildProps, TDataName>
	>(StatisticsStageseBySportsDocument, {
		alias: "statisticsStageseBySports",
		...operationOptions
	});
}

/**
 * __useStatisticsStageseBySportsQuery__
 *
 * To run a query within a React component, call `useStatisticsStageseBySportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsStageseBySportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsStageseBySportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsStageseBySportsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<StatisticsStageseBySportsQuery, StatisticsStageseBySportsQueryVariables>(
		StatisticsStageseBySportsDocument,
		baseOptions
	);
}
export function useStatisticsStageseBySportsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsStageseBySportsQuery,
		StatisticsStageseBySportsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<StatisticsStageseBySportsQuery, StatisticsStageseBySportsQueryVariables>(
		StatisticsStageseBySportsDocument,
		baseOptions
	);
}
export type StatisticsStageseBySportsQueryHookResult = ReturnType<typeof useStatisticsStageseBySportsQuery>;
export type StatisticsStageseBySportsLazyQueryHookResult = ReturnType<typeof useStatisticsStageseBySportsLazyQuery>;
export type StatisticsStageseBySportsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsStageseBySportsQuery,
	StatisticsStageseBySportsQueryVariables
>;
