import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertProfileMutationVariables = Types.Exact<{
	data: Types.UpsertProfileInput;
}>;

export type UpsertProfileMutation = { __typename?: "Mutation" } & {
	upsertProfile: { __typename?: "UpdateProfileResult" } & Pick<Types.UpdateProfileResult, "success" | "errorCode">;
};

export const UpsertProfileDocument = gql`
	mutation UpsertProfile($data: UpsertProfileInput!) {
		upsertProfile(data: $data) {
			success
			errorCode
		}
	}
`;
export type UpsertProfileMutationFn = ApolloReactCommon.MutationFunction<
	UpsertProfileMutation,
	UpsertProfileMutationVariables
>;
export type UpsertProfileComponentProps = Omit<
	ApolloReactComponents.MutationComponentOptions<UpsertProfileMutation, UpsertProfileMutationVariables>,
	"mutation"
>;

export const UpsertProfileComponent = (props: UpsertProfileComponentProps) => (
	<ApolloReactComponents.Mutation<UpsertProfileMutation, UpsertProfileMutationVariables>
		mutation={UpsertProfileDocument}
		{...props}
	/>
);

export type UpsertProfileProps<TChildProps = {}, TDataName extends string = "mutate"> = {
	[key in TDataName]: ApolloReactCommon.MutationFunction<UpsertProfileMutation, UpsertProfileMutationVariables>;
} &
	TChildProps;
export function withUpsertProfile<TProps, TChildProps = {}, TDataName extends string = "mutate">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		UpsertProfileMutation,
		UpsertProfileMutationVariables,
		UpsertProfileProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withMutation<
		TProps,
		UpsertProfileMutation,
		UpsertProfileMutationVariables,
		UpsertProfileProps<TChildProps, TDataName>
	>(UpsertProfileDocument, {
		alias: "upsertProfile",
		...operationOptions
	});
}

/**
 * __useUpsertProfileMutation__
 *
 * To run a mutation, you first call `useUpsertProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProfileMutation, { data, loading, error }] = useUpsertProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProfileMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertProfileMutation, UpsertProfileMutationVariables>
) {
	return ApolloReactHooks.useMutation<UpsertProfileMutation, UpsertProfileMutationVariables>(
		UpsertProfileDocument,
		baseOptions
	);
}
export type UpsertProfileMutationHookResult = ReturnType<typeof useUpsertProfileMutation>;
export type UpsertProfileMutationResult = ApolloReactCommon.MutationResult<UpsertProfileMutation>;
export type UpsertProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
	UpsertProfileMutation,
	UpsertProfileMutationVariables
>;
