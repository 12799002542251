import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsCoachesRanksOrgFullFragment } from "../fragment/StatisticsCoachesRanksOrg.generated";
import { StatisticsCoachesRanksOrgFullFragmentDoc } from "../fragment/StatisticsCoachesRanksOrg.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsCoachesRanksByOrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsCoachesRanksByOrganizationsQuery = { __typename?: "Query" } & {
	StatisticsCoachesRanksByOrganizations?: Types.Maybe<
		Array<{ __typename?: "StatisticsCoachesRanksOrg" } & StatisticsCoachesRanksOrgFullFragment>
	>;
};

export const StatisticsCoachesRanksByOrganizationsDocument = gql`
	query StatisticsCoachesRanksByOrganizations {
		StatisticsCoachesRanksByOrganizations {
			...StatisticsCoachesRanksOrgFull
		}
	}
	${StatisticsCoachesRanksOrgFullFragmentDoc}
`;
export type StatisticsCoachesRanksByOrganizationsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>,
	"query"
>;

export const StatisticsCoachesRanksByOrganizationsComponent = (
	props: StatisticsCoachesRanksByOrganizationsComponentProps
) => (
	<ApolloReactComponents.Query<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>
		query={StatisticsCoachesRanksByOrganizationsDocument}
		{...props}
	/>
);

export type StatisticsCoachesRanksByOrganizationsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsCoachesRanksByOrganizations<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables,
		StatisticsCoachesRanksByOrganizationsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables,
		StatisticsCoachesRanksByOrganizationsProps<TChildProps, TDataName>
	>(StatisticsCoachesRanksByOrganizationsDocument, {
		alias: "statisticsCoachesRanksByOrganizations",
		...operationOptions
	});
}

/**
 * __useStatisticsCoachesRanksByOrganizationsQuery__
 *
 * To run a query within a React component, call `useStatisticsCoachesRanksByOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCoachesRanksByOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCoachesRanksByOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsCoachesRanksByOrganizationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>(StatisticsCoachesRanksByOrganizationsDocument, baseOptions);
}
export function useStatisticsCoachesRanksByOrganizationsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		StatisticsCoachesRanksByOrganizationsQuery,
		StatisticsCoachesRanksByOrganizationsQueryVariables
	>(StatisticsCoachesRanksByOrganizationsDocument, baseOptions);
}
export type StatisticsCoachesRanksByOrganizationsQueryHookResult = ReturnType<
	typeof useStatisticsCoachesRanksByOrganizationsQuery
>;
export type StatisticsCoachesRanksByOrganizationsLazyQueryHookResult = ReturnType<
	typeof useStatisticsCoachesRanksByOrganizationsLazyQuery
>;
export type StatisticsCoachesRanksByOrganizationsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsCoachesRanksByOrganizationsQuery,
	StatisticsCoachesRanksByOrganizationsQueryVariables
>;
