import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpdateProfileCheckStatusMutationVariables = Types.Exact<{
	profileId: Types.Scalars["String"];
	status: Types.EProfileCheckStatus;
}>;

export type UpdateProfileCheckStatusMutation = { __typename?: "Mutation" } & {
	updateProfileCheckStatus: { __typename?: "BaseResult" } & Pick<Types.BaseResult, "success" | "errorCode">;
};

export const UpdateProfileCheckStatusDocument = gql`
	mutation updateProfileCheckStatus($profileId: String!, $status: EProfileCheckStatus!) {
		updateProfileCheckStatus(profileId: $profileId, status: $status) {
			success
			errorCode
		}
	}
`;
export type UpdateProfileCheckStatusMutationFn = ApolloReactCommon.MutationFunction<
	UpdateProfileCheckStatusMutation,
	UpdateProfileCheckStatusMutationVariables
>;
export type UpdateProfileCheckStatusComponentProps = Omit<
	ApolloReactComponents.MutationComponentOptions<
		UpdateProfileCheckStatusMutation,
		UpdateProfileCheckStatusMutationVariables
	>,
	"mutation"
>;

export const UpdateProfileCheckStatusComponent = (props: UpdateProfileCheckStatusComponentProps) => (
	<ApolloReactComponents.Mutation<UpdateProfileCheckStatusMutation, UpdateProfileCheckStatusMutationVariables>
		mutation={UpdateProfileCheckStatusDocument}
		{...props}
	/>
);

export type UpdateProfileCheckStatusProps<TChildProps = {}, TDataName extends string = "mutate"> = {
	[key in TDataName]: ApolloReactCommon.MutationFunction<
		UpdateProfileCheckStatusMutation,
		UpdateProfileCheckStatusMutationVariables
	>;
} &
	TChildProps;
export function withUpdateProfileCheckStatus<TProps, TChildProps = {}, TDataName extends string = "mutate">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		UpdateProfileCheckStatusMutation,
		UpdateProfileCheckStatusMutationVariables,
		UpdateProfileCheckStatusProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withMutation<
		TProps,
		UpdateProfileCheckStatusMutation,
		UpdateProfileCheckStatusMutationVariables,
		UpdateProfileCheckStatusProps<TChildProps, TDataName>
	>(UpdateProfileCheckStatusDocument, {
		alias: "updateProfileCheckStatus",
		...operationOptions
	});
}

/**
 * __useUpdateProfileCheckStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProfileCheckStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileCheckStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileCheckStatusMutation, { data, loading, error }] = useUpdateProfileCheckStatusMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProfileCheckStatusMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		UpdateProfileCheckStatusMutation,
		UpdateProfileCheckStatusMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<UpdateProfileCheckStatusMutation, UpdateProfileCheckStatusMutationVariables>(
		UpdateProfileCheckStatusDocument,
		baseOptions
	);
}
export type UpdateProfileCheckStatusMutationHookResult = ReturnType<typeof useUpdateProfileCheckStatusMutation>;
export type UpdateProfileCheckStatusMutationResult = ApolloReactCommon.MutationResult<UpdateProfileCheckStatusMutation>;
export type UpdateProfileCheckStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
	UpdateProfileCheckStatusMutation,
	UpdateProfileCheckStatusMutationVariables
>;
