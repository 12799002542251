import React from "react";
import { contactInfoIcons } from "../constants";

export interface IContactInfo {
	Id: string;
	Type: string;
	Value: string;
	Comment?: string | null;
	Icon?: boolean;
	Label?: boolean;
}

const getUrlWeb = (item: IContactInfo, text: JSX.Element) => {
	if (item.Value.indexOf("/") !== -1) {
		return <a href={item.Value}>{text}</a>;
	}
	switch (item.Type) {
		case "Facebook":
			return <a href={`https://fb.com/${item.Value}`}>{text}</a>;
		case "YouTube":
			return <a href={`https://youtube.com/channel/${item.Value}`}>{text}</a>;
		case "Instagramm":
			return <a href={`https://instagram.com/${item.Value}`}>{text}</a>;
		case "Twitter":
			return <a href={`https://twitter.com/${item.Value}`}>{text}</a>;
		case "Vk":
			return <a href={`https://vk.com/${item.Value}`}>{text}</a>;
		case "Od":
			return <a href={`https://ok.ru/${item.Value}`}>{text}</a>;
		case "URL":
			return <a href={`http://${item.Value}`}>{text}</a>;
		default:
			return <span>{text}</span>;
	}
};

const getLink = (item: IContactInfo) => {
	const text = (
		<>
			{item.Icon && <i className={`icon ${contactInfoIcons[item.Type]}`} />}
			{item.Label && <span>{item.Value}</span>}
		</>
	);
	switch (item.Type) {
		case "Email":
			return <a href={`mailto:${item.Value}`}>{text}</a>;
		case "Phone":
		case "CellPhone":
			return <a href={`tel:+${item.Value.replace(/[^0-9]/g, "")}`}>{text}</a>;
		case "Skype":
			return <a href={`skype:+${item.Value.replace(/[^0-9]/g, "")}?call`}>{text}</a>;
		case "WhatsApp":
			return <a href={`https://api.whatsapp.com/send?phone=${item.Value.replace(/[^0-9]/g, "")}`}>{text}</a>;
		case "Telegram":
			return <a href={`tg://resolve?domain=${item.Value}}`}>{text}</a>;
		case "Viber":
			return <a href={`viber://add?number=${item.Value.replace(/[^0-9]/g, "")}`}>{text}</a>;
		default:
			return getUrlWeb(item, text);
	}
};

const ProfileContact: React.ComponentType<IContactInfo> = (props) => {
	const { Type, Value, Comment } = props;

	return (
		<div className="contact-item">
			{getLink(props)}
			{Comment && <p className="contact-comment">{Comment}</p>}
		</div>
	);
};

export default ProfileContact;
