import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { EducationOrganizationRecFieldsFragment } from "../../graphql/fragment/EducationOrganization.generated";
import { useUi_EducationOrganizationsLazyQuery } from "../../graphql/query/UI_EducationOrganizations.generated";
import { EEducationType, ProfileEducation } from "../../graphql/types";

interface IEducationInstituteSearchProps {
	hash: string;
	city?: string;
	name?: string;
	regionId?: string;
	type?: EEducationType;
	changeText: (text: string | undefined) => void;
}

const EducationInstituteSearch = (props: IEducationInstituteSearchProps) => {
	const [name, setName] = useState(props.name || "");
	const [listSearch, setListSearch] = useState<Array<EducationOrganizationRecFieldsFragment>>();
	const [educationOrganizationsQuery, { data, loading }] = useUi_EducationOrganizationsLazyQuery();

	const [debouncedName] = useDebounce(name, 500);

	useEffect(() => {
		if (!name || name.length < 3) {
			setListSearch(undefined);
			return;
		}
		educationOrganizationsQuery({
			context: { headers: { Authorization: `Digest ${props.hash}` } },
			variables: {
				input: {
					RegionId: props.regionId ? parseInt(props.regionId, 10) : undefined,
					City: props.city,
					EducationType: props.type,
					Name: name,
					Limit: 15
				}
			}
		});
	}, [debouncedName]);

	const onChange = (v: string) => {
		setName(v);
		props.changeText(v);
	};
	const getLabel = useCallback((option: EducationOrganizationRecFieldsFragment) => option.Name, []);

	useEffect(() => {
		if (data && data.EducationOrganizations) {
			setListSearch(data.EducationOrganizations);
		}
	}, [data]);

	return (
		<Autocomplete
			id="institute"
			freeSolo
			loading={loading}
			options={listSearch || []}
			value={{ Id: "-1", Name: name }}
			getOptionLabel={getLabel}
			renderInput={(params) => (
				<TextField
					{...params}
					required
					label="Учебное заведение"
					margin="normal"
					onChange={(e) => onChange(e.target.value?.trim() ? e.target.value : "")}
					onBlur={() => onChange(name.trim())}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						)
					}}
				/>
			)}
		/>
	);
};

export default React.memo(EducationInstituteSearch);
