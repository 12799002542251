import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsCoachesOrgFullFragment = { __typename?: "StatisticsCoachesOrg" } & Pick<
	Types.StatisticsCoachesOrg,
	"OrgType" | "Department" | "All" | "FullTime" | "Mid" | "High"
>;

export const StatisticsCoachesOrgFullFragmentDoc = gql`
	fragment StatisticsCoachesOrgFull on StatisticsCoachesOrg {
		OrgType
		Department
		All
		FullTime
		Mid
		High
	}
`;
