import { Fab, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useUpsertProfilePlaceMutation } from "../../graphql/mutation/upsertProfilePlace.generated";
import { Ui_ProfilePlacesDocument, useUi_ProfilePlacesQuery } from "../../graphql/query/UI_ProfilePlaces.generated";
import { ProfilePlace } from "../../graphql/types";
import { isTempId, tempId } from "../../utils/utils";
import TitleAdd from "../common/TitleAdd";
import PlacesModal from "./PlacesModal";

interface IPlacesEditProps {
	hash: string;
}

export type TPartialProfilePlace = Partial<ProfilePlace> & { Id: string };

const PlacesEdit = (props: IPlacesEditProps) => {
	const { hash } = props;

	const [upsertProfilePlace, { loading: loadingMutation }] = useUpsertProfilePlaceMutation();

	const { loading: loadingQuery, data } = useUi_ProfilePlacesQuery({
		fetchPolicy: "cache-and-network",
		context: { headers: { Authorization: `Digest ${hash}` } }
	});
	const refetchQueries = [
		{
			query: Ui_ProfilePlacesDocument,
			context: { headers: { Authorization: `Digest ${hash}` } }
		}
	];

	const [places, setPlaces] = useState<Array<TPartialProfilePlace>>();

	useEffect(() => {
		if (data?.Places) {
			setPlaces(data?.Places);
		}
	}, [data?.Places]);

	const [modalState, setModalState] = useState<{
		visible: boolean;
		place?: TPartialProfilePlace;
	}>({ visible: false });

	const addPlace = (place: TPartialProfilePlace) => {
		const { Address, AddressId, Deleted, MutationStatus, ProfileId, __typename, ...placeInput } = place;
		upsertProfilePlace({
			variables: {
				data: {
					Address: {
						Id: isTempId(place.Address?.Id) ? undefined : place.Address?.Id,
						Text: place.Address?.Text || ""
					},
					...placeInput,
					Id: isTempId(place.Id) ? undefined : place.Id
				}
			},
			context: { headers: { Authorization: `Digest ${hash}` } },
			refetchQueries
		});
		if (place.Id != null) {
			const filteredPlace = places?.filter((item) => item.Id !== place.Id);
			setPlaces([...(filteredPlace || []), place]);
			return;
		}
		setPlaces([...(places || []), place]);
	};

	const deletePlace = (place: TPartialProfilePlace) => {
		upsertProfilePlace({
			variables: {
				data: {
					Id: place.Id,
					Deleted: true
				}
			},
			context: { headers: { Authorization: `Digest ${hash}` } },
			refetchQueries
		});
	};

	return (
		<Grid item sm={6}>
			<TitleAdd title="Площадки" onClick={() => setModalState({ visible: true, place: { Id: tempId() } })} />
			<PlacesModal
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				addPlace={addPlace}
				hash={hash}
				{...modalState}
			/>

			<List className="contact-list">
				{_.orderBy(places, "Name")?.map((item) => (
					<ListItem key={item.Id} button onClick={() => setModalState({ visible: true, place: item })}>
						<ListItemText primary={item.Name} secondary={item.Address?.Text} />
						<ListItemSecondaryAction onClick={() => deletePlace(item)}>
							<IconButton edge="end" aria-label="delete" size="small" color="primary">
								<i className={"icon icon--event-canceled"} />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Grid>
	);
};

export default PlacesEdit;
