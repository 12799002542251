import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { AddressFullFragment } from "../fragment/Address.generated";
import { AddressFullFragmentDoc } from "../fragment/Address.generated";
import {
	AttachmentForGalleryFragment,
	AttachmentFullFragment,
	AttachmentLinkFragment
} from "../fragment/Attachment.generated";
import {
	AttachmentForGalleryFragmentDoc,
	AttachmentFullFragmentDoc,
	AttachmentLinkFragmentDoc
} from "../fragment/Attachment.generated";
import { ContactFullFragment } from "../fragment/Contact.generated";
import { ContactFullFragmentDoc } from "../fragment/Contact.generated";
import { EducationRecFieldsFragment } from "../fragment/Education.generated";
import { EducationRecFieldsFragmentDoc } from "../fragment/Education.generated";
import { OrganizationForAvatarFragment, OrganizationFullFragment } from "../fragment/Organization.generated";
import { OrganizationForAvatarFragmentDoc, OrganizationFullFragmentDoc } from "../fragment/Organization.generated";
import { PersonForAvatarFragment, PersonFullFragment } from "../fragment/Person.generated";
import { PersonForAvatarFragmentDoc, PersonFullFragmentDoc } from "../fragment/Person.generated";
import { ProfileAchievementFullFragment } from "../fragment/ProfileAchievement.generated";
import { ProfileAchievementFullFragmentDoc } from "../fragment/ProfileAchievement.generated";
import { ProfileContactFullFragment } from "../fragment/ProfileContact.generated";
import { ProfileContactFullFragmentDoc } from "../fragment/ProfileContact.generated";
import { ProfileDisciplineFullFragment } from "../fragment/ProfileDiscipline.generated";
import { ProfileDisciplineFullFragmentDoc } from "../fragment/ProfileDiscipline.generated";
import { ProfileSportFullFragment } from "../fragment/ProfileSport.generated";
import { ProfileSportFullFragmentDoc } from "../fragment/ProfileSport.generated";
import { ProfileWorkingHoursFullFragment } from "../fragment/ProfileWorkingHours.generated";
import { ProfileWorkingHoursFullFragmentDoc } from "../fragment/ProfileWorkingHours.generated";
import { RegionFullFragment } from "../fragment/Region.generated";
import { RegionFullFragmentDoc } from "../fragment/Region.generated";
import { SearchFragment } from "../fragment/Search.generated";
import { SearchFragmentDoc } from "../fragment/Search.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ProfileEditQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProfileEditQuery = { __typename?: "Query" } & {
	MyProfile: { __typename?: "Profile" } & Pick<Types.Profile, "Id" | "Distance" | "ReviewsCount" | "CheckStatus"> & {
			Person?: Types.Maybe<
				{ __typename?: "Person" } & {
					LegalRepresentative?: Types.Maybe<
						{ __typename?: "Profile" } & Pick<Types.Profile, "Id"> & {
								Person?: Types.Maybe<{ __typename?: "Person" } & PersonForAvatarFragment>;
								Organization?: Types.Maybe<
									{ __typename?: "Organization" } & OrganizationForAvatarFragment
								>;
								AvatarAttachment?: Types.Maybe<{ __typename?: "Attachment" } & AttachmentLinkFragment>;
							}
					>;
					Federation?: Types.Maybe<{ __typename?: "SearchResult" } & SearchFragment>;
				} & PersonFullFragment
			>;
			Organization?: Types.Maybe<{ __typename?: "Organization" } & OrganizationFullFragment>;
			AvatarAttachment?: Types.Maybe<{ __typename?: "Attachment" } & AttachmentLinkFragment>;
			PrimaryRegion?: Types.Maybe<{ __typename?: "Region" } & RegionFullFragment>;
			SecondaryRegion?: Types.Maybe<{ __typename?: "Region" } & RegionFullFragment>;
			Attachments: Array<{ __typename?: "Attachment" } & AttachmentFullFragment>;
			Education: Array<{ __typename?: "ProfileEducation" } & EducationRecFieldsFragment>;
			Contacts: Array<{ __typename?: "ProfileContact" } & ProfileContactFullFragment>;
			Coaches?: Types.Maybe<Array<{ __typename?: "Contact" } & ContactFullFragment>>;
			Address?: Types.Maybe<{ __typename?: "Address" } & AddressFullFragment>;
			Sports: Array<{ __typename?: "ProfileSport" } & ProfileSportFullFragment>;
			Disciplines: Array<{ __typename?: "ProfileDiscipline" } & ProfileDisciplineFullFragment>;
			Achievements?: Types.Maybe<Array<{ __typename?: "ProfileAchievement" } & ProfileAchievementFullFragment>>;
			WorkingHours: Array<{ __typename?: "ProfileWorkingHours" } & ProfileWorkingHoursFullFragment>;
		};
};

export const ProfileEditDocument = gql`
	query ProfileEdit {
		MyProfile {
			Id
			Distance
			ReviewsCount
			CheckStatus
			Person {
				...PersonFull
				LegalRepresentative {
					Id
					Person {
						...PersonForAvatar
					}
					Organization {
						...OrganizationForAvatar
					}
					AvatarAttachment {
						...AttachmentLink
					}
				}
				Federation {
					...Search
				}
			}
			Organization {
				...OrganizationFull
			}
			AvatarAttachment {
				...AttachmentLink
			}
			PrimaryRegion {
				...RegionFull
			}
			SecondaryRegion {
				...RegionFull
			}
			Attachments {
				...AttachmentFull
			}
			Education {
				...EducationRecFields
			}
			Contacts {
				...ProfileContactFull
			}
			Coaches {
				...ContactFull
			}
			Address {
				...AddressFull
			}
			Sports {
				...ProfileSportFull
			}
			Disciplines {
				...ProfileDisciplineFull
			}
			Achievements {
				...ProfileAchievementFull
			}
			WorkingHours {
				...ProfileWorkingHoursFull
			}
		}
	}
	${PersonFullFragmentDoc}
	${PersonForAvatarFragmentDoc}
	${OrganizationForAvatarFragmentDoc}
	${AttachmentLinkFragmentDoc}
	${SearchFragmentDoc}
	${OrganizationFullFragmentDoc}
	${RegionFullFragmentDoc}
	${AttachmentFullFragmentDoc}
	${EducationRecFieldsFragmentDoc}
	${ProfileContactFullFragmentDoc}
	${ContactFullFragmentDoc}
	${AddressFullFragmentDoc}
	${ProfileSportFullFragmentDoc}
	${ProfileDisciplineFullFragmentDoc}
	${ProfileAchievementFullFragmentDoc}
	${ProfileWorkingHoursFullFragmentDoc}
`;
export type ProfileEditComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<ProfileEditQuery, ProfileEditQueryVariables>,
	"query"
>;

export const ProfileEditComponent = (props: ProfileEditComponentProps) => (
	<ApolloReactComponents.Query<ProfileEditQuery, ProfileEditQueryVariables> query={ProfileEditDocument} {...props} />
);

export type ProfileEditProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<ProfileEditQuery, ProfileEditQueryVariables>;
} &
	TChildProps;
export function withProfileEdit<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		ProfileEditQuery,
		ProfileEditQueryVariables,
		ProfileEditProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		ProfileEditQuery,
		ProfileEditQueryVariables,
		ProfileEditProps<TChildProps, TDataName>
	>(ProfileEditDocument, {
		alias: "profileEdit",
		...operationOptions
	});
}

/**
 * __useProfileEditQuery__
 *
 * To run a query within a React component, call `useProfileEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEditQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileEditQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileEditQuery, ProfileEditQueryVariables>
) {
	return ApolloReactHooks.useQuery<ProfileEditQuery, ProfileEditQueryVariables>(ProfileEditDocument, baseOptions);
}
export function useProfileEditLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileEditQuery, ProfileEditQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<ProfileEditQuery, ProfileEditQueryVariables>(ProfileEditDocument, baseOptions);
}
export type ProfileEditQueryHookResult = ReturnType<typeof useProfileEditQuery>;
export type ProfileEditLazyQueryHookResult = ReturnType<typeof useProfileEditLazyQuery>;
export type ProfileEditQueryResult = ApolloReactCommon.QueryResult<ProfileEditQuery, ProfileEditQueryVariables>;
