import * as Types from "../types";

import gql from "graphql-tag";
import { SportFullFragment } from "./Sport.generated";
import { SportFullFragmentDoc } from "./Sport.generated";

export type ProfileSportFullFragment = { __typename?: "ProfileSport" } & Pick<
	Types.ProfileSport,
	| "Id"
	| "SportRank"
	| "SportRankGained"
	| "SportRankConfirmed"
	| "RefereeRank"
	| "RefereeRankTill"
	| "CoachRank"
	| "CoachRankTill"
	| "CoachingFrom"
	| "OtherSport"
	| "AdditionalRank"
	| "AdditionalEducation"
	| "SportProgramm"
	| "CoachVeteran"
	| "SportVeteran"
> & { Sport: { __typename?: "Sport" } & SportFullFragment };

export const ProfileSportFullFragmentDoc = gql`
	fragment ProfileSportFull on ProfileSport {
		Id
		SportRank
		SportRankGained
		SportRankConfirmed
		RefereeRank
		RefereeRankTill
		CoachRank
		CoachRankTill
		CoachingFrom
		OtherSport
		AdditionalRank
		AdditionalEducation
		SportProgramm
		CoachVeteran
		SportVeteran
		Sport {
			...SportFull
		}
	}
	${SportFullFragmentDoc}
`;
