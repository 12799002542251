import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText
} from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { ProfileTypes } from "../../constants";
import { ContactFullFragment } from "../../graphql/fragment/Contact.generated";
import { useRemoveContactMutation } from "../../graphql/mutation/removeContact.generated";
import { useUpsertContactMutation } from "../../graphql/mutation/upsertContact.generated";
import { ProfileEditDocument } from "../../graphql/query/ProfileEdit.generated";
import { EContactType } from "../../graphql/types";
import { getProfileType, isTempId, tempId } from "../../utils/utils";
import Avatar from "../avatar";
import TitleAdd from "../common/TitleAdd";
import CoachesModal from "./CoachesModal";

interface ICoachesEditProps {
	hash: string;
	coaches: Array<TPartialCoach> | undefined;
	changeCoaches: (newContacts: Array<TPartialCoach>) => void;
}
export type TPartialCoach = Partial<ContactFullFragment> & { Id: string; GroupNames?: Array<string> };

const CoachesEdit = (props: ICoachesEditProps) => {
	const { hash, coaches, changeCoaches } = props;

	const refetchQueries = [
		{
			query: ProfileEditDocument,
			context: { headers: { Authorization: `Digest ${hash}` } }
		}
	];

	const [modalState, setModalState] = useState<{
		visible: boolean;
		coach?: TPartialCoach;
	}>({ visible: false });

	const [upsertContact, { loading, error }] = useUpsertContactMutation();
	const [removeContact, { loading: loadingRemove, error: errorRemove }] = useRemoveContactMutation();

	const addCoach = async (coach: TPartialCoach) => {
		const {
			CreatedAt,
			Data,
			MutationStatus,
			Password,
			Profile,
			Status,
			Type,
			UpdatedAt,
			__typename,
			...coachInput
		} = coach;

		let coachDataInput = {};

		if (coach.Data) {
			const { __typename: __typenameCoachData, ...input } = coach.Data;
			coachDataInput = input;
		}
		const res = await upsertContact({
			variables: {
				data: {
					...coachInput,
					Data: {
						...coachDataInput
					},
					Type: EContactType.Coach,
					Id: isTempId(coach.Id) ? undefined : coach.Id
				}
			},
			context: { headers: { Authorization: `Digest ${hash}` } },
			refetchQueries
		});
		if (!res || res.errors || !res.data?.upsertContact.success) {
			alert("Ошибка. Не удалось сохранить контакт тренера");
		}
	};
	const removeCoach = async (id: string) => {
		if (isTempId(id)) {
			const filteredCoach = coaches?.filter((coach) => id !== coach.Id);
			if (filteredCoach) {
				changeCoaches(filteredCoach);
			}
			return;
		}
		const res = await removeContact({
			variables: { id: Number(id) },
			context: { headers: { Authorization: `Digest ${hash}` } },
			refetchQueries
		});
		if (!res || res.errors || !res.data?.removeContact.success) {
			alert("Ошибка. Не удалось удалить контакт тренера");
		}
	};

	return (
		<Grid item sm={6}>
			<TitleAdd title="Тренеры" onClick={() => setModalState({ visible: true, coach: { Id: tempId() } })} />
			<CoachesModal
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				addCoach={addCoach}
				{...modalState}
			/>
			<List className="contact-list">
				{_.orderBy(coaches, "Name")?.map((item) => (
					<ListItem key={item.Id} button onClick={() => setModalState({ visible: true, coach: item })}>
						<ListItemIcon>
							<Avatar
								size={30}
								type={item.Profile ? getProfileType(item.Profile) : ProfileTypes.Coach}
								URL={item.Profile?.AvatarAttachment?.FileLink}
							/>
						</ListItemIcon>
						<ListItemText primary={item.Name} secondary={item.Comment} />
						<ListItemSecondaryAction onClick={() => removeCoach(item.Id)}>
							<IconButton edge="end" aria-label="delete" size="small" color="primary">
								<i className={"icon icon--event-canceled"} />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Grid>
	);
};

export default React.memo(CoachesEdit);
