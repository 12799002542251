export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTime: string;
	JSONObject: any;
	Upload: any;
};

export type Address = {
	__typename?: "Address";
	Id: Scalars["ID"];
	Text: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
	Index?: Maybe<Scalars["Float"]>;
	City?: Maybe<Scalars["String"]>;
	CityType?: Maybe<Scalars["String"]>;
	Settlement?: Maybe<Scalars["String"]>;
	SettlementType?: Maybe<Scalars["String"]>;
	Street?: Maybe<Scalars["String"]>;
	StreetType?: Maybe<Scalars["String"]>;
	House?: Maybe<Scalars["String"]>;
	HouseType?: Maybe<Scalars["String"]>;
	Building?: Maybe<Scalars["String"]>;
	BuildingType?: Maybe<Scalars["String"]>;
	Appartment?: Maybe<Scalars["String"]>;
	GeoLatitude?: Maybe<Scalars["Float"]>;
	GeoLongitude?: Maybe<Scalars["Float"]>;
	CheckStatus?: Maybe<Scalars["Float"]>;
	RegionId?: Maybe<Scalars["Float"]>;
	Region?: Maybe<Region>;
};

export type AddressInput = {
	Id: Scalars["ID"];
	Text: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
	Index?: Maybe<Scalars["Float"]>;
	City?: Maybe<Scalars["String"]>;
	CityType?: Maybe<Scalars["String"]>;
	Settlement?: Maybe<Scalars["String"]>;
	SettlementType?: Maybe<Scalars["String"]>;
	Street?: Maybe<Scalars["String"]>;
	StreetType?: Maybe<Scalars["String"]>;
	House?: Maybe<Scalars["String"]>;
	HouseType?: Maybe<Scalars["String"]>;
	Building?: Maybe<Scalars["String"]>;
	BuildingType?: Maybe<Scalars["String"]>;
	Appartment?: Maybe<Scalars["String"]>;
	GeoLatitude?: Maybe<Scalars["Float"]>;
	GeoLongitude?: Maybe<Scalars["Float"]>;
	CheckStatus?: Maybe<Scalars["Float"]>;
	RegionId?: Maybe<Scalars["Float"]>;
};

export type AddUsersToChatChannelResult = {
	__typename?: "AddUsersToChatChannelResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<AddUsersToChatChannelResultData>;
};

export type AddUsersToChatChannelResultData = {
	__typename?: "AddUsersToChatChannelResultData";
	roomId: Scalars["String"];
};

export type Attachment = {
	__typename?: "Attachment";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Relation: EAttachmentEntity;
	RelationId: Scalars["Float"];
	Type: EAttachmentType;
	FileLink: Scalars["String"];
	PreviewLink?: Maybe<Scalars["String"]>;
	Description?: Maybe<Scalars["String"]>;
	Data: Scalars["JSONObject"];
	OwnerProfileId?: Maybe<Scalars["ID"]>;
	MutationStatus?: Maybe<EClientMutationStatus>;
};

export type AttachmentInput = {
	File: Scalars["Upload"];
	FilePreview?: Maybe<Scalars["Upload"]>;
	Metadata: AttachmentMetadataInput;
	Relation: Scalars["String"];
	RelationId: Scalars["ID"];
};

export type AttachmentMetadataInput = {
	Id: Scalars["ID"];
	Type: EAttachmentType;
	Description: Scalars["String"];
	EntityTypeName: EAttachmentEntity;
	EntityId: Scalars["String"];
	Width?: Maybe<Scalars["Float"]>;
	Height?: Maybe<Scalars["Float"]>;
	IsAvatar?: Maybe<Scalars["Boolean"]>;
};

export type AttachmentUpdateUserMetadataInput = {
	Id: Scalars["ID"];
	Description: Scalars["String"];
};

export type AttendedInfo = {
	Id: Scalars["ID"];
	Attended: Scalars["Boolean"];
	TargetValue?: Maybe<Scalars["Float"]>;
	ResultValue?: Maybe<Scalars["Float"]>;
	CoachGrade?: Maybe<Scalars["Float"]>;
	CoachComment?: Maybe<Scalars["String"]>;
};

export type AttendedLogInput = {
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
	ContactId?: Maybe<Scalars["ID"]>;
};

export type AuthDevInput = {
	DeviceId: Scalars["String"];
};

export type AuthFirebaseInput = {
	IdToken: Scalars["String"];
};

export type AuthPayload = {
	__typename?: "AuthPayload";
	profile: Profile;
	token: Scalars["String"];
	authId: Scalars["String"];
};

export type AuthVkInput = {
	AccessToken: Scalars["String"];
	ExpiresIn: Scalars["Float"];
	UserId: Scalars["String"];
	Email: Scalars["String"];
};

export type Badges = {
	__typename?: "Badges";
	New?: Maybe<Scalars["Boolean"]>;
	Promo?: Maybe<Scalars["Boolean"]>;
};

export type BadgesInput = {
	New?: Maybe<Scalars["Boolean"]>;
	Promo?: Maybe<Scalars["Boolean"]>;
};

export type BaseResult = {
	__typename?: "BaseResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
};

export type CalendarViewInput = {
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
	Id?: Maybe<Scalars["String"]>;
	ProfileId?: Maybe<Scalars["String"]>;
	OrderDirection?: Maybe<Scalars["String"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
};

export type ChangeContactStatusResult = {
	__typename?: "ChangeContactStatusResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<ChangeContactStatusResultData>;
};

export type ChangeContactStatusResultData = {
	__typename?: "ChangeContactStatusResultData";
	Updated: Contact;
};

export type Contact = {
	__typename?: "Contact";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Status: EContactStatus;
	Type: EContactType;
	Comment?: Maybe<Scalars["String"]>;
	Name?: Maybe<Scalars["String"]>;
	Phone?: Maybe<Scalars["String"]>;
	Snils?: Maybe<Scalars["String"]>;
	SportTrainingStage?: Maybe<EProfileSportTrainingStage>;
	SportTrainingProgramm?: Maybe<ESportTrainingProgramm>;
	PartTime?: Maybe<Scalars["Boolean"]>;
	Password: Scalars["String"];
	ProfileId?: Maybe<Scalars["ID"]>;
	SportId?: Maybe<Scalars["ID"]>;
	MutationStatus?: Maybe<EClientMutationStatus>;
	Data?: Maybe<ContactCoachData>;
	Owner?: Maybe<Profile>;
	Profile?: Maybe<Profile>;
};

export type ContactCoachData = {
	__typename?: "ContactCoachData";
	OtherSport?: Maybe<Scalars["String"]>;
	CoachRank?: Maybe<Scalars["String"]>;
	CoachRankTill?: Maybe<Scalars["DateTime"]>;
	Achievements?: Maybe<Scalars["String"]>;
	Position?: Maybe<Scalars["String"]>;
	CoachingFrom?: Maybe<Scalars["DateTime"]>;
	Education?: Maybe<Scalars["String"]>;
	Retraining?: Maybe<Scalars["String"]>;
};

export type ContactCoachDataInput = {
	OtherSport?: Maybe<Scalars["String"]>;
	CoachRank?: Maybe<Scalars["String"]>;
	CoachRankTill?: Maybe<Scalars["DateTime"]>;
	Achievements?: Maybe<Scalars["String"]>;
	Position?: Maybe<Scalars["String"]>;
	CoachingFrom?: Maybe<Scalars["DateTime"]>;
	Education?: Maybe<Scalars["String"]>;
	Retraining?: Maybe<Scalars["String"]>;
};

export type ContactGroup = {
	__typename?: "ContactGroup";
	Name: Scalars["String"];
	ContactIds: Array<Scalars["Float"]>;
};

export type ContactGroups = {
	__typename?: "ContactGroups";
	Groups?: Maybe<Array<ContactGroup>>;
};

export type ContactInfo = {
	__typename?: "ContactInfo";
	Id?: Maybe<Scalars["ID"]>;
	Type: EContactInfoType;
	Value: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
};

export type ContactInfoInput = {
	Id?: Maybe<Scalars["ID"]>;
	Type: EContactInfoType;
	Value: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
};

export type DataLinkedResult = {
	__typename?: "DataLinkedResult";
	Link?: Maybe<Scalars["String"]>;
	Name?: Maybe<Scalars["String"]>;
	OrgType?: Maybe<EOrganizationType>;
	PersonType?: Maybe<EPersonType>;
	ContactType?: Maybe<EContactType>;
};

export type DirectorData = {
	__typename?: "DirectorData";
	Name?: Maybe<Scalars["String"]>;
};

export type DirectorDataInput = {
	Name?: Maybe<Scalars["String"]>;
};

export type Discipline = {
	__typename?: "Discipline";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	Code: Scalars["String"];
	SportId: Scalars["Float"];
};

export enum EAchievementEventLevel {
	Olympiad = "Olympiad",
	International = "International",
	Regional = "Regional",
	City = "City"
}

export enum EAchievementEventType {
	Primacy = "Primacy",
	Championship = "Championship",
	Cup = "Cup",
	Qualifying = "Qualifying",
	RelayRace = "RelayRace",
	Friendly = "Friendly",
	Classification = "Classification",
	Qualification = "Qualification",
	MatchMeeting = "MatchMeeting"
}

export enum EAttachmentEntity {
	Profile = "Profile",
	EventObject = "EventObject"
}

export enum EAttachmentType {
	Image = "IMAGE",
	Video = "VIDEO",
	Document = "DOCUMENT"
}

export enum EClientMutationStatus {
	None = "NONE",
	ClientUpdated = "CLIENT_UPDATED",
	Updated = "UPDATED",
	ClientDeleted = "CLIENT_DELETED",
	Deleted = "DELETED"
}

export enum EClientPlatform {
	Unknown = "UNKNOWN",
	Ios = "IOS",
	Android = "ANDROID",
	Web = "WEB"
}

export enum ECoachRank {
	CoachVeteran = "CoachVeteran",
	HighestRank = "HighestRank",
	FirstRank = "FirstRank",
	SecondRank = "SecondRank"
}

export enum EContactInfoType {
	Email = "Email",
	Phone = "Phone",
	CellPhone = "CellPhone",
	Url = "URL",
	Skype = "Skype",
	WhatsApp = "WhatsApp",
	Telegram = "Telegram",
	Viber = "Viber",
	Icq = "ICQ",
	Facebook = "Facebook",
	Twitter = "Twitter",
	Instagramm = "Instagramm",
	YouTube = "YouTube",
	Vk = "Vk",
	Od = "Od"
}

export enum EContactStatus {
	New = "New",
	Confirmed = "Confirmed",
	Declined = "Declined",
	Deleted = "Deleted"
}

export enum EContactType {
	Friend = "Friend",
	Child = "Child",
	Parent = "Parent",
	Coach = "Coach",
	Organization = "Organization",
	Sportsman = "Sportsman"
}

export type EducationOrganization = {
	__typename?: "EducationOrganization";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	FullName?: Maybe<Scalars["String"]>;
	AddressId?: Maybe<Scalars["ID"]>;
	Address?: Maybe<Address>;
};

export type EducationOrganizationInput = {
	Name?: Maybe<Scalars["String"]>;
	City?: Maybe<Scalars["String"]>;
	RegionId?: Maybe<Scalars["Float"]>;
	EducationType?: Maybe<EEducationType>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EEducationOrganizationOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
};

export enum EEducationMode {
	FullTimeEducation = "FullTimeEducation",
	PartTimeEducation = "PartTimeEducation",
	DistanceEducation = "DistanceEducation",
	FullPartTimeEducation = "FullPartTimeEducation",
	Externate = "Externate"
}

export enum EEducationOrganizationOrderField {
	Relevance = "Relevance",
	Name = "Name"
}

export enum EEducationType {
	PrimaryGeneralEducation = "PrimaryGeneralEducation",
	BasicGeneralEducation = "BasicGeneralEducation",
	SecondaryGeneralEducation = "SecondaryGeneralEducation",
	SecondaryVocationalEducation = "SecondaryVocationalEducation",
	HigherEducationBachelor = "HigherEducationBachelor",
	HigherEducationSpecialist = "HigherEducationSpecialist",
	HigherEducationMasters = "HigherEducationMasters",
	Retraining = "Retraining",
	Training = "Training",
	PrimaryVocationalEducation = "PrimaryVocationalEducation",
	PostgraduatVocationalEducation = "PostgraduatVocationalEducation"
}

export enum EEkpOrderField {
	Name = "Name",
	Distance = "Distance"
}

export enum EEventAccessType {
	Individual = "INDIVIDUAL",
	Closed = "CLOSED",
	Open = "OPEN",
	Approve = "APPROVE"
}

export enum EEventChangeType {
	Other = "Other",
	Coach = "Coach",
	Status = "Status",
	MoveDateTimeEvent = "MoveDateTimeEvent",
	ProfilePlace = "ProfilePlace",
	MaxParticipants = "MaxParticipants",
	Name = "Name",
	Difficulty = "Difficulty",
	Comment = "Comment",
	MetricName = "MetricName"
}

export enum EEventDifficulty {
	Beginner = "Beginner",
	Intermediate = "Intermediate",
	Advanced = "Advanced",
	Expert = "Expert"
}

export enum EEventOrderField {
	Name = "Name",
	Rating = "Rating",
	Distance = "Distance",
	Cost = "Cost"
}

export enum EEventParticipateStatus {
	Invited = "Invited",
	Requested = "Requested",
	Approved = "Approved",
	Rejected = "Rejected"
}

export enum EEventStatus {
	New = "NEW",
	Open = "OPEN",
	Closed = "CLOSED",
	Finished = "FINISHED",
	Canceled = "CANCELED"
}

export enum EEventType {
	Activity = "ACTIVITY",
	Training = "TRAINING",
	Competition = "COMPETITION",
	Ekp = "EKP"
}

export enum EGender {
	Undefined = "Undefined",
	Male = "Male",
	Female = "Female"
}

export enum EGeoObjectOrderField {
	Name = "Name",
	Distance = "Distance"
}

export enum EGeoObjectType {
	Other = "Other",
	SportSchool = "SportSchool",
	School = "School",
	University = "University",
	College = "College",
	FitnessCenter = "FitnessCenter",
	OutdoorSportsGround = "OutdoorSportsGround",
	IndoorSportsGround = "IndoorSportsGround",
	Pool = "Pool",
	SkatingRink = "SkatingRink",
	CycleTrack = "CycleTrack",
	Gym = "Gym",
	BoatingChannel = "BoatingChannel",
	BoatingSlalomChannel = "BoatingSlalomChannel",
	BiathlonComplex = "BiathlonComplex",
	MountainSkiingComplex = "MountainSkiingComplex",
	HorseRidingComplex = "HorseRidingComplex",
	SkiingComplex = "SkiingComplex",
	SkiJumpingComplex = "SkiJumpingComplex",
	TrackAndFieldArena = "TrackAndFieldArena",
	SportsComplex = "SportsComplex",
	SpeedSkatingTrack = "SpeedSkatingTrack",
	Stadium = "Stadium",
	ShootingRange = "ShootingRange",
	SportsTrack = "SportsTrack",
	FootballField = "FootballField",
	ChessCheckersCenter = "ChessCheckersCenter"
}

export type Ekp = {
	__typename?: "EKP";
	Id: Scalars["ID"];
	EkpType: Scalars["String"];
	Makeup: Scalars["String"];
	EkpNumber: Scalars["String"];
	EkpName: Scalars["String"];
	DateFrom: Scalars["DateTime"];
	DateTill: Scalars["DateTime"];
	Place: Scalars["String"];
	NumberOfAthletes?: Maybe<Scalars["Float"]>;
	Group: Scalars["String"];
	Program: Scalars["String"];
	GeoLatitude?: Maybe<Scalars["Float"]>;
	GeoLongitude?: Maybe<Scalars["Float"]>;
	RegionCode?: Maybe<Scalars["String"]>;
	RegionFiasId?: Maybe<Scalars["String"]>;
};

export type EkpFilterInput = {
	OmniString?: Maybe<Scalars["String"]>;
	DateFrom?: Maybe<Scalars["DateTime"]>;
	DateTill?: Maybe<Scalars["DateTime"]>;
	EkpType?: Maybe<Array<Scalars["String"]>>;
	RegionCode?: Maybe<Scalars["String"]>;
	RegionFiasId?: Maybe<Scalars["String"]>;
	Limit: Scalars["Float"];
	Offset: Scalars["Float"];
	WithCoords?: Maybe<Scalars["Boolean"]>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EEkpOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
};

export enum ELinkParticipateEventType {
	Follower = "Follower",
	Participant = "Participant"
}

export enum ELoginProfileType {
	Owner = "Owner",
	Child = "Child",
	Employee = "Employee"
}

export enum ELoginProvider {
	Dev = "DEV",
	Server = "SERVER",
	Vk = "VK",
	Firebase = "FIREBASE"
}

export enum EMedal {
	Bronse = "Bronse",
	Silver = "Silver",
	Gold = "Gold"
}

export enum EMetricTypeBodyPart {
	Core = "CORE",
	Arms = "ARMS",
	Back = "BACK",
	Chest = "CHEST",
	Legs = "LEGS",
	Shoulders = "SHOULDERS",
	Other = "OTHER",
	Olympic = "OLYMPIC",
	FullBody = "FULL_BODY",
	Cardio = "CARDIO"
}

export enum EMetricTypeDisplayAs {
	AsIs = "AS_IS",
	Ceil = "CEIL",
	Floor = "FLOOR",
	Round_2Digits = "ROUND_2_DIGITS",
	Time = "TIME"
}

export enum EOrderDirection {
	Asc = "ASC",
	Desc = "DESC"
}

export enum EOrganizationOrderField {
	Name = "Name",
	Rating = "Rating",
	Distance = "Distance"
}

export enum EOrganizationType {
	School = "School",
	ChildrenSchool = "ChildrenSchool",
	Complex = "Complex",
	Club = "Club",
	Other = "Other",
	Ministry = "Ministry",
	Federation = "Federation",
	SportObject = "SportObject",
	OlympicChildrenSchool = "OlympicChildrenSchool",
	OlympicSchool = "OlympicSchool",
	OlympicCollege = "OlympicCollege",
	Center = "Center",
	OlympicCenter = "OlympicCenter"
}

export enum EParticipationType {
	Viewer = "Viewer",
	Participant = "Participant"
}

export enum EPersonType {
	Sportsman = "Sportsman",
	Coach = "Coach",
	Child = "Child"
}

export enum EProfileCheckStatus {
	New = "New",
	Trusted = "Trusted",
	Blocked = "Blocked",
	Checking = "Checking"
}

export enum EProfileMaritalStatus {
	Unknown = "UNKNOWN",
	Married = "MARRIED",
	Divorced = "DIVORCED"
}

export enum EProfileOrderField {
	Name = "Name",
	Rating = "Rating",
	Distance = "Distance",
	CostPerHour = "CostPerHour"
}

export enum EProfileSportTrainingStage {
	Wellness = "Wellness",
	Initial = "Initial",
	Training = "Training",
	Improvement = "Improvement",
	Higher = "Higher"
}

export enum EQueryType {
	Calendar = "Calendar",
	Profile = "Profile",
	ProfileEdit = "ProfileEdit",
	Statistic = "Statistic"
}

export enum ERefereeRank {
	HonoraryRefereeRussian = "HonoraryRefereeRussian",
	RefereeAllRussianCategory = "RefereeAllRussianCategory",
	FirstRank = "FirstRank",
	SecondRank = "SecondRank",
	ThirdRank = "ThirdRank",
	YoungReferee = "YoungReferee"
}

export enum ESearchOrderField {
	Relevance = "Relevance",
	Name = "Name",
	Rating = "Rating",
	Distance = "Distance",
	Cost = "Cost",
	StartTime = "StartTime"
}

export enum ESearchType {
	Event = "Event",
	Person = "Person",
	Organization = "Organization",
	GeoObject = "GeoObject"
}

export enum ESportRank {
	InternationalSportsMaster = "InternationalSportsMaster",
	SportsMasterVeteran = "SportsMasterVeteran",
	SportsMaster = "SportsMaster",
	ChessRank = "ChessRank",
	SportsMasterCandidate = "SportsMasterCandidate",
	FirstRank = "FirstRank",
	SecondRank = "SecondRank",
	ThirdRank = "ThirdRank",
	FirstJuniorRank = "FirstJuniorRank",
	SecondJuniorRank = "SecondJuniorRank",
	ThirdJuniorRank = "ThirdJuniorRank"
}

export enum ESportTrainingProgramm {
	SportProgramm = "SportProgramm",
	AdditionalEducation = "AdditionalEducation"
}

export enum ETicketOrderField {
	UpdatedAt = "UpdatedAt",
	Deadline = "Deadline"
}

export enum ETicketRelation {
	Owner = "Owner",
	Applicant = "Applicant",
	Assignee = "Assignee"
}

export enum ETicketStatus {
	New = "New",
	Processing = "Processing",
	Expired = "Expired",
	Clarification = "Clarification",
	Declined = "Declined",
	Completed = "Completed"
}

export enum ETrackStatus {
	New = "NEW",
	Invited = "INVITED",
	Requested = "REQUESTED",
	Joined = "JOINED",
	Refused = "REFUSED",
	Removed = "REMOVED",
	Finished = "FINISHED"
}

export type Event = {
	__typename?: "Event";
	Contacts?: Maybe<Array<ContactInfo>>;
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
	Duration: Scalars["Float"];
	Recurrent?: Maybe<Scalars["Boolean"]>;
	Day?: Maybe<EWeekDays>;
	StartAt?: Maybe<Scalars["String"]>;
	Name: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
	Type: EEventType;
	Cost?: Maybe<Scalars["Float"]>;
	Rating?: Maybe<Scalars["Float"]>;
	AccessType: EEventAccessType;
	Status: EEventStatus;
	MaxTracks?: Maybe<Scalars["Float"]>;
	MaxParticipants?: Maybe<Scalars["Float"]>;
	Difficulty?: Maybe<EEventDifficulty>;
	Badges?: Maybe<Badges>;
	OtherSport?: Maybe<Scalars["String"]>;
	Organizers?: Maybe<Scalars["String"]>;
	ShowOwner?: Maybe<Scalars["Boolean"]>;
	EkpNumber?: Maybe<Scalars["Float"]>;
	ProfileId: Scalars["ID"];
	SportId?: Maybe<Scalars["ID"]>;
	DisciplineId?: Maybe<Scalars["ID"]>;
	AddressId?: Maybe<Scalars["ID"]>;
	ProfilePlaceId?: Maybe<Scalars["ID"]>;
	MetricName?: Maybe<Scalars["String"]>;
	MetricValue?: Maybe<Scalars["Float"]>;
	MutationStatus?: Maybe<EClientMutationStatus>;
	Distance?: Maybe<Scalars["Float"]>;
	FollowersCount?: Maybe<Scalars["Float"]>;
	Followed?: Maybe<Scalars["Boolean"]>;
	Notify?: Maybe<Scalars["Boolean"]>;
	Sport?: Maybe<Sport>;
	Discipline?: Maybe<Discipline>;
	Tracks?: Maybe<Array<Track>>;
	OurReview?: Maybe<EventObjectReview>;
	Reviews?: Maybe<Array<EventObjectReview>>;
	ReviewsCount?: Maybe<Scalars["Float"]>;
	Address?: Maybe<Address>;
	Profile?: Maybe<Profile>;
	Coaches?: Maybe<Array<Contact>>;
	Followers?: Maybe<Array<EventFollower>>;
	Changes?: Maybe<Array<EventChange>>;
	EditableFields?: Maybe<Array<Scalars["String"]>>;
	ProfilePlace?: Maybe<ProfilePlace>;
	Participants?: Maybe<Array<EventParticipant>>;
};

export type EventReviewsArgs = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
};

export type EventFollowersArgs = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
};

export type EventArgsInput = {
	Id?: Maybe<Array<Scalars["Float"]>>;
	My?: Maybe<Scalars["Boolean"]>;
	StartBefore?: Maybe<Scalars["DateTime"]>;
	StartAfter?: Maybe<Scalars["DateTime"]>;
	Type?: Maybe<EEventType>;
	AccessType?: Maybe<Array<EEventAccessType>>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
	RegionId?: Maybe<Array<Scalars["Float"]>>;
	Name?: Maybe<Scalars["String"]>;
	City?: Maybe<Scalars["String"]>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	RatingMin?: Maybe<Scalars["Float"]>;
	RatingMax?: Maybe<Scalars["Float"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EEventOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
	WithCoords?: Maybe<Scalars["Boolean"]>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
};

export type EventChange = {
	__typename?: "EventChange";
	Contacts?: Maybe<Array<ContactInfo>>;
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	StartTime?: Maybe<Scalars["DateTime"]>;
	EndTime?: Maybe<Scalars["DateTime"]>;
	ChangeType: EEventChangeType;
	Duration?: Maybe<Scalars["Float"]>;
	Day?: Maybe<EWeekDays>;
	StartAt?: Maybe<Scalars["String"]>;
	Name?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
	Cost?: Maybe<Scalars["Float"]>;
	Status?: Maybe<EEventStatus>;
	MaxParticipants?: Maybe<Scalars["Float"]>;
	Difficulty?: Maybe<EEventDifficulty>;
	Badges?: Maybe<Badges>;
	Coach1ContactId?: Maybe<Scalars["Float"]>;
	AddressId?: Maybe<Scalars["ID"]>;
	ProfilePlaceId?: Maybe<Scalars["ID"]>;
	MetricName?: Maybe<Scalars["String"]>;
	MetricValue?: Maybe<Scalars["Float"]>;
	EventId: Scalars["ID"];
	MutationStatus?: Maybe<EClientMutationStatus>;
	Address?: Maybe<Address>;
	Coaches?: Maybe<Array<Contact>>;
	ProfilePlace?: Maybe<ProfilePlace>;
};

export type EventFollower = {
	__typename?: "EventFollower";
	Id: Scalars["ID"];
	Notifications: Scalars["Boolean"];
	Profile: Profile;
	ProfileId: Scalars["Float"];
	Event: Event;
	EventId: Scalars["Float"];
	MutationStatus?: Maybe<EClientMutationStatus>;
};

export type EventObjectReview = {
	__typename?: "EventObjectReview";
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	UpdatedAt?: Maybe<Scalars["DateTime"]>;
	Rating: Scalars["Float"];
	Comment?: Maybe<Scalars["String"]>;
	GoodComment?: Maybe<Scalars["String"]>;
	BadComment?: Maybe<Scalars["String"]>;
	EventId: Scalars["ID"];
	OwnerProfile: Profile;
};

export type EventParticipant = {
	__typename?: "EventParticipant";
	Id: Scalars["ID"];
	Status: EEventParticipateStatus;
	AccessType: EEventAccessType;
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
	Comment?: Maybe<Scalars["String"]>;
	Attended?: Maybe<Scalars["Boolean"]>;
	TargetValue?: Maybe<Scalars["Float"]>;
	ResultValue?: Maybe<Scalars["Float"]>;
	CoachGrade?: Maybe<Scalars["Float"]>;
	ParticipantGrade?: Maybe<Scalars["Float"]>;
	CoachComment?: Maybe<Scalars["String"]>;
	ProfileId?: Maybe<Scalars["ID"]>;
	EventOwnerId?: Maybe<Scalars["ID"]>;
	ContactId?: Maybe<Scalars["Float"]>;
	EventId?: Maybe<Scalars["Float"]>;
	MutationStatus?: Maybe<EClientMutationStatus>;
	Profile?: Maybe<Profile>;
	EventOwner?: Maybe<Profile>;
	Contact: Contact;
	Event?: Maybe<Event>;
};

export enum EWeekDays {
	Monday = "Monday",
	Tuesday = "Tuesday",
	Wednesday = "Wednesday",
	Thursday = "Thursday",
	Friday = "Friday",
	Saturday = "Saturday",
	Sunday = "Sunday"
}

export type GeoObject = {
	__typename?: "GeoObject";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	Type?: Maybe<EGeoObjectType>;
	Description?: Maybe<Scalars["String"]>;
	Distance?: Maybe<Scalars["Float"]>;
	Address?: Maybe<Address>;
};

export type GeoObjectArgsInput = {
	Id?: Maybe<Array<Scalars["String"]>>;
	RegionId?: Maybe<Array<Scalars["Float"]>>;
	Type?: Maybe<Array<EGeoObjectType>>;
	Name?: Maybe<Scalars["String"]>;
	City?: Maybe<Scalars["String"]>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EGeoObjectOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
	WithCoords?: Maybe<Scalars["Boolean"]>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
};

export type InsertEventChangeInput = {
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	ChangeType: EEventChangeType;
	StartTime?: Maybe<Scalars["DateTime"]>;
	EndTime?: Maybe<Scalars["DateTime"]>;
	EventId: Scalars["Float"];
	Duration?: Maybe<Scalars["Float"]>;
	Name?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
	Cost?: Maybe<Scalars["Float"]>;
	MaxParticipants?: Maybe<Scalars["Float"]>;
	Day?: Maybe<EWeekDays>;
	StartAt?: Maybe<Scalars["String"]>;
	Difficulty?: Maybe<EEventDifficulty>;
	Status?: Maybe<EEventStatus>;
	Badges?: Maybe<BadgesInput>;
	Coach1ContactId?: Maybe<Scalars["ID"]>;
	AddressId?: Maybe<Scalars["ID"]>;
	ProfilePlaceId?: Maybe<Scalars["ID"]>;
	MetricName?: Maybe<Scalars["String"]>;
	MetricValue?: Maybe<Scalars["Float"]>;
};

export type InsertEventChangeResult = {
	__typename?: "InsertEventChangeResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<InsertEventChangeResultData>;
};

export type InsertEventChangeResultData = {
	__typename?: "InsertEventChangeResultData";
	EventChange: EventChange;
};

export type InsertLinkInput = {
	QueryType: EQueryType;
	QueryParams: Scalars["String"];
};

export type InsertLinkResult = {
	__typename?: "InsertLinkResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<Scalars["String"]>;
};

export type Link = {
	__typename?: "Link";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	Hash: Scalars["String"];
	QueryType: EQueryType;
	QueryParams: Scalars["String"];
};

export type LinkLoginsInput = {
	Provider: ELoginProvider;
	Platform?: Maybe<EClientPlatform>;
	VkAuthData?: Maybe<AuthVkInput>;
	FirebaseAuthData: AuthFirebaseInput;
	DevAuthData?: Maybe<AuthDevInput>;
	FirebaseAuthDataOld?: Maybe<AuthFirebaseInput>;
};

export type LinkParticipateEventInput = {
	Type: ELinkParticipateEventType;
	EventId: Scalars["ID"];
	StartTime?: Maybe<Scalars["DateTime"]>;
};

export type Log = {
	__typename?: "Log";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	MetricTypeId: Scalars["Float"];
	Value: Scalars["Float"];
	Repeats: Scalars["Float"];
	TextValue: Scalars["String"];
	MutationStatus?: Maybe<EClientMutationStatus>;
	MetricType: MetricType;
};

export type LoginInput = {
	Provider: ELoginProvider;
	Platform?: Maybe<EClientPlatform>;
	VkAuthData?: Maybe<AuthVkInput>;
	FirebaseAuthData?: Maybe<AuthFirebaseInput>;
	DevAuthData?: Maybe<AuthDevInput>;
};

export type LoginProfile = {
	__typename?: "LoginProfile";
	Id: Scalars["ID"];
	Type: Scalars["Float"];
	Status: Scalars["Float"];
	Token: Scalars["String"];
	AuthId: Scalars["Float"];
	Profile?: Maybe<Profile>;
};

export type LoginResult = {
	__typename?: "LoginResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<SignUpResultData>;
};

export type MetricType = {
	__typename?: "MetricType";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	Code: Scalars["String"];
	Unit: Scalars["String"];
	Descending: Scalars["Boolean"];
	MinValue: Scalars["Float"];
	MaxValue: Scalars["Float"];
	DisplayAs: EMetricTypeDisplayAs;
	BodyPart: EMetricTypeBodyPart;
	ShowRepeats: Scalars["Boolean"];
};

export type Mutation = {
	__typename?: "Mutation";
	registerUserToChat: RegisterUserToChatResult;
	addUsersToChatChannel: AddUsersToChatChannelResult;
	sendEventRequest: SendEmailResult;
	removeEvent: RemoveEventResult;
	upsertEvent: UpsertEventResult;
	upsertLog: UpsertLogResult;
	removeLog: RemoveLogResult;
	upsertProfile: UpdateProfileResult;
	removeProfile: UpdateProfileResult;
	restoreProfile: UpdateProfileResult;
	addContactGroup: UpdateProfileResult;
	updateContactGroup: UpdateProfileResult;
	removeContactGroup: UpdateProfileResult;
	upsertFavorite: UpdateProfileResult;
	upsertTicketSettings: UpdateProfileResult;
	sendProfileRequest: SendEmailResult;
	updateProfilePassword: UpdateProfilePasswordResult;
	sendAuth: SendAuthResult;
	updateProfileCheckStatus: BaseResult;
	upsertBCReview: UpdateProfileResult;
	upsertTrack: UpsertTrackResult;
	removeTrack: RemoveTrackResult;
	upsertProfileRevew: UpsertProfileReviewResult;
	removeProfileReview: BaseResult;
	upsertEventRevew: UpsertEventObjectReviewResult;
	removeEventReview: BaseResult;
	upsertEventFollower: UpsertEventFollowerResult;
	removeEventFollower: UpsertEventFollowerResult;
	removeEventChange: RemoveEventChangeResult;
	insertEventChange: InsertEventChangeResult;
	upsertContact: UpsertContactResult;
	upsertContacts: Array<UpsertContactResult>;
	removeContact: ChangeContactStatusResult;
	restoreContact: ChangeContactStatusResult;
	confirmContact: ChangeContactStatusResult;
	declineContact: ChangeContactStatusResult;
	connectContact: UpsertContactResult;
	addContactsToGroup: UpdateProfileResult;
	removeContactsFromGroup: UpdateProfileResult;
	inviteParticipant: UpsertEventParticipantResult;
	requestParticipation: UpsertEventParticipantResult;
	joinOpenEvent: UpsertEventParticipantResult;
	approveParticipation: UpsertEventParticipantResult;
	rejectParticipation: UpsertEventParticipantResult;
	setAttendedParticipants: UpsertEventParticipantResult;
	setEventResultParticipant: UpsertEventParticipantResult;
	upload: Array<Attachment>;
	updateAttachmentUserMetadata: UpdateAttachmentResult;
	removeAttachment: RemoveAttachmentResult;
	upsertProfilePlace: UpsertProfilePlaceResult;
	HashByQuery: InsertLinkResult;
	upsertProfilePhoneBook: BaseResult;
	upsertTicket: UpsertTicketResult;
	signUp: SignUpResult;
	linkLogins: SignUpResult;
	connectLogin: LoginResult;
	disconnectLogin: LoginResult;
	updatePushTokens: UpdatePushTokenResult;
};

export type MutationRegisterUserToChatArgs = {
	name: Scalars["String"];
	id: Scalars["String"];
};

export type MutationAddUsersToChatChannelArgs = {
	channelname: Scalars["String"];
	ids: Array<Scalars["String"]>;
};

export type MutationSendEventRequestArgs = {
	input: SendEventRequestInput;
};

export type MutationRemoveEventArgs = {
	data: RemoveEventInput;
};

export type MutationUpsertEventArgs = {
	data: UpsertEventInput;
};

export type MutationUpsertLogArgs = {
	data: UpsertLogInput;
};

export type MutationRemoveLogArgs = {
	data: RemoveLogInput;
};

export type MutationUpsertProfileArgs = {
	data: UpsertProfileInput;
};

export type MutationRemoveProfileArgs = {
	reason: Scalars["String"];
	id: Scalars["String"];
};

export type MutationRestoreProfileArgs = {
	id: Scalars["String"];
};

export type MutationAddContactGroupArgs = {
	groupNames: Array<Scalars["String"]>;
};

export type MutationUpdateContactGroupArgs = {
	input: UpdateContactGroupInput;
};

export type MutationRemoveContactGroupArgs = {
	groupNames: Array<Scalars["String"]>;
};

export type MutationUpsertFavoriteArgs = {
	input: ProfileDataFavoriteInput;
};

export type MutationUpsertTicketSettingsArgs = {
	input: UpsertProfileDataTicketsInput;
};

export type MutationSendProfileRequestArgs = {
	input: SendProfileRequestInput;
};

export type MutationSendAuthArgs = {
	id: Scalars["ID"];
};

export type MutationUpdateProfileCheckStatusArgs = {
	status: EProfileCheckStatus;
	profileId: Scalars["String"];
};

export type MutationUpsertBcReviewArgs = {
	input: UpsertProfileDataBcReviewInput;
};

export type MutationUpsertTrackArgs = {
	data: UpsertTrackInput;
};

export type MutationRemoveTrackArgs = {
	data: RemoveTrackInput;
};

export type MutationUpsertProfileRevewArgs = {
	data: UpsertProfileReviewInput;
};

export type MutationRemoveProfileReviewArgs = {
	id: Scalars["String"];
};

export type MutationUpsertEventRevewArgs = {
	data: UpsertEventReviewInput;
};

export type MutationRemoveEventReviewArgs = {
	id: Scalars["String"];
};

export type MutationUpsertEventFollowerArgs = {
	data: UpsertEventFollowerInput;
};

export type MutationRemoveEventFollowerArgs = {
	eventId: Scalars["ID"];
};

export type MutationRemoveEventChangeArgs = {
	ids: Array<Scalars["ID"]>;
};

export type MutationInsertEventChangeArgs = {
	data: InsertEventChangeInput;
};

export type MutationUpsertContactArgs = {
	data: UpsertContactInput;
};

export type MutationUpsertContactsArgs = {
	data: Array<UpsertContactInput>;
};

export type MutationRemoveContactArgs = {
	id: Scalars["Float"];
};

export type MutationRestoreContactArgs = {
	id: Scalars["Float"];
};

export type MutationConfirmContactArgs = {
	id: Scalars["Float"];
};

export type MutationDeclineContactArgs = {
	id: Scalars["Float"];
};

export type MutationConnectContactArgs = {
	password: Scalars["String"];
	id: Scalars["Float"];
};

export type MutationAddContactsToGroupArgs = {
	groupName: Scalars["String"];
	ids: Array<Scalars["Float"]>;
};

export type MutationRemoveContactsFromGroupArgs = {
	groupName: Scalars["String"];
	ids: Array<Scalars["Float"]>;
};

export type MutationInviteParticipantArgs = {
	data: UpsertEventParticipantInput;
};

export type MutationRequestParticipationArgs = {
	data: UpsertEventParticipantInput;
};

export type MutationJoinOpenEventArgs = {
	data: UpsertEventParticipantInput;
};

export type MutationApproveParticipationArgs = {
	id: Scalars["ID"];
};

export type MutationRejectParticipationArgs = {
	id: Scalars["ID"];
};

export type MutationSetAttendedParticipantsArgs = {
	data: SetEventAttendInput;
};

export type MutationSetEventResultParticipantArgs = {
	data: SetEventResultParticipantInput;
};

export type MutationUploadArgs = {
	files: Array<AttachmentInput>;
};

export type MutationUpdateAttachmentUserMetadataArgs = {
	input: AttachmentUpdateUserMetadataInput;
};

export type MutationRemoveAttachmentArgs = {
	input: RemoveAttachmentInput;
};

export type MutationUpsertProfilePlaceArgs = {
	data: UpsertProfilePlaceInput;
};

export type MutationHashByQueryArgs = {
	input: InsertLinkInput;
};

export type MutationUpsertProfilePhoneBookArgs = {
	input: Array<UpsertProfilePhoneBookInput>;
};

export type MutationUpsertTicketArgs = {
	data: UpsertTicketInput;
};

export type MutationSignUpArgs = {
	data: SignUpInput;
};

export type MutationLinkLoginsArgs = {
	input: LinkLoginsInput;
};

export type MutationConnectLoginArgs = {
	type: ELoginProfileType;
	password: Scalars["String"];
	profileId: Scalars["ID"];
};

export type MutationDisconnectLoginArgs = {
	profileId: Scalars["ID"];
};

export type MutationUpdatePushTokensArgs = {
	input: UpdatePushTokensInput;
};

export type Organization = {
	__typename?: "Organization";
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	UpdatedAt?: Maybe<Scalars["DateTime"]>;
	Type?: Maybe<EOrganizationType>;
	Inn: Scalars["String"];
	Name: Scalars["String"];
	FullName?: Maybe<Scalars["String"]>;
	Description?: Maybe<Scalars["String"]>;
	AccreditationOrder?: Maybe<Scalars["String"]>;
	AccreditationStart?: Maybe<Scalars["DateTime"]>;
	AccreditationEnd?: Maybe<Scalars["DateTime"]>;
	Data: OrganizationData;
	Rating?: Maybe<Scalars["Float"]>;
	Voices?: Maybe<Scalars["Float"]>;
	OKVED?: Maybe<Scalars["Float"]>;
	ProfileId: Scalars["ID"];
	Profile: Profile;
};

export type OrganizationArgsInput = {
	Id?: Maybe<Array<Scalars["Float"]>>;
	Type?: Maybe<Array<EOrganizationType>>;
	Name?: Maybe<Scalars["String"]>;
	City?: Maybe<Scalars["String"]>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
	DisciplineId?: Maybe<Array<Scalars["Float"]>>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	RatingMin?: Maybe<Scalars["Float"]>;
	RatingMax?: Maybe<Scalars["Float"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EOrganizationOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
	WithCoords?: Maybe<Scalars["Boolean"]>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
};

export type OrganizationData = {
	__typename?: "OrganizationData";
	Equipment?: Maybe<Scalars["String"]>;
	Rules?: Maybe<Scalars["String"]>;
	Achievements?: Maybe<Scalars["String"]>;
	Director?: Maybe<DirectorData>;
};

export type OrganizationDataInput = {
	Equipment?: Maybe<Scalars["String"]>;
	Rules?: Maybe<Scalars["String"]>;
	Achievements?: Maybe<Scalars["String"]>;
	Director?: Maybe<DirectorDataInput>;
};

export type OrganizationInput = {
	Id?: Maybe<Scalars["ID"]>;
	Type?: Maybe<EOrganizationType>;
	Inn?: Maybe<Scalars["String"]>;
	Name?: Maybe<Scalars["String"]>;
	FullName?: Maybe<Scalars["String"]>;
	Description?: Maybe<Scalars["String"]>;
	Data?: Maybe<OrganizationDataInput>;
	OKVED?: Maybe<Scalars["Float"]>;
};

export type OrganizatonsByTypes = {
	__typename?: "OrganizatonsByTypes";
	School: Scalars["Int"];
	ChildrenSchool: Scalars["Int"];
	OlympicSchool: Scalars["Int"];
	OlympicChildrenSchool: Scalars["Int"];
	OlympicCollege: Scalars["Int"];
	OlympicCenter: Scalars["Int"];
	Center: Scalars["Int"];
	Other: Scalars["Int"];
};

export type Person = {
	__typename?: "Person";
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	UpdatedAt?: Maybe<Scalars["DateTime"]>;
	Type?: Maybe<EPersonType>;
	Name?: Maybe<Scalars["String"]>;
	Gender?: Maybe<EGender>;
	Rating?: Maybe<Scalars["Float"]>;
	Voices?: Maybe<Scalars["Float"]>;
	CostPerHour?: Maybe<Scalars["Float"]>;
	Points?: Maybe<Scalars["Float"]>;
	PassportNumber?: Maybe<Scalars["Float"]>;
	FirstName?: Maybe<Scalars["String"]>;
	LastName?: Maybe<Scalars["String"]>;
	PreviousLastName?: Maybe<Scalars["String"]>;
	MiddleName?: Maybe<Scalars["String"]>;
	Birthday?: Maybe<Scalars["DateTime"]>;
	StartSportDate?: Maybe<Scalars["DateTime"]>;
	MaritalStatus?: Maybe<EProfileMaritalStatus>;
	SportTrainingStage?: Maybe<EProfileSportTrainingStage>;
	Birthplace?: Maybe<Scalars["String"]>;
	SubdivisionName?: Maybe<Scalars["String"]>;
	SubdivisionCode?: Maybe<Scalars["String"]>;
	IssuedAt?: Maybe<Scalars["DateTime"]>;
	SSN?: Maybe<Scalars["String"]>;
	BirthCertificate?: Maybe<Scalars["String"]>;
	BirthRecordAcceptedBy?: Maybe<Scalars["String"]>;
	BirthRecordDate?: Maybe<Scalars["DateTime"]>;
	MedicalInsuranceNumber?: Maybe<Scalars["String"]>;
	NextMedicalExaminationDate?: Maybe<Scalars["DateTime"]>;
	ProfileId: Scalars["ID"];
	PersonalCoachId?: Maybe<Scalars["ID"]>;
	FavoriteCoachId?: Maybe<Scalars["ID"]>;
	FavoriteOrganizationId?: Maybe<Scalars["ID"]>;
	LegalRepresentativeId?: Maybe<Scalars["ID"]>;
	FederationId?: Maybe<Scalars["ID"]>;
	Citizenship?: Maybe<Scalars["Float"]>;
	AgreePersonalData?: Maybe<Scalars["Boolean"]>;
	Data: PersonExtraData;
	Profile: Profile;
	LegalRepresentative?: Maybe<Profile>;
	PersonalCoach?: Maybe<Profile>;
	FavoriteCoach?: Maybe<Profile>;
	FavoriteOrganization?: Maybe<Profile>;
	Federation?: Maybe<SearchResult>;
};

export type PersonFederationArgs = {
	Longitude?: Maybe<Scalars["Float"]>;
	Latitude?: Maybe<Scalars["Float"]>;
};

export type PersonArgsInput = {
	Id?: Maybe<Array<Scalars["ID"]>>;
	Gender?: Maybe<EGender>;
	Type?: Maybe<EPersonType>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
	RegionId?: Maybe<Array<Scalars["Float"]>>;
	Name?: Maybe<Scalars["String"]>;
	City?: Maybe<Scalars["String"]>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	RatingMin?: Maybe<Scalars["Float"]>;
	RatingMax?: Maybe<Scalars["Float"]>;
	CostPerHourMin?: Maybe<Scalars["Float"]>;
	CostPerHourMax?: Maybe<Scalars["Float"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<EProfileOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
	WithCoords?: Maybe<Scalars["Boolean"]>;
};

export type PersonData = {
	PersonId?: Maybe<Scalars["String"]>;
	Type?: Maybe<EPersonType>;
	Name?: Maybe<Scalars["String"]>;
	Gender?: Maybe<EGender>;
	PassportNumber?: Maybe<Scalars["Float"]>;
	FirstName?: Maybe<Scalars["String"]>;
	LastName?: Maybe<Scalars["String"]>;
	PreviousLastName?: Maybe<Scalars["String"]>;
	MiddleName?: Maybe<Scalars["String"]>;
	Birthday?: Maybe<Scalars["DateTime"]>;
	StartSportDate?: Maybe<Scalars["DateTime"]>;
	MaritalStatus?: Maybe<EProfileMaritalStatus>;
	SportTrainingStage?: Maybe<EProfileSportTrainingStage>;
	Birthplace?: Maybe<Scalars["String"]>;
	SubdivisionName?: Maybe<Scalars["String"]>;
	SubdivisionCode?: Maybe<Scalars["String"]>;
	IssuedAt?: Maybe<Scalars["DateTime"]>;
	SSN?: Maybe<Scalars["String"]>;
	BirthCertificate?: Maybe<Scalars["String"]>;
	BirthRecordAcceptedBy?: Maybe<Scalars["String"]>;
	BirthRecordDate?: Maybe<Scalars["DateTime"]>;
	MedicalInsuranceNumber?: Maybe<Scalars["String"]>;
	NextMedicalExaminationDate?: Maybe<Scalars["DateTime"]>;
	CostPerHour?: Maybe<Scalars["Float"]>;
	Citizenship?: Maybe<Scalars["Float"]>;
	Data?: Maybe<PersonExtraDataInput>;
	AgreePersonalData?: Maybe<Scalars["Boolean"]>;
	LegalRepresentativeId?: Maybe<Scalars["String"]>;
	PersonalCoachId?: Maybe<Scalars["String"]>;
	FavoriteCoachId?: Maybe<Scalars["String"]>;
	FavoriteOrganizationId?: Maybe<Scalars["String"]>;
	FederationId?: Maybe<Scalars["String"]>;
};

export type PersonExtraData = {
	__typename?: "PersonExtraData";
	Gto?: Maybe<PersonGtoData>;
};

export type PersonExtraDataInput = {
	Gto?: Maybe<PersonGtoDataInput>;
};

export type PersonGtoData = {
	__typename?: "PersonGtoData";
	Medal: EMedal;
	Age: Scalars["Float"];
	Certificate?: Maybe<Scalars["String"]>;
};

export type PersonGtoDataInput = {
	Medal: EMedal;
	Age: Scalars["Float"];
	Certificate?: Maybe<Scalars["String"]>;
};

export type PlaceAddressData = {
	Id?: Maybe<Scalars["ID"]>;
	Text: Scalars["String"];
};

export type PlaceArgsInput = {
	Id?: Maybe<Array<Scalars["ID"]>>;
	ProfileId?: Maybe<Array<Scalars["ID"]>>;
	WithDeleted?: Maybe<Scalars["Boolean"]>;
};

export type Profile = {
	__typename?: "Profile";
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	UpdatedAt?: Maybe<Scalars["DateTime"]>;
	ConnectPassword?: Maybe<Scalars["String"]>;
	ContactGroups: ContactGroups;
	Deleted?: Maybe<Scalars["Boolean"]>;
	DeleteReason?: Maybe<Scalars["String"]>;
	CheckStatus?: Maybe<EProfileCheckStatus>;
	Data: ProfileData;
	Distance?: Maybe<Scalars["Float"]>;
	EventsFollowedCount?: Maybe<Scalars["Float"]>;
	Sports: Array<ProfileSport>;
	Disciplines: Array<ProfileDiscipline>;
	Contacts: Array<ProfileContact>;
	OurReview?: Maybe<ProfileReview>;
	Reviews: Array<ProfileReview>;
	ReviewsCount: Scalars["Float"];
	Address?: Maybe<Address>;
	EventsFollowed: Array<EventFollower>;
	Person?: Maybe<Person>;
	Organization?: Maybe<Organization>;
	PersonOpen?: Maybe<Person>;
	OrganizationOpen?: Maybe<Organization>;
	AvatarAttachment?: Maybe<Attachment>;
	Attachments: Array<Attachment>;
	WorkingHours: Array<ProfileWorkingHours>;
	Coaches?: Maybe<Array<Contact>>;
	Organizations?: Maybe<Array<Contact>>;
	Places?: Maybe<Array<ProfilePlace>>;
	PrimaryRegion?: Maybe<Region>;
	SecondaryRegion?: Maybe<Region>;
	Achievements?: Maybe<Array<ProfileAchievement>>;
	RocketChatPassword?: Maybe<Scalars["String"]>;
	Education: Array<ProfileEducation>;
	CanGetAccess?: Maybe<Scalars["Boolean"]>;
};

export type ProfileReviewsArgs = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
};

export type ProfileEventsFollowedArgs = {
	limit?: Maybe<Scalars["Int"]>;
	offset?: Maybe<Scalars["Int"]>;
};

export type ProfileAchievement = {
	__typename?: "ProfileAchievement";
	Id: Scalars["ID"];
	Date?: Maybe<Scalars["DateTime"]>;
	EventType?: Maybe<EAchievementEventType>;
	EventLevel?: Maybe<EAchievementEventLevel>;
	Info: Scalars["String"];
	Sport?: Maybe<Sport>;
	SportId?: Maybe<Scalars["ID"]>;
	OtherSport?: Maybe<Scalars["String"]>;
	ProfileId: Scalars["ID"];
	MutationStatus?: Maybe<EClientMutationStatus>;
};

export type ProfileAchievementInput = {
	Id?: Maybe<Scalars["ID"]>;
	Info?: Maybe<Scalars["String"]>;
	Date?: Maybe<Scalars["DateTime"]>;
	EventLevel?: Maybe<EAchievementEventLevel>;
	EventType?: Maybe<EAchievementEventType>;
	SportId?: Maybe<Scalars["ID"]>;
	OtherSport?: Maybe<Scalars["String"]>;
};

export type ProfileAddressData = {
	Id?: Maybe<Scalars["ID"]>;
	Text?: Maybe<Scalars["String"]>;
	RegionId?: Maybe<Scalars["ID"]>;
};

export type ProfileArgsInput = {
	Id: Scalars["ID"];
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
};

export type ProfileContact = {
	__typename?: "ProfileContact";
	Id: Scalars["ID"];
	Type: EContactInfoType;
	Value: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
};

export type ProfileData = {
	__typename?: "ProfileData";
	Favorite?: Maybe<ProfileDataFavorite>;
	Tickets?: Maybe<ProfileDataTickets>;
	BCReview?: Maybe<ProfileDataBcReview>;
};

export type ProfileDataBcReview = {
	__typename?: "ProfileDataBCReview";
	Rating: Scalars["Float"];
	Phone: Scalars["String"];
	Advantages?: Maybe<Scalars["String"]>;
	Disadvantages?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
};

export type ProfileDataFavorite = {
	__typename?: "ProfileDataFavorite";
	Sports?: Maybe<Array<Scalars["ID"]>>;
	Disciplines?: Maybe<Array<Scalars["ID"]>>;
};

export type ProfileDataFavoriteInput = {
	SportId?: Maybe<Scalars["ID"]>;
	DisciplineId?: Maybe<Scalars["ID"]>;
	Delete?: Maybe<Scalars["Boolean"]>;
};

export type ProfileDataTickets = {
	__typename?: "ProfileDataTickets";
	Enabled?: Maybe<Scalars["Boolean"]>;
	Contact?: Maybe<Scalars["String"]>;
	URL?: Maybe<Scalars["String"]>;
};

export type ProfileDiscipline = {
	__typename?: "ProfileDiscipline";
	Id: Scalars["ID"];
	Discipline: Discipline;
};

export type ProfileEducation = {
	__typename?: "ProfileEducation";
	Id: Scalars["ID"];
	RegionId: Scalars["String"];
	City?: Maybe<Scalars["String"]>;
	Type: EEducationType;
	EducationalInstitution: Scalars["String"];
	Specialization: Scalars["String"];
	Mode?: Maybe<EEducationMode>;
	StartYear?: Maybe<Scalars["String"]>;
	EndYear?: Maybe<Scalars["String"]>;
	IssueYear?: Maybe<Scalars["String"]>;
	ProfileId: Scalars["ID"];
};

export type ProfileEducationData = {
	Id?: Maybe<Scalars["ID"]>;
	RegionId: Scalars["String"];
	City?: Maybe<Scalars["String"]>;
	Type: EEducationType;
	EducationalInstitution: Scalars["String"];
	Specialization: Scalars["String"];
	Mode?: Maybe<EEducationMode>;
	StartYear?: Maybe<Scalars["String"]>;
	EndYear?: Maybe<Scalars["String"]>;
	IssueYear?: Maybe<Scalars["String"]>;
};

export type ProfileFill = {
	__typename?: "ProfileFill";
	Id: Scalars["ID"];
	CheckStatus?: Maybe<EProfileCheckStatus>;
	Inn: Scalars["String"];
	Name: Scalars["String"];
	Rating: Scalars["String"];
	UpdatedAt: Scalars["DateTime"];
	Type: Scalars["Float"];
	Place: Scalars["Boolean"];
	Coach: Scalars["Boolean"];
	WorkingHours: Scalars["Boolean"];
	Contacts: Scalars["Boolean"];
	Sports: Scalars["Boolean"];
	Events: Scalars["Boolean"];
	FullName: Scalars["Boolean"];
	Description: Scalars["Boolean"];
	Director: Scalars["Boolean"];
	Rules: Scalars["Boolean"];
	Achievements: Scalars["Boolean"];
	Equipment: Scalars["Boolean"];
	Address: Scalars["Boolean"];
	Region: Scalars["Boolean"];
	Link: Scalars["String"];
};

export type ProfilePhoneBook = {
	__typename?: "ProfilePhoneBook";
	Id: Scalars["ID"];
	Phone: Scalars["String"];
	Name: Scalars["String"];
	ProfileId: Scalars["ID"];
};

export type ProfilePlace = {
	__typename?: "ProfilePlace";
	Id: Scalars["ID"];
	Name: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
	IsPublic?: Maybe<Scalars["Boolean"]>;
	Deleted?: Maybe<Scalars["Boolean"]>;
	Favorite?: Maybe<Scalars["Boolean"]>;
	AddressId?: Maybe<Scalars["ID"]>;
	ProfileId: Scalars["ID"];
	MutationStatus?: Maybe<EClientMutationStatus>;
	Address?: Maybe<Address>;
};

export type ProfileReview = {
	__typename?: "ProfileReview";
	Id: Scalars["ID"];
	CreatedAt?: Maybe<Scalars["DateTime"]>;
	UpdatedAt?: Maybe<Scalars["DateTime"]>;
	Rating: Scalars["Float"];
	Comment?: Maybe<Scalars["String"]>;
	GoodComment?: Maybe<Scalars["String"]>;
	BadComment?: Maybe<Scalars["String"]>;
	ProfileId: Scalars["ID"];
	OwnerProfile: Profile;
};

export type ProfilesArgsInput = {
	Id: Array<Scalars["ID"]>;
	Deleted?: Maybe<Scalars["Boolean"]>;
};

export type ProfileSport = {
	__typename?: "ProfileSport";
	Id: Scalars["ID"];
	SportRank?: Maybe<ESportRank>;
	SportRankTill?: Maybe<Scalars["DateTime"]>;
	SportRankGained?: Maybe<Scalars["DateTime"]>;
	SportRankConfirmed?: Maybe<Scalars["DateTime"]>;
	RefereeRank?: Maybe<ERefereeRank>;
	RefereeRankTill?: Maybe<Scalars["DateTime"]>;
	CoachRank?: Maybe<ECoachRank>;
	CoachRankTill?: Maybe<Scalars["DateTime"]>;
	CoachVeteran?: Maybe<Scalars["Boolean"]>;
	SportVeteran?: Maybe<Scalars["Boolean"]>;
	CoachingFrom?: Maybe<Scalars["DateTime"]>;
	ProfileId: Scalars["ID"];
	Sport: Sport;
	SportId?: Maybe<Scalars["ID"]>;
	OtherSport?: Maybe<Scalars["String"]>;
	AdditionalRank?: Maybe<Scalars["String"]>;
	AdditionalEducation?: Maybe<Scalars["Boolean"]>;
	SportProgramm?: Maybe<Scalars["Boolean"]>;
};

export type ProfileSportData = {
	Id?: Maybe<Scalars["ID"]>;
	SportRank?: Maybe<ESportRank>;
	SportRankGained?: Maybe<Scalars["DateTime"]>;
	SportRankConfirmed?: Maybe<Scalars["DateTime"]>;
	RefereeRank?: Maybe<ERefereeRank>;
	RefereeRankTill?: Maybe<Scalars["DateTime"]>;
	CoachRank?: Maybe<ECoachRank>;
	CoachRankTill?: Maybe<Scalars["DateTime"]>;
	CoachingFrom?: Maybe<Scalars["DateTime"]>;
	CoachVeteran?: Maybe<Scalars["Boolean"]>;
	SportVeteran?: Maybe<Scalars["Boolean"]>;
	ProfileId?: Maybe<Scalars["ID"]>;
	SportId?: Maybe<Scalars["ID"]>;
	DisciplineId?: Maybe<Array<Scalars["ID"]>>;
	OtherSport?: Maybe<Scalars["String"]>;
	AdditionalRank?: Maybe<Scalars["String"]>;
	AdditionalEducation?: Maybe<Scalars["Boolean"]>;
	SportProgramm?: Maybe<Scalars["Boolean"]>;
};

export type ProfileWorkingHours = {
	__typename?: "ProfileWorkingHours";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Day: EWeekDays;
	WorkFrom: Scalars["String"];
	WorkTo: Scalars["String"];
};

export type ProfileWorkingHoursData = {
	Id?: Maybe<Scalars["ID"]>;
	Day: EWeekDays;
	WorkFrom: Scalars["String"];
	WorkTo: Scalars["String"];
};

export type Query = {
	__typename?: "Query";
	Events: Array<Event>;
	Event?: Maybe<Event>;
	LinkParticipateEvent?: Maybe<Scalars["String"]>;
	GeoObjects: Array<GeoObject>;
	Log?: Maybe<Log>;
	MetricTypes: Array<MetricType>;
	MyProfile: Profile;
	Profile: Profile;
	ProfilesByPhone: Array<Profile>;
	Profiles: Array<Profile>;
	QrCodeLink: DataLinkedResult;
	Sports: Array<Sport>;
	Track?: Maybe<Track>;
	CalendarView: Array<Event>;
	CalendarEventsDates: Array<Scalars["Float"]>;
	EventChangesForEvent: Array<EventChange>;
	EventChangesById?: Maybe<EventChange>;
	MyContacts: Array<Contact>;
	InOthersContactList: Array<Contact>;
	Contact?: Maybe<Contact>;
	InOthersContactCount: Scalars["Float"];
	Address?: Maybe<Address>;
	AttendedLog: Array<EventParticipant>;
	Disciplines: Array<Discipline>;
	Attachment?: Maybe<Attachment>;
	AttachmentsOfEvent: Array<Attachment>;
	AttachmentsOfProfile: Array<Attachment>;
	MyAttachments: Array<Attachment>;
	Ekp?: Maybe<Array<Ekp>>;
	EkpByEkpNumber?: Maybe<Array<Ekp>>;
	EkpFilter: Array<Ekp>;
	Organizations: Array<Organization>;
	Organization: Organization;
	Regions: Array<Region>;
	Places: Array<ProfilePlace>;
	Persons: Array<Person>;
	Person: Person;
	Search: Array<SearchResult>;
	Statistics?: Maybe<Statistics>;
	StatisticsProfileFill: StatisticsProfileFill;
	StatisticsProfileStatuses?: Maybe<StatisticsProfileStatuses>;
	StatisticsOrganizations?: Maybe<StatisticsOrganizations>;
	StatisticsStageseBySports?: Maybe<Array<StatisticsStagesSport>>;
	StatisticsStageseByOrganizations?: Maybe<Array<StatisticsStagesOrg>>;
	StatisticsSportRanksBySports?: Maybe<Array<StatisticsSportRanksSport>>;
	StatisticsSportRanksByOrganizations?: Maybe<Array<StatisticsSportRanksOrg>>;
	StatisticsCoachesBySports?: Maybe<Array<StatisticsCoachesSport>>;
	StatisticsCoachessByOrganizations?: Maybe<Array<StatisticsCoachesOrg>>;
	StatisticsCoachesRanksBySports?: Maybe<Array<StatisticsCoachesRanksSport>>;
	StatisticsCoachesRanksByOrganizations?: Maybe<Array<StatisticsCoachesRanksOrg>>;
	EducationOrganizations: Array<EducationOrganization>;
	QueryByHash?: Maybe<Link>;
	CalendarLink?: Maybe<Array<Event>>;
	Ticket: Array<Ticket>;
	TicketHistory: Array<TicketHistory>;
	LinkedProfiles?: Maybe<Array<LoginProfile>>;
	Ping: Scalars["String"];
};

export type QueryEventsArgs = {
	input: EventArgsInput;
};

export type QueryEventArgs = {
	id: Scalars["ID"];
};

export type QueryLinkParticipateEventArgs = {
	input: LinkParticipateEventInput;
};

export type QueryGeoObjectsArgs = {
	input: GeoObjectArgsInput;
};

export type QueryLogArgs = {
	id: Scalars["ID"];
};

export type QueryMetricTypesArgs = {
	onlyOwned: Scalars["Boolean"];
};

export type QueryProfileArgs = {
	input: ProfileArgsInput;
};

export type QueryProfilesByPhoneArgs = {
	phones: Array<Scalars["String"]>;
};

export type QueryProfilesArgs = {
	input: ProfilesArgsInput;
};

export type QueryQrCodeLinkArgs = {
	qrCode: Scalars["String"];
};

export type QuerySportsArgs = {
	ids?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryTrackArgs = {
	id: Scalars["ID"];
};

export type QueryCalendarViewArgs = {
	input: CalendarViewInput;
};

export type QueryCalendarEventsDatesArgs = {
	input: CalendarViewInput;
};

export type QueryEventChangesForEventArgs = {
	eventIds: Array<Scalars["ID"]>;
};

export type QueryEventChangesByIdArgs = {
	ids: Array<Scalars["ID"]>;
};

export type QueryInOthersContactListArgs = {
	contactStatus: Array<EContactStatus>;
};

export type QueryContactArgs = {
	id: Scalars["Float"];
};

export type QueryInOthersContactCountArgs = {
	contactStatus: Array<EContactStatus>;
};

export type QueryAddressArgs = {
	id: Scalars["ID"];
};

export type QueryAttendedLogArgs = {
	input: AttendedLogInput;
};

export type QueryDisciplinesArgs = {
	sportIds?: Maybe<Array<Scalars["ID"]>>;
	ids?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryAttachmentArgs = {
	id: Scalars["ID"];
};

export type QueryAttachmentsOfEventArgs = {
	event_id: Scalars["ID"];
};

export type QueryAttachmentsOfProfileArgs = {
	profile_id: Scalars["ID"];
};

export type QueryEkpArgs = {
	id: Scalars["ID"];
};

export type QueryEkpByEkpNumberArgs = {
	ekp_number: Scalars["String"];
};

export type QueryEkpFilterArgs = {
	filter: EkpFilterInput;
};

export type QueryOrganizationsArgs = {
	input: OrganizationArgsInput;
};

export type QueryOrganizationArgs = {
	input?: Maybe<OrganizationArgsInput>;
	id: Scalars["ID"];
};

export type QueryRegionsArgs = {
	ids?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryPlacesArgs = {
	input?: Maybe<PlaceArgsInput>;
};

export type QueryPersonsArgs = {
	input: PersonArgsInput;
};

export type QueryPersonArgs = {
	input?: Maybe<PersonArgsInput>;
	id: Scalars["ID"];
};

export type QuerySearchArgs = {
	input: SearchArgsInput;
};

export type QueryStatisticsArgs = {
	input: StatisticsInput;
};

export type QueryStatisticsProfileFillArgs = {
	input: StatisticsProfileFillInput;
};

export type QueryStatisticsProfileStatusesArgs = {
	input: StatisticsBaseInput;
};

export type QueryEducationOrganizationsArgs = {
	input: EducationOrganizationInput;
};

export type QueryQueryByHashArgs = {
	hash: Scalars["String"];
};

export type QueryCalendarLinkArgs = {
	input: CalendarViewInput;
};

export type QueryTicketArgs = {
	input: TicketArgsInput;
};

export type QueryTicketHistoryArgs = {
	ticketId: Scalars["ID"];
};

export type QueryLinkedProfilesArgs = {
	deleted?: Maybe<Scalars["Boolean"]>;
};

export type Region = {
	__typename?: "Region";
	Id: Scalars["ID"];
	Name: Scalars["String"];
	Type: Scalars["String"];
	Code: Scalars["String"];
	FiasId: Scalars["String"];
	GeoLatitude?: Maybe<Scalars["Float"]>;
	GeoLongitude?: Maybe<Scalars["Float"]>;
};

export type RegisterUserToChatResult = {
	__typename?: "RegisterUserToChatResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<RegisterUserToChatResultData>;
};

export type RegisterUserToChatResultData = {
	__typename?: "RegisterUserToChatResultData";
	userId: Scalars["String"];
};

export type RemoveAttachmentInput = {
	Id: Scalars["ID"];
};

export type RemoveAttachmentResult = {
	__typename?: "RemoveAttachmentResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<RemoveAttachmentResultData>;
};

export type RemoveAttachmentResultData = {
	__typename?: "RemoveAttachmentResultData";
	Attachment: Attachment;
};

export type RemoveEventChangeResult = {
	__typename?: "RemoveEventChangeResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<RemoveEventChangeResultData>;
};

export type RemoveEventChangeResultData = {
	__typename?: "RemoveEventChangeResultData";
	EventChange: Array<EventChange>;
};

export type RemoveEventInput = {
	Id: Scalars["ID"];
};

export type RemoveEventResult = {
	__typename?: "RemoveEventResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<RemoveEventResultData>;
};

export type RemoveEventResultData = {
	__typename?: "RemoveEventResultData";
	Event: Event;
};

export type RemoveLogInput = {
	Id: Scalars["ID"];
};

export type RemoveLogResult = {
	__typename?: "RemoveLogResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<RemoveLogResultData>;
};

export type RemoveLogResultData = {
	__typename?: "RemoveLogResultData";
	Log: Log;
};

export type RemoveTrackInput = {
	Id: Scalars["ID"];
};

export type RemoveTrackResult = {
	__typename?: "RemoveTrackResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<RemoveTrackResultData>;
};

export type RemoveTrackResultData = {
	__typename?: "RemoveTrackResultData";
	Track: Track;
};

export type SearchArgsInput = {
	Type: ESearchType;
	ProfileId?: Maybe<Array<Scalars["String"]>>;
	Name?: Maybe<Scalars["String"]>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
	RegionId?: Maybe<Array<Scalars["Float"]>>;
	City?: Maybe<Scalars["String"]>;
	CostMin?: Maybe<Scalars["Float"]>;
	CostMax?: Maybe<Scalars["Float"]>;
	Latitude?: Maybe<Scalars["Float"]>;
	Longitude?: Maybe<Scalars["Float"]>;
	Radius?: Maybe<Scalars["Float"]>;
	RatingMin?: Maybe<Scalars["Float"]>;
	RatingMax?: Maybe<Scalars["Float"]>;
	WithCoords?: Maybe<Scalars["Boolean"]>;
	LatitudeMap?: Maybe<Scalars["Float"]>;
	LongitudeMap?: Maybe<Scalars["Float"]>;
	LatitudeStart?: Maybe<Scalars["Float"]>;
	LatitudeEnd?: Maybe<Scalars["Float"]>;
	LongitudeStart?: Maybe<Scalars["Float"]>;
	LongitudeEnd?: Maybe<Scalars["Float"]>;
	ProfileCheckStatus?: Maybe<Array<EProfileCheckStatus>>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	Order?: Maybe<Array<SearchOrder>>;
	EventStartBefore?: Maybe<Scalars["DateTime"]>;
	EventStartAfter?: Maybe<Scalars["DateTime"]>;
	EventType?: Maybe<Array<EEventType>>;
	EventAccessType?: Maybe<Array<EEventAccessType>>;
	EventStatus?: Maybe<EEventStatus>;
	PersonType?: Maybe<EPersonType>;
	Gender?: Maybe<EGender>;
	OrganizationType?: Maybe<Array<EOrganizationType>>;
	ObjectType?: Maybe<Array<EGeoObjectType>>;
};

export type SearchOrder = {
	OrderField?: Maybe<ESearchOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
};

export type SearchResult = {
	__typename?: "SearchResult";
	Id: Scalars["ID"];
	Rating?: Maybe<Scalars["Float"]>;
	Name?: Maybe<Scalars["String"]>;
	ProfileId?: Maybe<Scalars["ID"]>;
	Address?: Maybe<Address>;
	PersonType?: Maybe<EPersonType>;
	OrgType?: Maybe<EOrganizationType>;
	ObjType?: Maybe<EGeoObjectType>;
	EventType?: Maybe<EEventType>;
	AvatarAttachment?: Maybe<Attachment>;
	Distance?: Maybe<Scalars["Float"]>;
	Cost?: Maybe<Scalars["Float"]>;
	StartTime?: Maybe<Scalars["DateTime"]>;
	EndTime?: Maybe<Scalars["DateTime"]>;
	WorkFrom?: Maybe<Scalars["String"]>;
	WorkTo?: Maybe<Scalars["String"]>;
	FollowersCount?: Maybe<Scalars["Float"]>;
	IsFollower?: Maybe<Scalars["Boolean"]>;
	location?: Maybe<SearchResultLocation>;
	MapDistance?: Maybe<Scalars["Float"]>;
	CheckStatus?: Maybe<EProfileCheckStatus>;
};

export type SearchResultLocation = {
	__typename?: "SearchResultLocation";
	latitude: Scalars["Float"];
	longitude: Scalars["Float"];
};

export type SendAuthResult = {
	__typename?: "SendAuthResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<SendAuthResultData>;
};

export type SendAuthResultData = {
	__typename?: "SendAuthResultData";
	Id: Scalars["String"];
};

export type SendEmailResult = {
	__typename?: "SendEmailResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
};

export type SendEventRequestInput = {
	ProfileIdFrom: Scalars["ID"];
	ProfileIdTo: Scalars["ID"];
	ParticipationType: EParticipationType;
	EventName: Scalars["String"];
	EventAddress: Scalars["String"];
	EventBegin: Scalars["String"];
	EventEnd: Scalars["String"];
	Message?: Maybe<Scalars["String"]>;
	SendMe?: Maybe<Scalars["Boolean"]>;
	DateDocument: Scalars["DateTime"];
};

export type SendProfileRequestInput = {
	ProfileIdFrom: Scalars["ID"];
	ProfileIdTo: Scalars["ID"];
	Message: Scalars["String"];
	ProfileSportId?: Maybe<Scalars["String"]>;
	SendMe?: Maybe<Scalars["Boolean"]>;
	DateDocument: Scalars["DateTime"];
};

export type SetEventAttendInput = {
	EventId: Scalars["ID"];
	AttendedInfo: Array<AttendedInfo>;
};

export type SetEventResultParticipantInput = {
	Id: Scalars["ID"];
	EventId: Scalars["ID"];
	ResultValue?: Maybe<Scalars["Float"]>;
	ParticipantGrade?: Maybe<Scalars["Float"]>;
};

export type SignUpInput = {
	Provider: ELoginProvider;
	Platform?: Maybe<EClientPlatform>;
	VkAuthData?: Maybe<AuthVkInput>;
	FirebaseAuthData?: Maybe<AuthFirebaseInput>;
	DevAuthData?: Maybe<AuthDevInput>;
};

export type SignUpResult = {
	__typename?: "SignUpResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<SignUpResultData>;
};

export type SignUpResultData = {
	__typename?: "SignUpResultData";
	profile: Profile;
	loginId: Scalars["ID"];
	token: Scalars["String"];
	authId: Scalars["Float"];
};

export type Sport = {
	__typename?: "Sport";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	Code: Scalars["String"];
};

export type Statistics = {
	__typename?: "Statistics";
	CoachesCount: Scalars["Int"];
	OrganizationsCount: Scalars["Int"];
	FederationsCount?: Maybe<Scalars["Int"]>;
	SportsmansCount: Scalars["Int"];
	EventsCount: Scalars["Int"];
	FinishedEventsCount: Scalars["Int"];
	ParticipantsCount: Scalars["Int"];
	FollowersCount: Scalars["Int"];
	ReviewsCount: Scalars["Int"];
};

export type StatisticsBaseInput = {
	RegionId?: Maybe<Scalars["Float"]>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
};

export type StatisticsCoaches = {
	__typename?: "StatisticsCoaches";
	All: Scalars["Int"];
	FullTime: Scalars["Int"];
	Mid: Scalars["Int"];
	High: Scalars["Int"];
};

export type StatisticsCoachesOrg = {
	__typename?: "StatisticsCoachesOrg";
	All: Scalars["Int"];
	FullTime: Scalars["Int"];
	Mid: Scalars["Int"];
	High: Scalars["Int"];
	OrgType: Scalars["ID"];
	Department?: Maybe<Scalars["String"]>;
};

export type StatisticsCoachesRanks = {
	__typename?: "StatisticsCoachesRanks";
	All: Scalars["Int"];
	HighestRank: Scalars["Int"];
	FirstRank: Scalars["Int"];
	SecondRank: Scalars["Int"];
	CoachVeteran: Scalars["Int"];
	SportVeteran: Scalars["Int"];
};

export type StatisticsCoachesRanksOrg = {
	__typename?: "StatisticsCoachesRanksOrg";
	All: Scalars["Int"];
	HighestRank: Scalars["Int"];
	FirstRank: Scalars["Int"];
	SecondRank: Scalars["Int"];
	CoachVeteran: Scalars["Int"];
	SportVeteran: Scalars["Int"];
	OrgType: Scalars["ID"];
	Department?: Maybe<Scalars["String"]>;
};

export type StatisticsCoachesRanksSport = {
	__typename?: "StatisticsCoachesRanksSport";
	All: Scalars["Int"];
	HighestRank: Scalars["Int"];
	FirstRank: Scalars["Int"];
	SecondRank: Scalars["Int"];
	CoachVeteran: Scalars["Int"];
	SportVeteran: Scalars["Int"];
	SportId: Scalars["ID"];
	SportName: Scalars["String"];
	SportCode: Scalars["String"];
};

export type StatisticsCoachesSport = {
	__typename?: "StatisticsCoachesSport";
	All: Scalars["Int"];
	FullTime: Scalars["Int"];
	Mid: Scalars["Int"];
	High: Scalars["Int"];
	SportId: Scalars["ID"];
	SportName: Scalars["String"];
	SportCode: Scalars["String"];
};

export type StatisticsInput = {
	RegionId?: Maybe<Scalars["Float"]>;
	SportId?: Maybe<Array<Scalars["Float"]>>;
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
};

export type StatisticsOrganizations = {
	__typename?: "StatisticsOrganizations";
	Education: OrganizatonsByTypes;
	Sport: OrganizatonsByTypes;
	Other?: Maybe<OrganizatonsByTypes>;
	AdditionalEducation: OrganizatonsByTypes;
	SportsProgramm: OrganizatonsByTypes;
};

export type StatisticsProfileFill = {
	__typename?: "StatisticsProfileFill";
	Count: Scalars["Float"];
	Profiles: Array<ProfileFill>;
};

export type StatisticsProfileFillInput = {
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
};

export type StatisticsProfileStatuses = {
	__typename?: "StatisticsProfileStatuses";
	New: Scalars["Int"];
	Trusted: Scalars["Int"];
	Blocked?: Maybe<Scalars["Int"]>;
	Checking: Scalars["Int"];
};

export type StatisticsSportRank = {
	__typename?: "StatisticsSportRank";
	Total: Scalars["Int"];
	Gained: Scalars["Int"];
	Confirmed?: Maybe<Scalars["Int"]>;
};

export type StatisticsSportRanks = {
	__typename?: "StatisticsSportRanks";
	InternationalSportsMaster: StatisticsSportRank;
	SportsMasterVeteran: StatisticsSportRank;
	SportsMaster: StatisticsSportRank;
	SportsMasterCandidate: StatisticsSportRank;
	FirstRank: StatisticsSportRank;
	Other: StatisticsSportRank;
};

export type StatisticsSportRanksOrg = {
	__typename?: "StatisticsSportRanksOrg";
	InternationalSportsMaster: StatisticsSportRank;
	SportsMasterVeteran: StatisticsSportRank;
	SportsMaster: StatisticsSportRank;
	SportsMasterCandidate: StatisticsSportRank;
	FirstRank: StatisticsSportRank;
	Other: StatisticsSportRank;
	OrgType: Scalars["ID"];
	Department?: Maybe<Scalars["String"]>;
};

export type StatisticsSportRanksSport = {
	__typename?: "StatisticsSportRanksSport";
	InternationalSportsMaster: StatisticsSportRank;
	SportsMasterVeteran: StatisticsSportRank;
	SportsMaster: StatisticsSportRank;
	SportsMasterCandidate: StatisticsSportRank;
	FirstRank: StatisticsSportRank;
	Other: StatisticsSportRank;
	SportId: Scalars["ID"];
	SportCode: Scalars["ID"];
	SportName: Scalars["String"];
};

export type StatisticsStages = {
	__typename?: "StatisticsStages";
	Wellness: Scalars["Int"];
	Initial: Scalars["Int"];
	Training: Scalars["Int"];
	Improvement: Scalars["Int"];
	Higher: Scalars["Int"];
	All: Scalars["Int"];
	AdditionalEducation: Scalars["Int"];
};

export type StatisticsStagesOrg = {
	__typename?: "StatisticsStagesOrg";
	Wellness: Scalars["Int"];
	Initial: Scalars["Int"];
	Training: Scalars["Int"];
	Improvement: Scalars["Int"];
	Higher: Scalars["Int"];
	All: Scalars["Int"];
	AdditionalEducation: Scalars["Int"];
	OrgType: Scalars["ID"];
	Department?: Maybe<Scalars["String"]>;
};

export type StatisticsStagesSport = {
	__typename?: "StatisticsStagesSport";
	Wellness: Scalars["Int"];
	Initial: Scalars["Int"];
	Training: Scalars["Int"];
	Improvement: Scalars["Int"];
	Higher: Scalars["Int"];
	All: Scalars["Int"];
	AdditionalEducation: Scalars["Int"];
	SportId: Scalars["ID"];
	SportName: Scalars["String"];
	SportCode: Scalars["String"];
};

export type Ticket = {
	__typename?: "Ticket";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	Status: ETicketStatus;
	ParticipationType?: Maybe<EParticipationType>;
	Deadline: Scalars["String"];
	Comment?: Maybe<Scalars["String"]>;
	Purpose: Scalars["String"];
	OwnerId: Scalars["ID"];
	ApplicantId: Scalars["ID"];
	AssigneeId: Scalars["ID"];
	EventId?: Maybe<Scalars["ID"]>;
	MutationStatus?: Maybe<EClientMutationStatus>;
	Data?: Maybe<TicketData>;
	Owner?: Maybe<Profile>;
	Applicant?: Maybe<Profile>;
	Assignee?: Maybe<Profile>;
	Event?: Maybe<Event>;
	LastChanges?: Maybe<TicketHistory>;
	SubjectProfile?: Maybe<Profile>;
	Sport?: Maybe<Sport>;
};

export type TicketArgsInput = {
	Id?: Maybe<Scalars["ID"]>;
	Relation?: Maybe<ETicketRelation>;
	PartialId?: Maybe<Scalars["String"]>;
	ParticipationType?: Maybe<Array<EParticipationType>>;
	EventId?: Maybe<Scalars["ID"]>;
	Status?: Maybe<Array<ETicketStatus>>;
	PersonType?: Maybe<Array<EPersonType>>;
	OrganizationType?: Maybe<Array<EOrganizationType>>;
	CreatedFrom?: Maybe<Scalars["DateTime"]>;
	CreatedTo?: Maybe<Scalars["DateTime"]>;
	Limit?: Maybe<Scalars["Float"]>;
	Offset?: Maybe<Scalars["Float"]>;
	OrderField?: Maybe<ETicketOrderField>;
	OrderDirection?: Maybe<EOrderDirection>;
};

export type TicketData = {
	__typename?: "TicketData";
	SubjectProfileId?: Maybe<Scalars["String"]>;
	ProfileSportId?: Maybe<Scalars["String"]>;
};

export type TicketDataInput = {
	SubjectProfileId?: Maybe<Scalars["String"]>;
	ProfileSportId?: Maybe<Scalars["String"]>;
};

export type TicketHistory = {
	__typename?: "TicketHistory";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	OldStatus?: Maybe<ETicketStatus>;
	OldDeadline?: Maybe<Scalars["String"]>;
	OldComment?: Maybe<Scalars["String"]>;
	OldAssigneeId?: Maybe<Scalars["ID"]>;
	TicketId: Scalars["ID"];
	MutationStatus?: Maybe<EClientMutationStatus>;
	OldData?: Maybe<TicketData>;
	OldAssignee?: Maybe<Profile>;
};

export type Track = {
	__typename?: "Track";
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	UpdatedAt: Scalars["DateTime"];
	EventId: Scalars["ID"];
	Status: ETrackStatus;
	Counts: Scalars["Boolean"];
	MutationStatus?: Maybe<EClientMutationStatus>;
	Logs: Array<Log>;
};

export type UpdateAttachmentResult = {
	__typename?: "UpdateAttachmentResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpdateAttachmentResultData>;
};

export type UpdateAttachmentResultData = {
	__typename?: "UpdateAttachmentResultData";
	Attachment: Attachment;
};

export type UpdateContactGroupInput = {
	GroupName: Scalars["String"];
	ContactIds?: Maybe<Array<Scalars["Float"]>>;
	NewName?: Maybe<Scalars["String"]>;
};

export type UpdateProfilePasswordResult = {
	__typename?: "UpdateProfilePasswordResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpdateProfilePasswordResultData>;
};

export type UpdateProfilePasswordResultData = {
	__typename?: "UpdateProfilePasswordResultData";
	ConnectPassword: Scalars["String"];
};

export type UpdateProfileResult = {
	__typename?: "UpdateProfileResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<UpdateProfileResultData>;
};

export type UpdateProfileResultData = {
	__typename?: "UpdateProfileResultData";
	profile: Profile;
};

export type UpdatePushTokenResult = {
	__typename?: "UpdatePushTokenResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
};

export type UpdatePushTokensInput = {
	AndroidPushToken?: Maybe<Scalars["String"]>;
	IosPushToken?: Maybe<Scalars["String"]>;
	FcmPushToken?: Maybe<Scalars["String"]>;
	Timezone?: Maybe<Scalars["Float"]>;
};

export type UpsertContactInput = {
	Id?: Maybe<Scalars["ID"]>;
	Type: EContactType;
	Status?: Maybe<EContactStatus>;
	GroupNames?: Maybe<Array<Scalars["String"]>>;
	Comment?: Maybe<Scalars["String"]>;
	Name?: Maybe<Scalars["String"]>;
	Phone?: Maybe<Scalars["String"]>;
	Snils?: Maybe<Scalars["String"]>;
	SportTrainingStage?: Maybe<EProfileSportTrainingStage>;
	SportTrainingProgramm?: Maybe<ESportTrainingProgramm>;
	PartTime?: Maybe<Scalars["Boolean"]>;
	Data?: Maybe<ContactCoachDataInput>;
	ProfileId?: Maybe<Scalars["ID"]>;
	SportId?: Maybe<Scalars["ID"]>;
};

export type UpsertContactResult = {
	__typename?: "UpsertContactResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertContactResultData>;
};

export type UpsertContactResultData = {
	__typename?: "UpsertContactResultData";
	Contact: Contact;
};

export type UpsertEventFollowerInput = {
	EventId: Scalars["ID"];
	Notifications: Scalars["Boolean"];
};

export type UpsertEventFollowerResult = {
	__typename?: "UpsertEventFollowerResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertEventFollowerResultData>;
};

export type UpsertEventFollowerResultData = {
	__typename?: "UpsertEventFollowerResultData";
	Follower: EventFollower;
};

export type UpsertEventInput = {
	Id: Scalars["ID"];
	Status: EEventStatus;
	Type: EEventType;
	AccessType: EEventAccessType;
	Name: Scalars["String"];
	StartTime: Scalars["DateTime"];
	EndTime: Scalars["DateTime"];
	Duration: Scalars["Float"];
	Recurrent?: Maybe<Scalars["Boolean"]>;
	Day?: Maybe<EWeekDays>;
	StartAt?: Maybe<Scalars["String"]>;
	OtherSport?: Maybe<Scalars["String"]>;
	SportId?: Maybe<Scalars["ID"]>;
	DisciplineId?: Maybe<Scalars["ID"]>;
	MaxParticipants?: Maybe<Scalars["Float"]>;
	Coach1Id?: Maybe<Scalars["ID"]>;
	ShowOwner?: Maybe<Scalars["Boolean"]>;
	Organizers?: Maybe<Scalars["String"]>;
	Address?: Maybe<AddressInput>;
	ProfilePlaceId?: Maybe<Scalars["ID"]>;
	Difficulty?: Maybe<EEventDifficulty>;
	Cost?: Maybe<Scalars["Float"]>;
	Comment?: Maybe<Scalars["String"]>;
	Badges?: Maybe<BadgesInput>;
	Contacts?: Maybe<Array<ContactInfoInput>>;
	MetricName?: Maybe<Scalars["String"]>;
	MetricValue?: Maybe<Scalars["Float"]>;
	ParticipantContactId?: Maybe<Array<Scalars["ID"]>>;
};

export type UpsertEventObjectReviewResult = {
	__typename?: "UpsertEventObjectReviewResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<EventObjectReview>;
};

export type UpsertEventParticipantInput = {
	EventId: Scalars["ID"];
	StartTime: Scalars["DateTime"];
	ContactIds?: Maybe<Array<Scalars["ID"]>>;
	Comment?: Maybe<Scalars["String"]>;
};

export type UpsertEventParticipantResult = {
	__typename?: "UpsertEventParticipantResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertEventParticipantResultData>;
};

export type UpsertEventParticipantResultData = {
	__typename?: "UpsertEventParticipantResultData";
	EventParticipants: Array<EventParticipant>;
};

export type UpsertEventResult = {
	__typename?: "UpsertEventResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertEventResultData>;
};

export type UpsertEventResultData = {
	__typename?: "UpsertEventResultData";
	Event: Event;
};

export type UpsertEventReviewInput = {
	Id: Scalars["ID"];
	Rating: Scalars["Float"];
	Comment?: Maybe<Scalars["String"]>;
	GoodComment?: Maybe<Scalars["String"]>;
	BadComment?: Maybe<Scalars["String"]>;
	EventId: Scalars["ID"];
};

export type UpsertLogInput = {
	Id: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	TrackId: Scalars["ID"];
	MetricTypeId: Scalars["ID"];
	Value: Scalars["Float"];
	Repeats: Scalars["Float"];
	TextValue: Scalars["String"];
};

export type UpsertLogResult = {
	__typename?: "UpsertLogResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertLogResultData>;
};

export type UpsertLogResultData = {
	__typename?: "UpsertLogResultData";
	Log: Log;
};

export type UpsertOrganizationResult = {
	__typename?: "UpsertOrganizationResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertOrganizationResultData>;
};

export type UpsertOrganizationResultData = {
	__typename?: "UpsertOrganizationResultData";
	organization: Organization;
};

export type UpsertPersonResult = {
	__typename?: "UpsertPersonResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertPersonResultData>;
};

export type UpsertPersonResultData = {
	__typename?: "UpsertPersonResultData";
	person: Person;
};

export type UpsertProfileDataBcReviewInput = {
	Rating: Scalars["Float"];
	Phone: Scalars["String"];
	Advantages?: Maybe<Scalars["String"]>;
	Disadvantages?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
};

export type UpsertProfileDataTicketsInput = {
	Enabled?: Maybe<Scalars["Boolean"]>;
	Contact?: Maybe<Scalars["String"]>;
	URL?: Maybe<Scalars["String"]>;
};

export type UpsertProfileInput = {
	Id?: Maybe<Scalars["ID"]>;
	LoginProfileType?: Maybe<ELoginProfileType>;
	Sport?: Maybe<Array<ProfileSportData>>;
	Address?: Maybe<ProfileAddressData>;
	ContactInfo?: Maybe<Array<ContactInfoInput>>;
	Person?: Maybe<PersonData>;
	PrimaryRegionId?: Maybe<Scalars["ID"]>;
	SecondaryRegionId?: Maybe<Scalars["ID"]>;
	Organization?: Maybe<OrganizationInput>;
	WorkingHours?: Maybe<Array<ProfileWorkingHoursData>>;
	Education?: Maybe<Array<ProfileEducationData>>;
	Achievements?: Maybe<Array<ProfileAchievementInput>>;
};

export type UpsertProfilePhoneBookInput = {
	Phone: Scalars["String"];
	Name: Scalars["String"];
};

export type UpsertProfilePlaceInput = {
	Id?: Maybe<Scalars["ID"]>;
	Name?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
	IsPublic?: Maybe<Scalars["Boolean"]>;
	Deleted?: Maybe<Scalars["Boolean"]>;
	Favorite?: Maybe<Scalars["Boolean"]>;
	Address?: Maybe<PlaceAddressData>;
};

export type UpsertProfilePlaceResult = {
	__typename?: "UpsertProfilePlaceResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertProfilePlaceResultData>;
};

export type UpsertProfilePlaceResultData = {
	__typename?: "UpsertProfilePlaceResultData";
	profilePlace: ProfilePlace;
};

export type UpsertProfileReviewInput = {
	Id: Scalars["ID"];
	Rating: Scalars["Float"];
	Comment?: Maybe<Scalars["String"]>;
	GoodComment?: Maybe<Scalars["String"]>;
	BadComment?: Maybe<Scalars["String"]>;
	ProfileId: Scalars["ID"];
};

export type UpsertProfileReviewResult = {
	__typename?: "UpsertProfileReviewResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<ProfileReview>;
};

export type UpsertTicketInput = {
	Id: Scalars["ID"];
	Status?: Maybe<ETicketStatus>;
	ParticipationType?: Maybe<EParticipationType>;
	Deadline?: Maybe<Scalars["String"]>;
	Comment?: Maybe<Scalars["String"]>;
	Purpose?: Maybe<Scalars["String"]>;
	OwnerId?: Maybe<Scalars["ID"]>;
	AssigneeId?: Maybe<Scalars["ID"]>;
	EventId?: Maybe<Scalars["ID"]>;
	Data?: Maybe<TicketDataInput>;
};

export type UpsertTicketResult = {
	__typename?: "UpsertTicketResult";
	success: Scalars["Boolean"];
	errorCode?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertTicketResultData>;
};

export type UpsertTicketResultData = {
	__typename?: "UpsertTicketResultData";
	ticket: Ticket;
};

export type UpsertTrackInput = {
	Id: Scalars["ID"];
	EventId: Scalars["ID"];
	CreatedAt: Scalars["DateTime"];
	Status: ETrackStatus;
	Counts: Scalars["Boolean"];
};

export type UpsertTrackResult = {
	__typename?: "UpsertTrackResult";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	data?: Maybe<UpsertTrackResultData>;
};

export type UpsertTrackResultData = {
	__typename?: "UpsertTrackResultData";
	Track: Track;
};
