import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { EducationOrganizationRecFieldsFragment } from "../fragment/EducationOrganization.generated";
import { EducationOrganizationRecFieldsFragmentDoc } from "../fragment/EducationOrganization.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Ui_EducationOrganizationsQueryVariables = Types.Exact<{
	input: Types.EducationOrganizationInput;
}>;

export type Ui_EducationOrganizationsQuery = { __typename?: "Query" } & {
	EducationOrganizations: Array<{ __typename?: "EducationOrganization" } & EducationOrganizationRecFieldsFragment>;
};

export const Ui_EducationOrganizationsDocument = gql`
	query UI_EducationOrganizations($input: EducationOrganizationInput!) {
		EducationOrganizations(input: $input) {
			...EducationOrganizationRecFields
		}
	}
	${EducationOrganizationRecFieldsFragmentDoc}
`;
export type Ui_EducationOrganizationsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables
	>,
	"query"
> &
	({ variables: Ui_EducationOrganizationsQueryVariables; skip?: boolean } | { skip: boolean });

export const Ui_EducationOrganizationsComponent = (props: Ui_EducationOrganizationsComponentProps) => (
	<ApolloReactComponents.Query<Ui_EducationOrganizationsQuery, Ui_EducationOrganizationsQueryVariables>
		query={Ui_EducationOrganizationsDocument}
		{...props}
	/>
);

export type Ui_EducationOrganizationsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables
	>;
} &
	TChildProps;
export function withUi_EducationOrganizations<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables,
		Ui_EducationOrganizationsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables,
		Ui_EducationOrganizationsProps<TChildProps, TDataName>
	>(Ui_EducationOrganizationsDocument, {
		alias: "uiEducationOrganizations",
		...operationOptions
	});
}

/**
 * __useUi_EducationOrganizationsQuery__
 *
 * To run a query within a React component, call `useUi_EducationOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUi_EducationOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUi_EducationOrganizationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUi_EducationOrganizationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<Ui_EducationOrganizationsQuery, Ui_EducationOrganizationsQueryVariables>(
		Ui_EducationOrganizationsDocument,
		baseOptions
	);
}
export function useUi_EducationOrganizationsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		Ui_EducationOrganizationsQuery,
		Ui_EducationOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<Ui_EducationOrganizationsQuery, Ui_EducationOrganizationsQueryVariables>(
		Ui_EducationOrganizationsDocument,
		baseOptions
	);
}
export type Ui_EducationOrganizationsQueryHookResult = ReturnType<typeof useUi_EducationOrganizationsQuery>;
export type Ui_EducationOrganizationsLazyQueryHookResult = ReturnType<typeof useUi_EducationOrganizationsLazyQuery>;
export type Ui_EducationOrganizationsQueryResult = ApolloReactCommon.QueryResult<
	Ui_EducationOrganizationsQuery,
	Ui_EducationOrganizationsQueryVariables
>;
