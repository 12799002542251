import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsProfileFillFullFragment } from "../fragment/StatisticsProfileFill.generated";
import { StatisticsProfileFillFullFragmentDoc } from "../fragment/StatisticsProfileFill.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsProfileFillQueryVariables = Types.Exact<{
	input: Types.StatisticsProfileFillInput;
}>;

export type StatisticsProfileFillQuery = { __typename?: "Query" } & {
	StatisticsProfileFill: { __typename?: "StatisticsProfileFill" } & StatisticsProfileFillFullFragment;
};

export const StatisticsProfileFillDocument = gql`
	query StatisticsProfileFill($input: StatisticsProfileFillInput!) {
		StatisticsProfileFill(input: $input) {
			...StatisticsProfileFillFull
		}
	}
	${StatisticsProfileFillFullFragmentDoc}
`;
export type StatisticsProfileFillComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>,
	"query"
> &
	({ variables: StatisticsProfileFillQueryVariables; skip?: boolean } | { skip: boolean });

export const StatisticsProfileFillComponent = (props: StatisticsProfileFillComponentProps) => (
	<ApolloReactComponents.Query<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>
		query={StatisticsProfileFillDocument}
		{...props}
	/>
);

export type StatisticsProfileFillProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>;
} &
	TChildProps;
export function withStatisticsProfileFill<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsProfileFillQuery,
		StatisticsProfileFillQueryVariables,
		StatisticsProfileFillProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsProfileFillQuery,
		StatisticsProfileFillQueryVariables,
		StatisticsProfileFillProps<TChildProps, TDataName>
	>(StatisticsProfileFillDocument, {
		alias: "statisticsProfileFill",
		...operationOptions
	});
}

/**
 * __useStatisticsProfileFillQuery__
 *
 * To run a query within a React component, call `useStatisticsProfileFillQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsProfileFillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsProfileFillQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStatisticsProfileFillQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>
) {
	return ApolloReactHooks.useQuery<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>(
		StatisticsProfileFillDocument,
		baseOptions
	);
}
export function useStatisticsProfileFillLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<StatisticsProfileFillQuery, StatisticsProfileFillQueryVariables>(
		StatisticsProfileFillDocument,
		baseOptions
	);
}
export type StatisticsProfileFillQueryHookResult = ReturnType<typeof useStatisticsProfileFillQuery>;
export type StatisticsProfileFillLazyQueryHookResult = ReturnType<typeof useStatisticsProfileFillLazyQuery>;
export type StatisticsProfileFillQueryResult = ApolloReactCommon.QueryResult<
	StatisticsProfileFillQuery,
	StatisticsProfileFillQueryVariables
>;
