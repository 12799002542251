import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { ProfilePlaceFullFragment } from "../fragment/ProfilePlace.generated";
import { ProfilePlaceFullFragmentDoc } from "../fragment/ProfilePlace.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertProfilePlaceMutationVariables = Types.Exact<{
	data: Types.UpsertProfilePlaceInput;
}>;

export type UpsertProfilePlaceMutation = { __typename?: "Mutation" } & {
	upsertProfilePlace: { __typename?: "UpsertProfilePlaceResult" } & Pick<
		Types.UpsertProfilePlaceResult,
		"success" | "message"
	> & {
			data?: Types.Maybe<
				{ __typename?: "UpsertProfilePlaceResultData" } & {
					profilePlace: { __typename?: "ProfilePlace" } & ProfilePlaceFullFragment;
				}
			>;
		};
};

export const UpsertProfilePlaceDocument = gql`
	mutation upsertProfilePlace($data: UpsertProfilePlaceInput!) {
		upsertProfilePlace(data: $data) {
			success
			message
			data {
				profilePlace {
					...ProfilePlaceFull
				}
			}
		}
	}
	${ProfilePlaceFullFragmentDoc}
`;
export type UpsertProfilePlaceMutationFn = ApolloReactCommon.MutationFunction<
	UpsertProfilePlaceMutation,
	UpsertProfilePlaceMutationVariables
>;
export type UpsertProfilePlaceComponentProps = Omit<
	ApolloReactComponents.MutationComponentOptions<UpsertProfilePlaceMutation, UpsertProfilePlaceMutationVariables>,
	"mutation"
>;

export const UpsertProfilePlaceComponent = (props: UpsertProfilePlaceComponentProps) => (
	<ApolloReactComponents.Mutation<UpsertProfilePlaceMutation, UpsertProfilePlaceMutationVariables>
		mutation={UpsertProfilePlaceDocument}
		{...props}
	/>
);

export type UpsertProfilePlaceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
	[key in TDataName]: ApolloReactCommon.MutationFunction<
		UpsertProfilePlaceMutation,
		UpsertProfilePlaceMutationVariables
	>;
} &
	TChildProps;
export function withUpsertProfilePlace<TProps, TChildProps = {}, TDataName extends string = "mutate">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		UpsertProfilePlaceMutation,
		UpsertProfilePlaceMutationVariables,
		UpsertProfilePlaceProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withMutation<
		TProps,
		UpsertProfilePlaceMutation,
		UpsertProfilePlaceMutationVariables,
		UpsertProfilePlaceProps<TChildProps, TDataName>
	>(UpsertProfilePlaceDocument, {
		alias: "upsertProfilePlace",
		...operationOptions
	});
}

/**
 * __useUpsertProfilePlaceMutation__
 *
 * To run a mutation, you first call `useUpsertProfilePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProfilePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProfilePlaceMutation, { data, loading, error }] = useUpsertProfilePlaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProfilePlaceMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertProfilePlaceMutation, UpsertProfilePlaceMutationVariables>
) {
	return ApolloReactHooks.useMutation<UpsertProfilePlaceMutation, UpsertProfilePlaceMutationVariables>(
		UpsertProfilePlaceDocument,
		baseOptions
	);
}
export type UpsertProfilePlaceMutationHookResult = ReturnType<typeof useUpsertProfilePlaceMutation>;
export type UpsertProfilePlaceMutationResult = ApolloReactCommon.MutationResult<UpsertProfilePlaceMutation>;
export type UpsertProfilePlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
	UpsertProfilePlaceMutation,
	UpsertProfilePlaceMutationVariables
>;
