import * as Types from "../types";

import gql from "graphql-tag";
import { ProfileForAvatarFragment } from "./Profile.generated";
import { ProfileForAvatarFragmentDoc } from "./Profile.generated";

export type ContactForAvatarFragment = { __typename?: "Contact" } & Pick<
	Types.Contact,
	"Id" | "Type" | "Comment" | "Name" | "Phone"
> & {
		Data?: Types.Maybe<{ __typename?: "ContactCoachData" } & Pick<Types.ContactCoachData, "Position">>;
		Profile?: Types.Maybe<{ __typename?: "Profile" } & ProfileForAvatarFragment>;
	};

export type ContactFullFragment = { __typename?: "Contact" } & Pick<
	Types.Contact,
	| "Id"
	| "CreatedAt"
	| "UpdatedAt"
	| "Status"
	| "Type"
	| "Comment"
	| "Name"
	| "Phone"
	| "Snils"
	| "Password"
	| "MutationStatus"
	| "PartTime"
> & {
		Data?: Types.Maybe<{ __typename?: "ContactCoachData" } & Pick<Types.ContactCoachData, "Position">>;
		Profile?: Types.Maybe<{ __typename?: "Profile" } & ProfileForAvatarFragment>;
	};

export const ContactForAvatarFragmentDoc = gql`
	fragment ContactForAvatar on Contact {
		Id
		Type
		Comment
		Name
		Phone
		Data {
			Position
		}
		Profile {
			...ProfileForAvatar
		}
	}
	${ProfileForAvatarFragmentDoc}
`;
export const ContactFullFragmentDoc = gql`
	fragment ContactFull on Contact {
		Id
		CreatedAt
		UpdatedAt
		Status
		Type
		Comment
		Name
		Phone
		Snils
		Password
		MutationStatus
		PartTime
		Data {
			Position
		}
		Profile {
			...ProfileForAvatar
		}
	}
	${ProfileForAvatarFragmentDoc}
`;
