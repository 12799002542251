import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
import { StatisticsSportRanksOrgFullFragment } from "../fragment/StatisticsSportRanksOrgFull.generated";
import { StatisticsSportRanksOrgFullFragmentDoc } from "../fragment/StatisticsSportRanksOrgFull.generated";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatisticsSportRanksByOrganizationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StatisticsSportRanksByOrganizationsQuery = { __typename?: "Query" } & {
	StatisticsSportRanksByOrganizations?: Types.Maybe<
		Array<{ __typename?: "StatisticsSportRanksOrg" } & StatisticsSportRanksOrgFullFragment>
	>;
};

export const StatisticsSportRanksByOrganizationsDocument = gql`
	query StatisticsSportRanksByOrganizations {
		StatisticsSportRanksByOrganizations {
			...StatisticsSportRanksOrgFull
		}
	}
	${StatisticsSportRanksOrgFullFragmentDoc}
`;
export type StatisticsSportRanksByOrganizationsComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>,
	"query"
>;

export const StatisticsSportRanksByOrganizationsComponent = (
	props: StatisticsSportRanksByOrganizationsComponentProps
) => (
	<ApolloReactComponents.Query<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>
		query={StatisticsSportRanksByOrganizationsDocument}
		{...props}
	/>
);

export type StatisticsSportRanksByOrganizationsProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>;
} &
	TChildProps;
export function withStatisticsSportRanksByOrganizations<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables,
		StatisticsSportRanksByOrganizationsProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables,
		StatisticsSportRanksByOrganizationsProps<TChildProps, TDataName>
	>(StatisticsSportRanksByOrganizationsDocument, {
		alias: "statisticsSportRanksByOrganizations",
		...operationOptions
	});
}

/**
 * __useStatisticsSportRanksByOrganizationsQuery__
 *
 * To run a query within a React component, call `useStatisticsSportRanksByOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsSportRanksByOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsSportRanksByOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsSportRanksByOrganizationsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>(StatisticsSportRanksByOrganizationsDocument, baseOptions);
}
export function useStatisticsSportRanksByOrganizationsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		StatisticsSportRanksByOrganizationsQuery,
		StatisticsSportRanksByOrganizationsQueryVariables
	>(StatisticsSportRanksByOrganizationsDocument, baseOptions);
}
export type StatisticsSportRanksByOrganizationsQueryHookResult = ReturnType<
	typeof useStatisticsSportRanksByOrganizationsQuery
>;
export type StatisticsSportRanksByOrganizationsLazyQueryHookResult = ReturnType<
	typeof useStatisticsSportRanksByOrganizationsLazyQuery
>;
export type StatisticsSportRanksByOrganizationsQueryResult = ApolloReactCommon.QueryResult<
	StatisticsSportRanksByOrganizationsQuery,
	StatisticsSportRanksByOrganizationsQueryVariables
>;
