import Box from "@material-ui/core/Box";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React from "react";

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
	return (
		<Box className="progress-with-label">
			<CircularProgress variant="static" {...props} />
			<Box className="progress">
				<Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
};

export default CircularProgressWithLabel;
