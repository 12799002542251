import * as Types from "../types";

import gql from "graphql-tag";
import { DisciplineFullFragment } from "./Discipline.generated";
import { DisciplineFullFragmentDoc } from "./Discipline.generated";

export type ProfileDisciplineFullFragment = { __typename?: "ProfileDiscipline" } & Pick<
	Types.ProfileDiscipline,
	"Id"
> & { Discipline: { __typename?: "Discipline" } & DisciplineFullFragment };

export const ProfileDisciplineFullFragmentDoc = gql`
	fragment ProfileDisciplineFull on ProfileDiscipline {
		Id
		Discipline {
			...DisciplineFull
		}
	}
	${DisciplineFullFragmentDoc}
`;
