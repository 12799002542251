import {
	EAchievementEventLevel,
	EAchievementEventType,
	ECoachRank,
	EEducationMode,
	EEducationType,
	EEventAccessType,
	EGender,
	EMedal,
	EOrganizationType,
	EPersonType,
	EProfileMaritalStatus,
	EProfileSportTrainingStage,
	ERefereeRank,
	ESportRank,
	EWeekDays
} from "../graphql/types";
import { EContactInfoType } from "../graphql/types";

export const PersonTypeNames: { [key: string]: string } = {
	[EPersonType.Sportsman]: "Спортсмен",
	[EPersonType.Child]: "Ребенок",
	[EPersonType.Coach]: "Тренер"
};

export const OrganizationTypeList: { [key: string]: string } = {
	[EOrganizationType.School]: "СШ",
	[EOrganizationType.ChildrenSchool]: "ДЮСШ",
	[EOrganizationType.OlympicSchool]: "СШОР",
	[EOrganizationType.OlympicChildrenSchool]: "СДЮСШОР",
	[EOrganizationType.OlympicCollege]: "УОР",
	[EOrganizationType.OlympicCenter]: "ЦОП",
	[EOrganizationType.Center]: "ЦСП",
	[EOrganizationType.Complex]: "Спортивный комплекс",
	[EOrganizationType.Club]: "Спортивный клуб",
	[EOrganizationType.Other]: "Другое",
	[EOrganizationType.Ministry]: "Министерство спорта",
	[EOrganizationType.Federation]: "Спортивная федерация",
	[EOrganizationType.SportObject]: "Объект спорта"
};

export const EduTypes = [
	EOrganizationType.School,
	EOrganizationType.ChildrenSchool,
	EOrganizationType.OlympicSchool,
	EOrganizationType.OlympicChildrenSchool,
	EOrganizationType.OlympicCollege,
	EOrganizationType.OlympicCenter,
	EOrganizationType.Center
];

export const GenderList: { [key: string]: string } = {
	[EGender.Male]: "мужской",
	[EGender.Female]: "женский",
	[EGender.Undefined]: "не указан"
};

export const MaritalStatusList: { [key: string]: string } = {
	[EProfileMaritalStatus.Unknown]: "Не указано",
	[EProfileMaritalStatus.Married]: "Женат / замужем",
	[EProfileMaritalStatus.Divorced]: "Не женат / не замужем"
};

export const SportRankList: { [key: string]: string } = {
	[ESportRank.InternationalSportsMaster]: "Мастер спорта России международного класса",
	[ESportRank.SportsMasterVeteran]: "Заслуженный мастер спорта России",
	[ESportRank.SportsMaster]: "Мастер спорта России",
	[ESportRank.ChessRank]: "Гроссмейстер России",
	[ESportRank.SportsMasterCandidate]: "Кандидат в мастера спорта",
	[ESportRank.FirstRank]: "Первый спортивный разряд",
	[ESportRank.SecondRank]: "Второй спортивный разряд",
	[ESportRank.ThirdRank]: "Третий спортивный разряд",
	[ESportRank.FirstJuniorRank]: "Первый юношеский спортивный разряд",
	[ESportRank.SecondJuniorRank]: "Второй юношеский спортивный разряд",
	[ESportRank.ThirdJuniorRank]: "Третий юношеский спортивный разряд"
};

export const shortSportRankList: { [key: string]: string } = {
	[ESportRank.InternationalSportsMaster]: "МСМК",
	[ESportRank.SportsMasterVeteran]: "ЗМС",
	[ESportRank.SportsMaster]: "МС",
	[ESportRank.SportsMasterCandidate]: "КМС",
	[ESportRank.FirstRank]: "1 разряд"
};

export const RefereeRankList: { [key: string]: string } = {
	[ERefereeRank.HonoraryRefereeRussian]: "Почетный спортивный судья России",
	[ERefereeRank.RefereeAllRussianCategory]: "Спортивный судья всероссийской категории",
	[ERefereeRank.FirstRank]: "Спортивный судья первой категории",
	[ERefereeRank.SecondRank]: "Спортивный судья второй категории",
	[ERefereeRank.ThirdRank]: "Спортивный судья третьей категории",
	[ERefereeRank.YoungReferee]: "Юный спортивный судья"
};

export const CoachRankList: { [key: string]: string } = {
	[ECoachRank.CoachVeteran]: "Заслуженный тренер России",
	[ECoachRank.HighestRank]: "Тренер высшей квалификационной категории",
	[ECoachRank.FirstRank]: "Тренер первой квалификационной категории",
	[ECoachRank.SecondRank]: "Тренер второй квалификационной категории"
};

export const weekDays = {
	[EWeekDays.Monday]: "Понедельник",
	[EWeekDays.Tuesday]: "Вторник",
	[EWeekDays.Wednesday]: "Среда",
	[EWeekDays.Thursday]: "Четверг",
	[EWeekDays.Friday]: "Пятница",
	[EWeekDays.Saturday]: "Суббота",
	[EWeekDays.Sunday]: "Воскресенье"
};

export const weekDaysShort = {
	[EWeekDays.Monday]: "Пн",
	[EWeekDays.Tuesday]: "Вт",
	[EWeekDays.Wednesday]: "Ср",
	[EWeekDays.Thursday]: "Чт",
	[EWeekDays.Friday]: "Пт",
	[EWeekDays.Saturday]: "Сб",
	[EWeekDays.Sunday]: "Вс"
};

export const typesEvent = {
	[EEventAccessType.Closed]: "По приглашению",
	[EEventAccessType.Open]: "Открытое",
	[EEventAccessType.Approve]: "По записи",
	[EEventAccessType.Individual]: ""
};

export interface IListAvailableContactInfoType {
	mask?: string;
	iconName: string;
	title: string;
	pattern: RegExp;
	errorMessage: string;
	placeholder?: string;
}

export const ContactTypes: { [key: string]: IListAvailableContactInfoType } = {
	[EContactInfoType.Phone]: {
		mask: "+7 (999) 999 99 99",
		pattern: /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/,
		iconName: "icon-sity-phone",
		title: "Городской телефон",
		errorMessage: "Используйте цифры (0-9) и символы «+,(,)»"
	},
	[EContactInfoType.CellPhone]: {
		mask: "+7 (999) 999 99 99",
		pattern: /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/,
		iconName: "phone-disabled",
		title: "Мобильный телефон",
		errorMessage: "Используйте цифры (0-9) и символы «+,(,)»"
	},
	[EContactInfoType.Viber]: {
		mask: "+7 (999) 999 99 99",
		pattern: /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/,
		iconName: "icon-viber",
		title: "Viber",
		errorMessage: "Используйте цифры (0-9) и символы «+,(,)»"
	},
	[EContactInfoType.Email]: {
		pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
		placeholder: "example@mail.com",
		iconName: "email-disabled",
		title: "Е-mail",
		errorMessage: "Используйте цифры (0-9), и латинские символы (A-Z, @)"
	},
	[EContactInfoType.WhatsApp]: {
		mask: "+7 (999) 999 99 99",
		pattern: /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/,
		iconName: "icon-whatsapp",
		title: "WhatsApp",
		errorMessage: "Используйте цифры (0-9) и символы «+,(,)»"
	},
	[EContactInfoType.Url]: {
		pattern: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/,
		placeholder: "example.com",
		iconName: "website-disabled",
		title: "Web-сайт",
		errorMessage: "Используйте цифры (0-9), и символы (A-Z, А-Я)"
	},
	[EContactInfoType.Icq]: {
		pattern: /^[0-9]+$/,
		placeholder: "12345678",
		iconName: "icon-icq",
		title: "ICQ",
		errorMessage: "Используйте цифры (0-9)"
	},
	[EContactInfoType.Telegram]: {
		pattern: /^(@)([a-zA-Z0-9\-\.]{5,})+|(\+7|7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
		placeholder: "@telegramId или +71234567890",
		iconName: "icon-telegramm",
		title: "Telegram",
		errorMessage: "Введите ID или телефон"
	},
	[EContactInfoType.Vk]: {
		pattern: /^[a-zA-Z0-9\-\._]+$|^(?:http(s)?:\/\/)?(www\.)?(vk\.com\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		placeholder: "VK ID или адрес",
		iconName: "icon-vk",
		title: "Вконтакте",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	},
	[EContactInfoType.Facebook]: {
		pattern: /^[a-zA-Z0-9\-\._]+$|^(?:http(s)?:\/\/)?(www\.)?(facebook\.com\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\\+,;=.]+$/,
		placeholder: "Fb ID или адрес",
		iconName: "icon-facebook",
		title: "Facebook",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	},
	[EContactInfoType.Instagramm]: {
		pattern: /^[a-zA-Z0-9\-\._]+$|^(?:http(s)?:\/\/)?(www\.)?(instagram\.com\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		placeholder: "адрес в инстаграмм",
		iconName: "icon-instagramm",
		title: "Instagram",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	},
	[EContactInfoType.Od]: {
		pattern: /^[a-zA-Z0-9\-\._]+$|^(?:http(s)?:\/\/)?(www\.)?(ok\.ru\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		placeholder: "OK ID или адрес",
		iconName: "icon-ok",
		title: "Одноклассники",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	},
	[EContactInfoType.Twitter]: {
		pattern: /^[a-zA-Z0-9\-\._]+$|^(?:http(s)?:\/\/)?(www\.)?(twitter\.com\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		placeholder: "twitter.com/ + id в твиттере",
		iconName: "icon-twitter",
		title: "Twitter",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	},
	[EContactInfoType.Skype]: {
		pattern: /^[a-zA-Z][a-zA-Z0-9_\-,.]{5,31}$/,
		placeholder: "Skype",
		iconName: "icon-skype",
		title: "Skype",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z)"
	},
	[EContactInfoType.YouTube]: {
		pattern: /^(?:http(s)?:\/\/)?(www\.)?(youtube\.com\/)[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		placeholder: "адрес должен содержать youtube.com",
		iconName: "icon-youtube",
		title: "YouTube",
		errorMessage: "Используйте цифры (0-9) латинские буквы (A-Z) и символы"
	}
};

export const AchievementEventTypeList: { [key: string]: string } = {
	[EAchievementEventType.Championship]: "Чемпионат",
	[EAchievementEventType.Cup]: "Кубок",
	[EAchievementEventType.Primacy]: "Первенство",
	[EAchievementEventType.Qualifying]: "Отборочное",
	[EAchievementEventType.Friendly]: "Товарищеское",
	[EAchievementEventType.RelayRace]: "Эстафета",
	[EAchievementEventType.MatchMeeting]: "Матчевая встреча",
	[EAchievementEventType.Qualification]: "Квалификационное",
	[EAchievementEventType.Classification]: "Классификационное"
};

export const AchievementEventLevelList: { [key: string]: string } = {
	[EAchievementEventLevel.City]: "Городской",
	[EAchievementEventLevel.Regional]: "Региональный",
	[EAchievementEventLevel.International]: "Международный",
	[EAchievementEventLevel.Olympiad]: "Олимпиада"
};

export const EducationTypeList: { [key: string]: string } = {
	[EEducationType.PrimaryGeneralEducation]: "Начальное общее образование",
	[EEducationType.BasicGeneralEducation]: "Основное общее образование",
	[EEducationType.SecondaryGeneralEducation]: "Среднее общее образование",
	[EEducationType.PrimaryVocationalEducation]: "Начальное профессиональное образование",
	[EEducationType.SecondaryVocationalEducation]: "Среднее профессиональное образование",
	[EEducationType.PostgraduatVocationalEducation]: "Послевузовское профессиональное образование",
	[EEducationType.HigherEducationBachelor]: "Высшее образование бакалавриат",
	[EEducationType.HigherEducationSpecialist]: "Высшее образование специалист",
	[EEducationType.HigherEducationMasters]: "Высшее образование магистратура",
	[EEducationType.Retraining]: "Дополнительное образование",
	[EEducationType.Training]: "Профессиональное обучение"
};

export const FormStudyList: { [key: string]: string } = {
	[EEducationMode.FullTimeEducation]: "Очная",
	[EEducationMode.PartTimeEducation]: "Заочная",
	[EEducationMode.DistanceEducation]: "Дистанционное",
	[EEducationMode.FullPartTimeEducation]: "Очно-заочная",
	[EEducationMode.Externate]: "Экстернат"
};

export const SportTrainingStageList: { [key: string]: string } = {
	[EProfileSportTrainingStage.Wellness]: "Cпортивно-оздоровительный этап",
	[EProfileSportTrainingStage.Initial]: "Этап начальной подготовки",
	[EProfileSportTrainingStage.Training]: "Тренировочный этап (этап спортивной специализации)",
	[EProfileSportTrainingStage.Improvement]: "Этап совершенствования спортивного мастерства",
	[EProfileSportTrainingStage.Higher]: "Этап высшего спортивного мастерства"
};

export const MedalList: { [key: string]: string } = {
	[EMedal.Bronse]: "Бронза",
	[EMedal.Silver]: "Серебро",
	[EMedal.Gold]: "Золото"
};

export const OrganizationTypesList: Array<keyof typeof EOrganizationType> = [
	"School",
	"ChildrenSchool",
	"Complex",
	"Club",
	"Other"
];
