import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EducationTypeList, FormStudyList } from "../../constants";
import { EEducationMode, EEducationType, ProfileEducation } from "../../graphql/types";
import { tempId } from "../../utils/utils";
import RegionSelector from "../common/RegionSelector";
import StandartDialog from "../common/StandartDialog";
import StandartTextInput from "../common/StandartTextInput";
import { TPartialProfileEducation } from "./EducationEdit";
import EducationInstituteSearch from "./EducationInstituteSearch";

interface IEducationsModalProps {
	hash: string;
	visible: boolean;
	education?: TPartialProfileEducation;
	onClose: () => void;
	addEducation: (education: TPartialProfileEducation) => void;
}

interface IValidState {
	RegionId: boolean;
	Type: boolean;
	StartYear: boolean;
	EndYear: boolean;
	IssueYear: boolean;
	EducationalInstitution: boolean;
	Specialization: boolean;
}

const defaultValdidty = {
	RegionId: true,
	Type: true,
	StartYear: true,
	EndYear: true,
	IssueYear: true,
	EducationalInstitution: true,
	Specialization: true
};

const EducationsModal = (props: IEducationsModalProps) => {
	const { hash, visible, education, onClose, addEducation } = props;

	const [educationState, setEducationState] = useState<TPartialProfileEducation>();
	const [formFieldsValid, setFormFieldsValid] = useState<IValidState>(defaultValdidty);

	useEffect(() => {
		setEducationState(education);
	}, [education]);

	useEffect(() => {
		if (visible) {
			setEducationState(education);
			setFormFieldsValid((prevState) => ({
				...prevState,
				RegionId: !!education?.RegionId?.length,
				Type: !!education?.Type?.length,
				EducationalInstitution: !!education?.EducationalInstitution?.length,
				Specialization: !!education?.Specialization?.length
			}));
		}
	}, [education, visible]);

	const changeData = (newState: Partial<ProfileEducation>, newValidState?: Partial<IValidState>) => {
		setEducationState((prevState) => {
			const nextState = { ...prevState, Id: prevState?.Id || tempId(), ...newState };
			setFormFieldsValid((prevValidState) => {
				const nextValidState = { ...prevValidState, ...newValidState };
				// год окончания должен быть >= году начала обучения
				nextValidState.EndYear =
					(nextValidState.EndYear ||
						(!!nextState.EndYear && nextState.EndYear > "1900" && nextState.EndYear < "2100")) &&
					(!nextState.EndYear ||
						!nextState.StartYear ||
						Number(nextState.EndYear) >= Number(nextState.StartYear));

				// год выпуска должен быть >= году начала обучения
				nextValidState.IssueYear =
					(nextValidState.IssueYear ||
						(!!nextState.IssueYear && nextState.IssueYear > "1900" && nextState.IssueYear < "2100")) &&
					(!nextState.IssueYear ||
						!nextState.StartYear ||
						Number(nextState.IssueYear) >= Number(nextState.StartYear));
				return nextValidState;
			});

			return nextState;
		});
	};

	if (!educationState) {
		return null;
	}

	const saveEducation = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		addEducation(educationState);
		onClose();
	};

	return (
		<StandartDialog
			visible={visible}
			onClose={onClose}
			buttons={[
				{ title: "Сохранить", disabled: Object.values(formFieldsValid).includes(false), onClick: saveEducation }
			]}
		>
			<div className="edit-education-form">
				<RegionSelector
					required
					error={!formFieldsValid.RegionId}
					title="Регион"
					hash={hash}
					onChange={(region) => changeData({ RegionId: region?.Id }, { RegionId: !!region?.Id.length })}
					selectedKey={educationState.RegionId}
				/>
				<StandartTextInput
					margin="dense"
					value={educationState.City || ""}
					onChange={(value) => changeData({ City: value })}
					label="Город (где расположено учебное заведение)"
					inputProps={{ maxLength: 150 }}
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel id="select-educationtype-label">Ступень образования *</InputLabel>
					<Select
						required
						value={educationState.Type || null}
						onChange={(event) => {
							const v = event.target.value as EEducationType;
							return changeData({ Type: v }, { Type: !!v?.length });
						}}
						labelId="select-educationtype-label"
						placeholder="Не выбрано"
						error={!educationState.Type}
					>
						{["", ...Object.keys(EducationTypeList)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{EducationTypeList[item] || "Не выбрано"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<EducationInstituteSearch
					hash={hash}
					changeText={(text) =>
						changeData({ EducationalInstitution: text }, { EducationalInstitution: !!text?.length })
					}
					regionId={educationState.RegionId}
					type={educationState.Type}
					city={educationState.City || undefined}
					name={educationState.EducationalInstitution}
				/>
				<StandartTextInput
					required
					error={!educationState.Specialization}
					margin="dense"
					value={educationState.Specialization || ""}
					onChange={(value, isValid) => changeData({ Specialization: value }, { Specialization: isValid })}
					inputProps={{ maxLength: 150 }}
					label="Специалаизация (в соответсвии с документом об образовании)"
				/>

				<FormControl fullWidth margin="normal">
					<InputLabel id="select-educationmode-label">Форма обучения</InputLabel>
					<Select
						value={educationState.Mode || null}
						onChange={(event) => changeData({ Mode: event.target.value as EEducationMode })}
						labelId="select-educationmode-label"
						placeholder="Не выбрано"
					>
						{["", ...Object.keys(FormStudyList)].map((item, index) => (
							<MenuItem key={index} value={item}>
								{FormStudyList[item] || "Не выбрано"}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<StandartTextInput
					fullWidth
					value={educationState.StartYear || ""}
					onChange={(value, isValid) => changeData({ StartYear: value }, { StartYear: isValid })}
					label="Год начала обучения"
					margin="normal"
					mask="9999"
					pattern={/19\d{2}|20\d{2}/}
					errorMessage="Год должен быть в диапазоне 1900-2099"
				/>
				<StandartTextInput
					fullWidth
					value={educationState.EndYear || ""}
					onChange={(value, isValid) => changeData({ EndYear: value }, { EndYear: isValid })}
					label="Год окончания обучения"
					margin="normal"
					mask="9999"
					pattern={/19\d{2}|20\d{2}/}
					error={!formFieldsValid.EndYear}
					errorMessage="Год должен быть в диапазоне 1900-2099. И быть не меньше года начала обучения"
				/>
				<StandartTextInput
					fullWidth
					value={educationState.IssueYear || ""}
					onChange={(value, isValid) => changeData({ IssueYear: value }, { IssueYear: isValid })}
					label="Год выпуска"
					margin="normal"
					mask="9999"
					pattern={/19\d{2}|20\d{2}/}
					error={!formFieldsValid.IssueYear}
					errorMessage="Год должен быть в диапазоне 1900-2099. И быть не меньше года начала обучения"
				/>
			</div>
		</StandartDialog>
	);
};

export default React.memo(EducationsModal);
