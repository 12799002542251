import * as Types from "../types";

import gql from "graphql-tag";

export type RegionFullFragment = { __typename?: "Region" } & Pick<
	Types.Region,
	"Id" | "Name" | "Type" | "GeoLatitude" | "GeoLongitude"
>;

export const RegionFullFragmentDoc = gql`
	fragment RegionFull on Region {
		Id
		Name
		Type
		GeoLatitude
		GeoLongitude
	}
`;
