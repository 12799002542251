import { DeepPartial } from "@athlete/utils";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import "react-responsive-modal/styles.css";
import { useSportsDataQuery } from "../../graphql/query/SportsData.generated";
import { ProfileDiscipline, ProfileSport, Sport } from "../../graphql/types";
import { isTempId } from "../../utils/utils";
import SportModalContent from "../profileEdit/SportModalContent";

type TDiscipline = Omit<ProfileDiscipline, "Id"> & { Id?: string };
interface ISportsEditProps {
	hash: string;
	profileId: string;
	isOrganization?: boolean;
	selectedSports?: Array<DeepPartial<ProfileSport>>;
	selectedDisciplines?: Array<TDiscipline>;
	setSelectedSports: React.Dispatch<React.SetStateAction<Array<DeepPartial<ProfileSport>> | undefined>>;
	setSelectedDisciplines: React.Dispatch<React.SetStateAction<Array<TProfileDiscipline> | undefined>>;
	title?: string;
	loading?: boolean;
	schoolOptions?: { AdditionalEducation?: boolean; SportProgramm?: boolean };
}

export type TProfileDiscipline = Omit<ProfileDiscipline, "Id"> & { Id?: string };

const SportsEdit: React.ComponentType<ISportsEditProps> = (props) => {
	const {
		hash,
		profileId,
		selectedSports,
		selectedDisciplines,
		setSelectedSports,
		setSelectedDisciplines,
		loading,
		isOrganization,
		title = "Список видов спорта",
		schoolOptions
	} = props;

	const [modalState, setModalState] = useState<{
		visible: boolean;
		sport?: DeepPartial<ProfileSport>;
		disciplines?: Array<TDiscipline>;
	}>({ visible: false });

	const changeDisciplines = (disciplines: Array<TDiscipline>) => {
		setSelectedDisciplines(disciplines);
		setModalState((prevState) => ({ ...prevState, disciplines }));
	};

	const { data, loading: loadingSports, error } = useSportsDataQuery({
		context: { headers: { Authorization: `Digest ${hash}` } }
	});

	const updateSportData = (sport: DeepPartial<ProfileSport>) => {
		setSelectedSports((prevState) => {
			if (sport.Sport?.Id != null) {
				const filteredSports = prevState?.filter((item) => item.Sport?.Id !== sport.Sport?.Id);
				return [...(filteredSports || []), { ...sport, ...schoolOptions }];
			}
			return [...(prevState || []), { ...sport, ...schoolOptions }];
		});
	};

	const onOptionChange = useCallback(
		(sports: Array<Sport>): void => {
			if (!sports.length) {
				return;
			}
			const sport = sports[0];

			if (sport.Id === "-1") {
				setModalState({
					visible: true,
					sport: { Sport: sport },
					disciplines: []
				});
				return;
			}
			let newSport: DeepPartial<ProfileSport> | undefined;
			const filteredSports = selectedSports?.filter((sportItem) => {
				if (sportItem.Sport?.Id !== sport?.Id) return true;
				newSport = sportItem;
				return false;
			});
			if (!newSport) {
				setSelectedSports((prevState) => [
					...(prevState || []),
					{ SportId: sport.Id, Sport: sport, ProfileId: profileId, ...schoolOptions }
				]);
			} else {
				setSelectedSports([...(filteredSports || []), { ...newSport, ...schoolOptions }]);
			}
		},
		[selectedSports]
	);
	const getOptionLabel = useCallback((option: Sport): string => option.Name, []);
	const getIsOptionDisabled = useCallback(
		(option: Sport): boolean =>
			!isTempId(option.Id) &&
			!!selectedSports?.some(
				(sportItem) =>
					sportItem.Sport?.Id === option?.Id &&
					(!schoolOptions ||
						(schoolOptions.AdditionalEducation && sportItem.AdditionalEducation) ||
						(schoolOptions.SportProgramm && sportItem.SportProgramm) ||
						// если вид спорта не помечем ни одним флажком, значит мы его отображаем в программ спортивной подготовки
						// и бдокируем для выбора при добавлении в эту секцию
						(schoolOptions.SportProgramm && !sportItem.SportProgramm && !sportItem.AdditionalEducation))
			),
		[selectedSports]
	);

	const visibleSports = useMemo(() => {
		if (!schoolOptions) {
			return selectedSports;
		}
		return selectedSports?.filter(
			(item) =>
				(schoolOptions.AdditionalEducation && item.AdditionalEducation) ||
				(schoolOptions.SportProgramm && (item.SportProgramm || !item.AdditionalEducation))
		);
	}, [selectedSports]);

	return (
		<>
			<SportModalContent
				hash={hash}
				isOrganization={isOrganization}
				onClose={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
				setSelectedDisciplines={changeDisciplines}
				updateSportData={updateSportData}
				{...modalState}
			/>
			<Grid item sm={6}>
				<h2>{title}</h2>
				<div className="sportslist">
					{_.orderBy(visibleSports, "Sport.Id")?.map((item) => (
						<Chip
							key={item.Id + (item.Sport?.Id || "")}
							label={item.OtherSport || item.Sport?.Name}
							onDelete={() => {
								let sportForDelete: DeepPartial<ProfileSport> | undefined;
								const filteredSports = selectedSports?.filter((sport) => {
									if (
										item.Id !== sport.Id ||
										item.Sport?.Id !== sport.Sport?.Id ||
										item.OtherSport !== sport.OtherSport
									) {
										return true;
									}
									if (schoolOptions?.AdditionalEducation && sport.SportProgramm) {
										// если есть в другом списке, просто удаляем из текущего
										sportForDelete = { ...item, AdditionalEducation: false };
										return false;
									}
									if (schoolOptions?.SportProgramm && sport.AdditionalEducation) {
										// если есть в другом списке, просто удаляем из текущего
										sportForDelete = { ...item, SportProgramm: false };
										return false;
									}
									return false;
								});
								if (filteredSports) {
									setSelectedSports(
										!sportForDelete ? filteredSports : [...filteredSports, sportForDelete]
									);
								}
							}}
							onClick={() =>
								setModalState({
									visible: true,
									sport: item,
									disciplines: selectedDisciplines?.filter(
										(disc) => disc?.Discipline?.SportId?.toString() === item.Sport?.Id
									)
								})
							}
							color="primary"
							variant="outlined"
						/>
					))}
				</div>
				<h2>Добавить вид спорта</h2>
				<Autocomplete
					loading={loading}
					fullWidth
					multiple
					options={data?.Sports || []}
					getOptionSelected={(option, value) => option.Id === value.Id}
					getOptionLabel={getOptionLabel}
					getOptionDisabled={getIsOptionDisabled}
					value={[]}
					disableCloseOnSelect={true}
					onChange={(_event, value) => onOptionChange(value)}
					loadingText="Загрузка..."
					noOptionsText="Нет элементов"
					renderInput={(params) => (
						<TextField
							{...params}
							label="Выберите"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</>
								)
							}}
						/>
					)}
				/>
			</Grid>
		</>
	);
};

export default React.memo(SportsEdit);
