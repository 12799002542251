import * as Types from "../types";

import gql from "graphql-tag";

export type StatisticsSportRanksSportFullFragment = { __typename?: "StatisticsSportRanksSport" } & Pick<
	Types.StatisticsSportRanksSport,
	"SportId" | "SportCode" | "SportName"
> & {
		InternationalSportsMaster: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMasterVeteran: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMaster: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		SportsMasterCandidate: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		FirstRank: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
		Other: { __typename?: "StatisticsSportRank" } & Pick<
			Types.StatisticsSportRank,
			"Total" | "Gained" | "Confirmed"
		>;
	};

export const StatisticsSportRanksSportFullFragmentDoc = gql`
	fragment StatisticsSportRanksSportFull on StatisticsSportRanksSport {
		InternationalSportsMaster {
			Total
			Gained
			Confirmed
		}
		SportsMasterVeteran {
			Total
			Gained
			Confirmed
		}
		SportsMaster {
			Total
			Gained
			Confirmed
		}
		SportsMasterCandidate {
			Total
			Gained
			Confirmed
		}
		FirstRank {
			Total
			Gained
			Confirmed
		}
		Other {
			Total
			Gained
			Confirmed
		}
		SportId
		SportCode
		SportName
	}
`;
