import React, { useState } from "react";
import Swiper, { ReactIdSwiperProps } from "react-id-swiper";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import "swiper/css/swiper.css";
import { ITheme } from "../constants";
import { AttachmentForGalleryFragment } from "../graphql/fragment/Attachment.generated";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */
interface IAttachmentData {
	Width?: number;
	Height?: number;
}
interface IAttachmentsProps {
	attachments: Array<AttachmentForGalleryFragment>;
	theme: ITheme;
}
const getIconClass = (filLink: string) => {
	const splitedName = filLink.split(".");
	const fileExtension = splitedName[splitedName.length - 1];

	switch (fileExtension) {
		case "doc":
		case "jpg":
		case "pdf":
		case "png":
		case "xls":
			return `icon--icon-${fileExtension}`;
		case "docx":
			return "icon--icon-doc";
		case "jpeg":
			return "icon--icon-jpg";
		case "xlsx":
			return "icon--icon-xls";
		default:
			return "icon--icon-file";
	}
};

const Attachments = (props: IAttachmentsProps) => {
	const [curAttachment, setCurAttachment] = useState<AttachmentForGalleryFragment>();

	const params: ReactIdSwiperProps = {
		effect: "coverflow",
		centeredSlides: true,
		slidesPerView: "auto",
		coverflowEffect: {
			rotate: 25,
			stretch: 0,
			depth: 100,
			modifier: 1,
			slideShadows: true
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			bulletActiveClass: `swiper-pagination-bullet-active ${props.theme.background}`
		}
	};

	const showModal = (attachment: AttachmentForGalleryFragment) => {
		setCurAttachment(attachment);
	};

	const mediaList: Array<React.ReactElement> = [];
	const downloadList: Array<React.ReactElement> = [];
	for (const attachment of props.attachments) {
		switch (attachment.Type) {
			case "IMAGE":
			case "VIDEO":
				mediaList.push(
					<div
						style={{ backgroundImage: `url(${attachment.PreviewLink})` }}
						key={attachment.Id}
						onClick={() => showModal(attachment)}
					/>
				);
				break;
			case "DOCUMENT":
			default:
				downloadList.push(
					<a
						href={attachment.FileLink}
						key={attachment.Id}
						className={`download-link ${props.theme.color}`}
						target="_blank"
					>
						<span>{attachment.Description}</span>
						<i className={`icon ${getIconClass(attachment.FileLink)}`} />
					</a>
				);
				break;
		}
	}
	return (
		<div className="attachments-container">
			{!!downloadList.length && (
				<>
					<h2>Дополнительные материалы:</h2>
					<div className="downloads-container">{downloadList}</div>
				</>
			)}
			{!!mediaList.length && (
				<>
					<h2>Галерея:</h2>
					<Swiper {...params}>{mediaList}</Swiper>
					<Modal
						isOpen={!!curAttachment}
						onRequestClose={() => setCurAttachment(undefined)}
						className="attachments-modal"
						overlayClassName="attachments-modal-overlay"
					>
						<div className="modal-content">
							<div className="attachments-modal-header">
								<p>{curAttachment?.Description || "Без названия"}</p>
								<i className="icon icon--event-canceled" onClick={() => setCurAttachment(undefined)} />
							</div>
							{curAttachment && curAttachment.Type === "IMAGE" && (
								<div className="modal-image-wrapper">
									<div
										style={{ backgroundImage: `url(${curAttachment.FileLink})` }}
										key={curAttachment.Id}
										className="modal-image"
									/>
								</div>
							)}
							{curAttachment && curAttachment.Type === "VIDEO" && (
								<div className="modal-player-wrapper">
									<ReactPlayer
										url={curAttachment.FileLink}
										key={curAttachment.Id}
										controls
										width="100%"
										height="100%"
										className="modal-player"
									/>
								</div>
							)}
						</div>
					</Modal>
				</>
			)}
		</div>
	);
};
export default Attachments;
